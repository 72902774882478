const actions = {
	LOAD_BOOKING_DATA: 'LOAD_BOOKING_DATA',
	LOAD_BOOKING_SUCCESS: 'LOAD_BOOKING_SUCCESS',
	LOAD_BOOKING_ERROR: 'LOAD_BOOKING_ERROR',

	loadBooking: () => ({
		type: actions.LOAD_BOOKING_DATA
	})
};
export default actions;
