import LabelWrapper from '@Z/components/atoms/LabelWrapper';
import React from 'react';

const RenderLabelSetting = ({ title, description }) => {
	return (
		<>
			{title && <LabelWrapper text={title} color='settings' tonality={0} fontSize={14} typeLabel='primary' />}
			{description && (
				<LabelWrapper
					text={description}
					color='settings'
					tonality={1}
					fontSize={12}
					typeLabel='prop'
					fontWeight={400}
				/>
			)}
		</>
	);
};

export default RenderLabelSetting;
