import styled from 'styled-components';
import { palette } from 'styled-theme';

export const AlertInfoMessage = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid ${palette('color', 10)} !important;
	border-radius: 4px;
	padding: 5px;
	background-color: #e6f7ff;
	margin: 10px 0px;

	.messageHeader {
		align-items: center;
		display: flex;
		color: ${palette('color', 10)};
		flex-direction: row;
	}
	.icon {
		margin: 0;
		padding: 0;
		width: 5%;
	}
	.message {
		width: 90%;
	}
	.cancelButton {
		width: 5%;
		background-color: transparent;
		border: none;
		cursor: pointer;
		color: ${palette('color', 10)};
		position: absolute;
		right: -2px;
		top: -5px;
		transition:
			background-color 0.3s,
			transform 0.3s;

		&:hover {
			background-color: transparent;
			color: ${palette('color', 10)};
			transform: scale(1.1);
		}
	}
`;

export const getStyleStatus = (option, loadingOptionID) => {
	const isOptionLoading = loadingOptionID === option.id;
	let border;

	if (option.member) {
		border = '1px solid #2AAECB'; // Blue
	} else if (option.invite === 'registered') {
		border = '1px solid #D9D9D9'; // Grey
	} else if (option.invite === 'inviting') {
		border = '1px solid #DC982E'; // Orange
	} else {
		border = '1px solid #389E0D'; // Green
	}

	return {
		backdropFilter: isOptionLoading ? 'blur(20px)' : '',
		border: border,
		borderRadius: '4px',
		transform: isOptionLoading ? 'scale(0.98)' : '',
		padding: isOptionLoading ? '10px 7px' : '10px 5px',
		transition: 'all 0.5s ease-out',
		marginBottom: 10
	};
};

export const EnrolPeopleModalWrapper = styled.div`
	.containerEmail {
		width: 100%;
		padding: 0 10px;
		h3 {
			margin-bottom: 10px;
		}
	}
	.containerInput {
		display: flex;
		align-items: center;
		width: 100%;
		div {
			flex: 1;
		}
		.input {
			display: flex;
			flex-direction: column;

			input {
				width: 99%;
				&:hover {
					border-color: ${palette('color', 10)} !important;
				}
			}
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid ${palette('border', 0)};
			padding: 6.5px 10px;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				border-color: ${palette('color', 10)} !important;
			}
			svg {
				font-size: 20px !important;
				border-color: ${palette('color', 1)} !important;
			}
		}
	}
	.containerList {
		display: flex;
		align-items: center !important;
		justify-content: space-between;
		margin: 10px 0;
		h3 {
			margin-bottom: 0;
		}
		button {
			display: flex;
			align-items: center;
			border: 0 !important;
			margin: 0 !important;
			padding: 0 !important;
			text-decoration: underline;

			i {
				font-size: 16px;
				margin-left: 5px;
			}
			&:hover {
				i {
					color: ${palette('primary', 0)};
				}
			}
		}
	}

	.containerEmails {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 2px;
		/* border: 1px solid ${palette('border', 0)}; */
		border-radius: 4px;
		/* padding: 8px; */
		margin-bottom: 5px;

		> p {
			font-size: 12px;
			color: ${palette('text', 2)};
			border: 1px solid ${palette('border', 0)};
			border-radius: 4px;
			width: 100%;
			margin-right: 10px;
			padding: 8px 10px;
			cursor: pointer;
		}
		.tag {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			padding: 5px 10px;
			i {
				margin-right: 10px;
			}
		}
	}

	.buttonInvited {
		display: flex;
		align-items: center;
		button:first-child {
			margin-right: 5px;
		}
		button:last-child {
			&:hover {
				border-color: ${palette('color', 2)} !important;
				color: ${palette('color', 2)} !important;
			}
		}
	}

	.containerEmails.invited {
		padding: 4px;

		transform: scale(1);
		transition: all 0.1s ease-out;
		border-color: ${palette('color', 9)};
		.tag {
			border: 1px solid ${palette('color', 9)};
			background-color: ${palette('tagBackgroundCustomColor', 2)};
		}
		i {
			color: ${palette('color', 9)};
		}
	}
	.containerEmails.inviting {
		transform: scale(1.02);
		transition: all 0.1s ease-out;
		border: 1px solid ${palette('color', 1)};
		padding: 4px;
		.tag {
			border: 1px solid ${palette('color', 1)};
			background-color: ${palette('tagBackgroundCustomColor', 1)};
		}
		i {
			color: ${palette('color', 1)};
		}
	}
`;
