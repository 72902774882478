import { Tag } from '@iso/components/uielements';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const TagWrapper = styled(Tag)`
	border-color: ${palette('secondary', 0)} !important;
	color: ${palette('primary', 0)} !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border-radius: 4px !important;
	margin: 2px !important;

	.tagIcons {
		opacity: 0.85;
	}
`;

const SelectDropDownWrapper = styled.div`
	position: relative;

	& > div {
		position: relative !important;
	}
`;
export { TagWrapper, SelectDropDownWrapper };
