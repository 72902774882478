import React, { useState } from 'react';
import { Dropdown, DropdownMenu, MenuItem } from '@iso/components/uielements';
import { SpaceWrapper } from './styles';
import {
	EditAssetGroupModal,
	DeleteAssetGroupModal,
	ManageAssetsInGroupModal,
	IndividualAssetGroupModal
} from '@Z/components/molecules';

const RenderActions = ({ group, children, hideView }) => {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [manageModalVisible, setManageModalVisible] = useState(false);
	const [viewModalVisible, setViewModalVisible] = useState(false);

	const actionsMenu = (actionsList) => (
		<DropdownMenu>
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const actions = [
		!hideView && (
			<IndividualAssetGroupModal
				data={{ groupID: group.groupID }}
				visible={viewModalVisible}
				setVisible={setViewModalVisible}
			/>
		),
		<ManageAssetsInGroupModal data={group} visible={manageModalVisible} setVisible={setManageModalVisible} />,
		<EditAssetGroupModal data={group} visible={editModalVisible} setVisible={setEditModalVisible} />,
		<DeleteAssetGroupModal data={group} visible={deleteModalVisible} setVisible={setDeleteModalVisible} />
	];

	return (
		<Dropdown overlay={() => actionsMenu(actions)} trigger={['click']}>
			{children ? (
				children
			) : (
				<SpaceWrapper>
					Actions <i className='material-icons-outlined'>expand_more</i>
				</SpaceWrapper>
			)}
		</Dropdown>
	);
};

export default RenderActions;
