import styled from 'styled-components';
import { palette } from 'styled-theme';

const CellWrapper = styled.div`
	padding: 5px 12px;
	display: flex;
	align-items: center;
	height: 32px;
	cursor: pointer;

	:hover {
		padding: 4px 11px;
		border: 1px solid ${palette('border', 0)};
		border-radius: 2px;
	}
`;

export { CellWrapper };
