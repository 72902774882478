import React, { useState, useEffect } from 'react';
import Card from '@iso/components/uielements/card';
import DeviceCardWrapper from './SLCardWrapper.styles';
import { useSelector } from 'react-redux';
import { mYTimeOptions, boolType } from '@iso/assets/styles/constants';
import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import { copyToClipBoard } from '@iso/lib/helpers/utility';
import { RenderDeviceActions } from '@Z/components/atoms';

import Icon from '@ant-design/icons';

const { Meta } = Card;
const SmartLockDeviceCard = ({ device }) => {
	const { floorsData } = useSelector((state) => state.floors);

	const [deviceModalVisible, setDeviceModalVisible] = useState(false);
	const [floors, setFloors] = React.useState([]);

	// Find all the floors device belongs to.
	useEffect(() => {
		if (floorsData.length > 0) {
			if (device.location && device.location.length > 0) {
				// find floors that is includes in the device location array of object
				let floorsArray = floorsData.filter((floor) =>
					device.location.find((location) => location.floorID === floor.floorID)
				);
				setFloors(floorsArray);
			}
		}
	}, [floorsData, device]);

	return (
		<DeviceCardWrapper>
			<Card
				className='hoverCard customCard'
				size='small'
				title={
					<IndividualDeviceInformationModal
						visible={deviceModalVisible}
						setVisible={setDeviceModalVisible}
						data={{
							deviceID: device.uID,
							categoryID: device.categoryID
						}}
					>
						<p className='wordFix'>
							<span className='spanFix' onClick={() => setDeviceModalVisible(true)}>
								{device.name}
							</span>
						</p>
					</IndividualDeviceInformationModal>
				}
				bordered
				hoverable
				extra={
					<div>
						<Icon
							component={() => <img src={device.icon} width={24} height={24} alt='Icon' />}
							className='customIcon'
						/>
					</div>
				}
				actions={[RenderDeviceActions({ device: device, onIndividualDeviceModal: false })]}
			>
				<Meta
					description={
						<div>
							<div className='stat' key={1}>
								<p>
									UID: <span style={{ fontWeight: 600 }}>{device.uID}</span>
									<span className='imageIcon'>
										<i
											className='material-icons-outlined imageIcon'
											style={{
												cursor: 'pointer'
											}}
											onClick={() => copyToClipBoard(device.uID)}
										>
											copy_content
										</i>
									</span>
								</p>
							</div>
							<div className='stat' style={{ paddingTop: '5px' }} key={2}>
								{/* Rendering Non-CustomFields as a field on a card. */}
								{device?.showCustomFieldOnCard?.map((field, index) => {
									if (field === 'Floor') {
										return (
											<div key={index}>
												<p className='infoTitles'>{field}</p>
												<p className='infoValues'>
													{floors.length > 0
														? floors.map((floor, key) => (
																<span key={floor.floorID}>
																	{floor.name}
																	{key < floors.length - 1 ? ', ' : null}
																</span>
														  ))
														: 'N/A'}
												</p>
											</div>
										);
									}
									return null;
								})}
								{/* Rendering CustomFields as a field on a card. */}
								{device.showCustomFieldOnCard &&
									device?.fields?.length > 0 &&
									device.fields
										.filter((value) => device.showCustomFieldOnCard.includes(value.title))
										.map((fields, index) => (
											<div key={index}>
												{fields.fieldTypeID === 1 ? (
													<div>
														<p className='infoTitles'>{fields.title}</p>
														<p className='infoValues'>
															{fields.value !== 'null' ? fields.value : 'N/A'}
														</p>
													</div>
												) : fields.fieldTypeID === 2 ? (
													<div>
														<p className='infoTitles'>{fields.title}</p>
														<p className='infoValues'>
															{fields.value !== 'null' ? fields.value : 'N/A'}
														</p>
													</div>
												) : fields.fieldTypeID === 3 ? (
													<div>
														<p className='infoTitles'>{fields.title}</p>
														<p className='infoValues'>
															{new Date(fields.value).toLocaleString(
																'en-AU',
																mYTimeOptions
															)}
														</p>
													</div>
												) : fields.fieldTypeID === 4 ? (
													<div>
														<p className='infoTitles'>{fields.title}</p>
														<p className='infoValues'>
															{fields.value !== 'null' ? boolType[fields.value] : 'N/A'}
														</p>
													</div>
												) : (
													<div>
														<p className='infoTitles'>{fields.title}</p>
														<p className='infoValues'>
															{fields.value !== 'null' ? fields.value : 'N/A'}
														</p>
													</div>
												)}
											</div>
										))}
							</div>
						</div>
					}
				/>
			</Card>
		</DeviceCardWrapper>
	);
};

export default SmartLockDeviceCard;
