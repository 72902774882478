import React, { Suspense, lazy } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '@iso/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import { ERROR_PAGES, PUBLIC_ROUTES } from './routes';

const Dashboard = lazy(() => import('../containers/Dashboard/Dashboard'));

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? <Component /> : <Redirect to={{ pathname: '/signin', state: { from: location } }} />
			}
		/>
	);
}

const Navigation = () => {
	const isAuthenticated = useSelector((state) => state.Auth.idToken);

	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Router>
					<Switch>
						{PUBLIC_ROUTES.map((route, index) => (
							<Route exact path={route.path} key={index} component={route.component} />
						))}
						<PrivateRoute path='/dashboard' component={Dashboard} isAuthenticated={isAuthenticated} />
						<Route component={ERROR_PAGES[0].component} />
					</Switch>
				</Router>
			</Suspense>
		</ErrorBoundary>
	);
};
export default Navigation;
