import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useGetSiteName = (siteID) => {
	const { sitesData } = useSelector((state) => state.sites);
	return sitesData.find((site) => site.siteID === siteID)?.name ?? 'Site Name Not Found';
};

/**
 * Custom hook that retrieves the display name and title of a person based on their ID.
 *
 * @param {number | number[]} personID - The ID or array of IDs of the person(s) to retrieve information for.
 * @returns {Object | Object[]} - An object or array of objects containing the display name and title of the person(s).
 */
export const useGetPersonDisplayName = (personID) => {
	const { peopleData, pending } = useSelector((state) => state.people);

	return useMemo(() => {
		const getDisplayName = (person) => {
			// Get the display name of the person
			const displayName = person?.displayName || (person?.isNewUser ? person?.email : 'person name missing. ');

			// Get the title of the person
			const title = person?.isNewUser
				? "Person hasn't hopped on Zezamii yet, but their name will pop up once they accept your invite!"
				: person?.email;

			return { displayName, title };
		};
		if (pending) return { displayName: 'Loading...', title: 'Loading...', loading: true };
		if (personID === true) {
			return peopleData.reduce((acc, person) => {
				acc[person.personID] = getDisplayName(person);
				return acc;
			}, {});
		} else if (Array.isArray(personID)) {
			// If personID is an array, iterate over each ID
			return personID.reduce((acc, id) => {
				// Find the person object with matching ID
				const person = peopleData.find((p) => p.personID === id);
				if (person) {
					// If person is found, call getDisplayName function to get display name and title
					acc[id] = getDisplayName(person);
				}
				return acc;
			}, {});
		} else {
			// If personID is not an array, find the person object with matching ID
			const person = peopleData.find((person) => person.personID === personID);
			// Call getDisplayName function to get display name and title
			return getDisplayName(person);
		}
	}, [personID, peopleData, pending]);
};
