import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import React from 'react';
import actions from './actions';
import { Button } from 'antd';
import { getCurrentUser } from '@iso/lib/firebase/firebase.authentication.util';

export function* loadOrganisationData() {
	yield takeEvery('LOAD_ORGANISATION_DATA', function* () {
		const user = yield call(getCurrentUser);
		const authUserUID = user && user.uid ? user.uid : '';
		const data = yield superFetch.get('/organisations/orgByPerson', {}, { fbPersonID: authUserUID }).then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.map((row) => ({
						abn: row.abn ? row.abn : 'N/A',
						acn: row.acn ? row.acn : 'N/A',
						address: row.address,
						adminPerson: row.adminPerson,
						alias: row.alias,
						contact: row.contact,
						createdDate: row.createdDate,
						modifiedDate: row.modifiedDate,
						name: row.name,
						orgID: row.orgID,
						parentOrgID: row.parentOrgID ? row.parentOrgID : 'N/A'
					}));
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		// Sorting the array based on firebase
		const sortedData = data.data.slice().sort((a, b) => a.orgID - b.orgID);

		if (data.data) {
			yield put({
				type: actions.LOAD_ORGANISATION_SUCCESS,
				organisationData: sortedData
			});
		} else {
			yield put({
				type: actions.LOAD_ORGANISATION_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadOrganisationData)]);
}
