import { RenderStatusTag } from '@Z/components/atoms';
import theme from '@iso/config/theme/default';
import { useSelector } from 'react-redux';

export const useGetSiteName = (siteID) => {
	const { sitesData } = useSelector((state) => state.sites);

	const getSiteName = (siteID) => {
		return sitesData.find((site) => site.siteID === siteID)?.name ?? 'Site Name Not Found';
	};

	return getSiteName(siteID);
};

export const getUserStatus = ({ deletedDate, isActive, isNewUser }) => {
	const getUserStatus = (deletedDate, isActive, isNewUser) => {
		switch (true) {
			case !!deletedDate:
				return [RenderStatusTag('Deleted', 'red'), theme.palette.tagCustomColor[0], 'error'];
			case isActive === false:
				return [RenderStatusTag('Disabled', 'gold'), theme.palette.tagCustomColor[3], 'disabled'];
			case !!isNewUser:
				return [RenderStatusTag('Enrolled', 'blue'), theme.palette.tagCustomColor[1], 'info'];
			default:
				return [RenderStatusTag('Active', 'green'), theme.palette.tagCustomColor[2], 'success'];
		}
	};

	return getUserStatus(deletedDate, isActive, isNewUser);
};
