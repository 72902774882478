import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from '@iso/components/uielements';

const AlertMessage = ({ icon, message, items, toggleDisplay }) => {
	return (
		<>
			<p className='messageHeader'>
				<span className='icon'>
					<i className='material-icons-outlined'>{icon}</i>
				</span>
				<span className='message'>{message}</span>
				<Button className='cancelButton' onClick={toggleDisplay} icon={<CloseCircleOutlined />} />
			</p>
			{items && items.length > 0 && (
				<p className='itemList'>
					{items.map((item, index) => (
						<span className='item' key={index}>
							{item}
						</span>
					))}
				</p>
			)}
		</>
	);
};

export default AlertMessage;
