import { accessControlPermissionType } from './accessControlPermissionType';
import { bookingStatusType } from './bookingStatusType';
import { bookingType } from './bookingType';
import { categoryType } from './categoryType';
import { constants } from './constants';
import { defaultCategory } from './defaultCategory';
import { defaultDeviceHeaders } from './defaultDeviceHeaders';
import { entityInfo } from './entityInfo';
import { managePermittedRolesConfig } from './managePermittedRoles.config';
import { rolesConfig } from './rolesConfig';
import { sampleDevicesConfig } from './sampleDevicesConfig';
import { searchAbleField } from './searchAbleField';

const remoteConfig = {
	// todo: All the default values needs to be added here.
	accessControlPermissionType,
	bookingStatusType,
	bookingType,
	categoryType,
	constants,
	defaultCategory,
	defaultDeviceHeaders,
	entityInfo,
	managePermittedRolesConfig,
	rolesConfig,
	sampleDevicesConfig,
	searchAbleField
};
export default remoteConfig;
