import {
	ManagePermissionModal,
	ManageTeamMemberModal,
	TeamsTable as PersonTeamsTable,
	TeamMembersTable
} from '@Z/components/molecules';
import Tabs from '@iso/components/uielements/tabs';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import useModuleSettings from '@iso/lib/hooks/useModuleSettings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ManagePeopleMemberModal from '../ManagePeopleMemberModal';
import PersonTeamAccessControlTable from '../PersonTeamAccessControlTable';

const DEFAULT_TAB = 'members';
const TeamSecondaryTabs = ({ entityType, entityObj }) => {
	const enabledAccessModule = useModuleSettings(CONSTANTS_SETTINGS.ACCESS_MODULE);

	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const [tabKey, setTabKey] = useState(DEFAULT_TAB);
	const [openModal, setOpenModal] = useState(false);
	const [managePermissionModal, setManagePermissionModal] = useState(false);

	const tabs = [
		{
			label: 'Team Members',
			key: 'members',
			children:
				entityType === constants.ENTITY_TYPE_ID_PERSON ? (
					<PersonTeamsTable teamsByPerson={entityObj.team} />
				) : entityType === constants.ENTITY_TYPE_ID_TEAM ? (
					<TeamMembersTable teamID={entityObj.teamID} />
				) : null
		},
		...(enabledAccessModule
			? [
					{
						label: 'Access',
						key: 'access',
						children: (
							<PersonTeamAccessControlTable
								entityType={entityType}
								entityID={
									entityType === constants.ENTITY_TYPE_ID_PERSON
										? entityObj.personID
										: entityType === constants.ENTITY_TYPE_ID_TEAM
										? entityObj.teamID
										: null
								}
							/>
						)
					}
			  ]
			: [])
	];

	const actions = {
		members:
			entityType === constants.ENTITY_TYPE_ID_PERSON ? (
				<ManagePeopleMemberModal open={openModal} setOpen={setOpenModal} personData={entityObj} />
			) : entityType === constants.ENTITY_TYPE_ID_TEAM ? (
				<ManageTeamMemberModal open={openModal} setOpen={setOpenModal} teamID={entityObj.teamID} />
			) : null,
		access: (
			<ManagePermissionModal
				visible={managePermissionModal}
				setVisible={setManagePermissionModal}
				entityID={entityType === constants.ENTITY_TYPE_ID_TEAM ? entityObj.teamID : entityObj.personID}
				entityType={entityType}
			/>
		)
	};

	return (
		<IsoWidgetBox>
			<Tabs
				items={tabs}
				size='small'
				defaultActiveKey={DEFAULT_TAB}
				type='card'
				animated={{ inkBar: true, tabPane: true }}
				tabBarExtraContent={actions[tabKey]}
				onChange={(activeKey) => setTabKey(activeKey)}
			/>
		</IsoWidgetBox>
	);
};

export default TeamSecondaryTabs;
