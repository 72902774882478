import { all, takeEvery, put, fork } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';
import actions from './actions';

export function* loadBookingData() {
	yield takeEvery('LOAD_BOOKING_DATA', function* () {
		const data = yield superFetch.get('/booking').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data.map((row) => ({
						bookingID: row.bookingID,
						name: row.name,
						description: row.description ? row.description : null,
						bookingTypeID: row.bookingTypeID,
						assignedToPerson: row.assignedToPerson,
						deviceID: row.deviceID,
						deletedDate: row.deletedDate,
						orgID: row.orgID,
						startTime: row.startTime,
						endTime: row.endTime,
						timeZone: row.timeZone,
						createdBy: row.createdBy,
						createdDate: row.createdDate,
						modifiedBy: row.modifiedBy,
						modifiedDate: row.modifiedDate,
						bookingType: row.bookingType,
						person: row.person,
						device: row.device,
						assignedToTeam: row.assignedToTeam,
						recurringFrequency: row.recurringFrequency,
						recurringInterval: row.recurringInterval,
						recurringEndDate: row.recurringEndDate,
						team: row.team
					}));
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);
				result['error'] = res.error;
				return result;
			}
		});

		if (data.data) {
			yield put({
				type: actions.LOAD_BOOKING_SUCCESS,
				bookingData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_BOOKING_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadBookingData)]);
}
