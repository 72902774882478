import {
	InventoryControlGatewaysTable,
	InventoryControlLocksTable,
	InventoryGatewaysTable,
	InventoryLocksTable,
	InventoryOrganisationTable
} from '@Z/components/molecules';
import { notification } from '@iso/components';
import Tabs, { TabPane } from '@iso/components/uielements/tabs';
import superFetch from '@iso/lib/helpers/superFetch';
import React, { useEffect, useState } from 'react';
import { InventoryLocksTabWrapper } from './InventoryLocksTabs.style';

const InventoryLocksTabs = () => {
	const [workspaces, setWorkspaces] = useState([]);
	useEffect(() => {
		superFetch
			.get('/organisations/AllOrgs')
			.then((res) => {
				if (res.statusCode === 200) {
					setWorkspaces(res.data);
				} else {
					notification('error', 'Error fetching workspace data. Please try again');
				}
			})
			.catch((e) => e);
	}, []);

	return (
		<InventoryLocksTabWrapper>
			<Tabs size='small' defaultActiveKey='1' type='card' animated={{ inkBar: true, tabPane: true }}>
				<TabPane tab='Locks' key='1'>
					<InventoryLocksTable workspaces={workspaces} />
				</TabPane>
				<TabPane tab='Gateways' key='2'>
					<InventoryGatewaysTable workspaces={workspaces} />
				</TabPane>
				<TabPane tab='Control Locks' key='3'>
					<InventoryControlLocksTable workspaces={workspaces} />
				</TabPane>
				<TabPane tab='Control Gateways' key='4'>
					<InventoryControlGatewaysTable workspaces={workspaces} />
				</TabPane>
				<TabPane tab='Organisation' key='5'>
					<InventoryOrganisationTable workspaces={workspaces} />
				</TabPane>
			</Tabs>
		</InventoryLocksTabWrapper>
	);
};

export default InventoryLocksTabs;
