const actions = {
	LOAD_SITES_DATA: 'LOAD_SITES_DATA',
	LOAD_SITES_SUCCESS: 'LOAD_SITES_SUCCESS',
	LOAD_SITES_ERROR: 'LOAD_SITES_ERROR',

	loadSites: () => ({
		type: actions.LOAD_SITES_DATA
	})
};
export default actions;
