import styled from 'styled-components';

const DeviceCategoriesHeaderWrapper = styled.div`
	.btnRow {
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
	.btnButton {
		margin-left: 10px;
	}
`;

export default DeviceCategoriesHeaderWrapper;
