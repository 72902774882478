import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import * as jwtDecode from 'jwt-decode';

import { notification } from '@iso/components';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import { db } from '@iso/lib/firebase/firebase';
import { getCurrentUser } from '@iso/lib/firebase/firebase.authentication.util';

import actions from './actions';
import SuperFetch from '@iso/lib/helpers/superFetch';

const history = createBrowserHistory();

export function* loginRequest() {
	yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
		const { token } = payload;
		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token: token
			});
		} else {
			notification('error', 'There is an Error! Please try again.');
			yield put({ type: actions.LOGIN_ERROR });
		}
	});
}

export function* dispatchUser() {
	yield takeEvery('DISPATCH_USER', function* () {
		const currentUser = yield call(getCurrentUser);

		const userSnapshot = yield db
			.collection('users')
			.doc(currentUser.uid)
			.get()
			.catch((err) => {
				throw err;
			});
		const permissionSnapshot = yield db
			.collection('users')
			.doc(currentUser.uid)
			.collection('permission')
			.get()
			.then((querySnapshot) => {
				return querySnapshot;
			})
			.catch((err) => {
				throw err;
			});

		// Extract Permissions of the User
		let userPermissions = [];
		permissionSnapshot.forEach((org) => {
			userPermissions.push(org.data());
		});

		const od = parseInt(localStorage.getItem('od'), 10);
		const workSpace = userPermissions.find((org) => org.orgID === od) ?? userPermissions[0];

		// Get a person to check if is a new User
		const person = yield SuperFetch.get(`/people/${workSpace.personID}`, {}, {}, workSpace.orgID);

		if (userPermissions.length > 0) {
			let userInfo = {
				...userSnapshot.data(),
				defaultProfile: {
					...person.data,
					roles: workSpace.roles,
					getDisplayName: function () {
						const displayName =
							person.data.displayName ||
							(person.data.isNewUser ? person.data.email : 'person name missing.');
						const title = person.data.isNewUser
							? "Person hasn't hopped on Zezamii yet, but their name will pop up once they accept your invite!"
							: person.data.email;
						return { displayName, title };
					}
				},
				userPermissions: userPermissions
			};

			yield put({
				type: actions.USER_DISPATCHED,
				profile: userInfo
			});
		} else {
			notification('error', 'There is an error fetching User Info. We are working on it!.');
			yield put({ type: actions.USER_DISPATCHED_ERROR });
			yield put({ type: actions.LOGOUT });
		}
	});
}

export function* switchPerson() {
	yield takeEvery('SWITCH_PERSON', function* ({ payload }) {
		const { orgID } = payload;
		const currentUser = yield call(getCurrentUser);

		const personPermission = yield db
			.collection('users')
			.doc(currentUser.uid)
			.collection('permission')
			.doc(orgID.toString())
			.get()
			.catch((err) => {
				throw err;
			});

		const personProfile = personPermission.data();

		// Get a person to check if is a new User
		const person = yield SuperFetch.get(`/people/${personProfile.personID}`, {}, {}, orgID);

		if (person) {
			yield put({
				type: actions.SWITCH_PERSON_SUCCESS,
				newProfile: {
					...person.data,
					roles: personProfile.roles,
					getDisplayName: function () {
						const displayName =
							person.data.displayName ||
							(person.data.isNewUser ? person.data.email : 'person name missing.');
						const title = person.data.isNewUser
							? "Person hasn't hopped on Zezamii yet, but their name will pop up once they accept your invite!"
							: person.data.email;
						return { displayName, title };
					}
				}
			});
		} else {
			notification('error', 'There is an error switching your profile. We are working on it!.');
			yield put({ type: actions.SWITCH_PERSON_ERROR });
		}
	});
}

export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
		yield localStorage.setItem('id_token', payload.token);
	});
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function* () {
		yield clearToken();
		history.push('/');
	});
}
export function* checkAuthorization() {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
		const token = getToken().get('idToken');
		const loggedIn = checkExpirity(token);
		if (token && loggedIn === 'loggedIn') {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token
			});
			yield put({
				type: actions.DISPATCH_USER
			});
		}
	});
}
export const checkExpirity = (token) => {
	if (!token) {
		return {
			error: 'not matched'
		};
	}
	try {
		const profile = jwtDecode(token);

		const expiredAt = profile.expiredAt || profile.exp * 1000;

		if (expiredAt > new Date().getTime()) {
			return 'loggedIn';
		} else {
			return { error: 'Token expired' };
		}
	} catch (e) {
		return { error: 'Server Error' };
	}
};

export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logout),
		fork(dispatchUser),
		fork(switchPerson)
	]);
}
