import React from 'react';
import { Col, Row } from 'antd';
import { RenderLabelSetting } from '..';
import { Button } from '@iso/components/uielements';

const RenderHeaderSetting = ({
	title,
	description,
	showDiscardButton,
	showSaveButton,
	onClickDiscard,
	onClickSave,
	disableSaveButton,
	loadingSaveButton,
	form
}) => {
	return (
		<Row className='containerHeader'>
			<Col span={16} className='containerLabel'>
				<RenderLabelSetting title={title} description={description} />
			</Col>
			<Col span={8} className='containerButtons'>
				{showDiscardButton && (
					<Button
						onClick={() => onClickDiscard()}
						style={{
							marginRight: '5px'
						}}
						type='secondary'
						size='medium'
					>
						Cancel
					</Button>
				)}
				{showSaveButton && (
					<Button
						title={'Save Changes'}
						disabled={disableSaveButton}
						loading={loadingSaveButton}
						onClick={() => onClickSave()}
						form={form}
						type='primary'
						size='medium'
					>
						Save Changes
					</Button>
				)}
			</Col>
		</Row>
	);
};

export default RenderHeaderSetting;
