import { Modal } from '@Z/components/atoms';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import deviceCategoryAction from '@iso/redux/deviceCategories/actions';
import siteAction from '@iso/redux/sites/actions';
import { Col, Divider, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCategoryFormWrapper, { MultiSelect } from './AddDeviceCategoryButton.style';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
const { loadDeviceCategories } = deviceCategoryAction;
const { loadSites } = siteAction;

const AddDeviceCategoryButton = () => {
	const dispatch = useDispatch();
	const [viewModal, setViewModal] = React.useState(false);
	const [confirmLoading, setConfirmLoading] = React.useState(false);
	const { sitesData, pending: sitePending } = useSelector((state) => state.sites);
	const { categoryTypes, fieldTypes } = useSelector((state) => state.types);

	const {
		remoteConfig: { categoryType }
	} = useSelector((state) => state.remoteConfig);

	const { rowStyle } = basicStyle;
	const [form] = Form.useForm();

	React.useEffect(() => {
		if (sitesData.length < 1 && sitePending) {
			dispatch(loadSites());
		}
	}, [sitePending, sitesData, dispatch]);

	const handleCancel = () => {
		setViewModal(false);
		form.resetFields();
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		const formData = removeEmptyOrNull(form.getFieldsValue(true));
		if (formData.isBookable) {
			formData.isBookable = true;
		} else {
			formData.isBookable = false;
		}

		const createCategoryBody = {
			name: formData.name,
			description: formData.description,
			isBookable: formData.typeID === categoryType.BOOKABLE_RESOURCES,
			isDefault: false,
			typeID: formData.typeID
		};

		const createdCategoryID = await superFetch.post('/deviceCategories', createCategoryBody).then((res) => {
			if (res.statusCode === 201) {
				notification('success', 'New Category Created.');
				return res.data.categoryID;
			} else if (res.error.statusCode === 400) {
				notification('error', 'Please fill out all required information.');
			} else {
				notification('error', 'There is an Error! We are mending the problem, try again soon!.');
			}
		});
		if (formData.siteID && formData.siteID.length > 0) {
			await superFetch
				.patch(`/deviceCategories/${createdCategoryID}/associations`, {
					siteIDs: formData.siteID
				})
				.then((res) => {
					if (!res.statusCode === 200) {
						notification('error', 'There is an Error! We are mending the problem, try again soon!.');
						return false;
					}
				});
		}

		if (createdCategoryID && formData.fields && formData.fields.length > 0) {
			const createFieldBody = { fields: formData.fields };
			await superFetch.post(`/deviceCategories/${createdCategoryID}/fields`, createFieldBody).then((res) => {
				if (res.statusCode === 201) {
					notification('success', 'Fields Added.');
					handleCancel();
				} else if (res.error.statusCode === 400) {
					notification('error', 'Please fill out all required information.');
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
				setConfirmLoading(false);
			});
		} else {
			handleCancel();
			setConfirmLoading(false);
		}
		dispatch(loadDeviceCategories());
	};

	return (
		<div>
			<Button type='primary' onClick={() => setViewModal(true)}>
				Add New Category
			</Button>
			<Modal
				title='Add New Category'
				visible={viewModal}
				onCancel={handleCancel}
				width={650}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button loading={confirmLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Add Category
					</Button>
				]}
			>
				<AddCategoryFormWrapper>
					<Divider className='title subtitle' orientation='left'>
						Category Information
					</Divider>
					<Form
						form={form}
						id={form}
						onFinish={handleSubmit}
						layout='vertical'
						initialValues={{
							typeID: categoryTypes[0] ? categoryTypes[0].typeID : null
						}}
					>
						<Row style={{ rowStyle }}>
							<Form.Item name='name' label='Name' rules={fieldsValidator.Name} style={{ width: '100%' }}>
								<Input />
							</Form.Item>
						</Row>
						<Row style={{ rowStyle, justifyContent: 'space-between ' }}>
							<Col span={10}>
								<Form.Item
									name='typeID'
									label='Type'
									rules={fieldsValidator.Required}
									style={{ width: '100%' }}
								>
									<Select placeholder={'Select Type'}>
										{categoryTypes &&
											categoryTypes.map((type) => (
												<Option key={type.typeID} value={type.typeID}>
													{type.name}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>

							<Col span={10}>
								<Form.Item name='siteID' label='Site' style={{ width: '100%' }}>
									<MultiSelect
										mode='multiple'
										placeholder={'Select Site'}
										style={{ fontSize: '14px' }}
									>
										{sitesData.map((site) => (
											<Option key={site.siteID} value={site.siteID}>
												{site.name}
											</Option>
										))}
									</MultiSelect>
								</Form.Item>
							</Col>
						</Row>

						<Row style={{ rowStyle }}>
							<Form.Item
								name='description'
								label='Description'
								rules={fieldsValidator.Description}
								style={{ width: '100%' }}
							>
								<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
							</Form.Item>
						</Row>

						<Divider className='title subtitle' orientation='left'>
							Category Custom Fields
						</Divider>

						<Form.List name='fields'>
							{(fields, { add, remove }) => (
								<div>
									{fields.map(({ key, name, ...restField }) => (
										<Row
											span={24}
											key={key}
											style={{ display: 'flex', marginBottom: 15 }}
											gutter={10}
										>
											<Col span={16}>
												<Form.Item
													{...restField}
													name={[name, 'title']}
													rules={fieldsValidator.Name}
													style={{ marginBottom: 0 }}
												>
													<Input placeholder='Field Name' />
												</Form.Item>
											</Col>

											<Col span={6}>
												<Form.Item
													{...restField}
													name={[name, 'fieldTypeID']}
													rules={fieldsValidator.Required}
													style={{ marginBottom: 0 }}
												>
													<Select placeholder={'Type'}>
														{fieldTypes.map((type) => (
															<Option key={type.fieldTypeID} value={type.fieldTypeID}>
																{type.name}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col style={{ alignItems: 'center' }}>
												<Form.Item {...restField} style={{ marginBottom: 0 }}>
													<MinusCircleOutlined onClick={() => remove(name)} />
												</Form.Item>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button
											type='dashed'
											onClick={() =>
												add({
													fieldTypeID: fieldTypes[0] ? fieldTypes[0].fieldTypeID : null
												})
											}
											block
											icon={<PlusOutlined />}
										>
											Add Custom Field
										</Button>
									</Form.Item>
								</div>
							)}
						</Form.List>

						<Row>
							<Col span={12}>
								{/*	
							<Form.Item
									labelCol={{ span: 10 }}
									wrapperCol={{ span: 12 }}
									label='Bookable'
									name='isBookable'
									style={colStyle}
									rules={[ { required: true } ]}
								>
									<RadioGroup buttonStyle='outline'>
										<RadioButton value={1}>Yes</RadioButton>
										<RadioButton value={0}>No</RadioButton>
									</RadioGroup>
								</Form.Item>
								*/}
							</Col>
						</Row>
					</Form>
				</AddCategoryFormWrapper>
			</Modal>
		</div>
	);
};

export default AddDeviceCategoryButton;
