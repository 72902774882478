export const defaultDeviceHeaders = [
	{ label: 'Device UID', key: 'uID' },
	{ label: 'Device Name', key: 'name' },
	{ label: 'Bookable', key: 'isBookable' },
	{ label: 'Type Name', key: 'typeName' },
	{ label: 'Category ID', key: 'categoryID' },
	{ label: 'Category Name', key: 'categoryName' },
	{ label: 'Category Description', key: 'categoryDescription' },
	{ label: 'Site', key: 'site' },
	{ label: 'Floor', key: 'floor' },
	{ label: 'Created On', key: 'createdDate' },
	{ label: 'Updated On', key: 'modifiedDate' },
	{ label: 'Deleted On', key: 'deletedDate' }
];
