import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const TeamCardWrapper = styled.div`
	height: 100%;
	width: 100%;

	.ant-card-bordered {
		border-color: ${palette('grayscale', 2)};
		height: 100%;

		${borderRadius()};
	}

	.hoverCard:hover {
		border-color: ${palette('secondary', 0)};
	}

	.title {
		font-size: 18px;
		color: ${palette('secondary', 0)};
	}
	.rowDivStyle {
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
		gap: 10px;
		width: 100%;
	}

	.divInfoStyle {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}

	.ant-card-head {
		border-bottom-color: ${palette('grayscale', 3)};

		.ant-card-head-title {
			font-size: 18px;
			color: ${palette('secondary', 0)};
		}
		.ant-card-extra > span {
			font-size: 18px;
			padding: 10px;
			margin-right: -15px;
		}
	}
	.ant-card-body {
		padding: 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.imageContainer {
		min-width: ${(props) => `${props.profileImageComponentSize}px`};
		align-content: center;
		display: flex;
		justify-content: flex-start;

		@media only screen and (max-width: 992px) {
			justify-content: center;
		}
	}
	.personTitle {
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: ${palette('text', 2)};
	}
	.primaryInfoContainer {
		.actionButtonContainer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
	v .personDescriptionContainer {
		font-size: 14px;
		font-weight: 300;
		margin-top: 10px;
		padding: 0px 5px;
		/* border: 1px solid ${palette('border', 0)}; */
		color: ${palette('text', 3)};
		${borderRadius('6px')};
		overflow: hidden;
	}

	.description {
		width: 100%;
	}
	.infoContainer {
		border-top: 1px solid ${palette('border', 0)};
		padding-top: 5px;
		margin-top: 5px;
		display: flex;
		align-items: flex-end;
		row-gap: 10px;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.label {
			font-size: 14px;
		}

		.value {
			color: ${palette('secondary', 0)};

			font-weight: 500;
		}
	}
`;

export default TeamCardWrapper;
