import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import superFetch from '@iso/lib/helpers/superFetch';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { Tooltip } from 'antd';
import React, { useState } from 'react';

const RemoteUnlockButton = ({ visible = false, setVisible, children, data }) => {
	// basic information for this modal to be used in the form.
	const { deviceID, name } = data;

	const [unlockLoading, setUnlockLoading] = useState(false);

	const handleCancel = () => {
		setVisible(false);
		setUnlockLoading(false);
	};

	const handleUnlock = async () => {
		if (!deviceID) return;
		setUnlockLoading(true);

		const body = {
			actionID: 1,
			deviceIDs: [deviceID],
			platform: 'Dashboard',
			method: 'Remote'
		};
		try {
			const res = await superFetch.post(`/devices/action`, body);
			if (res.statusCode === 200) {
				notification('success', `${name} has been unlocked successfully.`);
				handleCancel();
			} else {
				notification('error', res.data[0].errmsg || res.data[0].msg);
				setUnlockLoading(false);
			}
		} catch (error) {
			notification('error', 'Something went wrong. Please try again later.');
			handleCancel();
		}
	};

	// Permission check for remote unlock button.
	const isPermittedRemoteUnlock = useManagePermittedRoles('aCRemoteUnlock', {
		orgID: [data.orgID],
		siteID: [],
		floorID: data?.location?.length > 0 ? data?.location.map((location) => location.floorID) : []
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedRemoteUnlock
						? "You don't have permission"
						: 'Remotely Unlock your Device from Anywhere with Internet'
				}
			>
				{children ? (
					children
				) : (
					<>
						<Button
							disabled={!isPermittedRemoteUnlock}
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
						>
							Remote Unlock
						</Button>
					</>
				)}
			</Tooltip>
			<Modal
				title='Remote Unlock Door'
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						onClick={() => handleUnlock()}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={unlockLoading}
					>
						Unlock
					</Button>
				]}
			>
				<div style={{ textAlign: 'center' }}>
					Are you sure you want to Unlock <strong>{name ? name : ''}</strong>?
				</div>
			</Modal>
		</div>
	);
};

export default RemoteUnlockButton;
