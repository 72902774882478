import styled from 'styled-components';
import Select from '@iso/components/uielements/select';
import WithDirection from '@iso/lib/helpers/rtl';

const MultiSelect = styled(Select)`
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: auto;
	}
	.checkButton {
		position: absolute;
		right: 10px;
		top: 10px;
	}
`;

export default WithDirection(MultiSelect);
