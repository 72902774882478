import actions from './actions';

const initState = { bookingData: [], pending: true, searchable: true };

const bookingReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_BOOKING_SUCCESS:
			return {
				...state,
				bookingData: action.bookingData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
				pending: false
			};
		case actions.LOAD_BOOKING_ERROR:
			return { ...state, bookingData: [], pending: false };
		default:
			return state;
	}
};
export default bookingReducer;
