import { Typography } from 'antd';
import React from 'react';

const suggestions = {
	spaceBarSearchSuggestion: 'Next time try pressing space bar to search.',
	EditDeviceLocationTips: () => (
		<Typography>
			<ul style={{ marginBottom: '0px' }}>
				<li>To update the location, click and drag the pin within the map.</li>
				<li>To add a new location, select the Floor and click on the new pin from Top-Right corner.</li>
				<li>To remove the new location, click on the pin itself to remove it from the map.</li>
			</ul>
		</Typography>
	),
	ResendInvitationFirebase: () => (
		<Typography>
			<ul style={{ marginBottom: '0px' }}>
				<li>
					Your sign-up link has expired. We have already sent a new link for secure access to your email
					account. Please check your inbox to continue with the sign-up process. If you encounter any issues
					or do not receive the email, don't hesitate to contact our support team or use our chat bot for
					assistance.
				</li>
			</ul>
		</Typography>
	)
};

export default suggestions;
