import superFetch from '@iso/lib/helpers/superFetch';
export const getAccessData = async ({ teamID, personID, deviceID, assetGroupID }) => {
	if (teamID) {
		const teamAccessData = await superFetch.get(`/access`, {}, { teamID: teamID }).then((res) => {
			if (res.statusCode === 200) return res.data;
			return [];
		});
		return teamAccessData;
	} else if (personID) {
		const personAccessData = await superFetch.get(`/access`, {}, { personID: personID }).then((res) => {
			if (res.statusCode === 200) return res.data;
			return [];
		});
		return personAccessData;
	} else if (deviceID) {
		const lockAccessData = await superFetch.get(`/access`, {}, { deviceID: deviceID }).then((res) => {
			if (res.statusCode === 200) return res.data;
			return [];
		});
		return lockAccessData;
	} else if (assetGroupID) {
		const lockAccessData = await superFetch.get(`/access`, {}, { assetGroupID: assetGroupID }).then((res) => {
			if (res.statusCode === 200) return res.data;
			return [];
		});
		return lockAccessData;
	}
};
