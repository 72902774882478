import { all, takeEvery, put, fork } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';
import actions from './actions';

export function* loadDeviceCategoriesData() {
	yield takeEvery('LOAD_DEVICE_CATEGORIES_DATA', function* () {
		const data = yield superFetch.get('/deviceCategories').then((res) => {
			var result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data.map((row) => ({
						categoryID: row.categoryID,
						name: row.name,
						description: row.description ? row.description : 'N/A',
						modifiedDate: row.modifiedDate,
						createdDate: row.createdDate,
						isBookable: row.isBookable,
						isDefault: row.isDefault,
						defaultCategoryID: row.defaultCategoryID,
						typeID: row.typeID,
						typeName: row.typeName,
						deletedDate: row.deletedDate,
						sites: row.sites.length > 0 ? row.sites : [],
						orgs: row.orgs.length > 0 ? row.orgs : [],
						fields: row.fields.length > 0 ? row.fields : []
					}));
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;

				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_DEVICE_CATEGORIES_SUCCESS,
				deviceCategoriesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_DEVICE_CATEGORIES_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadDeviceCategoriesData)]);
}
