import Input from '@iso/components/uielements/input';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import { Form } from 'antd';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const RenderCredential = ({ setting, settings, index, setSettings }) => {
	const [form] = Form.useForm();
	const [options, setOptions] = useState([]);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);

	useEffect(() => {
		setOptions(
			setting.value || [
				{ name: 'userName', value: '' },
				{ name: 'password', value: '' }
			]
		);
	}, [setting]);

	useEffect(() => {
		const fieldsValue = options.reduce((acc, option) => {
			acc[option.name] = option.value;
			return acc;
		}, {});
		form.setFieldsValue(fieldsValue);
	}, [options, form]);

	const onValuesChange = (changedValues, allValues) => {
		const allLabelsFilled = Object.keys(allValues).every((field) => field.trim() !== '');

		const enrichArrayWithValues = (obj, arr) => {
			return arr.map((item) => ({
				...item,
				value: obj[item.name]
			}));
		};

		const finalValue = enrichArrayWithValues(allValues, options);

		const updatedSettings = [...settings];
		updatedSettings[index] = {
			...setting,
			value: allLabelsFilled ? finalValue : null
		};
		setSettings(updatedSettings);
	};
	const isPermittedEditJson = useManagePermittedRoles('editCredentialSetting', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	return (
		<Form form={form} onValuesChange={onValuesChange}>
			<Form.Item name={'userName'} rules={fieldsValidator.String} label={'Account Email'}>
				<Input placeholder='User Email' className='fieldString' disabled={!isPermittedEditJson} />
			</Form.Item>
			<Form.Item name={'password'} rules={fieldsValidator.String} label={'Password'}>
				<Input
					placeholder='Account Password'
					className='fieldString'
					type={passwordVisible ? 'text' : 'password'}
					suffix={
						<div
							title='Show Password'
							onClick={() => setPasswordVisible(!passwordVisible)}
							style={{ cursor: 'pointer' }}
						>
							{passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
						</div>
					}
					disabled={!isPermittedEditJson}
					onVisibleChange={(visible) => console.log(visible)}
				/>
			</Form.Item>
		</Form>
	);
};

export default RenderCredential;
