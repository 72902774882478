import React from 'react';
import Breadcrumbs from '@iso/components/uielements/breadcrumb';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const AnrBreadcrumb = (props) => <Breadcrumbs {...props} />;
const BreadCrumbItem = (props) => <Breadcrumbs.Item {...props} />;

const Breadcrumb = styled(AnrBreadcrumb)`
	color: ${palette('text', 3)};
	font-size: 13px;

	a {
		color: ${palette('text', 3)};
		font-size: 13px;
	}
	a:hover {
		color: ${palette('secondary', 0)} !important;
	}

	> span:last-child {
		font-weight: 600;
		color: ${palette('text', 1)};
	}
`;

const BreadCrumbWrapper = styled.div`
	height: 25px;
	margin: 10px 10px 0px 10px;
	padding-left: 10px;
`;

export default Breadcrumb;
export { BreadCrumbItem, BreadCrumbWrapper };
