import React from 'react';
import ComponentLabel, { EditableTitle } from './styles';

const Label = (props) => {
	const { text, style, editable, editableProps } = props;
	return editable ? (
		<ComponentLabel {...props}>
			<EditableTitle editable={editable} className='title' {...editableProps}>
				{text}
			</EditableTitle>
		</ComponentLabel>
	) : (
		<ComponentLabel title={text} style={style} {...props}>
			{text}
		</ComponentLabel>
	);
};

export default Label;
