const actions = {
	LOAD_ORGANISATION_SETTINGS_DATA: 'LOAD_ORGANISATION_SETTINGS_DATA',
	LOAD_ORGANISATION_SETTINGS_SUCCESS: 'LOAD_ORGANISATION_SETTINGS_SUCCESS',
	LOAD_ORGANISATION_SETTINGS_ERROR: 'LOAD_ORGANISATION_SETTINGS_ERROR',

	LOAD_PERSON_SETTINGS_DATA: 'LOAD_PERSON_SETTINGS_DATA',
	LOAD_PERSON_SETTINGS_SUCCESS: 'LOAD_PERSON_SETTINGS_SUCCESS',
	LOAD_PERSON_SETTINGS_ERROR: 'LOAD_PERSON_SETTINGS_ERROR',

	UPDATE_SELECTED_SETTING_GROUP: 'UPDATE_SELECTED_SETTING_GROUP',

	loadSettingsOrganisation: () => ({
		type: actions.LOAD_ORGANISATION_SETTINGS_DATA
	}),
	loadSettingsPerson: () => ({
		type: actions.LOAD_PERSON_SETTINGS_DATA
	}),

	updateSelectedSetting: (settingID) => ({
		type: actions.UPDATE_SELECTED_SETTING_GROUP,
		payload: settingID
	})
};
export default actions;
