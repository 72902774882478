const fieldsValidator = {
	'Name': [
		{
			required: true
		},
		{
			min: 3,
			message: 'Must be a minimum of 3 characters.'
		},
		{ max: 100, message: 'No more than 100 characters.' },
		{
			pattern: /^(?!.*([-/'@.,?!()$:;|])\1)[a-zA-Z0-9 \-/'@.,?!()$:;|]+$/,
			message: 'Name must contain letters, numbers'
		}
	],
	'Email': [
		{
			required: true,
			message: 'Please enter email.'
		},
		{
			pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
			message: 'Enter valid email.'
		}
	],
	'Password': [
		{
			required: true,
			message: 'Please enter a password!'
		},
		{
			pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
			message:
				'Password must be at least 8 characters long, contain a number, an uppercase letter and a lowercase letter.'
		}
	],
	'ABN': [
		{
			min: 11,
			max: 11,
			message: 'Must be an 11 digit number.'
		},
		{
			pattern: /^[0-9]+$/,
			message: 'Must be a number.'
		},
		{
			validator(_, value) {
				if (!value || value.trim() === '') {
					return Promise.reject(new Error('Must be a valid ABN'));
				}
				return Promise.resolve();
			}
		}
	],
	'ACN': [
		{
			min: 9,
			max: 9,
			message: 'Must be an 9 digit number.'
		},
		{
			pattern: /^[0-9]+$/,
			message: 'Must be a number.'
		},
		{
			validator(_, value) {
				if (!value || value.trim() === '') {
					return Promise.reject(new Error('Must be a valid ACN'));
				}
				return Promise.resolve();
			}
		}
	],
	'AddressLine1': [
		{
			max: 100,
			whitespace: true,
			message: 'No more than 100 characters.'
		},
		{
			required: true,
			message: 'Please enter address line 1.'
		}
	],
	'AddressLine2': [
		{
			max: 100,
			whitespace: true,
			message: 'No more than 100 characters.'
		}
	],
	'Suburb': [
		{
			max: 50,
			message: 'No more than 50 characters.'
		},
		{
			required: true,
			message: 'Please enter suburb.'
		}
	],
	'State': [
		{
			required: true,
			message: 'Please enter state.'
		}
	],
	'PostCode': [
		{
			required: true,
			pattern: /^[0-9]+$/,
			message: 'Please enter post code.'
		}
	],
	'Country': [
		{
			required: true,
			message: 'Please select a country.'
		}
	],
	'Required': [{ required: true }],
	'Fax': [
		{
			pattern: /^[0-9a-zA-Z@.+]+$/,
			message: 'Invalid contact information.'
		}
	],
	'Home': [
		{
			required: true,
			pattern: /^(?:(\+\d{1,3}[- ]?)?\d{10}|[0-9]+)$/,
			message: 'Enter valid Home number.'
		}
	],
	'Mobile': [
		{
			min: 10,
			max: 10,
			message: 'Must be an 10 digit number.'
		},
		{
			required: true,
			pattern: /^(?:(\+\d{1,3}[- ]?)?\d{10}|[0-9]+)$/,
			message: 'Enter valid mobile number.'
		}
	],
	'Work/Office': [
		{
			required: true,
			pattern: /^(?:(\+\d{1,3}[- ]?)?\d{10}|[0-9]+)$/,
			message: 'Enter valid Work/Office number.'
		}
	],
	'Description': [
		{
			whitespace: false
		},
		{
			pattern: /^[a-zA-Z0-9 \n.;/,-]+$/,
			message: 'May only contain letters, numbers, ,/;. and spaces.'
		}
	],
	'Sex': [
		{
			max: 10,
			required: false,
			whitespace: false
		},
		{
			pattern: /^[a-zA-Z]+$/,
			message: 'Must only contain letters and numbers.'
		}
	],
	'DimensionMap': [
		{
			whitespace: false
		},
		{
			pattern: /^[0-9.]+$/,
			message: 'Must only contain Dimension in meters.'
		}
	],
	'String': [
		{
			required: true,
			message: 'Please enter a String.'
		}
	],
	'Number': [
		{
			required: true,
			pattern: /^[0-9]+$/,
			message: 'Must be a digit number.'
		}
	]
};
export default fieldsValidator;
export const validateEmail = (value) => {
	let errors;

	if (!value) {
		errors = 'Required!';
	} else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value)) {
		errors = 'Invalid email address!';
	}

	return errors;
};

export const CustomFieldValidationsByFieldTypeID = {
	1: [
		{
			required: true,
			min: 3,
			message: 'Must be a minimum of 3 characters.'
		},
		{
			max: 300,
			message: 'Must be no more than 300 characters.'
		},
		{
			pattern: /^(?!-)(?!.*--)[A-Za-z0-9- ,/:@#$()]+(?<!-)$/,
			message: 'Must only contain spaces, letters, numbers or (/:#@$,-).'
		},
		{
			whitespace: true,
			message: 'Must not be empty.'
		}
	],
	2: [
		{
			required: true,
			pattern: /^[0-9]+$/,
			message: 'Must only contain numbers.'
		}
	],
	3: [
		{
			required: true,
			type: 'date',
			message: 'Invalid Date information.'
		}
	],
	4: [
		{
			required: true,
			type: 'boolean',
			message: 'Invalid True/False information.'
		}
	]
};

export const CustomFieldValidationsByRegex = {
	1: {
		valid: /^(?!-)(?!.*--)[A-Za-z0-9- ,/:@#$()]+(?<!-)$/,
		message: 'Must only contain spaces, letters, numbers or (/:#@$,-).'
	},
	2: {
		valid: /^[0-9]+$/,
		message: 'Must only contain numbers.'
	},
	3: {
		valid: /^((?:(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(?:\.\d+)?))(Z|[\\+-]\d{2}:\d{2})?)$/,
		message: 'Invalid Date information.'
	},
	4: {
		valid: /^(true|false)$/,
		message: 'Invalid True/False information.'
	}
};

export const isRequired = (value) => (!value ? 'Required!' : '');
