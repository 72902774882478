import actions from './actions';

const initState = { assetGroupsData: [], pending: true, searchable: true };

const assetGroupsReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_ASSET_GROUPS_SUCCESS:
			return { ...state, assetGroupsData: action.data, pending: false };
		case actions.LOAD_ASSET_GROUPS_ERROR:
			return { ...state, assetGroupsData: [], pending: false };
		default:
			return state;
	}
};
export default assetGroupsReducer;
