export const rolesConfig = {
	1: 'User',
	3: 'Team Manager',
	6: 'Cleaner',
	101: 'Global Admin',
	102: 'Billing Admin',
	103: 'Site Admin',
	104: 'Floor Admin ',
	105: 'Team Admin',
	106: 'Team Admin',
	107: 'Assets Admin',
	201: 'Developer',
	202: 'Asset Manager',
	203: 'Inventory Manager'
};
