import React, { useState, useEffect, useRef } from 'react';
import { Input, DatePicker, Select, InputNumber } from 'antd';
import Form from '@iso/components/uielements/form';
import { CellWrapper, FieldWrapper } from './editableField.styles';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { notification } from '@iso/components';
import Popconfirm from '@iso/components/Feedback/Popconfirm';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

export const EditableField = ({
	title,
	editable,
	children,
	fieldID,
	value,
	handleSave,
	handleDelete,
	fieldTypeName,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const [form] = Form.useForm();

	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		switch (fieldTypeName) {
			case 'Boolean':
				form.setFieldsValue({
					[title]: value === '1' ? 1 : 0
				});
				break;
			case 'Date':
				form.setFieldsValue({
					[title]: moment(value)
				});
				break;
			default:
				form.setFieldsValue({
					[title]: value
				});
				break;
		}
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleSave(fieldID, values[title]);
		} catch (errInfo) {
			notification('error', 'Update failed! Please check value is valid.');
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form form={form} component={false}>
				<FieldWrapper
					style={{
						margin: 0
					}}
					name={title}
					rules={fieldsValidator.Required}
				>
					{fieldTypeName === 'Date' ? (
						<DatePicker
							className='datePicker'
							format='DD MMMM YYYY'
							ref={inputRef}
							onPressEnter={save}
							onBlur={save}
							allowClear={false}
						/>
					) : fieldTypeName === 'Number' ? (
						<InputNumber className='number' ref={inputRef} onPressEnter={save} onBlur={save} />
					) : fieldTypeName === 'Boolean' ? (
						<Select className='boolean' ref={inputRef} onBlur={save}>
							<Select.Option value={1}>Yes</Select.Option>
							<Select.Option value={0}>No</Select.Option>
						</Select>
					) : (
						<Input className='text' ref={inputRef} onPressEnter={save} onBlur={save} />
					)}
				</FieldWrapper>
			</Form>
		) : (
			<CellWrapper style={{ display: 'flex', alignItems: 'center' }}>
				<div
					className='editable-field-value-wrap'
					style={{
						paddingRight: 24
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
				<Popconfirm
					title={`Are you sure to delete ${title} field value?`}
					onConfirm={() => handleDelete(fieldID)}
					okText='Delete'
				>
					<CloseOutlined className='editable-field-delete-btn' />
				</Popconfirm>
			</CellWrapper>
		);
	}

	return <div {...restProps}>{childNode}</div>;
};

export default EditableField;
