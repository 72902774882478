const actions = {
	LOAD_CONTACT_TYPES_DATA: 'LOAD_CONTACT_TYPES_DATA',
	LOAD_CONTACT_TYPES_SUCCESS: 'LOAD_CONTACT_TYPES_SUCCESS',
	LOAD_CONTACT_TYPES_ERROR: 'LOAD_CONTACT_TYPES_ERROR',

	LOAD_ADDRESS_TYPES_DATA: 'LOAD_ADDRESS_TYPES_DATA',
	LOAD_ADDRESS_TYPES_SUCCESS: 'LOAD_ADDRESS_TYPES_SUCCESS',
	LOAD_ADDRESS_TYPES_ERROR: 'LOAD_ADDRESS_TYPES_ERROR',

	LOAD_ADMIN_TYPES_DATA: 'LOAD_ADMIN_TYPES_DATA',
	LOAD_ADMIN_TYPES_SUCCESS: 'LOAD_ADMIN_TYPES_SUCCESS',
	LOAD_ADMIN_TYPES_ERROR: 'LOAD_ADMIN_TYPES_ERROR',

	LOAD_CATEGORY_TYPES_DATA: 'LOAD_CATEGORY_TYPES_DATA',
	LOAD_CATEGORY_TYPES_SUCCESS: 'LOAD_CATEGORY_TYPES_SUCCESS',
	LOAD_CATEGORY_TYPES_ERROR: 'LOAD_CATEGORY_TYPES_ERROR',

	LOAD_FIELD_TYPES_DATA: 'LOAD_FIELD_TYPES_DATA',
	LOAD_FIELD_TYPES_SUCCESS: 'LOAD_FIELD_TYPES_SUCCESS',
	LOAD_FIELD_TYPES_ERROR: 'LOAD_FIELD_TYPES_ERROR',

	LOAD_BOOKING_TYPES_DATA: 'LOAD_BOOKING_TYPES_DATA',
	LOAD_BOOKING_TYPES_SUCCESS: 'LOAD_BOOKING_TYPES_SUCCESS',
	LOAD_BOOKING_TYPES_ERROR: 'LOAD_BOOKING_TYPES_ERROR',

	LOAD_ENTITY_TYPES_DATA: 'LOAD_ENTITY_TYPES_DATA',
	LOAD_ENTITY_TYPES_SUCCESS: 'LOAD_ENTITY_TYPES_SUCCESS',
	LOAD_ENTITY_TYPES_ERROR: 'LOAD_ENTITY_TYPES_ERROR',

	loadContactTypes: () => ({
		type: actions.LOAD_CONTACT_TYPES_DATA
	}),
	loadAddressTypes: () => ({
		type: actions.LOAD_ADDRESS_TYPES_DATA
	}),
	loadAdminTypes: () => ({
		type: actions.LOAD_ADMIN_TYPES_DATA
	}),
	loadCategoryTypes: () => ({
		type: actions.LOAD_CATEGORY_TYPES_DATA
	}),
	loadFieldTypes: () => ({
		type: actions.LOAD_FIELD_TYPES_DATA
	}),
	loadBookingTypes: () => ({
		type: actions.LOAD_BOOKING_TYPES_DATA
	}),
	loadEntityTypes: () => ({
		type: actions.LOAD_ENTITY_TYPES_DATA
	})
};
export default actions;
