import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const ACLockDeviceCardWrapper = styled.div`
	.mapContainer {
		border: 2px solid ${palette('primary', 0)};
		${borderRadius()};
		width: 100%;
		height: 250px;
		overflow: hidden;
		position: relative;
		margin-bottom: 10px;
	}
	.deviceInformationContainer {
		background-color: ${palette('secondary', 7)};
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		margin-bottom: 10px;

		.deviceInfoContainer {
			display: flex;
			flex-wrap: wrap;
			gap: 0px 5px;
			align-items: baseline;

			.deviceNameDivider {
				color: ${palette('primary', 0)};
				font-size: x-large;
				margin: 0px 5px;
			}

			.uidContainer {
				font-weight: 500;
				cursor: pointer;
				display: flex;
				align-items: center;

				.IDLabel {
					color: ${palette('secondary', 2)};
				}

				.copyIcon {
					margin-left: 5px;
					font-size: 13px;
				}
			}
		}
		.actionContainer {
			display: flex;
			flex-wrap: wrap;
			& > div:not(:last-child) {
				margin-right: 5px;
			}
		}
	}
`;

export default ACLockDeviceCardWrapper;
