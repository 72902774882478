import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import AppProvider from './AppProvider';
import Navigation from './navigation';

const App = () => (
	<Provider store={store}>
		<AppProvider>
			<>
				<GlobalStyles />
				<Navigation />
			</>
		</AppProvider>
	</Provider>
);
Boot()
	.then(() => App())
	.catch((error) => console.error(error));

export default App;
