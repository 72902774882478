import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button, Form, Tag } from '@iso/components/uielements';
import superFetch from '@iso/lib/helpers/superFetch';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import peopleAction from '@iso/redux/people/actions';
import { Tooltip } from 'antd';
import _, { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from './select.style';
const { loadPeople } = peopleAction;

const ManageTeamMember = ({ teamID, children, open = false, setOpen }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [modifyMembersLoading, setModifyMembersLoading] = useState(false);
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
	const [members, setTeamMembersData] = useState([]);

	const { peopleData } = useSelector((state) => state.people);
	const { teamsData } = useSelector((state) => state.teams);

	const displayNames = useGetPersonDisplayName(true);

	useEffect(() => {
		const personWithTeamID = peopleData.filter((person) => person.team.some((user) => user.teamID === teamID));
		const orderedTeamMembers = _.orderBy(personWithTeamID, 'deletedDate', 'desc');

		setTeamMembersData(orderedTeamMembers);

		form.setFieldsValue({
			personID: orderedTeamMembers.map((member) => member.personID)
		});
	}, [peopleData, teamID, form]);

	const handleChange = (value) => {
		setSubmitButtonDisable(isEqual(members.map((member) => member.personID).sort(), value.sort()));
	};

	const handleCancel = () => {
		form.setFieldsValue({
			personID: members.map((member) => member.personID)
		});
		setSubmitButtonDisable(true);
		setOpen(false);
	};

	const onFinish = async () => {
		const admins =
			teamsData.length > 0 && teamsData.find((team) => team.teamID === teamID)
				? teamsData.find((team) => team.teamID === teamID).adminPerson
				: [];
		const formData = form.getFieldsValue(true);
		if (admins.every((admin) => !formData.personID.includes(admin.personID))) {
			notification('error', 'Team members should have at least one admin');
			return;
		}
		setModifyMembersLoading(true);

		const currentMembers = members.map((member) => member.personID);
		const modifiedMembers = currentMembers
			.concat(formData.personID)
			.filter((memberID) => !currentMembers.includes(memberID) || !formData.personID.includes(memberID));

		const addedMembers = modifiedMembers.filter((memberID) => formData.personID.includes(memberID));
		const removedMembers = modifiedMembers.filter((memberID) => currentMembers.includes(memberID));

		if (admins.some((admin) => removedMembers.includes(admin.personID))) {
			notification('error', 'Please revoke the admin role first before removing admin from the team');
			return;
		}

		const addSuccess =
			addedMembers.length > 0
				? await superFetch.patch(`/teams/${teamID}/members`, { personID: addedMembers }).then((res) => {
						if (res.statusCode === 201) {
							return true;
						} else {
							notification('error', 'There is an Error! We are mending the problem, try again soon!.');
							return false;
						}
				  })
				: false;

		const removeSuccess =
			removedMembers.length > 0
				? await superFetch.delete(`/teams/${teamID}/members`, { personID: removedMembers }).then((res) => {
						if (res.statusCode === 200) {
							return true;
						} else {
							notification('error', 'There is an Error! We are mending the problem, try again soon!.');
							return false;
						}
				  })
				: false;

		if (addSuccess) {
			const addedMembersData = addedMembers.map((memberID) => {
				return peopleData.find((person) => person.personID === memberID);
			});

			const updatedTeamMembers = members
				.filter((member) => !removedMembers.includes(member.personID))
				.concat(addedMembersData);

			dispatch(loadPeople());
			setTeamMembersData(updatedTeamMembers);
			notification('success', 'Added to the team');
		}

		if (removeSuccess) {
			dispatch(loadPeople());
			setTeamMembersData((prevTeamMembers) => {
				const updatedTeamMembers = prevTeamMembers.filter(
					(member) => !removedMembers.includes(member.personID)
				);
				return updatedTeamMembers;
			});
			notification('success', 'Deleted from the team');
		}
		setSubmitButtonDisable(true);
		setOpen(false);
		setModifyMembersLoading(false);
	};

	const tagRender = (props) => {
		const { label, value, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		const deleted = peopleData.find((member) => member.personID === value)?.deletedDate;

		const admins =
			teamsData.length > 0 && teamsData.find((team) => team.teamID === teamID)
				? teamsData.find((team) => team.teamID === teamID).adminPerson
				: [];

		return (
			<Tag
				title={displayNames[value].title}
				color={deleted ? 'red' : 'default'}
				onMouseDown={onPreventMouseDown}
				closable={!admins.map((admin) => admin.personID).includes(value)}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		);
	};

	// group category by type for select component to render
	const groupMembers = (people, teams) => {
		const admins =
			teams.length > 0 && teams.find((team) => team.teamID === teamID)
				? teams.find((team) => team.teamID === teamID).adminPerson
				: [];

		const options = [];

		// add all admins to the options array
		admins.forEach((admin) => {
			if (options.every((option) => option.adminTypeID !== admin.adminTypeID)) {
				options.push({
					label: admin.adminType,
					adminTypeID: admin.adminTypeID,
					options: []
				});
			}
			options
				.find((option) => option.adminTypeID === admin.adminTypeID)
				.options.push({
					label: displayNames[admin.personID] && displayNames[admin.personID].displayName,
					value: admin.personID,
					title: displayNames[admin.personID] && displayNames[admin.personID].title
				});
		});
		options.push({
			label: 'Members',
			options: []
		});

		// add other team members to the options array
		people.forEach((person) => {
			if (admins.every((admin) => admin.personID !== person.personID)) {
				options
					.find((option) => option.label === 'Members')
					.options.push({
						label: displayNames[person.personID] && displayNames[person.personID]?.displayName,
						value: person.personID,
						title: displayNames[person.personID] && displayNames[person.personID]?.title
					});
			}
		});
		return options;
	};

	const isPermittedManageMember = useManagePermittedRoles('manageMembers', {
		orgID: [teamsData[0]?.orgID],
		teamID: [teamsData.map((item) => item.teamID)]
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedManageMember ? "You don't have permission" : 'Remove and/or Add members for the Team'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setOpen(true);
							}}
							style={{ width: '100%' }}
							disabled={!isPermittedManageMember}
						>
							Manage Members
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title='Manage Members'
				visible={open}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						disabled={submitButtonDisable}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={modifyMembersLoading}
					>
						Update
					</Button>
				]}
			>
				<Form form={form} id={form} onFinish={onFinish} autoComplete='off'>
					<Form.Item name='personID'>
						<MultiSelect
							tagRender={tagRender}
							mode='multiple'
							style={{ width: '100%' }}
							onChange={handleChange}
							placeholder='Select Members'
							filterOption={(input, option) =>
								option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							options={groupMembers(peopleData, teamsData)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default ManageTeamMember;
