import defaultTheme from './default';

const customTheme = {
	...defaultTheme,
	palette: {
		primary: ['#f00'],
		secondary: ['#0f0']
	}
};

export default customTheme;
