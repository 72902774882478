import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const ProfilePreviewWrapper = styled.div`
	${borderRadius('4px')}
	margin: 5px 0px;
	padding: 10px 0px;
	& > div {
		margin-bottom: 5px;
	}

	.imageContainer {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	.titleContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.name {
			font-size: 22px;
			font-weight: 600;
			line-height: 20px;
			word-wrap: break-word;
			color: ${palette('text', 0)}; // ? Can change the color
		}
		.userType {
			margin-top: 5px;
			font-size: 15px;
			font-weight: 600;
			color: ${palette('secondary', 0)};
			line-height: 20px;
		}
	}
	.infoContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		div {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		h1 {
			font-size: 15px;
			font-weight: 500;
			color: ${palette('text', 2)};
			line-height: 20px;
			margin: 0 !important;
		}

		.divider {
			border: 1px solid ${palette('border', 5)};
		}
	}
	.actionContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	.extraInfoCollapse {
		.ant-collapse-header {
			color: ${palette('text', 0)};
			font-size: 16px;
			font-weight: 600;
			padding: 10px;
			border: none;
			border-radius: 5px;
			cursor: pointer;
			transition: background-color 0.3s ease;
			border-top: 1px solid ${palette('border', 1)};

			&:hover {
				background-color: ${palette('grayscale', 4)};
			}
		}

		.containerCard {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px 5px;
			overflow: hidden;
		}
	}
`;
export { ProfilePreviewWrapper };
