import { Loading, Modal } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import superFetch from '@iso/lib/helpers/superFetch';
import devicesAction from '@iso/redux/devices/actions';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';

const GrantPermissionModal = ({ visible, setVisible, children, data }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { deviceID, deviceName } = data;
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const { peopleData } = useSelector((state) => state.people);
	const displayNames = useGetPersonDisplayName(true);

	// basic information for this modal to be used in the form.
	const [loading, setLoading] = useState(true);
	const [grantLoading, setGrantLoading] = useState(false);
	const [people, setPeople] = useState([]);
	const [permittedPeople, setPermittedPeople] = useState([]);
	const { loadDevices } = devicesAction;

	const handleCancel = () => {
		setVisible(false);
		setLoading(true);
	};

	const getPermittedPeople = async (deviceID) => {
		const response = await getAccessData({ deviceID: deviceID });
		if (response.statusCode === 200) {
			setPermittedPeople(response);
		}
	};
	useEffect(() => {
		if (deviceID && visible && peopleData) {
			setPeople(peopleData);
			setLoading(false);
			getPermittedPeople(deviceID);
			form.resetFields();
		}
	}, [deviceID, visible, peopleData, form]);

	const onFinish = () => {
		setGrantLoading(true);
		const formData = form.getFieldsValue(true);

		// ? setting the permission Type 1: Default for every person
		const body = {
			deviceIDs: [deviceID],
			personIDs: formData.people,
			permissionTypeID: 1
		};
		superFetch.post(`/access/grantPermission`, body).then((res) => {
			if (res.statusCode === 200) {
				notification('success', 'Permission Granted.');
				handleCancel();
				dispatch(loadDevices());
			} else {
				notification('error', 'Error while granting permission. Please try again.');
			}
			setGrantLoading(false);
		});
	};

	return (
		<div>
			{children}
			<Modal
				title={'Grant Permission' + (deviceName ? ` to ${deviceName}` : '')}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button form={form} key='submit' htmlType='submit' type='primary' loading={grantLoading}>
						Grant
					</Button>
				]}
			>
				{loading ? (
					<Row style={rowStyle} gutter={0} justify='space-around'>
						<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
							<div style={loadingStyle}>
								<Loading />
							</div>
						</Col>
					</Row>
				) : (
					<Form form={form} id={form} onFinish={onFinish} autoComplete='off'>
						<Form.Item name='people'>
							<Select
								mode='multiple'
								style={{ width: '100%' }}
								showSearch
								placeholder={'Select People'}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
							>
								{people.map((person, key) => (
									<Option
										key={key}
										value={person.personID}
										title={displayNames[person.personID].title}
										disabled={permittedPeople.find((p) => p.personID === person.personID)}
									>
										{displayNames[person.personID].displayName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				)}
			</Modal>
		</div>
	);
};

export default GrantPermissionModal;
