import React from 'react';
import { Row, Col } from 'antd';
import { Loading, PageHeader, AddTeam } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const TeamsHeader = () => {
	const personState = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const { teamsData, pending } = useSelector((state) => state.teams);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const deletedNumber = teamsData.filter((team) => team.deletedDate).length;
	const isPermittedAddTeam = useManagePermittedRoles('addTeam', {
		orgID: [teamsData[0]?.orgID || personState.orgID],
		siteID: [],
		floorID: [],
		teamID: []
	});

	return pending ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<div>
			<PageHeader title='Teams' counter={deletedNumber < 1 ? teamsData.length : teamsData.length - deletedNumber}>
				{isPermittedAddTeam && <AddTeam />}
			</PageHeader>
		</div>
	);
};

export default TeamsHeader;
