import basicStyle, { smallTimeOptions } from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import ContentHolder from '@iso/components/utility/contentHolder';
import { LabelWrapper, Loading, Modal } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import React, { useEffect } from 'react';
import IndividualOrgInfoWrapper from './ODS.styles';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { useDispatch } from 'react-redux';
import organisationsAction from '@iso/redux/organisation/actions';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const IndividualOrgDetails = ({ data }) => {
	const { loadOrganisation } = organisationsAction;
	const [loadings, setLoading] = React.useState(true);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [editVisible, setEditVisible] = React.useState(false);

	const [updateOrgLoading, setUpdateOrgLoading] = React.useState(false);
	const [updateOrgObj, setUpdateOrgObj] = React.useState({});
	const [buttonDisable, setButtonDisable] = React.useState(true);

	useEffect(() => {
		form.setFieldsValue({
			abn: '',
			acn: ''
		});
	}, [form]);

	useEffect(() => {
		setLoading(true);
		if (data && Object.keys(data).length > 0) {
			setLoading(false);
		}
	}, [data]);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	const handleCancel = () => {
		setButtonDisable(true);
		setEditVisible(false);
	};

	const onFinish = () => {
		let newOrgInfo = {};
		const formData = form.getFieldsValue(true);

		if (formData.name !== updateOrgObj.name && formData.name !== '') {
			newOrgInfo['name'] = formData.name;
		}
		if (formData.abn !== updateOrgObj.abn && formData.abn !== '') {
			newOrgInfo['abn'] = formData.abn;
		}
		if (formData.acn !== updateOrgObj.acn && formData.acn !== '') {
			newOrgInfo['acn'] = formData.acn;
		}
		if (newOrgInfo.name || newOrgInfo.acn || newOrgInfo.abn) {
			setLoading(true);
			superFetch
				.patch('/organisations/' + updateOrgObj.orgID, newOrgInfo)
				.then((res) => {
					if (res.statusCode === 200) {
						dispatch(loadOrganisation());
						handleCancel();
						notification('success', 'Organisation Information Updated.');
					}
					setUpdateOrgLoading(false);
				})
				.catch((err) => {
					notification('error', 'Update Failed, Please try again.');
				});
		}
	};

	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		if (
			(updateOrgObj.name === formData.name || formData.name === '') &&
			(updateOrgObj.abn === formData.abn || formData.abn === '') &&
			(updateOrgObj.acn === formData.acn || formData.acn === '')
		) {
			return true;
		} else {
			return false;
		}
	};

	const isPermittedUpdateOrg = useManagePermittedRoles('updateOrg', {
		orgID: []
	});

	const labels = [
		{
			text: 'Organisation ID: ',
			textProp: data.orgID
		},
		{
			text: 'ABN: ',
			textProp: data.abn
		},
		{
			text: 'ACN: ',
			textProp: data.acn
		},
		{
			text: 'Alias: ',
			textProp: data.alias
		},
		{
			text: 'Date Created: ',
			textProp: new Date(data.createdDate).toLocaleString('en-AU', smallTimeOptions)
		},
		{
			text: 'Last Updated: ',
			textProp: new Date(data.modifiedDate).toLocaleString('en-AU', smallTimeOptions)
		}
	];

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<IndividualOrgInfoWrapper>
				<Row style={rowStyle} gutter={0} justify='space-between'>
					<LabelWrapper
						typeLabel={'primary'}
						text={data.name}
						color={'secondary'}
						tonality={12}
						fontSize={30}
						fontWeight={700}
					/>
					{isPermittedUpdateOrg && (
						<Button
							type='primary'
							onClick={() => {
								setUpdateOrgObj(data);
								setEditVisible(true);
								form.setFieldsValue({
									name: data.name,
									abn: data.abn === 'null' ? '' : data.abn,
									acn: data.acn === 'null' ? '' : data.acn
								});
							}}
						>
							Edit Details
						</Button>
					)}
				</Row>
				<Row style={rowStyle} gutter={0} justify='space-between'>
					<Col
						flex='none'
						xs={{
							order: 2
						}}
						md={{
							order: 2
						}}
						lg={{
							order: 2
						}}
						xl={{ order: 2 }}
					>
						<div className='infoDiv'>
							{labels.map((label, index) => {
								if (label.textProp !== 'null')
									return (
										<div className='info' key={index}>
											<LabelWrapper
												typeLabel={'secondary'}
												text={label.text}
												color={'text'}
												tonality={1}
												fontWeight={500}
											/>
											<LabelWrapper
												typeLabel={'prop'}
												text={label.textProp}
												color={'secondary'}
												tonality={2}
											/>
										</div>
									);
								return null;
							})}
						</div>
					</Col>
				</Row>
			</IndividualOrgInfoWrapper>

			<Modal
				title='Edit Organisation'
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateOrgLoading}
						disabled={buttonDisable}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					labelCol={{ lg: { span: 3, offset: 2 }, sm: { span: 3, offset: 0 } }}
					wrapperCol={{ lg: { span: 17 }, sm: { span: 16 }, xs: { span: 10 } }}
					onFinish={onFinish}
					autoComplete='off'
					onValuesChange={() => setButtonDisable(updateChange())}
				>
					<Form.Item label='Name: ' name='name' rules={fieldsValidator.Name} style={colStyle}>
						<Input />
					</Form.Item>
					<Form.Item label='ABN: ' name='abn' rules={fieldsValidator.ABN} style={colStyle}>
						<Input />
					</Form.Item>
					<Form.Item label='ACN: ' name='acn' rules={fieldsValidator.ACN} style={colStyle}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</IsoWidgetBox>
	);
};

export default IndividualOrgDetails;
