import React, { useState, useEffect } from 'react';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { smallTimeOptions } from '@iso/assets/styles/constants';
import { SimpleTable, SearchBar } from '@Z/components/atoms';
import { Col, Row, Collapse, Empty } from 'antd';
import Tag from '@iso/components/uielements/tag';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import ComponentButton from '@Z/components/atoms/ComponentButton';
import { LabelWrapper } from '@Z/components/atoms';

const CompactFloorsTable = ({ sitesData, floorsData }) => {
	const [filteredData, setFilteredData] = useState([]);
	const { Panel } = Collapse;

	useEffect(() => {
		const floorsBySite = [];
		filteredData.map((floor) => {
			const res = floorsBySite.some((item) => {
				if (item.siteID === floor.siteID) {
					item.floors.push(floor);
					return true;
				}
				return false;
			});
			if (!res) {
				floorsBySite.push({
					siteID: floor.siteID,
					name: floor.siteName,
					floors: [floor]
				});
			}
			return true;
		});
	}, [floorsData, sitesData, filteredData]);

	useEffect(() => {
		setFilteredData(floorsData);
	}, [floorsData, sitesData]);

	const handleSearch = (query) => {
		const lowerCaseQuery = query.toLowerCase();

		const matchedSites = sitesData.filter((site) => site.name.toLowerCase().includes(lowerCaseQuery));

		const floorsFromMatchedSites = floorsData.filter((floor) =>
			matchedSites.some((site) => site.siteID === floor.siteID)
		);

		const matchedFloors = floorsData.filter((floor) => floor.name.toLowerCase().includes(lowerCaseQuery));

		const combinedFloors = [...new Set([...floorsFromMatchedSites, ...matchedFloors])];

		setFilteredData(combinedFloors);
	};

	const NameWithlink = withHyperLink((a) => {
		return a.children;
	});

	const ButtonWithLink = withHyperLink(() => <ComponentButton text={'View'} />);

	const columns = [
		{
			title: 'Floor',
			dataIndex: 'name',
			key: 'name',
			width: 40,
			ellipsis: true,
			render: (text, record) => (
				<NameWithlink type='floor' dataID={record.floorID}>
					{text}
				</NameWithlink>
			)
		},

		{
			title: 'Added On',
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: 40,
			ellipsis: true,
			render: (text, record) => <p> {new Date(record.createdDate).toLocaleString('en-AU', smallTimeOptions)} </p>
		},
		{
			title: 'Status',
			key: 'actions',
			align: 'center',
			render: (text, record) =>
				record.deletedDate ? (
					<Tag
						color='red'
						style={{
							height: '28px',
							lineHeight: '28px',
							fontSize: '12px',
							fontWeight: '500',
							borderRadius: '4px'
						}}
					>
						DELETED
					</Tag>
				) : (
					<Tag
						color='green'
						style={{
							height: '28px',
							lineHeight: '28px',
							fontSize: '12px',
							fontWeight: '500',
							borderRadius: '4px'
						}}
					>
						ACTIVE
					</Tag>
				),
			ellipsis: true,
			width: 20
		},
		{
			key: 'View',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ButtonWithLink type='floor' dataID={record.floorID} />
				</div>
			),
			ellipsis: true,
			width: 20
		}
	];

	const filteredSites = sitesData.filter((site) => filteredData.some((floor) => floor.siteID === site.siteID));

	return (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<Row>
				<NameWithlink type='sites'>
					<LabelWrapper
						typeLabel={'primary'}
						text={'Sites'}
						color={'secondary'}
						tonality={0}
						fontSize={20}
						fontWeight={500}
					/>
				</NameWithlink>
				<NameWithlink type='floors'>
					<LabelWrapper
						typeLabel={'primary'}
						text={'& Floors'}
						color={'secondary'}
						tonality={0}
						fontSize={20}
						fontWeight={500}
						style={{ marginLeft: '5px' }}
					/>
				</NameWithlink>
			</Row>
			<SearchBar
				style={{ marginBottom: '10px', marginTop: '4px' }}
				placeholder='Search Site or Floor Name'
				enterButton='Search'
				loading={false}
				onChange={(e) => {
					handleSearch(e.target.value, floorsData);
				}}
				onSearch={(query) => {
					handleSearch(query);
				}}
			/>
			<Row gutter={24} justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<Collapse>
						{filteredSites.length > 0 &&
							filteredSites.map((site) => (
								<Panel
									header={site.name}
									key={site.siteID}
									extra={<ButtonWithLink type='site' dataID={site.siteID} />}
								>
									<SimpleTable
										columns={columns}
										pageSize={5}
										dataSource={filteredData.filter((floor) => floor.siteID === site.siteID)}
									/>
								</Panel>
							))}
					</Collapse>
					{filteredSites.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
				</Col>
			</Row>
		</IsoWidgetBox>
	);
};

export default CompactFloorsTable;
