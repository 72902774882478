import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import superFetch from '@iso/lib/helpers/superFetch';
import React, { useState } from 'react';
import { RevokeButtonWrapper } from './styles';

const RevokePersonPermissionModal = ({
	children,
	data,
	setPermissionStateUpdate,
	revokePermission,
	revokeAllPermissions
}) => {
	const { entity } = data;
	const [loading, setLoading] = useState(false);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleRevokePermission = async () => {
		setLoading(true);
		let res;
		if (revokePermission) {
			res = await superFetch.post('/access/revokePermission', {
				permissionTypeID: entity.permissionTypeID,
				...(entity.teamIDs ? { teamIDs: [entity.teamIDs] } : null),
				...(entity.personIDs ? { personIDs: [entity.personIDs] } : null),
				...(entity.deviceID ? { deviceIDs: [entity.deviceID] } : null),
				...(entity.assetGroupID ? { assetGroupIDs: [entity.assetGroupID] } : null)
			});
		} else {
			res = await superFetch.post('/access/revokePermissionByIDs', {
				permissionIDs: entity.permissionIDs
			});
		}
		if (res.statusCode === 200) {
			notification('success', 'Permission Revoked Successfully.');
			setPermissionStateUpdate(true);
		} else {
			notification('error', 'Error Revoking Permission.');
		}
		setConfirmModalVisible(false);
		setLoading(false);
	};
	return (
		<>
			{children ? (
				children
			) : (
				<RevokeButtonWrapper>
					<Button
						className={'revoke-permission-btn' + (revokeAllPermissions ? ' all' : '')}
						onClick={() => {
							setConfirmModalVisible(true);
						}}
						size={'small'}
					>
						Revoke{revokeAllPermissions && ' All'}
					</Button>
				</RevokeButtonWrapper>
			)}
			<Modal
				title={`Confirm Revoke Permission`}
				danger
				width={700}
				visible={confirmModalVisible}
				onCancel={() => setConfirmModalVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setConfirmModalVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleRevokePermission()}>
						Revoke
					</Button>
				]}
				style={{ textAlign: 'center' }}
			>
				Are you sure you want to revoke {revokeAllPermissions ? 'all of ' : ' '}
				<span style={{ fontWeight: '600', color: '#FF5B58' }}>{entity.permissionNameOwner}</span>
				's permission for <span style={{ fontWeight: '600', color: '#FF5B58' }}> {entity.name}</span>?
			</Modal>
		</>
	);
};

export default RevokePersonPermissionModal;
