import React from 'react';
import { Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import UserStatusAlertWrapper from './style';

const UserStatusAlert = ({ userStatus }) => {
	return (
		<UserStatusAlertWrapper>
			{userStatus[2] === 'info' && (
				<Alert
					className='alertContainer'
					message={
						<div className='alertMessage'>
							<span className='alertTitle'>Enrolled</span>
							<p>
								This person has been invited but hasn't completed their profile.{' '}
								<a
									href='https://support.zezamii.com/hc/en-us/articles/9972501707663-Understanding-People-in-the-Zezamii-Dashboard'
									target='_blank'
									rel='noopener noreferrer'
									className='learnMoreLink'
								>
									Learn More
									<i className='material-icons-outlined'>open_in_new_down</i>
								</a>
							</p>
						</div>
					}
					type='info'
					icon={<InfoCircleOutlined className='alertIcon' />}
					showIcon
				/>
			)}
			{userStatus[2] === 'error' && (
				<Alert
					className='alertContainer'
					message={
						<div className='alertMessage'>
							<span className='alertTitle'>Deleted</span>
							<p>
								This person has been deleted.{' '}
								<a
									href='https://support.zezamii.com/hc/en-us/articles/9972501707663-Understanding-People-in-the-Zezamii-Dashboard'
									target='_blank'
									rel='noopener noreferrer'
								>
									Learn More
									<i className='material-icons-outlined'>open_in_new_down</i>
								</a>
							</p>
						</div>
					}
					type='error'
					icon={<InfoCircleOutlined className='alertIcon' />}
					showIcon
				/>
			)}
			{userStatus[2] === 'disabled' && (
				<Alert
					className='alertContainer'
					message={
						<div className='alertMessage'>
							<span className='alertTitle'>Disabled</span>
							<p>
								This person has been disabled.{' '}
								<a
									href='https://support.zezamii.com/hc/en-us/articles/9972501707663-Understanding-People-in-the-Zezamii-Dashboard'
									target='_blank'
									rel='noopener noreferrer'
								>
									Learn More
									<i className='material-icons-outlined'>open_in_new_down</i>
								</a>
							</p>
						</div>
					}
					type='warning'
					icon={<InfoCircleOutlined className='alertIcon' />}
					showIcon
				/>
			)}
		</UserStatusAlertWrapper>
	);
};

export default UserStatusAlert;
