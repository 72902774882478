import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntRadiobox = (ComponentName) => styled(ComponentName)`
	&.ant-radio-wrapper,
	.ant-radio-wrapper,
	&.ant-radio-button-wrapper {
		font-size: 13px;
		color: ${palette('text', 1)};

		.ant-radio-inner {
			&:after {
				background-color: ${palette('primary', 0)};
			}
		}

		.ant-radio-checked .ant-radio-inner,
		.ant-radio-indeterminate .ant-radio-inner {
			border-color: ${palette('primary', 0)};
		}

		.ant-radio:hover .ant-radio-inner,
		.ant-radio-input:focus + .ant-radio-inner {
			border-color: ${palette('primary', 0)};
		}

		.ant-radio-disabled .ant-radio-inner:after {
			background-color: #ccc;
		}

		&:hover {
			color: ${palette('primary', 0)};
			.ant-radio-inner {
				border-color: ${palette('primary', 0)};
			}
		}

		.ant-radio-checked {
		}
	}

	.ant-radio-button-wrapper:not(:first-child)::before {
		content: none;
	}
	.ant-radio-button-wrapper {
		border-radius: 4px !important;
		border-color: ${palette('border', 0)};
		border-left-width: 1px !important;

		&.ant-radio-button-wrapper-checked {
			background-color: ${palette('primary', 0)} !important;
			border-color: ${palette('primary', 0)} !important;
			color: ${palette('whitescale', 0)};

			&:not(.ant-radio-button-wrapper-disabled) {
				border-color: ${palette('primary', 0)};

				&:before {
					background-color: white;
				}

				&:hover {
					border-color: ${palette('primary', 0)};
					color: ${palette('whitescale', 0)};
				}
			}
		}
		:focus {
			outline: none;
		}
	}
`;

export default AntRadiobox;
