import { all, takeEvery, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';
import actions from './actions';
import { GenericDefaultCategoryDeviceCard, SmartLockDeviceCard, GenericDeviceCard } from '@Z/components/atoms';

const getRemoteConfig = (state) => state.remoteConfig.remoteConfig.defaultCategory;
const componentMap = {
	GenericDefaultCategoryDeviceCard: GenericDefaultCategoryDeviceCard,
	SmartLockDeviceCard: SmartLockDeviceCard
};

export function* loadDevicesData() {
	yield takeEvery('LOAD_DEVICES_DATA', function* () {
		const defaultCategory = yield select(getRemoteConfig);
		// Step 1: Map the names to the imported icons and components
		const data = yield superFetch.get('/devices').then((res) => {
			var result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data.map((row) => ({
						categoryID: row.categoryID,
						siteID: row.siteID ? row.siteID : 'N/A',
						orgID: row.orgID,
						categoryCreatedDate: row.categoryCreatedDate,
						categoryName: row.categoryName,
						categoryDescription: row.categoryDescription,
						isBookable: row.isBookable,
						isDefault: row.isDefault,
						defaultCategoryID: row.defaultCategoryID,
						icon: row.isDefault
							? defaultCategory[row.defaultCategoryID].icon.trim()
							: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FAssetDeviceIconOutlined24x24.svg?alt=media&token=0548b52c-0489-4ab5-8f51-c4f1d8fa7724',
						markerIcon: row.isDefault
							? defaultCategory[row.defaultCategoryID].markerIcon.trim()
							: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FAssetDevice_default_Marker.svg?alt=media&token=e51a3f0b-61f8-4a7e-bbcb-afd5559c951f',
						cardComponent: row.isDefault
							? componentMap[defaultCategory[row.defaultCategoryID].component]
							: GenericDeviceCard,
						showCustomFieldOnCard: row.isDefault
							? defaultCategory[row.defaultCategoryID].showCustomFieldOnCard
							: false,
						showOnAdditionalFields: row.isDefault
							? defaultCategory[row.defaultCategoryID].showOnAdditionalFields
							: false,
						editableCustomFields: row.isDefault
							? defaultCategory[row.defaultCategoryID].editableCustomFields
							: false,
						typeName: row.typeName,
						typeID: row.typeID,
						uID: row.uID,
						name: row.name,
						createdDate: row.createdDate,
						modifiedDate: row.modifiedDate,
						deletedDate: row.deletedDate,
						location: row.location.length > 0 ? row.location : []
					}));
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;

				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_DEVICES_SUCCESS,
				devicesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_DEVICES_ERROR,
				error: data.error
			});
		}
	});
}

const getCategories = (state) => state.deviceCategories.deviceCategoriesData;
const getDevices = (state) => state.devices.devicesData;

export function* loadCustomFieldsByCategories() {
	yield takeLatest(['LOAD_DEVICE_CATEGORIES_SUCCESS', 'LOAD_DEVICES_SUCCESS'], function* () {
		const categories = yield select(getCategories);
		const devices = yield select(getDevices);

		// Check if there are categories and devices.
		if (categories.length > 0 && devices.length > 0) {
			const fields = yield all(
				categories.map((cat) => cat.fields.length > 0 && call(loadCategoryCustomFields, cat.categoryID))
			);

			// Clean the Fields from the categories
			const devicesFields = fields
				.filter((i) => i)
				.map((i) => i.fields)
				.flat();

			// Associate the fields with the devices.
			devices.forEach((device) => {
				devicesFields.forEach((field) => {
					if (device.uID === field.deviceID) {
						device['fields'] = field.fields;
					}
				});
			});

			// Put out the success Response
			yield put({
				type: actions.LOAD_DEVICES_CUSTOM_FIELDS_SUCCESS,
				devicesWithFields: devices
			});
		}
	});
}
function* loadCategoryCustomFields(categoryID) {
	let catFields = [];
	yield superFetch
		.get(`/deviceCategories/${categoryID}/devices/fields`)
		.then((res) => {
			if (res.statusCode === 200) {
				catFields = { categoryID: categoryID, fields: res.data };
			} else {
				catFields = { categoryID: categoryID, fields: [] };
			}
		})
		.catch((e) => e);
	return catFields;
}
export default function* rootSaga() {
	yield all([fork(loadDevicesData), fork(loadCustomFieldsByCategories)]);
}
