import { smallTimeOptions } from '@iso/assets/styles/constants';
import { ComponentLabel, RenderDeviceActions } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeviceSummaryWrapper from './DeviceSummary.styles';
const IndividualDeviceSummary = ({ deviceID }) => {
	const { devicesData } = useSelector((state) => state.devices);
	const { floorsData } = useSelector((state) => state.floors);

	const [device, setDevice] = useState({});
	const [floors, setFloors] = React.useState([]);

	// get Devices data from the store. (this is the data that is used in the modal)
	useEffect(() => {
		if (devicesData.length > 0) {
			setDevice(devicesData.find((device) => device.uID === deviceID) || {});
		}
	}, [devicesData, deviceID]);

	// Find all the floors device belongs to.
	useEffect(() => {
		if (floorsData.length > 0) {
			if (device.location && device.location.length > 0) {
				// find floors that is includes in the device location array of object
				let floorsArray = floorsData.filter((floor) =>
					device.location.find((location) => location.floorID === floor.floorID)
				);
				setFloors(floorsArray);
			}
		}
	}, [floorsData, device]);

	return (
		<div>
			<DeviceSummaryWrapper>
				<Row>
					<Col lg={24} md={24} sm={24} xs={24} className='titleHeader'>
						<ComponentLabel
							style={{
								fontSize: '16px',
								marginLeft: '9px',
								color: 'black',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								wordBreak: 'break-all',
								display: 'inline-block'
							}}
							text='About Device'
						/>

						<div className='deviceDiv'>
							<RenderDeviceActions device={device} splitActions={false} onIndividualDeviceModal={true} />
						</div>
					</Col>
				</Row>
				<Row className='secondaryInfo'>
					<Col lg={12} md={12} sm={12} xs={12}>
						<label className='infoLabel'>Bookable: </label>
						<p className='infoProp'>{device.isBookable ? 'Yes' : 'No'}</p>
						<label className='infoLabel'>Device ID: </label>
						<p className='infoProp'>{device.uID ? device.uID : 'N/A'}</p>
						<label className='infoLabel'>Device Type: </label>
						<p className='infoProp'>{device.typeName}</p>
						<label className='infoLabel'>Floor: </label>
						<p className='infoProp'>
							{floors.length > 0
								? floors.map((floor, key) => (
										<span key={floor.floorID}>
											{floor.name}
											{key < floors.length - 1 ? ', ' : null}
										</span>
								  ))
								: 'N/A'}
						</p>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12}>
						<label className='infoLabel'>Date Aquired:</label>
						<p className='infoProp'>
							{new Date(device.createdDate).toLocaleString('en-AU', smallTimeOptions)}
						</p>
						<label className='infoLabel'>{'Last Updated:'}</label>
						<p className='infoProp'>
							{new Date(device.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
						</p>
						<label className='infoLabel'>Category: </label>
						<p className='infoProp'>{device.categoryName}</p>
					</Col>
				</Row>
			</DeviceSummaryWrapper>
		</div>
	);
};

export default IndividualDeviceSummary;
