import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';
import { MoreOutlined } from '@ant-design/icons';
const DeviceSummaryWrapper = styled.div`
	background-color: ${palette('secondary', 7)};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	padding: 15px 10px 15px 5px;

	//margin: 5px 24px 0px 0px; //T R B L
	border-right: 1px solid ${palette('border', 0)};

	@media only screen and (max-width: 991px) {
		border-right: 0px;
		margin-bottom: 5px;
		/* flex-direction: row; */
	}
	.secondaryInfo {
		padding-top: 10px;
		padding-left: 10px;
	}

	.titleHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.deleteButton {
		&:hover {
			border-color: ${palette('error', 2)};
			color: ${palette('error', 2)};
		}
	}

	.deviceHeaderTitle {
		font-size: 12px;
		font-weight: 700;
	}

	.deviceDiv {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 5px;
	}

	.deviceHeaderCol {
		padding-bottom: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: flex-start;

		.deviceTitle {
			font-size: 10px;
			font-weight: 700;
			opacity: 0.8;
			color: ${palette('secondary', 5)};
			align-self: center;
		}
	}
	.idLabel {
		font-size: 11px;
		font-weight: 500;
		opacity: 0.5;
		color: ${palette('secondary', 5)};
		padding-left: 10;
		margin-left: 10;
	}
	.actionButton {
		display: flex;
		justify-content: flex-end;

		.ant-btn {
			margin-right: 10px;

			&:last-child {
				margin-right: 0px;
			}
		}
		.switchDeviceButton {
			margin-right: 10px;

			@media only screen and (max-width: 991px) {
				margin-right: 0px;
				display: none;
			}
		}
		.deletedTag {
			height: 36px;
			line-height: 36px;
			font-size: 12px;
			font-weight: 500;
			${borderRadius('4px')};
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.infoLabel {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
	}

	.infoActivityLabel {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.7;
	}

	.infoTitle {
		font-size: 20px;
		font-weight: 700;
		opacity: 0.8;
		color: black;
		margin-left: 20px;
		vertical-align: middle;
	}
	.infoProp {
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 10px;
		color: ${palette('secondary', 3)};
	}
	.infoActivityProp {
		font-size: 12px;
		font-weight: 400;
		opacity: 0.7;
		display: inline-block;
		margin-left: 5px;
	}

	.editButton {
		display: flex;
		justify-content: flex-end;
	}
	.descriptionArea {
		display: 'block';
		margin-top: '11px';
	}
	.editDevice {
		display: flex;
		justify-content: center;

		@media only screen and (max-width: 1000px) {
			padding-top: 10px;
		}
	}
`;
export default DeviceSummaryWrapper;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export { MoreOutlinedIcon };
