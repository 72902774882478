import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import TextArea from 'antd/lib/input/TextArea';
import Form from '@iso/components/uielements/form';
import { useSelector } from 'react-redux';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const BuySmartLockButton = ({ visible = false, setVisible, children, type }) => {
	const person = useSelector((state) => state.Auth.userProfile.defaultProfile);

	const [form] = Form.useForm();
	const [confirmLoading, setConfirmLoading] = useState(false);

	const handleSubmit = async () => {
		setConfirmLoading(true);
		const formData = form.getFieldsValue(true);
		const data = {
			personID: person.personID,
			orgID: person.orgID,
			inquiry: formData.inquiry
		};

		superFetch
			.post('/access/inquiryEmail', {
				data
			})
			.then((res) => {
				if (res.statusCode === 200) {
					notification('success', 'Your inquiry has been sent successfully.');
					return true;
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
			});

		handleCancel();
		setConfirmLoading(false);
	};

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
	};

	return (
		<div>
			{children ? (
				children
			) : (
				<Button style={{ margin: '0px 10px' }} onClick={() => setVisible(true)}>
					Buy Smart {type ? type : ' Locks'}
				</Button>
			)}
			<Modal
				title='Buy Smart Locks'
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button key='submit' loading={confirmLoading} htmlType='submit' form={form} type='primary'>
						Send Inquiry
					</Button>
				]}
			>
				<Form form={form} id={form} onFinish={handleSubmit} layout='vertical'>
					<Form.Item
						name='inquiry'
						label='Enter your purchase inquiry below:'
						rules={[
							{ required: true, message: 'Please input your inquiry!', whitespace: false },
							[...fieldsValidator.Description]
						]}
					>
						<TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default BuySmartLockButton;
