import React from 'react';
import { LabelWrapper } from '@Z/components/atoms';
import { Button } from '@iso/components/uielements';
import { Col, Row } from 'antd';
import SessionExpiredModalWrapper from './styles';
import { InfoCircleOutlined } from '@ant-design/icons';
import theme from '@iso/config/theme/default';
import authAction from '@iso/redux/auth/actions';
import { useDispatch } from 'react-redux';
const { logout } = authAction;

const SessionExpiredModal = ({ viewModal, setViewModal, loading }) => {
	const dispatch = useDispatch();

	return (
		<SessionExpiredModalWrapper
			visible={viewModal}
			className={'modal'}
			maskClosable={false}
			destroyOnClose={false}
			footer={[
				<Row justify={'end'} style={{ marginRight: 20 }}>
					<Button
						loading={loading}
						key='submit'
						htmlType='submit'
						type='primary'
						onClick={() => dispatch(logout())}
					>
						Log in
					</Button>
				</Row>
			]}
			closable={false}
		>
			<Row align={'middle'}>
				<Col>
					<InfoCircleOutlined style={{ fontSize: '30px', color: theme.palette.primary[0] }} />
				</Col>
				<Col>
					<LabelWrapper
						text='Your session has expired'
						typeLabel='primary'
						fontSize={'20'}
						style={{ marginLeft: 10 }}
					/>
					<LabelWrapper
						text='Please log in again to complete your profile'
						typeLabel='prop'
						style={{ marginLeft: 10 }}
						color='text'
						tonality={6}
					/>
				</Col>
			</Row>
		</SessionExpiredModalWrapper>
	);
};

export default SessionExpiredModal;
