import React, { useEffect } from 'react';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { PageHeader, Loading, AddFloor } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const FloorsHeader = () => {
	const [loadings, setLoading] = React.useState(true);
	const { floorsData, pending } = useSelector((state) => state.floors);
	const { sitesData } = useSelector((state) => state.sites);
	const [availableFloor, setAvailableFloor] = React.useState([]);

	useEffect(() => {
		setLoading(true);
		if (!pending) {
			setAvailableFloor(floorsData.filter((floor) => !floor.deletedDate));
			setLoading(false);
		}
	}, [floorsData, pending]);
	const deletedNumber = availableFloor.filter((Dfloors) => Dfloors.deletedDate !== null).length;

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	const isPermittedAddFloor = useManagePermittedRoles('addFloor', {
		orgID: [floorsData[0]?.orgID],
		siteID: sitesData.map((site) => site.siteID) || []
	});

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<PageHeader
			title='Floors'
			counter={deletedNumber < 1 ? availableFloor.length : availableFloor.length - deletedNumber}
		>
			{isPermittedAddFloor && <AddFloor />}
		</PageHeader>
	);
};

export default FloorsHeader;
