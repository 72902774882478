import styled from 'styled-components';
import { palette } from 'styled-theme';

import { MoreOutlined } from '@ant-design/icons';
const SearchAssetGroupsWrapper = styled.div`
	width: calc(100% - 60px);

	.header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 2px;
	}

	.ant-divider-horizontal {
		margin-top: 10px;
	}

	.ant-divider-vertical {
		margin: 0px -5px 0px 10px;
	}
	.rowDiv {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.statsText {
		font-size: 14px;
		font-weight: 400;
		color: ${palette('secondary', 0)};
	}
	.headerText {
		font-size: 16px;
		font-weight: 500;
		color: ${palette('secondary', 0)};
		display: flex !important;
		align-items: baseline !important;
		cursor: pointer;
	}

	.paragraphText {
		margin-right: 20px;
	}

	.customText {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export { MoreOutlinedIcon };
export default SearchAssetGroupsWrapper;
