import { Modal, SelectPeopleDropDownFormComponent } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import siteAction from '@iso/redux/sites/actions';
import teamAction from '@iso/redux/teams/actions';
import { Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { loadSites } = siteAction;
const { loadTeams } = teamAction;

const AddTeam = () => {
	const dispatch = useDispatch();
	const [viewModal, setViewModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [contactInfoDisable, setContactInfoDisable] = useState(true);
	const [selectedCType, setSelectedCType] = useState({});

	const { sitesData, pending } = useSelector((state) => state.sites);
	const { contactTypes } = useSelector((state) => state.types);
	const { FBPersonID } = useSelector((state) => state.Auth.userProfile);
	const { personID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const [form] = Form.useForm();

	useEffect(() => {
		if (sitesData.length < 1 && pending) {
			dispatch(loadSites());
		}
	}, [pending, sitesData, dispatch]);

	const handleCancel = () => {
		setViewModal(false);
		setContactInfoDisable(true);
		form.resetFields();
	};
	const handleSubmit = async () => {
		setConfirmLoading(true);
		const formData = removeEmptyOrNull(form.getFieldsValue(true));
		formData.admin = [{ userID: FBPersonID, personID }];

		const body = { ...formData };
		delete body.people;
		const newTeam = await superFetch.post('/teams', body).then((res) => {
			if (res.statusCode === 201) {
				dispatch(loadTeams());
				handleCancel();
				notification('success', 'Team created Successfully.');
			} else if (res.error.statusCode === 400) {
				notification('error', 'Please fill out all required information.');
			} else {
				notification('error', 'There is an Error! We are mending the problem, try again soon!.');
			}
			return res;
		});
		if (newTeam.statusCode === 201) {
			const { teamID } = newTeam.data;
			await superFetch.patch(`/teams/${teamID}/members`, { personID: formData.people }).then((res) => {
				if (res.statusCode === 201) {
					notification('success', 'Team Members Added.');
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
			});
		}

		setConfirmLoading(false);
	};

	return (
		<div>
			<Button type='primary' onClick={() => setViewModal(true)}>
				Create New Team
			</Button>
			<Modal
				title='Add New Team'
				visible={viewModal}
				onCancel={handleCancel}
				width={650}
				confirmLoading={confirmLoading}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button loading={confirmLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Add Team
					</Button>
				]}
			>
				<Form form={form} id={form} onFinish={handleSubmit} layout='vertical'>
					<Row style={{ justifyContent: 'space-between' }}>
						<Col span={11}>
							<Form.Item
								style={{ width: '100%' }}
								name='name'
								label='Team Name'
								rules={fieldsValidator.Name}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								style={{ width: '100%' }}
								name='siteID'
								label='Site'
								rules={fieldsValidator.Required}
							>
								<Select placeholder={'Select Site'}>
									{sitesData.map((site) => (
										<Option key={site.siteID} value={site.siteID}>
											{site.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name='description' label='Description' rules={fieldsValidator.Description}>
								<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
							</Form.Item>
						</Col>
					</Row>
					<Row align='bottom'>
						<Col span={11}>
							<Form.Item
								style={{ width: '100%' }}
								label='Contact Type'
								name={['contact', 0, 'contactTypeID']}
							>
								<Select
									onSelect={(x, y) => {
										setSelectedCType(y);
										setContactInfoDisable(false);
									}}
									placeholder={'Type'}
								>
									{contactTypes.map((type) => (
										<Option key={type.contactTypeID} value={type.contactTypeID}>
											{type.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col offset={1} flex={'auto'}>
							<Form.Item
								style={{ width: '100%' }}
								name={['contact', 0, 'contactInformation']}
								rules={
									fieldsValidator[
										selectedCType.children ? selectedCType.children : fieldsValidator.Name
									]
								}
							>
								<Input disabled={contactInfoDisable} placeholder='Enter Contact Information' />
							</Form.Item>
						</Col>
					</Row>
					<Divider orientation='left' orientationMargin={0}>
						Add Team Member
					</Divider>
					<SelectPeopleDropDownFormComponent key={'AddTeamModal'} />
				</Form>
			</Modal>
		</div>
	);
};

export default AddTeam;
