import styled from 'styled-components';

const DownloadModalWrapper = styled.div`
	h1 {
		margin: 10px 10px;
		font-size: 25px;
	}
	h3 {
		font-size: 12px;
		margin: 10px;
		text-align: center;
	}
	.row {
		width: 100%;
		.col {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
`;

export default DownloadModalWrapper;
