import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const WelcomeWrapper = styled.div`
	width: 100%;
	margin-top: 10px;

	.welcomeContainer {
		padding: 30px;

		.subHeadLine {
			font-size: 20px;
			font-weight: 500;
			color: ${palette('text', 2)};

			.highlightedText {
				font-weight: 600;
			}
		}

		.promoteActionContainer {
			.subSubLine {
				font-size: 16px;
				font-weight: 500;
				color: ${palette('text', 0)};
			}
			.actionBoxContainer {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				flex-wrap: wrap;

				.actionBox {
					margin-top: 15px;
					width: 300px;
					${borderRadius('5px')};
					border: 1px solid ${palette('grayscale', 3)};

					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;
					text-align: center;

					.actionBoxContent {
						margin: 20px 0;

						.actionBoxTitle {
							font-size: 16px;
							font-weight: 500;
							text-align: start;
							padding: 0 15px;
							margin-bottom: 15px;
							color: ${palette('text', 0)};
						}
					}
				}
			}
		}
	}
`;
export default WelcomeWrapper;
