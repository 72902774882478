import actions from './actions';

const initState = {
	orgSettings: [],
	personSettings: [],
	groupSettingsData: [],
	selectedSettingGroupID: null,
	pending: true
};

const defaultSettingsReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_ORGANISATION_SETTINGS_SUCCESS:
			return { ...state, orgSettings: action.payload, pending: false };
		case actions.LOAD_ORGANISATION_SETTINGS_ERROR:
			return { ...state, orgSettings: [], pending: false };
		case actions.LOAD_PERSON_SETTINGS_SUCCESS:
			return { ...state, personSettings: action.payload, pending: false };
		case actions.LOAD_PERSON_SETTINGS_ERROR:
			return { ...state, personSettings: [], pending: false };
		case actions.UPDATE_SELECTED_SETTING_GROUP:
			return {
				...state,
				selectedSettingGroupID: action.payload
			};
		default:
			return state;
	}
};
export default defaultSettingsReducer;
