import basicStyle from '@iso/assets/styles/constants';
import React from 'react';
import Loader from '@iso/components/utility/loader';
const { loadingStyle } = basicStyle;

const Spinner = () => (
	<div style={loadingStyle}>
		<Loader />
	</div>
);

export default Spinner;
