import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';
const FloorPlanMapWrapper = styled.div`
	.showFilterCollapse {
		.ant-collapse-item > .ant-collapse-header {
			display: flex;
			justify-content: flex-end;
			padding-right: 0px;
			padding-top: 0px;
		}

		.ant-collapse-content > .ant-collapse-content-box {
			padding-top: 0px !important;

			.filterPanelContent {
				display: flex;
				justify-content: space-between;

				.ant-checkbox-wrapper {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.siteMap {
		overflow: hidden;
		border: 2px solid ${palette('primary', 0)};
		height: 500px;
		width: 100%;
		${borderRadius('10px')};
	}

	.ant-layout {
		background-color: transparent;
	}

	.devicesMainContentContainer {
		overflow: auto;
		padding-bottom: 5px;
		padding-right: 5px;
		margin-bottom: -5px;
		margin-right: -5px;

		.deviceContentContainer {
			margin: 0px 0px 0px 17px;
			height: 100%;

			.categoryContentContainer {
				overflow: hidden;
				margin-top: 15px;
				max-height: min-content;
				position: relative;
			}

			.deviceItemsContainer {
				height: fit-content;
			}
		}
	}

	.cardContainer {
		padding: 10px;
		column-width: 200px;
		column-gap: 4px;
		width: 100%;
		display: flow-root;
	}
`;

export default FloorPlanMapWrapper;
