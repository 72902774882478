import React, { useState } from 'react';
import Card from '@iso/components/uielements/card';
import GenericDeviceCardWrapper from './GenericDeviceCardWrapper.styles';
import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import { copyToClipBoard } from '@iso/lib/helpers/utility';
import { RenderDeviceActions } from '@Z/components/atoms';
import Icon from '@ant-design/icons';

const { Meta } = Card;
const GenericDeviceCard = ({ device }) => {
	const [deviceModalVisible, setDeviceModalVisible] = useState(false);

	return (
		<GenericDeviceCardWrapper>
			<Card
				className='hoverCard customCard'
				size='small'
				title={
					<IndividualDeviceInformationModal
						visible={deviceModalVisible}
						setVisible={setDeviceModalVisible}
						data={{
							deviceID: device.uID,
							categoryID: device.categoryID
						}}
					>
						<p className='wordFix'>
							<span className='spanFix' onClick={() => setDeviceModalVisible(true)}>
								{device.name}
							</span>
						</p>
					</IndividualDeviceInformationModal>
				}
				bordered
				hoverable
				extra={
					<div className='statsContainer'>
						<Icon
							component={() => <img src={device.icon} width={24} height={24} alt='Icon' />}
							className='customIcon'
						/>
					</div>
				}
				actions={[RenderDeviceActions({ device: device })]}
			>
				<Meta
					description={[
						<div className='stat' key={1}>
							<p>
								UID: {device.uID}
								<span className='imageIcon'>
									<i
										className='material-icons-outlined imageIcon'
										style={{
											cursor: 'pointer'
										}}
										onClick={() => copyToClipBoard(device.uID)}
									>
										copy_content
									</i>
								</span>
							</p>
						</div>
					]}
				/>
			</Card>
		</GenericDeviceCardWrapper>
	);
};

export default GenericDeviceCard;
