import styled from 'styled-components';
import { Modal } from '@Z/components/atoms';

const SessionExpiredModalWrapper = styled(Modal)`
	.ant-modal-header {
		border-radius: 10px !important;
	}
	.ant-modal-footer {
		border-radius: 10px !important;
	}
	.ant-modal-content {
		border-radius: 10px !important;
	}
`;

export default SessionExpiredModalWrapper;
