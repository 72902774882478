import styled from 'styled-components';
import { palette } from 'styled-theme';

export const RevokeButtonWrapper = styled.div`
	display: flex;
	justify-content: center;

	.revoke-permission-btn {
		color: ${palette('error', 0)};
		border: 1px solid ${palette('error', 2)};
		&:hover {
			color: ${palette('error', 1)};
			border: 1px solid ${palette('error', 0)};
		}
	}
	.all {
		background-color: ${palette('error', 0)};
		color: #fff;
		&:hover {
			background-color: ${palette('error', 1)};
			color: #fff;
		}
	}
`;
