/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 10-02-2022 14:38:13
 * @desc [description]
 */

import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { Loading } from '@Z/components/atoms';
import { EntityInformationTabs } from '@Z/components/organisms';
import { OrgInfoContainer } from '@Z/components/molecules';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import Collapse from '@iso/components/uielements/collapse';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { entityType } from '@iso/config/entityType';

const { Panel } = Collapse;

const OrganisationInfoSites = () => {
	const [loadings, setLoading] = React.useState(true);
	const [currentOrg, setCurrentOrg] = React.useState({});
	const organisation = useSelector((state) => state.Organisation.organisationData);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);

	React.useEffect(() => {
		setLoading(true);
		setCurrentOrg(organisation.find((org) => org.orgID === orgID) || {});
		setLoading(false);
	}, [organisation, orgID]);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox>
			<Collapse className='panelLayout' expandIconPosition='right' style={{ borderRadius: '4px' }}>
				<Panel className='primaryTitle' header='Organisation Details:' key={1}>
					<Row gutter={[16, 16]}>
						<Col lg={12} md={12} sm={24} xs={24}>
							<OrgInfoContainer data={currentOrg} />
						</Col>
						<Col lg={12} md={12} sm={24} xs={24}>
							<EntityInformationTabs data={currentOrg} type={entityType.WORKSPACE} />
						</Col>
					</Row>
				</Panel>
			</Collapse>
		</IsoWidgetBox>
	);
};
export default OrganisationInfoSites;
