import actions from './actions';

const initState = { floorsData: [], pending: true, searchable: true };

const floorsReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_FLOORS_SUCCESS:
			return { ...state, floorsData: action.floorsData, pending: false };
		case actions.LOAD_FLOORS_ERROR:
			return { ...state, floorsData: [], pending: false };
		default:
			return state;
	}
};
export default floorsReducer;
