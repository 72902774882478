import { LabelWrapper, Modal } from '@Z/components/atoms';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { notification } from '@iso/components/index';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Tooltip from '@iso/components/uielements/tooltip';
import SuperFetch from '@iso/lib/helpers/superFetch';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import peopleAction from '@iso/redux/people/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserTypeWrapper } from './SetUserTypeWrapper.styles';
const { loadPeople } = peopleAction;

const SetUserTypeModal = ({ visible, setVisible, data, children }) => {
	const { floorsData } = useSelector((state) => state.floors);
	const dispatch = useDispatch();

	const isPermittedAssignUserType = useManagePermittedRoles('assignUserType', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: floorsData.map((floor) => floor.siteID === data.siteID)
	});

	const [loading, setLoading] = useState(false);
	const [roles, setRoles] = useState([]);
	const [selectedUserType, setSelectedUserType] = useState(null);
	const [form] = Form.useForm();

	const getRoles = useCallback(async () => {
		try {
			const res = await SuperFetch.get('/misc/usertypes', {});
			const filteredRoles = res.data.filter((role) => role.userTypeID !== data.userTypeID);

			setRoles(filteredRoles);
		} catch (error) {
			notification('error', 'Failed, Please try again.');
		}
	}, [data.userTypeID]);

	useEffect(() => {
		getRoles();
	}, [getRoles, visible]);

	const handleAssignRole = async () => {
		setLoading(true);
		try {
			const res = await SuperFetch.patch(
				`/people/${data.personID}`,
				{
					userTypeID: selectedUserType
				},
				{}
			);

			if (res.statusCode === 200) {
				notification('success', 'The user Type has been assigned.');
				setLoading(false);
				setSelectedUserType(null);
				handleCancel();
				dispatch(loadPeople());
			}
		} catch (error) {
			setLoading(false);
			notification('error', 'Failed, Please try again.');
		}
	};

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
	};

	const getIconName = (role) => {
		switch (role) {
			case 'Admin':
				return <UsergroupAddOutlined />;
			case 'User':
				return <UsergroupAddOutlined />;
			default:
				return <UsergroupAddOutlined />;
		}
	};

	return (
		<>
			<Tooltip
				title={
					!isPermittedAssignUserType ? "You don't have permission" : 'Assign a Specific Role to this Person'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
							disabled={!isPermittedAssignUserType}
						>
							Set User Type
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Select Member Type`}
				visible={visible}
				onCancel={() => setVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => handleCancel()}>
						Cancel
					</Button>,
					<Button type='primary' loading={loading} key='delete' onClick={() => handleAssignRole()}>
						Assign
					</Button>
				]}
			>
				<SetUserTypeWrapper>
					{roles &&
						roles.map((option) => {
							return (
								<Button
									gutter={4}
									key={option.userTypeID}
									className='container'
									onClick={() => {
										setSelectedUserType(option.userTypeID);
									}}
									style={{
										backgroundColor: option.userTypeID === selectedUserType ? 'white' : '#f0f0f0'
									}}
								>
									{option.userTypeID === selectedUserType ? (
										<i className='material-icons-outlined'>check_circle</i>
									) : (
										<i className='material-icons-outlined'>{getIconName()}</i>
									)}
									<LabelWrapper text={option.name} fontWeight={500} />
								</Button>
							);
						})}
				</SetUserTypeWrapper>
			</Modal>
		</>
	);
};

export default SetUserTypeModal;
