import Button from '@iso/components/uielements/button';
import React from 'react';
import SearchAssetGroupsWrapper from './searchAssetGroupsWrapper.styles';
import { Typography } from 'antd';
import { IndividualAssetGroupModal } from '@Z/components/molecules';
import { Row, Col } from 'antd';
import { Divider } from '@Z/components/atoms';
const SearchAssetGroupsCard = ({ assetGroup, setVisibility }) => {
	const { Paragraph } = Typography;

	const [viewModalVisible, setViewModalVisible] = React.useState(false);
	const viewIndividualAssetGroup = () => {
		setViewModalVisible(true);
	};
	return (
		<div>
			<SearchAssetGroupsWrapper>
				<p className='headerText' onClick={() => viewIndividualAssetGroup()}>
					{assetGroup.name}
				</p>
				<Row className='rowDiv'>
					<Col xs={18}>
						<div className='header'>
							<p className='statsText'>Total: {assetGroup.assets.length}</p>
							<Divider type='vertical'> </Divider>
							<p className='statsText'>Type: {assetGroup.typeName}</p>
						</div>

						<div className='paragraphContainer'>
							{assetGroup.description && (
								<Paragraph
									className='paragraphText'
									ellipsis={{
										rows: 2
									}}
									style={{ marginRight: '5px', marginBottom: '0' }}
								>
									{assetGroup.description}
								</Paragraph>
							)}
						</div>
					</Col>
					<Col xs={6}>
						<Button size='small' onClick={() => viewIndividualAssetGroup()}>
							View
						</Button>
					</Col>
					<Divider style={{ marginTop: '5px' }} type='horizontal' />
				</Row>
			</SearchAssetGroupsWrapper>

			<IndividualAssetGroupModal
				data={{ groupID: assetGroup.groupID }}
				visible={viewModalVisible}
				setVisible={setViewModalVisible}
			>
				{' '}
				{/* Empty Space is to not render the view button */}
			</IndividualAssetGroupModal>
		</div>
	);
};

export default SearchAssetGroupsCard;
