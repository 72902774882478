import styled from 'styled-components';
const DropdownSpaceWrapper = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1d3557;
	font-weight: 500;
	padding: 10px 0;

	&:hover {
		outline: #457b9d 2px solid;
	}
`;
export { DropdownSpaceWrapper as SpaceWrapper };
