import styled from 'styled-components';
import { palette } from 'styled-theme';
import Card from '@iso/components/uielements/card';

const CompactFloorPreviewWrapper = styled(Card)`
	.person_title {
		display: flex;
		margin-top: 30px;
		justify-content: space-between;

		.name {
			font-size: 22px;
			color: ${palette('secondary', 0)};
		}
	}

	.email {
		opacity: 0.8;
	}

	.person_information {
		margin-bottom: 10px;
		font-weight: 500;
	}

	.label {
		font-size: 15px;
		margin-top: 10px;
		opacity: 0.6;
	}
`;

const CompactPeopleTableWrapper = styled.div`
	@media only screen and (max-width: 992px) {
		.previewButton {
			display: none;
		}
	}
	@media only screen and (min-width: 992px) {
		.actionButton {
			display: none;
		}
	}
`;

export { CompactPeopleTableWrapper };
export default CompactFloorPreviewWrapper;
