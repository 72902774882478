import { ComponentLabel, Loading, RevokePersonPermissionModal, SimpleTable } from '@Z/components/atoms';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IndividualAssetGroupModal from '../IndividualAssetGroupModal';
import { ExpandablePermissionTable } from './components/ExpandablePermissionTable';
import AccessControlTableWrapper from './personTeamAccessControlTable.styles';

const PersonTeamAccessControlTable = ({ entityID, entityType }) => {
	const NameWithlink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});
	const { devicesData } = useSelector((state) => state.devices);
	const { assetGroupsData } = useSelector((state) => state.assetGroups);
	const {
		remoteConfig: { constants, accessControlPermissionType }
	} = useSelector((state) => state.remoteConfig);

	const [expandedRow, setExpandedRow] = useState(0);
	const [permittedDevices, setPermittedDevices] = useState([]);
	const [permissionStateUpdated, setPermissionStateUpdated] = useState(false);
	const [deviceGroupModalVisible, setDeviceGroupModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (entityID) {
			const getData = async () => {
				setLoading(true);
				const permittedDevices = await getAccessData(
					entityType === constants.ENTITY_TYPE_ID_PERSON ? { personID: entityID } : { teamID: entityID }
				);
				setPermittedDevices(deviceGrouper(permittedDevices, constants));
				setLoading(false);
				setPermissionStateUpdated(false);
			};
			getData();
		}
	}, [entityID, entityType, permissionStateUpdated, devicesData, constants]);

	//!!!WE NEED CHECK HOW WE WILL IMPLEMENT THE PERMISSION HERE
	//  const isPermittedRevokePermission = useManagePermittedRoles('aCPermissionManagement', {
	// 	orgID: [orgID]
	// 	// siteID: permittedLocationsObjects.map((item) => item.siteID).filter((item) => item),
	// 	// floorID: permittedLocationsObjects.map((item) => item.floorID).filter((item) => item)
	// });

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			flex: 1,
			ellipsis: true,
			render: (text, record) => {
				if (record.permissions[0].entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP) {
					const group = assetGroupsData.find(
						(deviceGroup) => deviceGroup.groupID === record.permissions[0].entityID
					);
					return (
						<IndividualAssetGroupModal
							data={{ groupID: group.groupID }}
							visible={deviceGroupModalVisible}
							setVisible={setDeviceGroupModalVisible}
						>
							<ComponentLabel
								style={{
									fontSize: '12px',
									fontWeight: '500',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									paddingLeft: '10px',
									cursor: 'pointer'
								}}
								text={group.name}
								onClick={() => setDeviceGroupModalVisible(true)}
							/>
						</IndividualAssetGroupModal>
					);
				} else {
					return (
						<NameWithlink type='device' dataID={Number(record.uID)}>
							<ComponentLabel
								style={{
									fontSize: '12px',
									fontWeight: '500',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									paddingLeft: '10px'
								}}
								text={text}
							/>
						</NameWithlink>
					);
				}
			},
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => record['displayName'] === value
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Type',
			key: 'entityType',
			align: 'center',
			flex: 1,
			ellipsis: true,
			dataIndex: ['permissions', '0', 'entityType'],
			render: (record) => {
				return (
					<p title={record.name.replace('Asset', '') || 'N/A'}>{record.name.replace('Asset', '') || 'N/A'}</p>
				);
			}
		},
		{
			title: 'Permission',
			dataIndex: ['permissions', '0', 'permissionType'],
			key: 'permissionType',
			ellipsis: true,
			flex: 1,
			align: 'center',
			filters: [
				{ text: accessControlPermissionType[1].title, value: accessControlPermissionType[1].id },
				{ text: accessControlPermissionType[2].title, value: accessControlPermissionType[2].id },
				{ text: accessControlPermissionType[3].title, value: accessControlPermissionType[3].id }
			],
			onFilter: (value, record) => record.permissions[0].permissionTypeID === value,
			render: (record) => {
				return <p title={record.shortDescription || 'N/A'}>{record.shortDescription || 'N/A'}</p>;
			}
		},
		{
			title: 'Actions',
			dataIndex: ['permissions', '0'],
			flex: 1,
			align: 'center',
			key: 'revoke',
			render: (record) => {
				//? Depends entityTypeID we have information to be able to understand if is a group or device that we want to revoke specific permissions
				return (
					<RevokePersonPermissionModal
						setPermissionStateUpdate={setPermissionStateUpdated}
						data={{
							entity: {
								permissionTypeID: record.permissionTypeID,
								...(record.entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP
									? {
											assetGroupID: record.assetGroupID,
											name: record.assetGroup.name,
											entityTypeID: record.entityTypeID
									  }
									: {
											deviceID: record.deviceID,
											name: record.device.name,
											entityTypeID: record.entityTypeID
									  }),
								...(entityType === constants.ENTITY_TYPE_ID_TEAM
									? { teamIDs: record.teamID, permissionNameOwner: record.team.name }
									: { personIDs: record.personID, permissionNameOwner: record.person.displayName })
							}
						}}
						revokePermission
						revokeAllPermissions
					/>
				);
			},
			ellipsis: true
		}
	];

	return (
		<AccessControlTableWrapper>
			{loading ? (
				<Loading />
			) : (
				<SimpleTable
					columns={columns}
					pageSize={7}
					dataSource={permittedDevices}
					rowKey='uID'
					expandable={
						entityType === constants.ENTITY_TYPE_ID_PERSON && {
							expandedRowRender: (a) => (
								<ExpandablePermissionTable
									permData={a.permissions}
									setPermissionStateUpdated={setPermissionStateUpdated}
								/>
							),
							expandedRowKeys: expandedRow,
							onExpand: (expanded, record) => {
								const keys = [];
								if (expanded) {
									keys.push(record.uID);
								}
								setExpandedRow(keys);
							}
						}
					}
				/>
			)}
		</AccessControlTableWrapper>
	);
};

const deviceGrouper = (permittedDevices, constants) => {
	const groupedDevice = permittedDevices.reduce((result, current) => {
		const { device, assetGroup } = current;
		const key =
			current.entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP ? assetGroup.assetGroupID : device.deviceID;
		result[key] = result[key] || [];
		result[key].push(current);
		return result;
	}, {});

	const devices = Object.keys(groupedDevice).map((key) => ({
		uID: key,
		name:
			groupedDevice[key][0].entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP
				? groupedDevice[key][0].assetGroup.name
				: groupedDevice[key][0].device.name,
		permissions: groupedDevice[key]
	}));

	return devices;
};

export default PersonTeamAccessControlTable;
