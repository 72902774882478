import React, { useEffect, useState } from 'react';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { PageHeader, Loading, RevokePermissionModal } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { BuySmartLockButton, CreatePermissionModal } from '@Z/components/atoms';

const ACHeaderComponent = () => {
	const [loadings, setLoading] = React.useState(true);
	const { floorsData, pending } = useSelector((state) => state.floors);
	const [buySmartLockVisible, setBuySmartLockVisible] = useState(false);
	const [visibleCreatePermissionModal, setVisibleCreatePermissionModal] = useState(false);
	const [visibleRevokePermissionModal, setVisibleRevokePermissionModal] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (!pending) {
			setLoading(false);
		}
	}, [floorsData, pending]);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<PageHeader title='Access'>
			<div style={{ margin: '0px -10px 0px', display: 'flex', justifyContent: 'flex-end' }}>
				<CreatePermissionModal
					visible={visibleCreatePermissionModal}
					setVisible={setVisibleCreatePermissionModal}
				/>
				<RevokePermissionModal
					visible={visibleRevokePermissionModal}
					setVisible={setVisibleRevokePermissionModal}
				/>
				<BuySmartLockButton visible={buySmartLockVisible} setVisible={setBuySmartLockVisible} />
			</div>
		</PageHeader>
	);
};

export default ACHeaderComponent;
