import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import Tooltip from '@iso/components/uielements/tooltip';
import { entityType } from '@iso/config/entityType';
import { getPermissionByEntityType } from '@iso/lib/helpers/getPrimary';
import SuperFetch from '@iso/lib/helpers/superFetch';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import floorsAction from '@iso/redux/floors/actions';
import organisationAction from '@iso/redux/organisation/actions';
import siteAction from '@iso/redux/sites/actions';
import teamsAction from '@iso/redux/teams/actions';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const { loadTeams } = teamsAction;
const { loadFloors } = floorsAction;
const { loadOrganisation } = organisationAction;
const { loadSites } = siteAction;

function AddEntityAdminModal({ children, visible, setVisible, data, type, edit, setEdit }) {
	const { adminTypes } = useSelector((state) => state.types);
	const { peopleData } = useSelector((state) => state.people);
	const { permissionName, rules } = getPermissionByEntityType(type, data);

	// Permission to add or update contact from entities
	const isPermittedUpdate = useManagePermittedRoles(permissionName, rules);

	const [form] = Form.useForm();
	const displayNames = useGetPersonDisplayName(true);
	const dispatch = useDispatch();

	const [saveLoading, setSaveLoading] = useState(false);
	const [availableAdminType, setAvailableAdminType] = useState([]);
	const [availablePeople, setAvailablePeople] = useState([]);

	useEffect(() => {
		if (type === entityType.TEAM) {
			const teamPeople = peopleData.filter((person) =>
				person.team.some((personTeam) => personTeam.teamID === data.teamID)
			);
			setAvailablePeople(teamPeople);
		} else {
			setAvailablePeople(peopleData);
		}
	}, [peopleData, type, data.teamID]);

	useEffect(() => {
		if (edit) {
			const nameAdmin = adminTypes?.find((admin) => admin.permissionID === data.permissionID).name;
			setAvailableAdminType({ permissionID: data?.permissionID, name: nameAdmin });
			form.setFieldsValue({
				permissionID: nameAdmin,
				personID: data?.personID
			});
		} else {
			form.resetFields();
		}
	}, [data, form, edit, visible, adminTypes]);

	useEffect(() => {
		if (!edit) {
			setAvailableAdminType(adminTypes?.filter((types) => types.isAdminType && types.relatedToEntityID === type));
		}
	}, [data, edit, type, adminTypes]);

	const handleCancel = () => {
		form.resetFields();
		setSaveLoading(false);
		setVisible(false);
		setEdit(false);
	};

	const dispatchActions = () => {
		switch (type) {
			case entityType.WORKSPACE:
				dispatch(loadOrganisation());
				break;
			case entityType.SITE:
				dispatch(loadSites());
				break;
			case entityType.FLOOR:
				dispatch(loadSites());
				dispatch(loadFloors());
				break;
			case entityType.TEAM:
				dispatch(loadTeams());
				break;
			default:
				break;
		}
	};

	const onFinish = async () => {
		const formData = form.getFieldsValue(true);

		// check if the personID or roleID is not empty, null or undefined
		if (!formData.personID || !formData.permissionID) {
			notification('error', 'Please fill out all required information with proper type.');
			setSaveLoading(false);
			return;
		}
		// set the loading true
		setSaveLoading(true);

		switch (type) {
			case entityType.WORKSPACE:
				formData.entityID = data.orgID;
				break;
			case entityType.SITE:
				formData.entityID = data.siteID;
				break;
			case entityType.FLOOR:
				formData.entityID = data.floorID;
				break;
			case entityType.TEAM:
				formData.entityID = data.teamID;
				break;
			case entityType.PERSON:
				formData.entityID = data.personID;
				break;
			default:
				break;
		}

		try {
			const res = await SuperFetch.post(`/roles/grantRole`, formData);

			if (res.statusCode === 201) {
				dispatchActions();
				handleCancel();
				notification('success', 'New role permission granted.');
			}
		} catch (error) {
			setSaveLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Tooltip
					title={!isPermittedUpdate ? "You don't have permission" : 'Grant Roles to People.'}
					placement='topLeft'
				>
					<>
						<Button size='small' onClick={() => setVisible(true)} disabled={!isPermittedUpdate}>
							Add Admin
						</Button>
					</>
				</Tooltip>
			)}
			<Modal
				title={`Grant Admin Role`}
				visible={visible}
				onCancel={handleCancel}
				width={720}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={() => form.resetFields()}>
						Reset
					</Button>,
					<Button loading={saveLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Grant Role
					</Button>
				]}
			>
				<Form
					id={form}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					onFinish={onFinish}
					autoComplete='off'
					form={form}
				>
					<Row>
						<Col span={12}>
							<Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 12 }} label='Person' name='personID'>
								<Select
									placeholder={'Select A Person'}
									showSearch
									optionFilterProp='children'
									filterOption={(input, option) => {
										return option.children?.toLowerCase().includes(input?.toLowerCase());
									}}
									filterSort={(optionA, optionB) => {
										return optionA.children
											?.toLowerCase()
											.localeCompare(optionB.children?.toLowerCase());
									}}
								>
									{availablePeople.map((person, key) => {
										return (
											<Option
												key={key}
												value={person.personID}
												title={displayNames[person.personID]?.title}
											>
												{displayNames[person.personID]?.displayName}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item wrapperCol={{ span: 12 }} label='Type' name='permissionID'>
								<Select
									placeholder={'Select Type'}
									showSearch
									disabled={edit}
									optionFilterProp='children'
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
								>
									{availableAdminType.length > 0 &&
										availableAdminType.map((type, key) => (
											<Option key={key} value={type.permissionID}>
												{type.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
}

export default AddEntityAdminModal;
