const actions = {
	LOAD_DEFAULT_ORGANISATION_SETTINGS_DATA: 'LOAD_DEFAULT_ORGANISATION_SETTINGS_DATA',
	LOAD_DEFAULT_ORGANISATION_SETTINGS_SUCCESS: 'LOAD_DEFAULT_ORGANISATION_SETTINGS_SUCCESS',
	LOAD_DEFAULT_ORGANISATION_SETTINGS_ERROR: 'LOAD_DEFAULT_ORGANISATION_SETTINGS_ERROR',

	LOAD_DEFAULT_PERSON_SETTINGS_DATA: 'LOAD_DEFAULT_PERSON_SETTINGS_DATA',
	LOAD_DEFAULT_PERSON_SETTINGS_SUCCESS: 'LOAD_DEFAULT_PERSON_SETTINGS_SUCCESS',
	LOAD_DEFAULT_PERSON_SETTINGS_ERROR: 'LOAD_DEFAULT_PERSON_SETTINGS_ERROR',

	LOAD_GROUP_SETTINGS_DATA: 'LOAD_GROUP_SETTINGS_DATA',
	LOAD_GROUP_SETTINGS_SUCCESS: 'LOAD_GROUP_SETTINGS_SUCCESS',
	LOAD_GROUP_SETTINGS_ERROR: 'LOAD_GROUP_SETTINGS_ERROR',

	loadDefaultSettingsOrganisation: () => ({
		type: actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_DATA
	}),

	loadDefaultSettingsPerson: () => ({
		type: actions.LOAD_DEFAULT_PERSON_SETTINGS_DATA
	}),

	loadSettingsGroup: () => ({
		type: actions.LOAD_GROUP_SETTINGS_DATA
	})
};
export default actions;
