import React from 'react';
import { Tag } from 'antd';
import { TagStatusWrapper } from './RenderStatusTag.styles';

const getStatusBackgroundColor = (color) => {
	switch (color) {
		case 'green':
			return '#5DAB33';
		case 'gold':
			return '#DC982E';
		case 'red':
			return '#CF1422';
		case 'blue':
			return '#0062D9';
		default:
			break;
	}
};

const RenderStatusTag = (statusText, color) => {
	const backgroundColor = getStatusBackgroundColor(color);
	return (
		<TagStatusWrapper>
			<Tag className='containerTagStatus' color={color}>
				<div
					className='tagStatus'
					style={{
						backgroundColor
					}}
				/>
				{statusText}
			</Tag>
		</TagStatusWrapper>
	);
};
export default RenderStatusTag;
