export const deviceGrouper = (permittedDevices = [], constants) => {
	if (permittedDevices.length === 0) {
		return [];
	}
	const groupedDevice = permittedDevices.reduce((result, current) => {
		const { device, assetGroup } = current;
		const key =
			current.entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP ? assetGroup.assetGroupID : device.deviceID;
		result[key] = result[key] || [];
		result[key].push(current);
		return result;
	}, {});

	const devices = Object.keys(groupedDevice).map((key) => ({
		uID: key,
		name:
			groupedDevice[key][0].entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP
				? groupedDevice[key][0].assetGroup.name
				: groupedDevice[key][0].device.name,
		permissions: groupedDevice[key]
	}));

	return devices;
};
