import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Collapse from '@iso/components/uielements/collapse';
import FloorAdditionalInfoComponentWrapper from './Styles';
import { Divider } from '@Z/components/atoms';
const { Panel } = Collapse;
const FloorAdditionalInfoComponent = ({ data }) => {
	const [devicesOnFloor, setDevicesOnFloor] = React.useState([]);
	const [locksOnFloor, setLocksOnFloor] = React.useState([]);
	const { devicesData } = useSelector((state) => state.devices);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	useEffect(() => {
		if (data.floorID && devicesData.length > 0) {
			const devices = [];
			const locks = [];

			devicesData.forEach((item) => {
				const location = item?.location?.map((location) => {
					if (location.floorID === data.floorID) return location;
					return null;
				});
				const cleanDevices = location.filter((item) => item);

				if (cleanDevices.length > 0) devices.push({ item });

				const MatchingFloor = item?.location?.filter((location) => location.floorID === data.floorID);

				if (MatchingFloor?.length > 0) {
					if (item.defaultCategoryID === constants.SMART_LOCKS) {
						locks.push(item);
					}
				}
			});

			setDevicesOnFloor(devices);
			setLocksOnFloor(locks);
		} else {
			setDevicesOnFloor([]);
			setLocksOnFloor([]);
		}
	}, [data, devicesData, constants]);

	const headerText = (
		<Divider orientation='left' plain>
			<span className='headerTitle'> Additional Floor Information</span>
		</Divider>
	);

	return Object.keys(data).length > 0 && devicesOnFloor.length > 0 ? (
		<FloorAdditionalInfoComponentWrapper>
			<Collapse expandIconPosition='right' style={{ borderRadius: '4px' }} ghost>
				<Panel header={headerText} key={1}>
					<div>Total devices on floor: {devicesOnFloor.length} </div>
					<div>Total locks on floor: {locksOnFloor.length}</div>
				</Panel>
			</Collapse>
		</FloorAdditionalInfoComponentWrapper>
	) : null;
};
export default FloorAdditionalInfoComponent;
