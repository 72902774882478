import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

import { MoreOutlined } from '@ant-design/icons';
const GenericDeviceCardWrapper = styled.div`
	display: flow-root;
	width: inherit;
	overflow: auto;
	margin-bottom: 4px;

	.btnActions {
		font-size: 8px;
		line-height: 8px;
	}
	.customIcon {
		display: inline-block;
		margin-left: 5px;
		font-size: 24px;
	}
	.wordFix {
		overflow-wrap: break-word;
		max-width: 170px;
		display: flex;
	}

	.spanFix {
		cursor: pointer;
		max-width: 220px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		word-break: break-all;
		display: inline-block;
		line-height: 16px;
	}
	.infoLabel {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
		margin-right: 4px;
		margin-left: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.ant-card-hoverable {
		cursor: default;
	}
	.ant-card-actions > li {
		margin: 0;
	}

	.infoActivityProp {
		font-size: 12px;
		font-weight: 400;
		opacity: 0.7;
		/* display: inline-block;
		display: flex;
		justify-content: space-between;
		align-items: center; */
	}

	.customCard {
		max-width: 315px;
		overflow: auto;
		background-color: ${palette('secondary', 7)};
		border-radius: 4px;
		border: 2px transparent solid !important;
	}
	.customCard:hover {
		border: 2px ${palette('secondary', 0)} solid !important;
	}
	.ant-card-bordered {
		border-color: ${palette('grayscale', 2)};
		${borderRadius()};
	}
	.ant-card-actions {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.ant-card-head {
		border-bottom-color: ${palette('grayscale', 3)};

		.ant-card-head-title {
			font-size: 18px;
			color: ${palette('secondary', 0)};
		}
		.ant-card-extra > span {
			font-size: 18px;
			padding: 10px;
			margin-right: -15px;
		}
	}

	.device-card-content {
		display: flex;
		justify-content: start;
		flex-direction: column;

		.contact-container {
			font-size: 15px;
			font-weight: 500;
			text-transform: capitalize;
			padding-bottom: 5px;
		}
		.address-container {
			padding-left: 5px;
		}
	}
	.statsContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: space-evenly;
		align-items: center;
		text-align: center;

		@media only screen and (max-width: 991px) {
			margin-top: 10px;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	.ant-card-body {
		padding: 8px 12px !important;
	}

	.stat {
		min-width: 65px;
		display: flex;
		justify-content: flex-start;
	}

	.imageIcon {
		width: 15px;
		display: inline-block;
		margin-left: 5px;
		font-size: 14px;
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export { MoreOutlinedIcon };
export default GenericDeviceCardWrapper;
