import { DownOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu } from '@iso/components/uielements/dropdown';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import floorsAction from '@iso/redux/floors/actions';
import { Modal } from '@Z/components/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { loadFloors } = floorsAction;
const { colStyle } = basicStyle;

const UpdateFloor = (orgID, siteID, floorID) => {
	const perm = useManagePermittedRoles('updateFloor', {
		orgID: [orgID],
		siteID: [siteID],
		floorID: [floorID]
	});
	return perm;
};
const DeleteFloor = (orgID, siteID) => {
	const perm = useManagePermittedRoles('deleteFloor', {
		orgID: [orgID],
		siteID: [siteID]
	});
	return perm;
};

const RenderActionMenu = ({ record }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState(false);
	const [editVisible, setEditVisible] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(true);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [floorToBeDeleted, setFloorToBeDeleted] = useState({});

	// HOC
	const ViewFloorWithLink = withHyperLink(() => <p>View Floor</p>);

	useEffect(() => {
		form.resetFields();
	}, [initialValues, form]);

	if (!record) return null;

	const action = (_text, record) => [
		{
			key: '1',
			label: <ViewFloorWithLink type='floor' dataID={record.floorID} />
		},
		UpdateFloor(record.orgID, record.siteID, record.floorID)
			? {
					key: '2',
					label: (
						<p
							onClick={() => {
								setInitialValues(record);
								setEditVisible(true);
							}}
						>
							Update
						</p>
					)
			  }
			: null,
		DeleteFloor(record.orgID, record.siteID)
			? {
					key: '3',
					label: (
						<p
							onClick={() => {
								setFloorToBeDeleted(record);
								setDeleteModalVisible(true);
							}}
						>
							Delete
						</p>
					)
			  }
			: null
	];

	// Update Functions
	const handleCancel = () => {
		form.resetFields();
		setInitialValues(false);
		setEditVisible(false);
	};
	const onFinish = () => {
		setLoading(true);
		const newFloorInfo = {};
		const formData = form.getFieldsValue(true);
		if (formData.name !== initialValues.name && formData.name !== '') {
			newFloorInfo.name = formData.name;
		}
		if (formData.description !== initialValues.description && formData.description !== '') {
			newFloorInfo.description = formData.description;
		}
		superFetch
			.patch('/floors/' + initialValues.floorID, newFloorInfo)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Floors Information Updated.');
					}
					dispatch(loadFloors());
				}
				setLoading(false);
				handleCancel();
				return res;
			})
			.catch(() => {
				setLoading(false);
				notification('error', 'Update Failed, Please try again.');
			});
	};
	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		return (
			(initialValues.name === formData.name || formData.name === '') &&
			(initialValues.description === formData.description || formData.description === '')
		);
	};

	//  Delete Functions
	const handleDeleteModalCancel = () => {
		setDeleteModalVisible(false);
	};
	const handleDelete = () => {
		setLoading(true);
		superFetch
			.delete(`/floors/${floorToBeDeleted.floorID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Floor Deleted.');
						setDeleteModalVisible(false);
					}
					dispatch(loadFloors());
				}
				setLoading(false);
				return res;
			})
			.catch(() => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	return (
		<React.Fragment>
			<Dropdown overlay={<DropdownMenu items={action('s', record)} />} trigger={['click']}>
				<Button size='small'>
					Actions <DownOutlined />
				</Button>
			</Dropdown>
			{initialValues && (
				<Modal
					title='Edit Floor'
					visible={editVisible}
					onCancel={handleCancel}
					destroyOnClose={true}
					footer={[
						<Button key='back' onClick={handleCancel}>
							Cancel
						</Button>,
						<Button
							form={form}
							key='submit'
							htmlType='submit'
							type='primary'
							loading={loading}
							disabled={buttonDisable}
						>
							Update
						</Button>
					]}
				>
					<Form
						form={form}
						id={form}
						labelCol={{
							lg: { span: 6, offset: 0 },
							sm: { span: 6, offset: 0 }
						}}
						wrapperCol={{
							lg: { span: 17 },
							sm: { span: 16 },
							xs: { span: 10 }
						}}
						onFinish={onFinish}
						initialValues={initialValues}
						autoComplete='off'
						onValuesChange={() => setButtonDisable(updateChange())}
					>
						<Form.Item label='Name' name='name' rules={fieldsValidator.Name}>
							<Input />
						</Form.Item>

						<Form.Item
							label='Description'
							name='description'
							rules={fieldsValidator.Description}
							style={colStyle}
						>
							<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
						</Form.Item>
					</Form>
				</Modal>
			)}
			<Modal
				title={`Delete a Floor`}
				danger
				visible={deleteModalVisible}
				onCancel={handleDeleteModalCancel}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => handleDeleteModalCancel()}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete the floor{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{floorToBeDeleted.name}'</span>?
				</p>
			</Modal>
		</React.Fragment>
	);
};

export default RenderActionMenu;
