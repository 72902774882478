import React, { useEffect, useState } from 'react';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import DatePicker from '@iso/components/uielements/datePicker';
import { RadioButton, RadioGroup } from '@iso/components/uielements/radio';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import InputNumber from '@iso/components/uielements/InputNumber';
import superFetch from '@iso/lib/helpers/superFetch';
import deviceCategoryAction from '@iso/redux/deviceCategories/actions';
import { Modal, LeafletMap } from '@Z/components/atoms';
import { Col, Row, Divider, Empty } from 'antd';
import Input from '@iso/components/uielements/input';
import { useDispatch, useSelector } from 'react-redux';
import devicesAction from '@iso/redux/devices/actions';
import floorsAction from '@iso/redux/floors/actions';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import FloorMapFormItem, { DividerWrapper } from './addDeviceButton.style';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
const { loadDeviceCategories } = deviceCategoryAction;
const { loadDevices } = devicesAction;
const { loadFloors } = floorsAction;

const AddDeviceButton = () => {
	const dispatch = useDispatch();
	const [viewModal, setViewModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const {
		deviceCategoriesData,
		pending: categoryPending,
		currentCategory
	} = useSelector((state) => state.deviceCategories);
	const { floorsData, pending: floorsPending } = useSelector((state) => state.floors);
	const [marker, setMarker] = useState({});
	const [selectedFloor, setSelectedFloor] = useState(floorsData.length > 0 && floorsData[0].floorID);
	const MAP_HEIGHT = 460;
	const MAP_WIDTH = 800;
	const [form] = Form.useForm();
	const { fieldTypes } = useSelector((state) => state.types);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const [selectedCategory, setSelectedCategory] = useState({});
	const [map, setMap] = useState('');

	useEffect(() => {
		!floorsData.find((floor) => floor.floorID === selectedFloor) &&
			setSelectedFloor(floorsData.length > 0 && floorsData[0].floorID);
		const loadFloorPlan = async () => {
			const floor = floorsData.find((floor) => floor.floorID === selectedFloor) || floorsData[0];
			if (floor.url && floor.url.toString().toLowerCase() !== 'n/a') {
				await getDownloadURL(floor.url).then((url) => setMap(url));
			}
		};
		if (floorsData.length > 0) {
			loadFloorPlan();
		}
	}, [floorsData, selectedFloor]);

	useEffect(() => {
		if (deviceCategoriesData.length === 0 && categoryPending) dispatch(loadDeviceCategories()); // for choosing categories
		if (floorsData.length === 0 && floorsPending) dispatch(loadFloors()); // for choosing floor
	}, [dispatch, deviceCategoriesData, categoryPending, floorsData, floorsPending]);

	useEffect(
		() => {
			const allowedCategory = deviceCategoriesData.filter(
				(category) => !constants.ADD_DEVICE_EXCLUDED_CATEGORIES.includes(category.defaultCategoryID)
			);

			form.setFieldsValue({
				categoryID: constants.ADD_DEVICE_EXCLUDED_CATEGORIES.includes(currentCategory.defaultCategoryID)
					? allowedCategory[0].categoryID
					: currentCategory.categoryID
			});
			setSelectedCategory(currentCategory);
		},
		// eslint-disable-next-line
		[currentCategory]
	);

	// group category by type for select component to render
	const groupCategories = (categories) => {
		const options = [];
		// exclude categories that are not allowed to be added
		categories
			.filter((category) => !constants.ADD_DEVICE_EXCLUDED_CATEGORIES.includes(category.defaultCategoryID))
			.forEach((category) => {
				if (options.every((option) => option.typeID !== category.typeID)) {
					options.push({
						label: category.typeName,
						typeID: category.typeID,
						options: []
					});
				}
				options
					.find((option) => option.typeID === category.typeID)
					.options.push({
						label: category.name,
						value: category.categoryID
					});
			});
		return options;
	};

	const handleCancel = () => {
		setSelectedFloor(floorsData.length > 0 && floorsData[0].floorID);
		setViewModal(false);
		form.resetFields();
	};

	const unselectedOptions = (name) =>
		deviceCategoriesData
			.find((category) => category.categoryID === selectedCategory.categoryID)
			.fields.map((field) => {
				if (form.getFieldsValue().fields) {
					const formData = form.getFieldsValue();
					if (
						!formData.fields.map((f) => f.fieldID).includes(field.fieldID) ||
						formData.fields[name].fieldID === field.fieldID
					) {
						return (
							<Option key={field.fieldID} value={field.fieldID}>
								{field.title}
							</Option>
						);
					}
				}
				return null;
			});
	const handleSubmit = async () => {
		setConfirmLoading(true);
		const formData = form.getFieldsValue(true);
		const createDeviceBody = {
			name: formData.name,
			categoryID: formData.categoryID
		};

		const createFieldsBody = formData.fields
			? formData.fields.map(
					(field) =>
						field.fieldID &&
						(field.value || field.value === 0) && {
							fieldID: field.fieldID,
							value: field.value
						}
			  )
			: [];
		if (createFieldsBody.includes(undefined)) {
			notification('error', 'Please select and fill in all fields');
			setConfirmLoading(false);
			return;
		}

		//if floor and device location is provided, add them to the body
		if (selectedFloor) {
			const floorMap = floorsData.find((floor) => floor.floorID === selectedFloor).mapID;
			// if floor map and the marker are provided
			if (floorMap && floorMap !== 'N/A' && marker.lat && marker.lng) {
				// if the marker is inside the floor map
				if (marker.lat > MAP_HEIGHT || marker.lat < 0 || marker.lng > MAP_WIDTH || marker.lng < 0) {
					setConfirmLoading(false);
					return notification('error', 'Please select a valid device location on the map');
				} else {
					createDeviceBody.floorID = selectedFloor;
					createDeviceBody.mapID = floorMap;
					createDeviceBody.xAxis = marker.lng;
					createDeviceBody.yAxis = marker.lat;
				}
			}
		}

		const results = { success: 0, error: 0 };

		if (!formData.number || formData.number === 1) {
			if (
				await superFetch
					.post('/devices', {
						...createDeviceBody,
						name: createDeviceBody.name
					})
					.then((res) => {
						if (res.statusCode === 201) {
							//ToDo: add custom fields to device here in new superfetch req
							createFieldsBody &&
								createFieldsBody.length > 0 &&
								superFetch.post(`/devices/${res.data.AssetID}/fields`, createFieldsBody).then((res) => {
									if (res.statusCode === 200) {
										notification('success', 'Custom fields added.');
										return true;
									} else {
										notification('error', 'Custom fields not added.');
									}
								});
							return true;
						} else if (res.error.statusCode === 400) {
							notification('error', 'Please fill out all required information.');
						} else {
							notification('error', 'There is an Error! We are mending the problem, try again soon!.');
						}
					})
			) {
				results.success++;
			} else {
				results.error++;
			}
		} else {
			for (let i = 0; i < formData.number; i++) {
				if (
					await superFetch
						.post('/devices', {
							...createDeviceBody,
							name: `${createDeviceBody.name} ${i + 1}`
						})
						.then((res) => {
							if (res.statusCode === 201) {
								createFieldsBody.length > 0 &&
									superFetch
										.post(`/devices/${res.data.AssetID}/fields`, createFieldsBody)
										.then((res) => {
											if (res.statusCode === 200) {
												return true;
											}
										});
								return true;
							} else if (res.error.statusCode === 400) {
								notification('error', 'Please fill out all required information.');
							} else {
								notification(
									'error',
									'There is an Error! We are mending the problem, try again soon!.'
								);
							}
						})
				) {
					results.success++;
				} else {
					results.error++;
				}
			}
		}

		if (results.error > 0) {
			notification('error', `${results.error} device(s) failed to add!`);
		}
		if (results.success > 0) {
			dispatch(loadDevices());
			notification('success', `${results.success} device(s) added successfully!`);
		}
		handleCancel();
		setConfirmLoading(false);
	};

	const onChange = (fieldID, name) => {
		const { fields } = form.getFieldsValue();

		const fieldTypeID = deviceCategoriesData
			.find((category) => category.categoryID === selectedCategory.categoryID)
			.fields.find((field) => field.fieldID === fieldID).fieldTypeID;

		Object.assign(fields[name], { fieldTypeID });
		Object.assign(fields[name], { value: null });

		form.setFieldsValue({ fields });
	};

	return (
		<div>
			<Button
				type='primary'
				onClick={() =>
					deviceCategoriesData.length > 0
						? setViewModal(true)
						: notification('info', 'please create a new category before adding a new device.')
				}
			>
				Add New Device
			</Button>
			<Modal
				title='Add New Device'
				visible={viewModal}
				onCancel={handleCancel}
				width={1000}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button loading={confirmLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Add Device
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					onFinish={handleSubmit}
					layout='vertical'
					initialValues={{
						number: 1,
						floorID: floorsData.length > 0 && floorsData[0].floorID,
						categoryID: currentCategory.categoryID
					}}
				>
					<Row>
						<Col lg={9} md={9} sm={24} xs={24} style={{ padding: 40 }}>
							<Form.Item name='name' label='Name' rules={fieldsValidator.Name}>
								<Input />
							</Form.Item>
							<Form.Item name='categoryID' label='Category' rules={fieldsValidator.Required}>
								<Select
									placeholder={'Select Category'}
									onChange={(categoryID) => {
										setSelectedCategory(
											deviceCategoriesData.find((category) => category.categoryID === categoryID)
										);
										form.resetFields(['fields']);
									}}
									options={groupCategories(deviceCategoriesData)}
								/>
							</Form.Item>
							<Form.Item name='floorID' label='Floor'>
								<Select placeholder={'Select Floor'} onChange={(floorID) => setSelectedFloor(floorID)}>
									{floorsData &&
										floorsData.map((floor) => (
											<Option key={floor.floorID} value={floor.floorID}>
												{floor.name}
											</Option>
										))}
								</Select>
							</Form.Item>
							<Form.Item name='number' label='Number of Devices'>
								<InputNumber min={1} max={100} />
							</Form.Item>
						</Col>
						<Col lg={1} md={1} sm={0} xs={0}>
							<Divider type='vertical' style={{ height: '100%' }} />
						</Col>
						<Col lg={14} md={14} sm={24} xs={24}>
							<FloorMapFormItem
								label='Device Location'
								tooltip='Place marker on the floor map to select the location of the device.'
							>
								{floorsData.find((floor) => floor.floorID === selectedFloor) &&
								floorsData.find((floor) => floor.floorID === selectedFloor).url !== 'N/A' ? (
									<div style={{ height: 300, margin: 'auto' }}>
										<LeafletMap
											drawSingleMarkerEnabled
											mapHeight={MAP_HEIGHT}
											mapWidth={MAP_WIDTH}
											scrollWheelZoom={true}
											url={map}
											setMarker={setMarker}
										/>

										<Alert
											className='alertStyle'
											message='Click the icon in the top right of the map to to place the device location.'
											type='info'
											showIcon
										/>
									</div>
								) : (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={
											floorsData.length === 0 ? (
												<span>
													Please create a floor on the floors page and upload a floor map
												</span>
											) : (
												<span>Please upload a floor map on the floors page</span>
											)
										}
									/>
								)}
							</FloorMapFormItem>
						</Col>
					</Row>

					{selectedCategory.fields && selectedCategory.fields.length > 0 && (
						<div>
							<DividerWrapper>
								<Divider orientation='center'>Additional Information</Divider>
							</DividerWrapper>

							<Row style={{ display: 'flex', justifyContent: 'center' }}>
								<Form.List name='fields'>
									{(fields, { add, remove }) => (
										<div style={{ width: '500px' }}>
											{fields.map(({ key, name, ...restField }) => (
												<Row
													key={key}
													style={{ display: 'flex', marginBottom: 15 }}
													gutter={10}
												>
													<Col span={8}>
														<Form.Item {...restField} name={[name, 'fieldID']}>
															<Select
																placeholder={'Field'}
																onChange={(e) => onChange(e, name)}
															>
																{unselectedOptions(name)}
															</Select>
														</Form.Item>
													</Col>
													<Col span={14}>
														<Form.Item
															{...restField}
															name={[name, 'value']}
															rules={fieldsValidator.Required}
														>
															{form.getFieldsValue().fields[name].fieldTypeID === 1 ? (
																<Input placeholder='Value' />
															) : form.getFieldsValue().fields[name].fieldTypeID === 2 ? (
																<InputNumber placeholder='Value' />
															) : form.getFieldsValue().fields[name].fieldTypeID === 3 ? (
																<DatePicker placeholder='Date' />
															) : form.getFieldsValue().fields[name].fieldTypeID === 4 ? (
																<RadioGroup buttonStyle='outline'>
																	<RadioButton value={1}>Yes</RadioButton>
																	<RadioButton value={0}>No</RadioButton>
																</RadioGroup>
															) : (
																<Input />
															)}
														</Form.Item>
													</Col>
													<Col span={2} style={{ alignItems: 'center' }}>
														<Form.Item {...restField} style={{ marginBottom: 0 }}>
															<MinusCircleOutlined onClick={() => remove(name)} />
														</Form.Item>
													</Col>
												</Row>
											))}
											<Form.Item>
												<Button
													type='dashed'
													onClick={() =>
														add({
															fieldTypeID: fieldTypes[0]
																? fieldTypes[0].fieldTypeID
																: null
														})
													}
													block
													icon={<PlusOutlined />}
												>
													Add Custom Field
												</Button>
											</Form.Item>
										</div>
									)}
								</Form.List>
							</Row>
						</div>
					)}
				</Form>
			</Modal>
		</div>
	);
};

export default AddDeviceButton;
