export const settingFieldType = {
	STRING: 1,
	NUMBER: 2,
	BOOLEAN: 3,
	SINGLESELECT: 4,
	MULTISELECT: 5,
	JSON: 6,
	FILEUPLOAD: 7,
	DATE: 8,
	BUTTON: 9,
	MULTIVALUE: 15,
	CREDENTIAL: 16
};
