import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import React from 'react';
import actions from './actions';
import { Button } from 'antd';

export function* loadTypesData() {
	yield takeEvery('LOAD_CONTACT_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/contactType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data;
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_CONTACT_TYPES_SUCCESS,
				contactTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_CONTACT_TYPES_ERROR,
				error: data.error
			});
		}
	});
	yield takeEvery('LOAD_ADDRESS_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/addressType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data;
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_ADDRESS_TYPES_SUCCESS,
				addressTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_ADDRESS_TYPES_ERROR,
				error: data.error
			});
		}
	});
	yield takeEvery('LOAD_ADMIN_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/adminType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.filter((item) => !item.deletedDate);
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_ADMIN_TYPES_SUCCESS,
				adminTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_ADMIN_TYPES_ERROR,
				error: data.error
			});
		}
	});
	yield takeEvery('LOAD_CATEGORY_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/categoryTypes').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.filter((item) => !item.deletedDate);
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_CATEGORY_TYPES_SUCCESS,
				categoryTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_CATEGORY_TYPES_ERROR,
				error: data.error
			});
		}
	});
	yield takeEvery('LOAD_FIELD_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/categoryFieldType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.filter((item) => !item.deletedDate);
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_FIELD_TYPES_SUCCESS,
				fieldTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_FIELD_TYPES_ERROR,
				error: data.error
			});
		}
	});
	yield takeEvery('LOAD_BOOKING_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/bookingType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.filter((item) => !item.deletedDate);
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_BOOKING_TYPES_SUCCESS,
				bookingTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_BOOKING_TYPES_ERROR,
				error: data.error
			});
		}
	});

	yield takeEvery('LOAD_ENTITY_TYPES_DATA', function* () {
		const data = yield superFetch.get('/misc/entityType').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.filter((item) => !item.deletedDate);
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_ENTITY_TYPES_SUCCESS,
				entityTypesData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_ENTITY_TYPES_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadTypesData)]);
}
