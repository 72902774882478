import React from 'react';
import googleConfig from '@iso/config/google_map.config';
import { GoogleApiWrapper } from 'google-maps-react';
import BasicMapWrapper from './Maps.styles';

function WrappedMaps(props) {
	const { data } = props;
	const address = data.address || [];

	const [state] = React.useState({
		center: {
			lat: address.length > 0 ? address[0].latitude : -27.4698,
			lng: address.length > 0 ? address[0].longitude : 153.0251
		},
		zoom: 13
	});

	function loadMap(element) {
		const { google } = props;
		if (!element || !google) return;
		const Map = new google.maps.Map(element, {
			zoom: state.zoom,
			center: state.center,
			rotateControl: false,
			streetViewControl: false,
			mapTypeControl: false,
			fullscreenControl: false,
			zoomControl: false,
			scaleControl: false
		});

		// add markers to map or add default marker as fallback
		if (data.markers && data.markers.length > 0) {
			data.markers.map((row) => {
				new google.maps.Marker({
					position: new google.maps.LatLng(row.latitude, row.longitude),
					map: Map,
					flat: true,
					animation: google.maps.Animation.DROP
				});
				return true;
			});
		} else {
			// add default marker
			new google.maps.Marker({
				position: new google.maps.LatLng(state.center),
				map: Map,
				flat: true,
				animation: google.maps.Animation.DROP
			});
		}
	}
	const { loaded } = props;
	return (
		<div>
			{loaded ? (
				<BasicMapWrapper>
					<div className='isoGoogleMap' style={{ height: '600px', width: '100%' }} ref={loadMap} />
				</BasicMapWrapper>
			) : (
				<span>API is Loading</span>
			)}
		</div>
	);
}

export default GoogleApiWrapper({
	apiKey: googleConfig.apiKey
})(WrappedMaps);
