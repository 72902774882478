import styled from 'styled-components';
import { borderRadius } from '@iso/lib/helpers/style_utils';
import { palette } from 'styled-theme';
const LeafletMapStyleWrapper = styled.div`
	${borderRadius()};
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const MapActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	column-gap: 5px;
	position: absolute;
	z-index: 500;
	top: 10px;
	right: 10px;

	.switchDropDownBtn {
		border-color: ${palette('primary', 5)};
	}
`;
export { LeafletMapStyleWrapper, MapActionWrapper };
