import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntSteps = (ComponentName) => styled(ComponentName)`
	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
		border-color: ${palette('primary', 0)};
		background-color: ${palette('primary', 0)};
	}
`;

export default AntSteps;
