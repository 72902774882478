import React, { useState, useEffect } from 'react';
import { SimpleTable, SearchBar, Loading } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import Button from '@iso/components/uielements/button';
import RenderOrganisationActions from '../RenderOrganisationActions';
import { uniqBy } from 'lodash';

const InventoryOrganisationTable = ({ workspaces }) => {
	const [filteredData, setFilteredData] = useState([]);
	const [filteredInfo, setFilteredInfo] = useState({});

	const [initialData, setInitialData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		setInitialData(workspaces);
		setFilteredData(workspaces);
		setLoading(false);
	}, [workspaces]);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'orgID',
			key: 'orgID',
			ellipsis: true,
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: lock['orgID'],
						value: lock['orgID']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['orgID'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['orgID'] || null
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: lock['name'],
						value: lock['name']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['name'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['name'] || null
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'actions',
			fixed: 'right',
			width: 250,
			render: (text, record) => <RenderOrganisationActions data={record} />
		}
	];

	const clearFilters = () => {
		setFilteredInfo({});
		setFilteredData(initialData);
	};

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) => object['name'].toLowerCase().includes(query.toLowerCase()) || object['orgID'] === Number(query)
		);
		setFilteredData(searchResult);
	};

	const handleChange = (_pagination, filters) => {
		setFilteredInfo(filters);
	};

	return (
		<div style={{ overflow: 'hidden', padding: '0px 10px 10px 10px' }}>
			<Row justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
						<Button size={'small'} onClick={clearFilters}>
							Clear Filter(s)
						</Button>
					</div>
					<SearchBar
						style={{ marginBottom: '10px' }}
						placeholder='Search ID or Name'
						enterButton='Search'
						loading={false}
						onChange={(e) => {
							handleSearch(e.target.value, initialData);
						}}
						onSearch={(query) => {
							handleSearch(query, initialData);
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={24} md={24} sm={24} xs={24}>
					{loading ? (
						<Loading />
					) : (
						<SimpleTable
							columns={columns}
							pageSize={10}
							dataSource={filteredData}
							onChange={handleChange}
							scroll={{ x: 1000 }}
						/>
					)}
				</Col>
			</Row>
		</div>
	);
};

export default InventoryOrganisationTable;
