import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button, Form, Radio, RadioGroup } from '@iso/components/uielements';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import inventoryActions from '@iso/redux/inventory/actions';
import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InventoryModifyLocationModal from './InventoryModifyLocationModal';

const { loadInventoryGateways, loadInventoryLocks } = inventoryActions;

const AssignUnassignInventoryAssets = ({ data, type, workspaces }) => {
	const dispatch = useDispatch();
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const [saveLoading, setSaveLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visibleEditLocation, setVisibleEditLocation] = useState(false);
	const [opType, setOpType] = useState({ op: null, type: '' });
	const [selectedSpace, setSelectedSpace] = useState('');
	const [categoryType, setCategoryType] = useState(null);
	const [subCategoryType, setSubCategoryType] = useState([]);
	const [categoryID, setCategoryID] = useState(null);

	const handleCancel = () => {
		setVisible(false);
		setSelectedSpace('');
		setCategoryType(null);
		setCategoryID(null);
		setSubCategoryType([]);
	};

	useEffect(() => {
		setOpType({
			op: data.orgOrgID ? 'Unassign' : 'Assign',
			type: type
		});

		return () => {
			setOpType({ op: null, type: '' });
		};
	}, [data, type]);

	const loadInventory = (categoryID) => {
		if (categoryID === constants.SMART_LOCKS) {
			dispatch(loadInventoryLocks());
		} else if (categoryID === constants.SMART_GATEWAYS) {
			dispatch(loadInventoryGateways());
		}
	};

	const handleSubmit = async () => {
		setSaveLoading(true);
		if (opType.op === 'Assign') {
			const body = {
				orgID: selectedSpace,
				inventories: [data.inventoryUID],
				defaultCategoryID: categoryType,
				...(categoryID ? { categoryID: categoryID } : null)
			};
			try {
				await superFetch.post('/devices/inventoryToWorkspace', body);
				handleCancel();
				loadInventory(data.defaultCategoryID);
				notification('success', `${opType.type} added to the workspace.`);
			} catch (error) {
				notification('error', 'Error assigning lock. Please try again.');
			}
			setSaveLoading(false);
		} else {
			const body = {
				deviceID: data.assetID
			};
			try {
				await superFetch.post('/devices/unassignAssets', body);
				handleCancel();
				loadInventory(data.defaultCategoryID);
				notification('success', `${opType.type} Removed to the workspace.`);
			} catch (error) {
				notification('error', 'Error assigning lock. Please try again.');
			}
			setSaveLoading(false);
		}
	};

	const handleSelectSpace = async (orgID, defaultCategoryID) => {
		setSelectedSpace(orgID);
		setCategoryType(defaultCategoryID);
		if (orgID) {
			try {
				const res = await superFetch.get(
					`/deviceCategories`,
					{},
					{
						defaultCategoryID: defaultCategoryID
					},
					orgID
				);
				setSubCategoryType(res.data ?? []);
			} catch (e) {
				notification('error', e.message);
			}
		}
	};

	// Check for required data
	if (!(data && type)) return null;
	return (
		<React.Fragment key={data.inventoryUID}>
			{opType.op ? (
				<div>
					{opType.op === 'Unassign' ? (
						<InventoryModifyLocationModal
							editVisible={visibleEditLocation}
							setEditVisible={setVisibleEditLocation}
							data={{ orgID: data.orgOrgID, asset: data }}
						>
							<Button
								size={'small'}
								type='secondary'
								onClick={() => setVisibleEditLocation(true)}
								style={{ marginRight: '10px' }}
							>
								Modify Location
							</Button>
						</InventoryModifyLocationModal>
					) : null}
					<Button size={'small'} type='secondary' onClick={() => setVisible(true)}>
						{opType.op}
					</Button>
				</div>
			) : null}

			{opType.op ? (
				<Modal
					title={opType.op + ' Workspace'}
					open={visible}
					onCancel={handleCancel}
					width={450}
					maskClosable={false}
					destroyOnClose={true}
					footer={[
						<Button key='reset' onClick={handleCancel}>
							Cancel
						</Button>,
						<Button
							loading={saveLoading}
							disabled={
								(selectedSpace.length === 0 && opType.op === 'Assign') ||
								(categoryType === null && opType.op === 'Assign') ||
								(subCategoryType.length > 0 && categoryID === null && opType.op === 'Assign')
							}
							onClick={handleSubmit}
							key='submit'
							htmlType='submit'
							type='primary'
						>
							{opType.op}
						</Button>
					]}
				>
					<Form layout='vertical'>
						{opType.op === 'Assign' ? (
							<Row gutter={16}>
								<Col>
									<Typography style={{ marginRight: '15px' }}>
										<p style={{ fontSize: '16px' }}>Select Workspace</p>
									</Typography>
									<Form.Item>
										<Select
											showSearch
											onSelect={(val) => {
												handleSelectSpace(val, categoryType);
											}}
											placeholder={'Type'}
											style={{ width: '250px' }}
											filterOption={(input, option) =>
												String(option?.children ?? '')
													.toLowerCase()
													.includes(String(input).toLowerCase())
											}
										>
											{workspaces.map((space) => (
												<Option value={space.orgID} key={space.orgID}>
													{space.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label='Category'>
										<RadioGroup
											onChange={(e) => {
												handleSelectSpace(selectedSpace, e.target.value);
												setCategoryID(null);
											}}
										>
											{constants.ACTIONABLE_SMART_LOCKS.includes(data.defaultCategoryID) ? (
												<>
													<Radio value={constants.SMART_LOCKS}>Smart Locks</Radio>
													<Radio value={constants.SMART_LOCKER}>Smart Lockers</Radio>
												</>
											) : (
												<Radio value={constants.SMART_GATEWAYS}>Smart Lock Gateway</Radio>
											)}
										</RadioGroup>
									</Form.Item>
								</Col>
								{subCategoryType?.length > 0 && (
									<Col>
										<Form.Item label='Sub Category'>
											<Select
												onSelect={(val) => setCategoryID(val)}
												placeholder={'Type'}
												style={{ width: '250px' }}
												value={categoryID}
											>
												{subCategoryType.map((category) => (
													<Option value={category.categoryID} key={category.categoryID}>
														{category.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								)}
							</Row>
						) : (
							<Typography style={{ marginRight: '15px' }}>
								<p style={{ fontSize: '16px', textAlign: 'center', color: '#EC3D3A' }}>
									Are you sure you want to unassign the lock from workspace?
								</p>
							</Typography>
						)}
					</Form>
				</Modal>
			) : null}
		</React.Fragment>
	);
};

export default AssignUnassignInventoryAssets;
