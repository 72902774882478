import styled from 'styled-components';
import Form from '@iso/components/uielements/form';

export const FloorMapFormItem = styled(Form.Item)`
	height: 100%;

	.alertStyle {
		line-height: 10px;
		font-size: 12px;
		margin-top: 10px;
	}
`;

const DividerWrapper = styled.div`
	//margin-top: 20px;
	padding-top: 30px;
	.ant-divider-inner-text {
		font-weight: 500;
		align-items: center;
		//padding-left: 10px;
	}
	margin: 0px;
`;
export { DividerWrapper };

export default FloorMapFormItem;
