// import { Typography } from 'antd';
import styled from 'styled-components';
import { palette, font } from 'styled-theme';
import { css } from 'styled-components';

const stylesGlobal = css`
	color: ${(props) => palette(props.color ? props.color : 'primary', props.tonality ? props.tonality : 0)};
	font-family: ${(props) => font(props.typeLabel)};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const TypographyTitleWrapper = styled.h3`
	font-size: ${(props) => (props.fontSize ? props.fontSize + 'px !important' : '25px')};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
	${stylesGlobal}
	@media (max-width: 480px) {
		font-size: 16px !important;
	}
`;

const TypographyParagraphWrapper = styled.p`
	font-size: ${(props) => (props.fontSize ? props.fontSize + 'px !important' : '16px')};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
	${stylesGlobal}
	@media (max-width: 480px) {
		font-size: 14px !important;
	}
`;

const TypographyPropWrapper = styled.span`
	font-size: ${(props) => (props.fontSize ? props.fontSize + 'px !important' : '14px')};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
	${stylesGlobal}
	@media (max-width: 480px) {
		font-size: 12px !important;
	}
`;

export { TypographyTitleWrapper, TypographyParagraphWrapper, TypographyPropWrapper };
