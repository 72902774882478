import { Loading } from '@Z/components/atoms';
import DeleteEntityAdminModal from '@Z/components/atoms/DeleteEntityAdminModal';
import { MoreOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import Card from '@iso/components/uielements/card';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { getPermissionByEntityType } from '@iso/lib/helpers/getPrimary';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import Col from '@iso/ui/Antd/Grid/Col';
import Row from '@iso/ui/Antd/Grid/Row';
import { Empty, Tooltip } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

const EntityAdminTab = ({ admin, data, type }) => {
	const { permissionName, rules } = getPermissionByEntityType(type, data);

	const isPermittedUpdate = useManagePermittedRoles(permissionName, rules);

	const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState(false);
	const { rowStyle, loadingStyle } = basicStyle;

	const actions = (item) => [
		<Tooltip title={!isPermittedUpdate ? "You don't have permission" : 'Revoke Admin'}>
			<>
				<Button
					size={'small'}
					onClick={() => {
						setVisibleDeleteModal(true);
						setSelectedAdmin({ ...item, ...data });
					}}
					disabled={!isPermittedUpdate}
					style={{ width: '100%' }}
					danger
				>
					Revoke
				</Button>
			</>
		</Tooltip>
	];

	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{/* filter removed the unauthorised values */}
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const NameWithlink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	return !admin ? (
		<div style={loadingStyle}>
			<Loading />
		</div>
	) : admin && admin.length > 0 ? (
		<Row style={rowStyle} gutter={[8, 8]}>
			{admin.map((item, key) => (
				<Col key={key} lg={12} md={12} sm={12} xs={12}>
					<Card
						size='small'
						title={item.adminType ? item.adminType : 'Default'}
						extra={
							<Dropdown overlay={() => actionMenu(actions(item))} trigger={['click']}>
								<MoreOutlinedIcon />
							</Dropdown>
						}
						style={{ height: '100%' }}
					>
						<NameWithlink type={'person'} dataID={item.personID}>
							{item.displayName}
						</NameWithlink>

						<p>{item.email}</p>
					</Card>
				</Col>
			))}
			<DeleteEntityAdminModal
				visible={visibleDeleteModal}
				setVisible={setVisibleDeleteModal}
				data={selectedAdmin}
				type={type}
			>
				<div />
			</DeleteEntityAdminModal>
		</Row>
	) : (
		<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Admin Permission Granted</span>} />
	);
};
const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;
export default EntityAdminTab;
