import actions from './actions';
import _ from 'lodash';

const initState = { peopleData: [], pending: true, searchable: true };

const peopleReducer = (state = initState, action) => {
	const orderData = _.orderBy(action.peopleData, 'deletedDate', 'desc');
	switch (action.type) {
		case actions.LOAD_PEOPLE_SUCCESS:
			return {
				...state,
				peopleData: orderData,
				pending: false
			};
		case actions.LOAD_PEOPLE_ERROR:
			return { ...state, peopleData: [], pending: false };
		default:
			return state;
	}
};
export default peopleReducer;
