import styled from 'styled-components';

const AccessControlTableWrapper = styled.div`
	width: 100%;
	min-height: 30px;
	.ant-table-column-title {
		padding-left: 6px;
	}
`;

export default AccessControlTableWrapper;
