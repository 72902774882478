const actions = {
	LOAD_ORGANISATION_DATA: 'LOAD_ORGANISATION_DATA',
	LOAD_ORGANISATION_SUCCESS: 'LOAD_ORGANISATION_SUCCESS',
	LOAD_ORGANISATION_ERROR: 'LOAD_ORGANISATION_ERROR',

	loadOrganisation: () => ({
		type: actions.LOAD_ORGANISATION_DATA
	})
};
export default actions;
