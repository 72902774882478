/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 17-02-2022  13:48:26
 * @desc [description]
 */
import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { Loading, SearchableTable, AddFloor } from '@Z/components/atoms';
import ContentHolder from '@iso/components/utility/contentHolder';
import React from 'react';
import { Empty } from 'antd';
import superFetch from '@iso/lib/helpers/superFetch';
import { getPrimaryContact } from '@iso/lib/helpers/getPrimary';
import { Col, Row } from 'antd';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { useSelector } from 'react-redux';

const { rowStyle, colStyle, loadingStyle } = basicStyle;

const FloorTable = ({ siteID }) => {
	const floorsData = useSelector((state) => state.floors.floorsData);

	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(true);
		// load Floors
		superFetch
			.get('/floors', {}, { siteID: siteID })
			.then((res) => {
				if (res.statusCode === 200) {
					setData(res.data);
				}
				setLoading(false);
			})
			.catch((e) => setLoading(false));
	}, [siteID, floorsData]);

	// HOC
	const FloorWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const ButtonWithLink = withHyperLink((props) => <Button {...props} />);

	const columns = [
		{
			title: 'Floor Name',
			dataIndex: 'name',
			key: 'name',
			width: 50,
			ellipsis: true,
			render: (text, record) => (
				<FloorWithLink type='floor' dataID={record.floorID}>
					<span>{text}</span>
				</FloorWithLink>
			)
		},
		{
			title: 'Primary Contact',
			dataIndex: 'contactInformation',
			key: 'contactInformation',
			width: 50,
			ellipsis: true,
			render: (text, record) => {
				const primaryContact = getPrimaryContact(record.contact);
				return <p>{primaryContact.contactInformation}</p>;
			}
		},
		{
			title: 'View Floor',
			key: 'Actions',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ButtonWithLink size='small' type='floor' dataID={record.floorID}>
						View
					</ButtonWithLink>
				</div>
			),
			ellipsis: true,
			width: 30,
			align: 'center'
		}
	];

	return loading ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : data && data.length > 0 ? (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<SearchableTable
				columns={columns}
				pageSize={3}
				dataSource={data}
				filterColumn={['name']}
				title={<FloorWithLink type='floors'>Floors</FloorWithLink>}
			/>
		</IsoWidgetBox>
	) : (
		<IsoWidgetBox>
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Floor Data</span>}>
				<AddFloor />
			</Empty>
		</IsoWidgetBox>
	);
};

export default FloorTable;
