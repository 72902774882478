/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 25-02-2022  13:38:20
 * @desc [description]
 */
import React, { useState, useEffect } from 'react';
import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { useHistory } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { Loading, SearchableTable, Modal } from '@Z/components/atoms';
import Input from '@iso/components/uielements/input';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { notification } from '@iso/components';
import { useDispatch } from 'react-redux';
import SuperFetch from '@iso/lib/helpers/superFetch';
import organisationsAction from '@iso/redux/organisation/actions';
import Form from '@iso/components/uielements/form';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { loadOrganisation } = organisationsAction;

const OrganisationTable = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const currentPath = history.location.pathname;
	const [loadings, setLoading] = useState(true);
	const [editVisible, setEditVisible] = useState(false);
	const [data, setData] = useState([]);
	const organisationData = useSelector((state) => state.Organisation.organisationData);
	const organisationDataPending = useSelector((state) => state.Organisation.pending);
	const [updateOrgLoading, setUpdateOrgLoading] = React.useState(false);
	const [updateOrgObj, setUpdateOrgObj] = React.useState({});
	const [buttonDisable, setButtonDisable] = React.useState(true);

	const [form] = Form.useForm();

	useEffect(() => {
		setLoading(true);
		if (!organisationDataPending) {
			setData(organisationData);
			setLoading(false);
		}
	}, [organisationData, organisationDataPending]);

	const handleCancel = () => {
		setButtonDisable(true);
		setEditVisible(false);
	};
	const onFinish = async () => {
		let newOrgInfo = {};
		const formData = form.getFieldsValue(true);
		if (formData.name !== updateOrgObj.name && formData.name !== '') {
			newOrgInfo['name'] = formData.name;
		}
		if (formData.abn !== updateOrgObj.abn && formData.abn !== '') {
			newOrgInfo['abn'] = formData.abn;
		}
		if (formData.acn !== updateOrgObj.acn && formData.acn !== '') {
			newOrgInfo['acn'] = formData.acn;
		}
		if (newOrgInfo.name || newOrgInfo.acn || newOrgInfo.abn) {
			await SuperFetch.patch('/organisations/' + updateOrgObj.orgID, newOrgInfo)
				.then((res) => {
					if (res) {
						if (res.statusCode === 200) {
							notification('success', 'Organisation Information Updated.');
						}
						dispatch(loadOrganisation());
					}
					return res;
				})
				.catch((err) => {
					notification('error', 'Update Failed, Please try again.');
				});
		}
		handleCancel();
		setUpdateOrgLoading(false);
	};
	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		if (
			(updateOrgObj.name === formData.name || formData.name === '') &&
			(updateOrgObj.abn === formData.abn || formData.abn === '') &&
			(updateOrgObj.acn === formData.acn || formData.acn === '')
		) {
			return true;
		} else {
			return false;
		}
	};
	// const editOrgPermission = useManagePermittedRoles('editOrg', { orgID });
	const isPermittedEditOrg = useManagePermittedRoles('updateOrg', { orgID: [] });
	const actionMenu = (_text, record) => (
		<DropdownMenu>
			<MenuItem
				style={{ fontSize: '12px' }}
				onClick={() => {
					history.push({
						pathname: `${currentPath}/organisation`,
						state: { orgID: record.orgID }
					});
				}}
				key='1'
			>
				View Organisation
			</MenuItem>
			{isPermittedEditOrg && (
				<MenuItem
					style={{ fontSize: '12px' }}
					onClick={() => {
						setUpdateOrgObj(record);
						setEditVisible(true);
						form.setFieldsValue({
							name: record.name,
							abn: record.abn,
							acn: record.acn
						});
					}}
					key='2'
				>
					Edit Organisation
				</MenuItem>
			)}
		</DropdownMenu>
	);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 30,
			ellipsis: true,
			render: (text, record) => (
				<span
					style={{
						cursor: 'pointer'
					}}
					onClick={() => {
						history.push({
							pathname: `${currentPath}/organisation`,
							state: { orgID: record.orgID }
						});
					}}
				>
					{text}
				</span>
			)
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
			width: 20,
			ellipsis: true
		},
		{
			title: 'ABN',
			dataIndex: 'abn',
			key: 'abn',
			width: 30,
			ellipsis: true
		},
		{
			title: 'ACN',
			dataIndex: 'acn',
			key: 'acn',
			width: 30,
			ellipsis: true
		},

		{
			title: 'Actions',
			key: 'Actions',
			render: (text, record) => (
				<Dropdown overlay={actionMenu(text, record)} trigger={['click']}>
					<Button size='small'>
						Actions <DownOutlined />
					</Button>
				</Dropdown>
			),
			ellipsis: true,
			width: 50
		}
	];

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<SearchableTable
				columns={columns}
				pageSize={7}
				dataSource={data}
				filterColumn={['name', 'alias']}
				placeholder={'Organisation Name or Alias'}
			/>
			<Modal
				title='Edit Organisation'
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateOrgLoading}
						disabled={buttonDisable}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 10 }}
					onFinish={onFinish}
					autoComplete='off'
					onValuesChange={() => setButtonDisable(updateChange())}
				>
					<Form.Item label='Name: ' name='name' rules={fieldsValidator.Name} style={colStyle}>
						<Input />
					</Form.Item>

					<Form.Item label='ABN: ' name='abn' rules={fieldsValidator.ABN} style={colStyle}>
						<Input />
					</Form.Item>

					<Form.Item label='ACN: ' name='acn' rules={fieldsValidator.ACN} style={colStyle}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</IsoWidgetBox>
	);
};

export default OrganisationTable;
