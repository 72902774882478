import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';
import Card from '@iso/components/uielements/card';

const FloorPreviewWrapper = styled(Card)`
	.floorMap {
		border: 2px solid ${palette('primary', 0)};
		height: 250px;
		width: 100%;
		${borderRadius('10px')};
		overflow: hidden;
		position: relative;
	}
	.floorTitle {
		display: flex;
		margin-top: 15px;
		justify-content: space-between;

		.floorName {
			font-size: 22px;
			color: ${palette('secondary', 0)};
		}
	}
	.floorDescription {
		margin-top: 15px;
	}
	.floor-card-content {
		display: flex;
		justify-content: start;
		flex-direction: column;

		.contact-container {
			font-size: 15px;
			font-weight: 500;
			text-transform: capitalize;
			padding-bottom: 5px;
		}
		.address-container {
			padding-left: 5px;
		}
	}
`;

export default FloorPreviewWrapper;
