const DeviceMarkerContent = (props) => {
	return ` 
        <!DOCTYPE html>
        <html>

        <head>
            <style>
                .container {
                    background-color: #ecf1f5;
                    border-radius: 4px;
                    border: 1px solid #D8D8D8; 
                    overflow: hidden;
                    max-width:250px;
                    min-width: 140px;
                }

                .ant-card-head {
                    padding: 5px 5px 0px 5px;
                }

                .ant-card-head-wrapper {
                    display: flex;
                    justify-content: space-between;
                    width: auto;
                    line-height: 16px;
                }

                .deviceTitle {
                    font-size: 18px;
                    color: #1D3557;
                    white-space: break-spaces;
                    word-break: break-all;
                }

                .ant-card-extra {
                    float: right;
                }

                .ant-card-body {
                    padding: 8px 12px !important;
                }

                .stat {
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 14px;
                }

                .uid {
                    margin: 10px 0px;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .icon{
                    font-size: 14px;
                    width: 14px;
                    color: #3C6E71;
                    bottom: -2px;
                    position: relative;
                    margin-left: 5px;
                }
            </style>
            <script>
                function copyUID () {
                    navigator.clipboard.writeText(${props.uID});
                }
            </script>
        </head>

        <body>
            <div class="container">
                <div class="ant-card-head">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title">
                            <div><span class="deviceTitle" style="cursor: pointer;">${props.name}</span></div>
                        </div>
                        <div class="ant-card-extra">
                            <div class="statsContainer"><span role="img" class="anticon customIcon"><svg viewBox="0 0 216 216"
                                        width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"
                                        class="">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: #fff;
                                                }

                                                .cls-2 {
                                                    fill: #112034;
                                                }

                                                .cls-3 {
                                                    fill: #1d3557;
                                                    stroke: #fff;
                                                    stroke-miterlimit: 10;
                                                    stroke-width: 8.57px;
                                                }

                                                .cls-4 {
                                                    fill: #4a5d79;
                                                }

                                                .cls-5 {
                                                    fill: #617c99;
                                                }
                                            </style>
                                        </defs>
                                        <g id="Layer_4" data-name="Layer 4">
                                            <rect class="cls-1" x="1.47" y="1.21" width="213.27" height="213.27"></rect>
                                            <rect class="cls-2" x="10.37" y="9.55" width="162.39" height="110.5" rx="6.08">
                                            </rect>
                                            <path class="cls-3"
                                                d="M199.14,82.14H94.51A11.31,11.31,0,0,0,83.28,93.53V198.16a11.31,11.31,0,0,0,11.23,11.39H199.14a11.31,11.31,0,0,0,11.23-11.39V93.53A11.31,11.31,0,0,0,199.14,82.14Z">
                                            </path>
                                            <path class="cls-4"
                                                d="M16.44,128.91H78.75a0,0,0,0,1,0,0v70.23a6,6,0,0,1-6,6H16.44a6,6,0,0,1-6-6V134.88a6,6,0,0,1,6-6Z">
                                            </path>
                                            <path class="cls-5"
                                                d="M184.36,9.8h18.85A2.82,2.82,0,0,1,206,12.62V74.5a3.37,3.37,0,0,1-3.37,3.37H181.55a0,0,0,0,1,0,0V12.62a2.82,2.82,0,0,1,2.82-2.82Z">
                                            </path>
                                        </g>
                                    </svg></span></div>
                        </div>
                    </div>
                </div>
                <div class="ant-card-body">
                    <div class="ant-card-meta1">
                        <div class="ant-card-meta-detail1">
                            <div class="ant-card-meta-description1">
                                <div class="stat">
                                    <p class="uid" onClick="navigator.clipboard.writeText(${props.uID})" >UID: ${props.uID}<span class="imageIcon"><i class="material-icons-outlined imageIcon icon"
                                                style="cursor: pointer;">copy_content</i></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </body>

        </html>
    `;
};

export { DeviceMarkerContent };
