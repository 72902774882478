import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';
import { Typography } from 'antd';

const TeamSummaryWrapper = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;

	@media only screen and (max-width: 1200px) {
		border-right: 0px;
	}

	.infoDescription {
		display: flex;
		flex-direction: row;
		@media only screen and (max-width: 1400px) {
			flex-direction: column;
		}
	}

	.header {
		display: flex;
		gap: 20px;
		justify-content: space-between;
		align-items: flex-start;
	}
	.teamHeaderCol {
		display: flex;
		flex: 1;
		.teamInfoContainer {
			flex: 1;
			margin-left: 7px;
		}
	}

	.actionButton {
		display: flex;
		margin-left: 5px;
		align-items: stretch;
		justify-content: flex-end;
		.ant-btn {
			margin-right: 10px;

			&:last-child {
				margin-right: 0px;
			}
		}
		.switchTeamButton {
			margin-right: 10px;

			@media only screen and (max-width: 1000px) {
				margin-right: 0px;
				display: none;
			}
		}
		.deletedTag {
			height: 36px;
			line-height: 36px;
			font-size: 12px;
			font-weight: 500;
			${borderRadius('4px')};
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.infoLabel {
		font-size: 15px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
		margin-right: 5px;
	}

	.infoActivityLabel {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.7;
	}
	.infoDiv {
		display: flex;
	}

	.infoContainer {
		display: grid;
		grid-auto-flow: row;
		width: 75%;
	}

	.infoDateLabel {
		font-size: 10px;
		font-weight: 400;
		opacity: 0.6;
		display: inline-block;
		margin-right: 5px;
	}

	.infoActivityLabel {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.7;
	}

	.infoTitle {
		font-size: 30px;
		font-weight: 700;
		opacity: 0.8;
		color: black;
		margin-left: 20px;
		vertical-align: middle;
	}
	.infoProp {
		font-size: 15px;
		font-weight: 600;
		color: ${palette('secondary', 3)};
		overflow-wrap: anywhere;
		display: flex;
	}
	.infoActivityProp {
		font-size: 12px;
		font-weight: 400;
		opacity: 0.7;
		display: inline-block;
		margin-left: 5px;
	}
	.infoDates {
		display: flex;
		justify-content: space-between;
	}
	.editButton {
		display: flex;
		justify-content: flex-end;
	}
	.editTeam {
		display: flex;
		justify-content: center;

		@media only screen and (max-width: 1000px) {
			padding-top: 10px;
		}
	}
`;
export const TypographyParagraphWrapper = styled(Typography.Paragraph)`
	margin-bottom: 0 !important;
	width: 100%;
	&.ant-typography-edit-content {
		.ant-input {
			font-size: 14px;
			font-weight: 600;
			color: ${palette('secondary', 3)};
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;

export const MetaContainer = styled.div`
	position: absolute;
	right: 10px;
	bottom: 5px;
	.metaContainer {
		font-size: 9px;
		font-weight: 400;
		opacity: 0.6;
		justify-content: flex-end;

		.metaField {
			width: max-content;
			font-weight: 500;
			.metaLabel {
				font-weight: 400;
			}
		}

		.dateContainer {
			row-gap: 1px !important;

			.ant-space-item {
				justify-content: flex-end;
			}
		}
	}
`;
export default TeamSummaryWrapper;
