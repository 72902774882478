import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button, Input, SelectOption as Option, Radio, RadioGroup, Select, Textarea } from '@iso/components/uielements';
import Form from '@iso/components/uielements/form';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import useModuleSettings from '@iso/lib/hooks/useModuleSettings';
import groupAction from '@iso/redux/assetGroups/actions';
import { Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { loadAssetGroups } = groupAction;

/**
 * @description Create Asset Group Button Component
 * @author Kishan Virani
 * @date 09/05/2023
 * @param {*} { children, forAccessControl } - children is used to render the button, forAccessControl is used to set the group type to access control and disable the general selection
 * @return {*}
 */
const AddAssetGroup = ({ children, forAccessControl }) => {
	// Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const enabledAccessModule = useModuleSettings(CONSTANTS_SETTINGS.ACCESS_MODULE);
	// Global States
	const { sitesData, pending: sitesPending } = useSelector((state) => state.sites);
	const { devicesData, pending: devicesPending } = useSelector((state) => state.devices);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	// Local States
	const [viewModal, setViewModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [assetGroupType, setAssetGroupType] = useState(
		forAccessControl ? constants.ACCESS_CONTROL : constants.GENERAL
	); // Setting the default asset group type
	const [filteredDevices, setFilteredDevices] = useState([]);

	useEffect(() => {
		if (assetGroupType === constants.ACCESS_CONTROL) {
			const locks = devicesData.filter((device) =>
				constants.ACCESS_CONTROL_CATEGORIES.includes(device.defaultCategoryID)
			);
			setFilteredDevices(locks);
		} else {
			setFilteredDevices(devicesData);
		}
	}, [assetGroupType, devicesData, constants]);

	const handleCancel = () => {
		setViewModal(false);
		setConfirmLoading(false);
		setAssetGroupType(constants.GENERAL);
		form.resetFields();
	};

	const handleSubmit = () => {
		setConfirmLoading(true);
		const inputs = removeEmptyOrNull(form.getFieldsValue(true));
		// Set selected group Type.
		inputs.typeID = assetGroupType;

		superFetch
			.post('/deviceGroups', inputs)
			.then((res) => {
				if (res.statusCode === 201) {
					notification('success', `Successfully created ${inputs.name} group.`);
					dispatch(loadAssetGroups());
					handleCancel();
				} else if (res.error.statusCode === 400) {
					notification('error', 'Please fill out all required information.');
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
				setConfirmLoading(false);
			})
			.catch((e) => {
				notification('error', 'Something went wrong, try again soon.');
				setConfirmLoading(false);
				handleCancel();
			});
	};
	return (
		<div>
			{children ? (
				children
			) : (
				<Button type='primary' onClick={() => setViewModal(true)} loading={sitesPending && devicesPending}>
					Create Asset Group
				</Button>
			)}
			<Modal
				title='New Asset Group'
				visible={viewModal}
				onCancel={handleCancel}
				width={600}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button loading={confirmLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Add Group
					</Button>
				]}
			>
				<Form form={form} id={form} onFinish={handleSubmit} layout='vertical'>
					<Row gutter={16}>
						<Col sm={24} xs={24} lg={12} md={12}>
							<Form.Item name='name' label='Name' rules={fieldsValidator.Name}>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={24} xs={24} lg={12} md={12}>
							<Form.Item name='siteID' label='Site'>
								<Select placeholder={'Select Site'}>
									{sitesData.map((site) => (
										<Option key={site.siteID} value={site.siteID}>
											{site.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name='description'
								label='Description'
								tooltip='Describe the assets being placed into this group.'
								rules={fieldsValidator.Description}
							>
								<Textarea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
							</Form.Item>
						</Col>
					</Row>
					<Divider orientation='left' orientationMargin={0}>
						Select Assets
					</Divider>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name='typeID'
								label='Group Type'
								tooltip='Access group can be use to grant or revoke permission for multiple lock Assets.'
							>
								<RadioGroup
									defaultValue={assetGroupType}
									onChange={(e) => {
										setAssetGroupType(e.target.value);
										// Deselect all the selected device values
										form.setFieldValue('assets', []);
									}}
								>
									{/* General Group Type*/}
									<Radio disabled={forAccessControl} value={1}>
										General
									</Radio>
									{/* Access Group Type*/}
									{enabledAccessModule && <Radio value={2}>Access</Radio>}
								</RadioGroup>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name='assets'
								label='Assets'
								tooltip='Select all the assets you want to add to the group.'
								rules={fieldsValidator.Required}
							>
								<Select
									allowClear
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									mode={'multiple'}
									placeholder={'Select Asset'}
								>
									{filteredDevices.map((asset) => (
										<Option key={asset.uID} value={asset.uID}>
											{asset.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default AddAssetGroup;
