import { store } from './store';
import authActions from '@iso/redux/auth/actions';
import fbRemoteConfig from '@iso/redux/fbRemoteConfig/actions';

const boot = () => {
	return new Promise(() => {
		store.dispatch(authActions.checkAuthorization()); //  trigger authentication check on page load
		store.dispatch(fbRemoteConfig.loadRemoteConfig()); // trigger remote config load on page load
	});
};
export default boot;
