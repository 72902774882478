import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'antd';
import Input from '@iso/components/uielements/input';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import { Button } from '@iso/components/uielements';

const RenderMultiValue = ({ setting, settings, index, setSettings }) => {
	const [form] = Form.useForm();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setOptions(setting.value || []);
	}, [setting]);

	useEffect(() => {
		const fieldsValue = options.reduce((acc, option) => {
			acc[option.name] = option.value;
			return acc;
		}, {});
		form.setFieldsValue(fieldsValue);
	}, [options, form]);

	const onValuesChange = (changedValues, allValues) => {
		const allLabelsFilled = Object.keys(allValues).every((field) => field.trim() !== '');

		const enrichArrayWithValues = (obj, arr) => {
			return arr.map((item) => ({
				...item,
				value: obj[item.name]
			}));
		};

		const finalValue = enrichArrayWithValues(allValues, options);

		const updatedSettings = [...settings];
		updatedSettings[index] = {
			...setting,
			value: allLabelsFilled ? finalValue : null
		};
		setSettings(updatedSettings);
	};

	const handleAddOption = () => {
		const newId = options.length + 1;
		setOptions([...options, { id: newId, name: `string${newId}`, value: '' }]);
	};

	const handleDeleteOption = (id) => {
		setOptions(options.filter((option) => option.id !== id));
		const updatedSettings = [...settings];
		updatedSettings[index] = {
			...setting,
			value: options.filter((option) => option.id !== id)
		};
		setSettings(updatedSettings);
	};

	return (
		<Form form={form} onValuesChange={onValuesChange}>
			{options.map(({ id, name }) => (
				<Row key={id} gutter={10} className='containerField'>
					<Col span={22}>
						<Form.Item name={name} rules={fieldsValidator.String}>
							<Input placeholder='Enter String' className='fieldString' />
						</Form.Item>
					</Col>
					<Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							onClick={() => handleDeleteOption(id)}
							icon={<CloseCircleOutlined />}
							style={{ border: 0, boxShadow: 'none' }}
						/>
					</Col>
				</Row>
			))}
			<Form.Item>
				<Button type='dashed' onClick={handleAddOption} block icon={<PlusOutlined />}>
					Add Custom Field
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RenderMultiValue;
