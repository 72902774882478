import { UploadOutlined } from '@ant-design/icons';
import { Button, Select } from '@iso/components/uielements';
import Datepicker from '@iso/components/uielements/datePicker';
import Input, { Textarea } from '@iso/components/uielements/input';
import Switches from '@iso/components/uielements/switch';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { settingFieldType } from '@iso/config/settingFieldType';
import { uploadFiles } from '@iso/lib/firebase/firebase.util';
import { Segmented, Upload } from 'antd';
import moment from 'moment';
import React from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import RenderMultiValue from '../RenderMultiValue';
import RenderCredential from '../RenderCredential';

const RenderFieldTypeSetting = ({
	setting,
	index,
	settings,
	setSettings,
	fileList,
	setFileList,
	handleRemoveUpload
}) => {
	const {
		remoteConfig: { entityInfo }
	} = useSelector((state) => state.remoteConfig);
	const { orgID, personID } = useSelector((state) => state.Auth.userProfile.defaultProfile);

	let value = [];

	const generateFilePath = async (file) => {
		const generatedPath =
			`${entityInfo['Settings'].entityDir}/` +
			orgID +
			personID +
			Date.now() +
			(file.type === 'image/png' ? '.png' : '.jpeg');
		return generatedPath;
	};

	const checkBeforeUpload = async (file) => {
		const filePath = await generateFilePath(file);
		await uploadFiles(filePath, file);

		const updatedSettings = [...settings];
		updatedSettings[index] = {
			...setting,
			value: filePath
		};
		setSettings(updatedSettings);
		setFileList([...fileList, file]);
	};

	const isPermittedEditJson = useManagePermittedRoles('editJsonSetting', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	const renderSettingByFieldType = (setting) => {
		switch (setting.fieldTypeID) {
			case settingFieldType.STRING:
				return (
					<Textarea
						placeholder={`Enter a ${setting.title}`}
						value={setting.value}
						onChange={(e) => {
							const newValue = e.target.value;
							const updatedSettings = [...settings];
							updatedSettings[index] = {
								...setting,
								value: newValue
							};
							setSettings(updatedSettings);
						}}
						className='fieldString'
						autoSize={{ minRows: 1, maxRows: 9 }}
					/>
				);
			case settingFieldType.NUMBER:
				return (
					<Input
						placeholder={`Enter a ${setting.title}`}
						value={setting.value}
						onChange={(e) => {
							const newValue = e.target.value;
							const updatedSettings = [...settings];
							updatedSettings[index] = {
								...setting,
								value: newValue
							};
							setSettings(updatedSettings);
						}}
						className='fieldString'
						autoSize
						type={setting.fieldTypeID === settingFieldType.STRING ? 'text' : 'number'}
					/>
				);
			case settingFieldType.BOOLEAN:
				return (
					<div className='containerSwitch'>
						<Switches
							checked={setting.value === 1}
							type='primary'
							onChange={(checked) => {
								// Convert the checked value to 0 or 1 and update the setting.value
								const newValue = checked ? 1 : 0;

								// Create a copy of the settings array
								const updatedSettings = [...settings];

								// Update the specific setting's value
								updatedSettings[index] = {
									...setting,
									value: newValue
								};

								// Update the settings state with the modified array
								setSettings(updatedSettings);
							}}
						/>
					</div>
				);
			case settingFieldType.SINGLESELECT:
			case settingFieldType.MULTISELECT:
				if (setting.valueOptions.length > 4) {
					return (
						<Select
							placeholder={`Select ${setting.title}`}
							options={setting.valueOptions.map((setting) => {
								return {
									label: setting,
									value: setting
								};
							})}
							mode='multiple'
							value={setting.value.length === 0 ? [] : setting.value}
							onChange={(selectedValues) => {
								const updatedSettings = [...settings];
								updatedSettings[index] = {
									...setting,
									value: selectedValues
								};
								setSettings(updatedSettings);
							}}
						/>
					);
				} else {
					return (
						<Segmented
							type='primary'
							options={setting.valueOptions}
							value={setting.value}
							onChange={(newValue) => {
								const updatedSettings = [...settings];
								updatedSettings[index] = {
									...setting,
									value: newValue
								};
								setSettings(updatedSettings);
							}}
						/>
					);
				}
			case settingFieldType.JSON:
				value = JSON.parse(setting.value);

				return (
					<ReactJson
						src={value}
						theme={'bright'}
						style={{
							width: '100%'
						}}
						displayArrayKey={false}
						displayDataTypes={false}
						onAdd={
							!isPermittedEditJson
								? false
								: (newEntry) => {
										const updatedSettings = [...settings];
										updatedSettings[index] = {
											...setting,
											value: JSON.stringify(newEntry.updated_src) // Update the value with the modified JSON data
										};

										setSettings(updatedSettings);
								  }
						}
						onEdit={
							!isPermittedEditJson
								? false
								: (newValue) => {
										const updatedSettings = [...settings];
										updatedSettings[index] = {
											...setting,
											value: JSON.stringify(newValue.updated_src) // Update the value with the desired new value
										};
										setSettings(updatedSettings);
								  }
						}
						onDelete={
							!isPermittedEditJson
								? false
								: (newValue) => {
										const updatedSettings = [...settings];
										updatedSettings[index] = {
											...setting,
											value: JSON.stringify(newValue.updated_src) // Update the value with the desired new value
										};
										setSettings(updatedSettings);
								  }
						}
					/>
				);
			case settingFieldType.FILEUPLOAD:
				return (
					<Upload
						name='file'
						maxCount={1}
						action={async (file) => await checkBeforeUpload(file)}
						accept='.png,.jpeg,.jpg,.webp'
						onRemove={handleRemoveUpload}
						fileList={fileList}
						style={{
							width: '100%'
						}}
					>
						<Button icon={<UploadOutlined />}>Click to Upload</Button>
					</Upload>
				);
			case settingFieldType.DATE:
				return (
					<Datepicker
						value={setting.value ? moment(setting.value, 'DD/MM/YYYY') : null}
						onChange={(date, dateString) => {
							const updatedSettings = [...settings];

							updatedSettings[index] = {
								...setting,
								value: dateString || null
							};
							setSettings(updatedSettings);
						}}
						className={'fieldDate'}
						format='DD/MM/YYYY'
						disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day'))}
					/>
				);
			case settingFieldType.BUTTON:
				return (
					<Button type='primary' className='fieldButton'>
						Primary Button
					</Button>
				);
			case settingFieldType.MULTIVALUE:
				return (
					<RenderMultiValue setting={setting} index={index} settings={settings} setSettings={setSettings} />
				);
			case settingFieldType.CREDENTIAL:
				return (
					<RenderCredential setting={setting} index={index} settings={settings} setSettings={setSettings} />
				);
			default:
				return <div />;
		}
	};

	return renderSettingByFieldType(setting);
};

export default RenderFieldTypeSetting;
