import { all, takeEvery, put, fork } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';

import actions from './actions';

export function* loadTeamsData() {
	yield takeEvery('LOAD_TEAMS_DATA', function* () {
		const data = yield superFetch.get('/teams').then((res) => {
			var result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data.map((row) => ({
						teamID: row.teamID,
						siteID: row.siteID ? row.siteID : 'N/A',
						orgID: row.orgID,
						name: row.name,
						description: row.description,
						totalMember: row.totalMember,
						photoUrl: row.photoUrl ? row.photoUrl : 'N/A',
						modifiedDate: row.modifiedDate,
						createdDate: row.createdDate,
						adminPerson: row.adminPerson ? row.adminPerson : [],
						contact: row.contact ? row.contact : []
					}));
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_TEAMS_SUCCESS,
				teamsData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_TEAMS_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadTeamsData)]);
}
