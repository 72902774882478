import { HybridMap } from '@Z/components/atoms';
import Button from '@iso/components/uielements/button';
import Checkbox from '@iso/components/uielements/checkbox';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { Col, Collapse, Empty, Row } from 'antd';
import { uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FloorPlanMapWrapper from './floorPlanMap.styles';

const { Panel } = Collapse;
const FloorPlanMap = ({ floorData }) => {
	const [showAll, setShowAll] = React.useState([]);

	const [showDevicesBox, setShowDevicesBox] = React.useState(true);
	const [showAreasBox, setShowAreasBox] = React.useState(false);
	const [showRoomsBox, setShowRoomsBox] = React.useState(false);
	const [showAllBox, setShowAllBox] = React.useState(false);
	const [deviceLocations, setDeviceLocations] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState([]);
	const [currentCategory, setCurrentCategory] = React.useState(null);
	const [selectedCategoryDevices, setSelectedCategoryDevices] = React.useState([]);
	const { devicesData } = useSelector((state) => state.devices);

	useEffect(() => {
		if (floorData.floorID && showDevicesBox && devicesData.length > 0) {
			const devices = [];
			devicesData.forEach((item) => {
				const location = item?.location?.map((location) => {
					if (location.floorID === floorData.floorID) return location;
					return null;
				});
				const cleanLocation = location.filter((item) => item);

				if (cleanLocation.length > 0)
					devices.push({ ...item, x: cleanLocation[0].xAxis, y: cleanLocation[0].yAxis });
			});

			setDeviceLocations(devices);
			setSelectedCategoryDevices(devices);
			checkCategory(devices);
		} else {
			setDeviceLocations([]);
		}
	}, [floorData, devicesData, showDevicesBox]);

	const checkCategory = (deviceLocations) => {
		var deviceCategoryIDs = uniqBy(
			deviceLocations.filter((item) => item.categoryID),
			'categoryID'
		);

		setSelectedCategory(deviceCategoryIDs);
	};

	useEffect(() => {
		if (currentCategory !== null) {
			var deviceCategoryDevices = deviceLocations.filter((item) => item.categoryID === currentCategory);
			setSelectedCategoryDevices(deviceCategoryDevices);
		}
	}, [currentCategory, deviceLocations]);

	const handleClear = () => {
		setSelectedCategoryDevices([]);
		setCurrentCategory(null);
		setShowAllBox(false);
		setShowAreasBox(false);
		setShowDevicesBox(false);
		setShowRoomsBox(false);
	};

	const handleAreas = (e) => {
		if (e.target.checked === true) {
			setShowAreasBox(true);
			setSelectedCategoryDevices(deviceLocations);
		} else {
			setShowAreasBox(false);
			setSelectedCategoryDevices(deviceLocations);
		}
	};

	const handleRooms = (e) => {
		if (e.target.checked === true) {
			setShowRoomsBox(true);
			setSelectedCategoryDevices(deviceLocations);
		} else if (e.target.checked === false) {
			setShowRoomsBox(false);
			setSelectedCategoryDevices(deviceLocations);
		}
	};

	const handleShowDevices = (e) => {
		if (e.target.checked === true) {
			setShowDevicesBox(true);
			setShowAllBox(false);
			const deviceData = selectedCategoryDevices.filter((item) => item.typeName === 'Device');
			setSelectedCategoryDevices(deviceData);
		} else if (e.target.checked === false) {
			setShowDevicesBox(false);
			setSelectedCategoryDevices([]);
		}
	};

	const handleShowAll = (e) => {
		if (e.target.checked === true && showDevicesBox === true) {
			setShowAreasBox(true);

			setShowRoomsBox(true);
			setShowAllBox(true);
			setCurrentCategory(null);
			setShowAll(deviceLocations);
		} else if (e.target.checked === true) {
			setShowAreasBox(true);
			setShowDevicesBox(true);
			setShowRoomsBox(true);
			setShowAllBox(true);
			setShowAll(deviceLocations);
			setSelectedCategoryDevices(showAll);
		} else if (e.target.checked === false) {
			setShowAllBox(false);
			setShowAreasBox(false);
			setShowDevicesBox(false);
			setShowRoomsBox(false);
			setShowAll([]);
			setSelectedCategoryDevices(showAll);
		}
	};

	return floorData && Object.keys(floorData).length > 0 ? (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<FloorPlanMapWrapper>
				<Row>
					<Col lg={24} md={24} sm={24} xs={24}>
						<Collapse accordion ghost expandIcon={() => null} className='showFilterCollapse'>
							<Panel
								header={
									<div>
										<Button
											style={{ marginRight: '15px' }}
											type='secondary'
											className='showFilterButton'
										>
											Show Filters
										</Button>
									</div>
								}
								collapsible='header'
								key={0}
								className='showFilterCollapsePanel'
							>
								<div className='filterPanelContent'>
									<Checkbox checked={showAreasBox} onChange={(e) => handleAreas(e)}>
										Areas
									</Checkbox>
									<Checkbox checked={showRoomsBox} onChange={(e) => handleRooms(e)}>
										Rooms
									</Checkbox>
									<Checkbox checked={showDevicesBox} onChange={(e) => handleShowDevices(e)}>
										Devices
									</Checkbox>

									<Checkbox
										checked={showAllBox}
										onChange={(e) => {
											handleShowAll(e);
										}}
									>
										{showAllBox ? 'Hide' : 'Show'} all
									</Checkbox>
									<div style={{ display: 'flex', alignContent: 'center', gap: '10px' }}>
										<Select
											value={currentCategory}
											placeholder={'Select Category'}
											style={{ width: '150px' }}
											onChange={(value) => {
												setCurrentCategory(value);
											}}
										>
											{selectedCategory.length > 0 &&
												selectedCategory.map(
													(category, key) =>
														category.categoryName && (
															<Option
																key={key}
																value={category.categoryID}
																style={{ width: '150px', display: 'flex' }}
															>
																{category.categoryName}
															</Option>
														)
												)}
										</Select>
										<Button
											type='secondary'
											onClick={() => handleClear()}
											className='showFilterButton'
										>
											Clear Filters
										</Button>
									</div>
								</div>
							</Panel>
						</Collapse>
					</Col>
				</Row>
				<Row>
					<Col lg={24} md={24} sm={24} xs={24}>
						<div className='siteMap'>
							<div style={{ width: '100%', height: '100%' }}>
								<HybridMap
									data={{
										...floorData,
										markerList: selectedCategoryDevices
									}}
									mapType={'Floor'}
								/>
							</div>
						</div>
					</Col>
				</Row>

				<div className='cardContainer'>
					{selectedCategoryDevices.map((item) => (
						<item.cardComponent key={item.uID} device={item} />
					))}
				</div>
			</FloorPlanMapWrapper>
		</IsoWidgetBox>
	) : (
		<IsoWidgetBox>
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Floor Plan Added</span>} />
		</IsoWidgetBox>
	);
};

export default FloorPlanMap;
