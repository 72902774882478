/**
 * @author Kishan Virani
 * @email Kishan@Zezamii.com
 * @create date 12-10-2021  15:24:45
 * @modify date 24-10-2022 11:39:42
 * @desc [description]
 */

import basicStyle from '@iso/assets/styles/constants';

import { ComponentLabel } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
/**
 * This is a Page header component rendering the page title along with the company name.
 * It can be used by passing mandatory title variable as  String.
 *
 * The far right actions can be added as a child of the component.
 *
 * @export
 * @param {*} props
 * @returns
 */
const PageHeader = (props) => {
	const title = props.title ? props.title : 'N/A';

	const organisation = useSelector((state) => state.Organisation.organisationData);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);

	const [currentOrg, setCurrentOrg] = React.useState({});

	const { rowStyle, colStyle } = basicStyle;
	React.useEffect(() => {
		setCurrentOrg(organisation.find((org) => org.orgID === orgID) || {});
	}, [organisation, orgID]);

	return (
		<Row style={{ rowStyle, alignItems: 'center' }} gutter={0} justify='start'>
			<Col lg={12} md={12} sm={24} xs={24}>
				<Row>
					<Col style={{ textAlign: 'start', paddingRight: 10 }}>
						<ComponentLabel
							style={{ fontsize: 21, fontWeight: 500 }}
							text={
								title +
								(Object.keys(currentOrg).length > 0 && currentOrg.name ? ' - ' + currentOrg.name : '')
							}
						/>
					</Col>
					{props.counter ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Col style={{ paddingRight: 10, fontSize: '21px' }}>|</Col>
							<Col style={{ paddingTop: '3px' }}>
								<ComponentLabel
									style={{ fontSize: 15 }}
									text={'Total Active ' + title + ' : ' + props.counter}
								/>
							</Col>
						</div>
					) : null}
				</Row>
			</Col>
			<Col lg={12} md={12} sm={24} xs={24} style={(colStyle, { textAlign: 'end' })}>
				{/* Far right actions buttons. */}
				{props.children}
			</Col>
		</Row>
	);
};
export default PageHeader;
