import React, { useState, useEffect } from 'react';
import { smallTimeOptions } from '@iso/assets/styles/constants'; //ToDO: For Time Format
import { SimpleTable, SearchBar, Loading } from '@Z/components/atoms';
import { RenderActionControlInventory } from '@Z/components/molecules';
import { Col, Row } from 'antd';
import { notification } from '@iso/components';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import Button from '@iso/components/uielements/button';
import superFetch from '@iso/lib/helpers/superFetch';

const InventoryControlGatewaysTable = ({ workspaces }) => {
	const [filteredData, setFilteredData] = useState([]);
	const [filteredInfo, setFilteredInfo] = useState({});

	const [initialData, setInitialData] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchGateways = () => {
		superFetch
			.get(
				`/controlServer/gateways`,
				{},
				{
					page: 1,
					size: 1000
				}
			)
			.then((res) => {
				if (res.statusCode === 200) {
					setInitialData(res.data);
					setFilteredData(res.data);
					setLoading(false);
				} else {
					notification('error', 'Error while granting permission. Please try again.');
				}
			});
	};

	useEffect(() => {
		fetchGateways();
		return () => {
			setInitialData([]);
		};
	}, []);

	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'ID',
			key: 'ID',
			width: 70,
			ellipsis: true,
			fixed: 'left',
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: lock['id'],
						value: lock['id']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['id'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['id'] || null
		},
		{
			title: 'Asset ID',
			dataIndex: ['organisation', 'assetID'],
			key: 'assetID',
			filters: uniqBy(
				initialData
					.filter((lock) => lock.organisation && lock.organisation.assetID !== undefined)
					.map((lock) => {
						return {
							text: lock.organisation.assetID,
							value: lock.organisation.assetID
						};
					}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record.organisation && record.organisation.assetID === value,
			filterSearch: true,
			filteredValue: filteredInfo.assetID || null
		},
		{
			title: 'Gateway Name',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: lock['name'],
						value: lock['name']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['name'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['name'] || null
		},
		{
			title: 'Lock Mac',
			dataIndex: 'mac',
			key: 'mac',
			ellipsis: true,
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: lock['mac'],
						value: lock['mac']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['mac'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['mac'] || null
		},
		{
			title: 'IP Address',
			dataIndex: 'ip',
			key: 'ip',
			filters: uniqBy(
				initialData
					.filter((gateway) => gateway.ip !== null && gateway.ip !== undefined)
					.map((gateway) => {
						return {
							text: gateway.ip,
							value: gateway.ip
						};
					}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record.ip === value,
			filterSearch: true,
			filteredValue: filteredInfo.ip || null
		},
		{
			title: 'IP Config Flag',
			dataIndex: 'ipConfigFlag',
			key: 'ipConfigFlag',
			filters: uniqBy(
				initialData
					.filter((gateway) => gateway.ipConfigFlag !== null && gateway.ipConfigFlag !== undefined)
					.map((gateway) => {
						return {
							text: gateway.ipConfigFlag.toString(),
							value: gateway.ipConfigFlag
						};
					}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record.ipConfigFlag === value,
			filterSearch: true,
			filteredValue: filteredInfo.ipConfigFlag || null
		},
		{
			title: 'Is Online',
			dataIndex: 'onlineFlag',
			key: 'onlineFlag',
			filters: uniqBy(
				initialData
					.filter((gateway) => gateway.onlineFlag !== null && gateway.onlineFlag !== undefined)
					.map((gateway) => {
						return {
							text: gateway.onlineFlag.toString(),
							value: gateway.onlineFlag
						};
					}),
				'value'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record.onlineFlag === value,
			filterSearch: true,
			filteredValue: filteredInfo.onlineFlag || null
		},
		{
			title: 'Date Added',
			dataIndex: 'createDate',
			key: 'createDate',

			render: (text) => <p> {new Date(text).toLocaleString('en-AU', smallTimeOptions)} </p>,
			ellipsis: true,
			filters: uniqBy(
				initialData.map((lock) => {
					return {
						text: new Date(lock.createDate).toLocaleString('en-AU', smallTimeOptions),
						value: new Date(lock.createDate).toLocaleString('en-AU', smallTimeOptions)
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) =>
				new Date(record.createDate).toLocaleString('en-AU', smallTimeOptions) === value,
			filterSearch: true,
			filteredValue: filteredInfo.createDate || null
		},
		{
			title: 'Workspace',
			dataIndex: ['organisation', 'name'],
			key: 'orgName',
			filters: uniqBy(
				initialData
					.filter((gateway) => gateway.organisation && gateway.organisation.name)
					.map((gateway) => {
						return {
							text: gateway.organisation.name,
							value: gateway.organisation.name
						};
					}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record.organisation && record.organisation.name === value,
			filterSearch: true,
			filteredValue: filteredInfo.orgName || null
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'actions',
			fixed: 'right',
			width: 250,
			render: (text, record) => (
				<RenderActionControlInventory
					key={record.id}
					data={{ ...record, defaultCategoryID: constants.ZEZAMII_GATEWAYS }}
					type={'gateway'}
					workspaces={workspaces}
					getControlLocksAndGateways={() => fetchGateways()}
				/>
			)
		}
	];

	const clearFilters = () => {
		setFilteredInfo({});
		setFilteredData(initialData);
	};

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object['name'].toLowerCase().includes(query.toLowerCase()) ||
				object['mac'].toLowerCase().includes(query.toLowerCase()) ||
				object['ID'] === Number(query) ||
				object.organisation['assetID'] === Number(query)
		);
		setFilteredData(searchResult);
	};
	const handleChange = (_pagination, filters) => {
		setFilteredInfo(filters);
	};
	return (
		<div style={{ overflow: 'hidden', padding: '0px 10px 10px 10px' }}>
			<Row justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
						<Button size={'small'} onClick={clearFilters}>
							Clear Filter(s)
						</Button>
					</div>
					<SearchBar
						style={{ marginBottom: '10px' }}
						placeholder='Search ID, Asset ID, Gateway Name Or Mac Address'
						enterButton='Search'
						loading={false}
						onChange={(e) => {
							handleSearch(e.target.value, initialData);
						}}
						onSearch={(query) => {
							handleSearch(query, initialData);
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={24} md={24} sm={24} xs={24}>
					{loading ? (
						<Loading />
					) : (
						<SimpleTable
							columns={columns}
							pageSize={10}
							dataSource={filteredData}
							onChange={handleChange}
							scroll={{ x: 1000 }}
						/>
					)}
				</Col>
			</Row>
		</div>
	);
};

export default InventoryControlGatewaysTable;
