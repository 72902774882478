import actions from './actions';

const initState = { remoteConfig: [], pending: true, searchable: false };

const remoteConfigReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_REMOTE_CONFIG_SUCCESS:
			return {
				...state,
				remoteConfig: action.remoteConfigData,
				pending: false
			};
		case actions.LOAD_REMOTE_CONFIG_ERROR:
			return { ...state, remoteConfig: [], pending: false };
		default:
			return state;
	}
};
export default remoteConfigReducer;
