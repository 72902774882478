export const colors = {
	blue: {
		deep: 'rgb(0, 121, 191)',
		light: 'lightblue',
		lighter: '#d9fcff',
		soft: '#E6FCFF'
	},
	black: '#4d4d4d',
	shadow: 'rgba(0,0,0,0.2)',
	grey: {
		darker: '#C1C7D0',
		dark: '#E2E4E6',
		medium: '#DFE1E5',
		N30: '#EBECF0',
		light: '#F4F5F7'
	},
	green: 'rgb(185, 244, 188)',
	white: 'white',
	purple: 'rebeccapurple',
	orange: '#FF991F'
};
export const grid = 8;

export const borderRadius = 10;

export const timeOptions = {
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hourCycle: 'h12'
};

export const smallTimeOptions = {
	year: 'numeric',
	month: 'short',
	day: 'numeric'
};

export const mYTimeOptions = {
	year: 'numeric',
	month: 'long'
};

export const customFieldsType = {
	1: 'text_fields',
	2: 'numbers',
	3: 'today', //date
	4: 'rule' //boolean
};

export const boolType = {
	0: 'No',
	1: 'Yes',
	false: 'No',
	true: 'Yes',
	null: 'N/A'
};

const rowStyle = {
	width: '100%',
	display: 'flex',
	flexFlow: 'row wrap'
};
const colStyle = {
	marginBottom: '16px'
	// width:'100%'
};
const gutter = 16;

const loadingStyle = {
	textAlign: 'center',
	// background: '#f1f3f6',
	padding: '0px 0px'
};
const pageStyle = {
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'flex-start',
	overflow: 'hidden',
	width: '100%'
};
const basicStyle = {
	rowStyle,
	colStyle,
	gutter,
	loadingStyle,
	pageStyle
};

export default basicStyle;
