import { Modal } from '@Z/components/atoms';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import deviceCategoryAction from '@iso/redux/deviceCategories/actions';
import { Col, Divider, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCategoryFormWrapper from './AddDeviceCategoryButton.style';
import _ from 'lodash';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { editDeviceCategory } = deviceCategoryAction;

const EditDeviceCategoryButton = ({ category, children }) => {
	const dispatch = useDispatch();

	const { sitesData } = useSelector((state) => state.sites);
	const { categoryTypes, fieldTypes } = useSelector((state) => state.types);

	const [viewModal, setViewModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [disabledEditData, setDisabledEditData] = useState(true);
	const [newAssociations, setNewAssociations] = useState([]);
	const [deleteAssociations, setDeleteAssociations] = useState([]);

	const { colStyle } = basicStyle;
	const [form] = Form.useForm();
	const oldState = useRef();

	useEffect(() => {
		const EDIT_DATA = {
			name: category.name,
			siteID: category.sites.map((site) => site.siteID),
			typeID: category.typeID,
			description: category.description,
			fields: category.fields
		};
		oldState.current = EDIT_DATA;
		form.setFieldsValue(EDIT_DATA);
	}, [category.name, category.sites, category.typeID, category.description, category.fields, viewModal, form]);

	const handleCancel = () => {
		setViewModal(false);
		form.resetFields();
	};

	const onChangSelect = (array) => {
		const added = [];
		const deleted = [];

		// Check for deleted properties
		category.sites.forEach((item) => {
			const found = array.find((modItem) => modItem === item.siteID);
			if (!found) {
				deleted.push(item.siteID);
			}
		});

		// Check for added properties
		array.forEach((item) => {
			const found = category.sites.find((origItem) => origItem.siteID === item);
			if (!found) {
				added.push(item);
			}
		});

		setNewAssociations(added);
		setDeleteAssociations(deleted);
	};

	const handleSubmit = async (values) => {
		// setConfirmLoading(true);

		const editCategoryBody = {
			name: values.name,
			description: values.description,
			typeID: values.typeID
		};

		//Compare old state with new state
		const infoAdded = !_.isEqual(editCategoryBody, {
			name: oldState.current.name,
			description: oldState.current.description,
			typeID: oldState.current.typeID
		});

		//check if have added sites
		const sitesAdded = !_.isEqual(values.siteID, oldState.current.siteID);
		const fieldsAdded = !_.isEqual(values.fields, oldState.current.fields);

		try {
			if (infoAdded) {
				await superFetch.patch(`/deviceCategories/${category.categoryID}`, editCategoryBody);
			} else if (sitesAdded) {
				// added associated sites from the category
				if (newAssociations.length > 0) {
					await superFetch.patch(`/deviceCategories/${category.categoryID}/associations`, {
						siteIDs: newAssociations
					});
				} else if (deleteAssociations.length > 0) {
					// remove associated sites from the category
					await superFetch.delete(`/deviceCategories/${category.categoryID}/associations`, {
						siteIDs: deleteAssociations
					});
				}
			} else if (fieldsAdded) {
				const createFieldBody = {
					fields: values.fields
				};
				await superFetch.post(`/deviceCategories/${category.categoryID}/fields`, createFieldBody);
			}

			dispatch(
				editDeviceCategory({
					...category,
					name: values.name,
					description: values.description,
					typeID: values.typeID,
					fields: values.fields,
					sites: category.sites.filter((item) => values.siteID.includes(item.siteID))
				})
			);
			notification('success', 'Category updated successfully');
			setConfirmLoading(false);
			handleCancel();
		} catch (error) {
			setConfirmLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	return (
		<div>
			<span onClick={() => setViewModal(true)}>{children}</span>
			<Modal
				title='Edit Category'
				visible={viewModal}
				onCancel={handleCancel}
				width={650}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						loading={confirmLoading}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						disabled={disabledEditData}
					>
						Edit Category
					</Button>
				]}
			>
				<AddCategoryFormWrapper>
					<Divider className='title subtitle' orientation='left'>
						Category Information
					</Divider>
					<Form
						form={form}
						id={form}
						onFinish={handleSubmit}
						layout='vertical'
						onValuesChange={(props, values) => {
							const res = _.isEqual(oldState.current, values);
							setDisabledEditData(res);
						}}
					>
						<Row gutter={20}>
							<Col span={12}>
								<Form.Item name='name' label='Name' rules={fieldsValidator.Name}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name='siteID' label='Site'>
									<Select
										mode='multiple'
										placeholder={'Select Site'}
										onChange={(e) => onChangSelect(e)}
									>
										{sitesData.map((site) => (
											<Option key={site.siteID} value={site.siteID}>
												{site.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={12}>
								<Form.Item name='typeID' label='Type' rules={fieldsValidator.Required} style={colStyle}>
									<Select placeholder={'Select Type'}>
										{categoryTypes &&
											categoryTypes.map((type) => (
												<Option key={type.typeID} value={type.typeID}>
													{type.name}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name='description' label='Description' rules={fieldsValidator.Description}>
									<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
								</Form.Item>
							</Col>
						</Row>
						<Divider className='title subtitle' orientation='left'>
							Category Custom Fields
						</Divider>
						<Form.List name='fields'>
							{(fields, { add, remove }) => (
								<div>
									{fields.map(({ key, name, ...restField }) => {
										const disabled = name < category.fields.length;
										return (
											<Row key={key} style={{ display: 'flex', marginBottom: 15 }} gutter={10}>
												<Col span={14}>
													<Form.Item
														{...restField}
														name={[name, 'title']}
														rules={fieldsValidator.Name}
														style={{ marginBottom: 0 }}
													>
														<Input disabled={disabled} placeholder='Field Name' />
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item
														{...restField}
														name={[name, 'fieldTypeID']}
														rules={fieldsValidator.Required}
														style={{ marginBottom: 0 }}
													>
														<Select placeholder={'Type'} disabled={disabled}>
															{fieldTypes.map((type) => (
																<Option key={type.fieldTypeID} value={type.fieldTypeID}>
																	{type.name}
																</Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												{!disabled && (
													<Col span={2} style={{ alignItems: 'center' }}>
														<Form.Item {...restField} style={{ marginBottom: 0 }}>
															<MinusCircleOutlined onClick={() => remove(name)} />
														</Form.Item>
													</Col>
												)}
											</Row>
										);
									})}
									<Form.Item>
										<Button
											type='dashed'
											onClick={() =>
												add({
													fieldTypeID: fieldTypes[0] ? fieldTypes[0].fieldTypeID : null
												})
											}
											block
											icon={<PlusOutlined />}
										>
											Add Custom Field
										</Button>
									</Form.Item>
								</div>
							)}
						</Form.List>
					</Form>
				</AddCategoryFormWrapper>
			</Modal>
		</div>
	);
};
export default EditDeviceCategoryButton;
