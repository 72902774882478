import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import superFetch from '@iso/lib/helpers/superFetch';
import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

const ExportDeviceCSVButton = ({ device, children }) => {
	const floorData = useSelector((state) => state.floors.floorsData);
	const {
		remoteConfig: { defaultDeviceHeaders: defaultHeaders, defaultCategory }
	} = useSelector((state) => state.remoteConfig);

	const [downloadReady, setDownloadReady] = useState(false);
	const [floors, setFloors] = useState([]);
	const [headers, setHeaders] = useState(defaultHeaders);

	useEffect(() => {
		setFloors(floorData);
	}, [floorData]);

	// Fix for the API response call.
	const csvInstance = useRef();
	useEffect(() => {
		if (downloadReady && csvInstance.current && csvInstance.current.link) {
			csvInstance.current.link.click();
			setDownloadReady(false);
			setHeaders(defaultHeaders);
		}
	}, [downloadReady, defaultHeaders]);

	const getData = async () => {
		// get all the custom field value of the device
		const cFValueRes = await superFetch.get(`/devices/${device.uID}/fields`).then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				notification('error', 'Error exporting device CSV.');
				return [];
			}
		});

		// get all the custom fields of the category
		const cFHeaderRes = await superFetch.get(`/deviceCategories/${device.categoryID}/fields`).then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				notification('error', 'Error exporting device CSV.');
				return [];
			}
		});

		// Getting Custom fields Value Headers and aggregating them with default device Headers
		const cFHeader = cFHeaderRes.map((item) => {
			return { label: item.title, key: item.title };
		});

		// Only includes custom fields that is allowed to be exported for default categories.
		if (device.isDefault && defaultCategory[device.defaultCategoryID]?.exportAbleDeviceCustomFields.length > 0) {
			setHeaders([
				...headers,
				...cFHeader.filter((val) =>
					defaultCategory[device.defaultCategoryID].exportAbleDeviceCustomFields.includes(val.label)
				)
			]);
		} else {
			setHeaders([...headers, ...cFHeader]);
		}

		// organising the custom fields data
		const deviceFields = {};
		cFValueRes.forEach((item) => {
			deviceFields[item.fieldTitle] = item.value;
		});

		// aggregating the device data
		Object.keys(device).forEach((key) => {
			deviceFields[key] = device[key];
		});

		// aggregating the Location data
		if (device?.location?.length > 0) {
			const locations = device.location.map((item) => floors.find((floor) => floor.floorID === item.floorID));

			// Only getting the first location floor and site
			const sites = [];
			locations.forEach((item) => {
				if (!sites.includes(item.siteName)) sites.push(item.siteName);
			});

			const floorNames = [];
			locations.forEach((item) => {
				if (!floorNames.includes(item.name)) floorNames.push(item.name);
			});

			deviceFields.site = sites.join(' - ');
			deviceFields.floor = floorNames.join(' - ');
		}

		// return as array of one device with custom fields
		return [deviceFields];
	};

	return (
		<React.Fragment>
			<Tooltip
				title={'Export Device’s Information'}
				placement='rightBottom'
				onClick={async () => {
					const newData = await getData();
					if (newData.length > 0) {
						setDownloadReady(newData);
					}
				}}
			>
				{children ? (
					children
				) : (
					<Button
						size={'small'}
						style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						Export Device
					</Button>
				)}
			</Tooltip>
			{downloadReady ? (
				<CSVLink data={downloadReady} headers={headers} filename={`${device.name}.csv`} ref={csvInstance} />
			) : undefined}
		</React.Fragment>
	);
};
export default ExportDeviceCSVButton;
