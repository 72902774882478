import { Loading, RenderBookingActions, SimpleTable } from '@Z/components/atoms';
import { MinusOutlined } from '@ant-design/icons';
import calculateBookingStatus from '@iso/lib/helpers/bookingHelpers';
import superFetch from '@iso/lib/helpers/superFetch';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { Row } from 'antd';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import { useSelector } from 'react-redux';
import { TableBookingWrapper } from './BookingView.styles';

const BookingTable = ({ bookingData, previewBooking, setPreviewBooking, deviceID }) => {
	const { bookingData: bookingDataFromRedux } = useSelector((state) => state.booking);
	const displayNames = useGetPersonDisplayName(true);
	const {
		remoteConfig: { bookingStatusType }
	} = useSelector((state) => state.remoteConfig);

	const [bookings, setBookings] = useState([]);
	const [select, setSelect] = useState([0]);
	const [loading, setLoading] = useState(false);

	const rowSelection = {
		type: 'radio',
		selectedRowKeys: select,
		visible: false,
		onChange: (selectedRowKeys) => {
			setSelect(selectedRowKeys);
		}
	};

	const NavigateWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const getBookingByDevice = useCallback(async () => {
		if (deviceID) {
			setLoading(true);
			try {
				const res = await superFetch.get(
					`/booking`,
					{},
					{
						deviceID: deviceID,
						deleted: true,
						completed: true
					}
				);
				setBookings(res.data);
				setLoading(false);
			} catch (error) {
				setBookings([]);
				setLoading(false);
			}
		}
	}, [deviceID]);

	useEffect(() => {
		getBookingByDevice();
	}, [getBookingByDevice, bookingDataFromRedux]);

	useEffect(() => {
		if (bookingData) {
			setBookings(bookingData);
			setPreviewBooking(bookingData[select]);
		}
	}, [bookingData, select, setPreviewBooking]);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 130,
			ellipsis: true
		},
		{
			title: 'Type',
			dataIndex: ['bookingType', 'name'],
			key: 'bookingType',
			width: 75,
			ellipsis: true,
			sorter: (a, b) => a.device.name.localeCompare(b.device.name)
		},
		{
			title: 'Status',
			key: 'name',
			width: 90,
			ellipsis: true,
			filters: [
				{ text: bookingStatusType[1].title, value: bookingStatusType[1].title },
				{ text: bookingStatusType[2].title, value: bookingStatusType[2].title },
				{ text: bookingStatusType[3].title, value: bookingStatusType[3].title },
				{ text: bookingStatusType[4].title, value: bookingStatusType[4].title }
			],
			onFilter: (value, record) =>
				calculateBookingStatus(
					record.startTime,
					record.endTime,
					record.deletedDate,
					true,
					bookingStatusType
				) === value,
			render: (props) =>
				calculateBookingStatus(props.startTime, props.endTime, props.deletedDate, false, bookingStatusType)
		},
		{
			title: 'Device',
			dataIndex: ['device'],
			key: 'device',
			width: 120,
			ellipsis: true,
			sorter: (a, b) => a.device.name.localeCompare(b.device.name),
			render: (record) => (
				<NavigateWithLink type={'device'} dataID={record.uID}>
					{record.name}
				</NavigateWithLink>
			)
		},
		{
			title: 'Person',
			dataIndex: ['person'],
			key: 'person',
			width: 115,
			ellipsis: true,
			sorter: (a, b) => a.person.displayName.localeCompare(b.person.displayName),
			render: (record) => (
				<NavigateWithLink type={'person'} dataID={record.personID} title={displayNames[record.personID]?.title}>
					{displayNames[record.personID]?.displayName}
				</NavigateWithLink>
			)
		},
		{
			title: 'Time',
			width: 150,
			ellipsis: true,
			render: (_, props) => <GetTime startTime={props.startTime} endTime={props.endTime} />,
			sorter: (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix()
		},
		{
			title: 'Actions',
			ellipsis: true,
			align: 'center',
			key: 'endTime',
			width: 100,
			render: (record) => {
				const bookingStatus = calculateBookingStatus(
					record.startTime,
					record.endTime,
					record.deletedDate,
					true,
					bookingStatusType
				);
				if (
					bookingStatus.includes(bookingStatusType[1].title) ||
					bookingStatus.includes(bookingStatusType[2].title)
				) {
					return (
						<RenderBookingActions
							data={record}
							key={record.bookingID}
							activeButton={record.bookingID === previewBooking?.bookingID}
						/>
					);
				} else {
					return <div />;
				}
			}
		}
	];

	if (!deviceID && !bookingData) return <></>;
	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : (
				<TableBookingWrapper>
					<SimpleTable
						columns={columns}
						pageSize={10}
						dataSource={bookings}
						rowSelection={!deviceID && rowSelection}
						onRow={(record) => ({
							onClick: () => {
								setSelect([record.key]);
								setPreviewBooking(record);
							}
						})}
						rowClassName={'row-cursor'}
					/>
				</TableBookingWrapper>
			)}
		</Fragment>
	);
};
const GetTime = ({ startTime, endTime }) => {
	const finalStartTime = moment(startTime + 'Z').local();
	const finalEndTime = moment(endTime + 'Z').local();
	return (
		<Row className='bookingTime'>
			<div>
				<h1>{moment(finalStartTime).format('h:mm A')}</h1>
				<h3>{moment(finalStartTime).format('ddd DD MMM')}</h3>
			</div>
			<div className='icon-minus'>
				<MinusOutlined />
			</div>
			<div>
				<h1>{moment(finalEndTime).format('HH:mm A')}</h1>
				<h3>{moment(finalEndTime).format('ddd DD MMM')}</h3>
			</div>
		</Row>
	);
};

export default BookingTable;
