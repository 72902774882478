import actions from './actions';

const initState = { devicesData: [], pending: true, searchable: true };

const devicesReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_DEVICES_SUCCESS:
			return { ...state, devicesData: action.devicesData, pending: false };
		case actions.LOAD_DEVICES_ERROR:
			return { ...state, devicesData: [], pending: false };
		case actions.LOAD_DEVICES_CUSTOM_FIELDS_SUCCESS:
			return { ...state, devicesData: action.devicesWithFields, pending: false };
		default:
			return state;
	}
};
export default devicesReducer;
