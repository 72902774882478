import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Modal } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Steps from '@iso/components/uielements/steps';
import { notification } from '@iso/components';
import ContentHolder from '@iso/components/utility/contentHolder';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import steps from './steps';
import { useDispatch } from 'react-redux';
import siteAction from '@iso/redux/sites/actions';

const { loadSites } = siteAction;
const { Step } = Steps;

const AddSite = () => {
	const { rowStyle, colStyle } = basicStyle;
	const [current, setCurrent] = useState(0);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [visible, setVisible] = useState(false);

	const [stepForm] = Form.useForm();
	const dispatch = useDispatch();

	const showModal = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		stepForm.resetFields();
		setCurrent(0);
	};

	const onFinish = (values) => {
		setConfirmLoading(true);
		const formData = removeEmptyOrNull(stepForm.getFieldsValue(true));
		superFetch.post('/sites', formData).then((res) => {
			if (res.statusCode === 201) {
				dispatch(loadSites());
				setVisible(false);
				setCurrent(0);
				stepForm.resetFields();
			} else if (res.error.statusCode === 400) {
				notification('error', 'Please fill out all contact or address information or reset them.');
			} else {
				notification('error', 'There is an Error! We are mending the problem, try again soon!.');
			}
			setConfirmLoading(false);
		});
	};

	const next = () => {
		stepForm.validateFields().then((values) => setCurrent(current + 1));
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const reset = () => {
		if (current === 0) {
			stepForm.resetFields(['alias', 'name', 'abn', 'acn']);
		}
		if (current === 1) {
			stepForm.setFieldsValue({ contact: '' });
			stepForm.resetFields(['contact']);
		}
		if (current === 2) {
			stepForm.setFieldsValue({ address: '' });
			stepForm.resetFields(['address']);
		}
	};

	return (
		<div>
			<Button type='primary' onClick={showModal}>
				Add New Site
			</Button>
			<Modal
				title='Add New Site'
				visible={visible}
				onCancel={handleCancel}
				width={960}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={reset}>
						Reset
					</Button>,
					current > 0 && (
						<Button key='previous' onClick={() => prev()}>
							Previous
						</Button>
					),
					current < steps.length - 1 && (
						<Button key='next' type='primary' onClick={() => next()}>
							Next
						</Button>
					),
					current === steps.length - 1 && (
						<Button loading={confirmLoading} form={stepForm} key='submit' htmlType='submit' type='primary'>
							Submit
						</Button>
					)
				]}
			>
				<div style={{ margin: '0 100px' }}>
					<Form
						id={stepForm}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						onFinish={onFinish}
						autoComplete='off'
						form={stepForm}
					>
						<Row style={rowStyle} gutter={0} justify='space-around'>
							<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
								<ContentHolder>
									<Steps current={current} style={colStyle}>
										{steps.map((item) => (
											<Step key={item.title} title={item.title} />
										))}
									</Steps>
								</ContentHolder>
							</Col>
						</Row>

						<div>{steps[current].content}</div>
					</Form>
				</div>
			</Modal>
		</div>
	);
};

export default AddSite;
