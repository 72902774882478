import { Typography } from 'antd';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const ComponentLabel = styled.h3`
	color: ${palette('secondary', 0)};
	font-size: x-large;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	.title {
		font-size: 24px;
		font-weight: 600;
		color: ${palette('secondary', 0)};
	}
`;

export const EditableTitle = styled(Typography.Title)`
	margin-bottom: 0px !important;
	&.ant-typography-edit-content {
		margin-top: 6px;
		margin-left: 12px;
		.ant-input {
			font-size: 24px;
			font-weight: 500;
		}
		.ant-typography-edit-content-confirm {
			display: none;
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;
export default ComponentLabel;
