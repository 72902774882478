export const constants = {
	//Grant Permissions
	ENTITY_TYPE_ID_PERSON: 4,
	ENTITY_TYPE_ID_TEAM: 5,
	ENTITY_TYPE_ID_ASSETDEVICE: 6,
	ENTITY_TYPE_ID_ASSETGROUP: 7,
	//Asset Group Types
	ACCESS_CONTROL: 2,
	GENERAL: 1,
	//Default Category
	/*
	 * List of default categories
	 */
	HEALTH_SAFETY: 1,
	SMART_LOCKS: 12,
	SMART_GATEWAYS: 13,
	SMART_LOCKER: 14,
	VERKADA_CAMERAS: 15,
	ZEZAMII_ACCESS: 17,
	ZEZAMII_GATEWAYS: 18,
	ZEZAMII_LOCKERS: 19,
	ADD_DEVICE_EXCLUDED_CATEGORIES: [12, 13, 14, 17, 18, 19],
	ACCESS_CONTROL_CATEGORIES: [12, 17],
	SMART_CATEGORIES: [12, 13, 14, 17, 18, 19],
	NON_EDITABLE_SMART_CATEGORIES: [12, 13, 17, 18],

	/*
	 * Smart Categories
	 * These categories are considered as smart categories. (locker, lock, gateway)
	 * Smart categories cannot modify the additional fields by user.
	 */
	ACTIONABLE_SMART_LOCKS: [12, 14, 17, 19]
	/*
	 * Actionable Smart Locks
	 * These category will show the action for unlock or lock the smart categories (locker, lock, gateway)
	 */
};
