import React from 'react';
import ImgCrop from 'antd-img-crop';
import Upload from '@iso/components/uielements/upload';
import { useSelector, useDispatch } from 'react-redux';
import { notification } from '@iso/components';
import superFetch from '@iso/lib/helpers/superFetch';
import { uploadFiles, deleteFiles } from '@iso/lib/firebase/firebase.util';
import floorsAction from '@iso/redux/floors/actions';
import sitesAction from '@iso/redux/sites/actions';
import teamsAction from '@iso/redux/teams/actions';
import peopleAction from '@iso/redux/people/actions';
import authAction from '@iso/redux/auth/actions';

const { loadFloors } = floorsAction;
const { loadTeams } = teamsAction;
const { loadSites } = sitesAction;
const { loadPeople } = peopleAction;
const { dispatchUser } = authAction;

/**
 * @description
 * @author Kishan Virani
 * @date 10/03/2022
 * @export
 * @param {*} props
 * @param {*} cropperOptions
 * @param {*} uploadOptions
 * @param {*} entityName E.g. Site | Floor | People | Team
 * @param {*} entityID E.g. Unique Identifier of Site | Floor | People | Teams
 * @param {*} uploadType E.g. indoorMap | profilePicture
 * @param {*} mapData E.g. Data related to indoorMap  - mapData={{name:'indoorMap',dimensionX:'100',dimensionY:'100',note:'Some sort of note'}}
 * @param {*} setUploadLoading  Loading state updater
 * @param {*} oldData  Existing data of the entity, e.g. floor plan before uploading new one
 * @return {*}
 */
const UploadImagesToFirebaseComponent = (props) => {
	const { entityID, entityName, setUploadLoading, uploadType, mapData, setUploadSuccessful, oldData, uploadRef } =
		props;
	const dispatch = useDispatch();
	const { FBPersonID } = useSelector((state) => state.Auth.userProfile);
	const {
		remoteConfig: { entityInfo }
	} = useSelector((state) => state.remoteConfig);

	const generateFilePath = async (file, userID) => {
		return (
			`${entityInfo[entityName].entityDir}/` +
			userID +
			Date.now() +
			(file.type === 'image/png' ? '.png' : '.jpeg')
		);
	};

	const cleanUpOldData = async (data, type) => {
		// Delete indoor maps reference in Zezamii DB
		if (data.mapID && data.url && type === 'indoorMap') {
			await superFetch.delete(`/maps/${data.mapID}`).then((res) => {
				if (res && res.statusCode === 200) {
					notification('success', 'Old map and Device file deleted successfully');
				}
			});
			await deleteFiles(data.url);
		} else if (data.photoUrl && type === 'profilePicture') {
			if (!data.photoUrl.includes('/People/dummy')) {
				await deleteFiles(data.photoUrl);
			}
		}
	};

	const checkBeforeUpload = async (file) => {
		if (FBPersonID) {
			if (setUploadLoading) setUploadLoading(true);
			const correctFileType =
				file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
			const correctFileSize = file.size / 1024 / 1024 < 10;

			if (!correctFileType) {
				notification('error', 'You can only upload JPEG/PNG/WEBP file!');
				return;
			}
			if (!correctFileSize) {
				notification('error', 'Image must smaller than 10MB!');
				return;
			}
			if (!entityID) {
				notification('error', 'Please provide correct EntityID');
				return;
			}
			if ((entityName === 'Floor' || entityName === 'Site') && !mapData) {
				notification('error', 'Please provide correct Map Information');
				return;
			} else {
				//Upload file to firebase
				const filePath = await generateFilePath(file, FBPersonID);
				await uploadFiles(filePath, file);

				// Update entity with new image filepath
				if (uploadType === 'profilePicture' && (entityName === 'People' || entityName === 'Team') && filePath) {
					try {
						const body = { photoUrl: filePath };
						await superFetch.patch(`/${entityInfo[entityName].apiSlug}/${entityID}`, body).then((res) => {
							if (res && res.statusCode === 200) {
								switch (entityName) {
									case 'People':
										dispatch(dispatchUser());
										dispatch(loadPeople());
										break;
									case 'Team':
										dispatch(loadTeams());
										break;
									default:
										notification('error', 'Something went wrong! Please try again later.');
								}
								if (setUploadSuccessful) setUploadSuccessful(true);
								notification('success', 'Profile Updated Successfully.');
								cleanUpOldData(oldData, uploadType);
							}
						});
					} catch (err) {
						notification('error', 'Error while uploading image! Please try again.');
					}
				} else if (
					uploadType === 'indoorMap' &&
					(entityName === 'Floor' || entityName === 'Site') &&
					mapData &&
					filePath
				) {
					let mapID;
					// Create a indoor maps in Zezamii DB
					const mapBody = { ...mapData, url: filePath };
					await superFetch.post(`/maps/`, mapBody).then((res) => {
						if (res && res.statusCode === 201 && res.data) {
							mapID = res.data.ID;
						}
					});

					// Update entity with new image filepath
					if (mapID) {
						await superFetch
							.patch(`/${entityInfo[entityName].apiSlug}/${entityID}`, {
								mapID: mapID
							})
							.then((res) => {
								if (res && res.statusCode === 200) {
									switch (entityName) {
										case 'Floor':
											dispatch(loadFloors());
											break;
										case 'Site':
											dispatch(loadSites());
											break;
										default:
											notification('error', 'Something went wrong! Please try again later.');
									}
									if (setUploadSuccessful) setUploadSuccessful(true);
									notification('success', 'Floor Plan Updated Successfully.');
									cleanUpOldData(oldData, uploadType);
								}
							});
					} else {
						notification('error', 'Error while uploading Floor Plan! Please try again.');
					}
				}
			}
			if (setUploadLoading) setUploadLoading(false);
			return correctFileType && correctFileSize && entityID;
		}
	};
	return (
		<ImgCrop
			{...props.cropperOptions}
			minZoom={0.1}
			zoom={true}
			onModalCancel={() => {
				if (setUploadLoading) setUploadLoading(false);
			}}
			cropperProps={{
				restrictPosition: false
			}}
		>
			<Upload
				ref={uploadRef}
				showUploadList={false}
				beforeUpload={(file) => checkBeforeUpload(file)}
				accept='.png,.jpeg,.jpg,.webp'
				{...props.uploadOptions}
				customRequest={() => {
					// Empty function to prevent default upload function
				}}
			>
				{props.children}
			</Upload>
		</ImgCrop>
	);
};
export default UploadImagesToFirebaseComponent;
