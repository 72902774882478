const firebasConfig = {
	apiKey: 'AIzaSyBVKXTuUmnd2bWzg5ASXjY8DQIyCytL5CA',
	authDomain: 'zezamii-3f2a8.firebaseapp.com',
	projectId: 'zezamii-3f2a8',
	storageBucket: 'zezamii-3f2a8.appspot.com',
	messagingSenderId: '661920598022',
	appId: '1:661920598022:web:0a5ab5f228d1a40c6a5b87',
	measurementId: 'G-VLW8Q8TNYV'
};

export default firebasConfig;
