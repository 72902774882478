const actions = {
	LOAD_DEVICE_CATEGORIES_DATA: 'LOAD_DEVICE_CATEGORIES_DATA',
	LOAD_DEVICE_CATEGORIES_SUCCESS: 'LOAD_DEVICE_CATEGORIES_SUCCESS',
	LOAD_DEVICE_CATEGORIES_ERROR: 'LOAD_DEVICE_CATEGORIES_ERROR',
	ADD_CURRENT_CATEGORY: 'ADD_CURRENT_CATEGORY',
	EDIT_CATEGORY: 'EDIT_CATEGORY',

	loadDeviceCategories: () => ({
		type: actions.LOAD_DEVICE_CATEGORIES_DATA
	}),
	addCurrentCategory: (category) => ({
		type: actions.ADD_CURRENT_CATEGORY,
		payload: category
	}),
	editDeviceCategory: (category) => ({
		type: actions.EDIT_CATEGORY,
		payload: category
	})
};
export default actions;
