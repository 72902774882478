const actions = {
	LOAD_FLOORS_DATA: 'LOAD_FLOORS_DATA',
	LOAD_FLOORS_SUCCESS: 'LOAD_FLOORS_SUCCESS',
	LOAD_FLOORS_ERROR: 'LOAD_FLOORS_ERROR',

	loadFloors: () => ({
		type: actions.LOAD_FLOORS_DATA
	})
};
export default actions;
