import actions from './actions';

const initState = { organisationData: [], pending: true };

const organisationReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_ORGANISATION_SUCCESS:
			return {
				organisationData: action.organisationData,
				pending: false
			};
		case actions.LOAD_ORGANISATION_ERROR:
			return {
				organisationData: [],
				pending: false
			};
		default:
			return state;
	}
};

export default organisationReducer;
