import styled from 'styled-components';
import { palette } from 'styled-theme';
import { MoreOutlined } from '@ant-design/icons';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const MenuWrapper = styled.div`
	width: 100%;

	.siderContainer {
		${borderRadius()};
		overflow: auto;
		height: fit-content;

		.menuContainer {
			overflow: auto;

			.ant-menu-item {
				margin-top: 0px;
				margin-bottom: 0px;
			}
			.ant-menu-item-selected {
				background-color: ${palette('secondary', 11)};
				color: ${palette('secondary', 0)};
				font-weight: 500;
			}

			.ant-menu-item-selected::after {
				border-right: 3px solid ${palette('secondary', 8)};
			}

			.ant-menu-inline .ant-menu-item:not(:last-child) {
				margin-bottom: 0px;
			}

			.ant-menu-item:hover,
			.ant-menu-item:active {
				color: ${palette('secondary', 0)};
				font-weight: 500;
			}
			.ant-menu-sub.ant-menu-inline {
				background: ${palette('whitescale', 0)};
			}
			.ant-menu-item:active {
				background-color: ${palette('secondary', 11)};
			}
			.ant-menu-submenu-title,
			.ant-menu-submenu-arrow:hover {
				color: ${palette('secondary', 0)};
				font-weight: 500;
			}
			.ant-menu-submenu-arrow {
				color: ${palette('secondary', 0)};
			}

			.ant-menu-submenu-selected {
				color: ${palette('secondary', 0)};
			}

			.ant-menu-item-group-title,
			.ant-menu-submenu-title {
				position: sticky;
				box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.03);
				margin-bottom: 0px;
				margin-top: 0px;
				top: 0;
				background-color: ${palette('whitescale', 0)};
				z-index: 30;
			}
			.ant-menu-item-group-list {
				height: 100%;
				overflow: auto;

				li {
					width: 100%;
				}
			}
			.ant-menu-sub {
				overflow: auto;
				max-height: calc(100% - 45px);
				height: 100%;
			}

			.menuGroup {
				max-height: 50%;
				height: auto;
				overflow: auto;
				overflow-x: hidden;
			}

			.menuGroup:not(:first-child) {
				border-top: 1px solid ${palette('border', 0)};
			}
		}
	}

	.ant-layout {
		background-color: transparent !important;
	}

	.devicesMainContentContainer {
		overflow: auto;
		padding-bottom: 5px;
		padding-right: 5px;
		margin-bottom: -5px;
		margin-right: -5px;

		.deviceContentContainer {
			margin: 0px 0px 0px 17px;
			height: 100%;
		}
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;
export { MenuWrapper, MoreOutlinedIcon };
