import styled from 'styled-components';

const DeviceLocationWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;

	.infoActivityLabel {
	}

	.customIcon {
		cursor: pointer;
		margin-left: 5px;
		font-size: 12px;
	}
	.locationInfoContainer {
		display: flex;
		align-items: center;
	}

	.selectFloorContainer {
		display: flex;
		align-items: center;
		.floorLabel {
			margin-right: 5px;
		}
	}
`;
export { DeviceLocationWrapper };
