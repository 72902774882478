import { Loading, Modal } from '@Z/components/atoms';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import InputNumber from '@iso/components/uielements/InputNumber';
import Button from '@iso/components/uielements/button';
import DatePicker from '@iso/components/uielements/datePicker';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import { RadioButton, RadioGroup } from '@iso/components/uielements/radio';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import { CustomFieldValidationsByFieldTypeID as CustomFieldValidations } from '@iso/lib/helpers/validators/fieldsValidator';
import devicesAction from '@iso/redux/devices/actions';
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DeviceEditModal = ({ editVisible, setEditVisible, children, data, iconOnly }) => {
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const { fieldTypes } = useSelector((state) => state.types);
	const { deviceCategoriesData } = useSelector((state) => state.deviceCategories);
	const { devicesData } = useSelector((state) => state.devices);

	const dispatch = useDispatch();
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const { loadDevices } = devicesAction;
	const [form] = Form.useForm();

	// basic information for this modal to be used in the form.
	const { deviceID, categoryID } = data;

	const [loading, setLoading] = useState(true);
	const [updateDeviceLoading, setUpdateDeviceLoading] = useState(false);
	const [editableCustomFields, setEditableCustomFields] = useState([]);
	const [initialFields, setInitialFields] = useState([]);
	const [currentFields, setCurrentFields] = useState([]);

	useEffect(() => {
		const device = devicesData.find((device) => device.uID === deviceID);
		const customValues = deviceCategoriesData.find((category) => category.categoryID === categoryID).fields;

		if (device.isDefault && device?.editableCustomFields?.length > 0) {
			const fieldsToEdit = customValues.filter((value) => device.editableCustomFields.includes(value.title));
			setEditableCustomFields(fieldsToEdit);
		} else if (device.isDefault && device?.editableCustomFields?.length === 0) {
			setEditableCustomFields(customValues);
		} else {
			setEditableCustomFields(customValues);
		}
	}, [deviceCategoriesData, categoryID, devicesData, deviceID]);

	useEffect(() => {
		const fetchData = async () => {
			if (deviceID && editVisible) {
				try {
					const res = await superFetch.get(`/devices/${deviceID}/fields`);
					setInitialFields(res.data);
					setLoading(false);
				} catch (error) {
					// Handle error (e.g., show error message, log error)
					console.error('Error fetching data:', error);
				}
			}
		};

		fetchData();
	}, [deviceID, editVisible]);

	const handleCancel = () => {
		setEditVisible(false);
		setLoading(true);
		form.resetFields();
	};

	const onFinish = () => {
		setUpdateDeviceLoading(true);
		const { fields } = form.getFieldsValue(true);
		if (!fields) return;

		const body = fields.map((field) => ({
			fieldID: field.fieldID,
			value: field.value
		}));
		superFetch.post(`/devices/${deviceID}/fields`, body).then((res) => {
			if (res.statusCode === 200) {
				notification('success', 'Device Field Updated.');
				handleCancel();
				dispatch(loadDevices());
			}
			setUpdateDeviceLoading(false);
		});
	};

	const onChange = (fieldID, name) => {
		const { fields } = form.getFieldsValue();
		form.resetFields();
		setCurrentFields(fields);

		// get the fieldTypeID by fieldID in the category field
		const fieldTypeID = deviceCategoriesData
			.find((category) => category.categoryID === categoryID)
			.fields.find((field) => field.fieldID === fieldID).fieldTypeID;

		Object.assign(fields[name], { fieldTypeID });
		const initialField = initialFields.find((field) => field.fieldID === fieldID);

		if (initialField) {
			// format the initial date and boolean value
			switch (fieldTypeID) {
				case 3:
					Object.assign(fields[name], { value: moment(initialField.value) });
					break;
				case 4:
					Object.assign(fields[name], { value: initialField.value === 'true' });
					break;
				default:
					Object.assign(fields[name], { value: initialField.value });
			}
		} else {
			Object.assign(fields[name], { value: null });
		}

		form.setFieldsValue({ fields });
	};

	// render all unselected options from the category fields
	const unselectedOptions = (name) =>
		editableCustomFields.map((field) => {
			if (form.getFieldsValue().fields) {
				const formData = form.getFieldsValue();
				if (
					!formData.fields.map((f) => f.fieldID).includes(field.fieldID) ||
					formData.fields[name].fieldID === field.fieldID
				) {
					return (
						<Option key={field.fieldID} value={field.fieldID}>
							{field.title}
						</Option>
					);
				} else {
					return null;
				}
			} else {
				return (
					<Option key={field.fieldID} value={field.fieldID}>
						{field.title}
					</Option>
				);
			}
		});

	const isPermittedUpdateDevice = useManagePermittedRoles('updateDevice', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedUpdateDevice
						? "You don't have permission"
						: 'Update Information for this Device’s Additional Fields'
				}
				placement='rightBottom'
			>
				{children ? (
					<>{children}</>
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setEditVisible(true);
							}}
							disabled={!isPermittedUpdateDevice}
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							Modify Additional Fields
						</Button>
					</>
				)}
			</Tooltip>
			<Modal
				title='Edit Custom Fields'
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateDeviceLoading}
						disabled={currentFields.length === 0}
					>
						Update
					</Button>
				]}
			>
				{loading ? (
					<Row style={rowStyle} gutter={0} justify='space-around'>
						<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
							<div style={loadingStyle}>
								<Loading />
							</div>
						</Col>
					</Row>
				) : (
					<Form
						form={form}
						id={form}
						onFinish={onFinish}
						autoComplete='off'
						initialValues={{
							fields: [
								{
									fieldTypeID: fieldTypes[0] ? fieldTypes[0].fieldTypeID : null
								}
							]
						}}
					>
						<Form.List name='fields'>
							{(fields, { add, remove }) => (
								<div>
									{fields.map(({ key, name, ...restField }) => (
										<Row key={key} style={{ display: 'flex', marginBottom: 15 }} gutter={10}>
											<Col span={10}>
												<Form.Item {...restField} name={[name, 'fieldID']}>
													<Select
														showSearch
														placeholder={'Select a title'}
														onChange={(e) => onChange(e, name)}
														filterOption={(input, option) =>
															option.children
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														}
														filterSort={(optionA, optionB) =>
															optionA.children
																.toLowerCase()
																.localeCompare(optionB.children.toLowerCase())
														}
													>
														{unselectedOptions(name)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													{...restField}
													name={[name, 'value']}
													rules={
														form?.getFieldsValue()?.fields?.length > 0
															? CustomFieldValidations[
																	form.getFieldsValue()?.fields[name]?.fieldTypeID
															  ]
															: []
													}
												>
													{form?.getFieldsValue()?.fields?.length > 0 ? (
														form.getFieldsValue().fields[name].fieldTypeID === 1 ? (
															<Input placeholder='Text' />
														) : form.getFieldsValue().fields[name].fieldTypeID === 2 ? (
															<InputNumber placeholder='Number' />
														) : form.getFieldsValue().fields[name].fieldTypeID === 3 ? (
															<DatePicker placeholder='Date' />
														) : form.getFieldsValue().fields[name].fieldTypeID === 4 ? (
															<RadioGroup buttonStyle='outline'>
																<RadioButton value={true}>Yes</RadioButton>
																<RadioButton value={false}>No</RadioButton>
															</RadioGroup>
														) : (
															<Input />
														)
													) : (
														<Input />
													)}
												</Form.Item>
											</Col>
											<Col span={2} style={{ alignItems: 'center' }}>
												<Form.Item {...restField} style={{ marginBottom: 0 }}>
													<MinusCircleOutlined
														onClick={() => {
															const indexToRemove = name;
															const newFields = [...currentFields];
															newFields.splice(indexToRemove, 1);
															setCurrentFields(newFields);
															remove(name);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									))}
									{deviceCategoriesData.find((category) => category.categoryID === categoryID).fields
										.length > fields.length && (
										<Form.Item>
											<Button
												type='dashed'
												onClick={() =>
													add({
														fieldTypeID: fieldTypes[0] ? fieldTypes[0].fieldTypeID : null
													})
												}
												block
												icon={<PlusOutlined />}
											>
												Add Custom Field
											</Button>
										</Form.Item>
									)}
								</div>
							)}
						</Form.List>
					</Form>
				)}
			</Modal>
		</div>
	);
};

export default DeviceEditModal;
