/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 10-07-2023  11:16:12
 * @desc [description]
 */
import { SearchableTable } from '@Z/components/atoms';
import React from 'react';
import { useSelector } from 'react-redux';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import ComponentButton from '@Z/components/atoms/ComponentButton';

const TeamsTable = ({ teamsByPerson, customColumns, showInContainer }) => {
	const { sitesData } = useSelector((state) => state.sites);

	const ButtonWithLink = withHyperLink(() => <ComponentButton text={'View'} />);

	const NameWithLink = withHyperLink((a) => {
		return a.children;
	});

	const columns = [
		{
			title: 'Team Name',
			dataIndex: 'name',
			key: 'name',
			width: 55,
			ellipsis: true,
			render: (text, record) => (
				<NameWithLink type='team' dataID={record.teamID}>
					{text}
				</NameWithLink>
			)
		},
		{
			title: 'Team Description',
			dataIndex: 'description',
			key: 'description',
			width: 80,
			ellipsis: true
		},

		{
			title: 'Site Name',
			dataIndex: 'siteName',
			key: 'siteName',
			width: 35,
			ellipsis: true,

			render: (text, record) => {
				const siteDetails = sitesData.find((site) => site.siteID === record.siteID) || [];
				return (
					<NameWithLink type='site' dataID={record.siteID} key={record.siteID}>
						<p>{siteDetails.name}</p>
					</NameWithLink>
				);
			}
		},

		{
			key: 'View',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ButtonWithLink type='team' dataID={record.teamID} />
				</div>
			),
			ellipsis: true,
			width: 40
		}
	];

	return (
		<SearchableTable
			columns={customColumns || columns}
			pageSize={6}
			dataSource={teamsByPerson}
			filterColumn={['name']}
			placeholder={'Team Name'}
			title={showInContainer ? <NameWithLink type='teams'>Teams</NameWithLink> : null}
		/>
	);
};
export default TeamsTable;
