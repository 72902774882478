import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import React from 'react';
import actions from './actions';
import { Button } from 'antd';

export function* loadFloorsData() {
	yield takeEvery('LOAD_FLOORS_DATA', function* () {
		const data = yield superFetch.get('/floors').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result.data = res.data.map((row) => ({
						floorID: row.floorID,
						name: row.name,
						description: row.description,
						createdDate: row.createdDate,
						modifiedDate: row.modifiedDate,
						deletedDate: row.deletedDate,
						siteID: row.siteID,
						siteName: row.siteName,
						siteAbn: row.siteAbn ? row.siteAbn : 'N/A',
						siteAcn: row.siteAcn ? row.siteAcn : 'N/A',
						latitude: row.latitude ? row.latitude : 'N/A',
						longitude: row.longitude ? row.longitude : 'N/A',
						orgID: row.orgID,
						orgAlias: row.orgAlias,
						orgName: row.orgName,
						orgAbn: row.orgAbn ? row.orgAbn : 'N/A',
						orgAcn: row.orgAcn ? row.orgAcn : 'N/A',
						parentOrgID: row.parentOrgID ? row.parentOrgID : 'N/A',
						mapID: row.mapID ? row.mapID : 'N/A',
						mapName: row.mapName ? row.mapName : 'N/A',
						dimensionX: row.dimensionX ? row.dimensionX : 'N/A',
						dimensionY: row.dimensionY ? row.dimensionY : 'N/A',
						note: row.note ? row.note : 'N/A',
						url: row.url ? row.url : 'N/A',
						siteAddress: row.siteAddress ? row.siteAddress : [],
						contact: row.contact ? row.contact : [],
						adminPerson: row.adminPerson ? row.adminPerson : []
					}));
				} else {
					result.data = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result.error = res.error;
				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_FLOORS_SUCCESS,
				floorsData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_FLOORS_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadFloorsData)]);
}
