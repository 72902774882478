import styled from 'styled-components';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const StickerWidgetWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: stretch;
	overflow: hidden;
	${borderRadius('4px')};
	.isoIconWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		flex-shrink: 0;
		background-color: rgba(0, 0, 0, 0.1);
		i {
			font-size: 40px;
		}
	}
	.isoContentWrapper {
		width: 100%;
		padding: 13px;
		display: flex;
		flex-direction: column;
		.isoStatNumber {
			font-size: 20px;
			font-weight: 600;
			line-height: 1.1;
			margin: 0 0 5px;
		}
		.isoLabel {
			font-size: 12px;
			font-weight: 500;
			margin: 0;
			line-height: 1;
		}
	}
`;

export { StickerWidgetWrapper };
