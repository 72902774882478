import { DownOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import ContentHolder from '@iso/components/utility/contentHolder';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import superFetch from '@iso/lib/helpers/superFetch';
import sitesAction from '@iso/redux/sites/actions';
import { Loading, Modal, HybridMap } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IndividualSitesInfoWrapper from './ISF.styles.js';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles.js';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator.js';

const IndividualSiteDetails = ({ siteInfo }) => {
	const history = useHistory();
	const currentPath = history.location.pathname;
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const { loadSites } = sitesAction;
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [loadings, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const [sites, setSites] = React.useState([]);

	const sitesData = useSelector((state) => state.sites.sitesData);
	const sitesDataPending = useSelector((state) => state.sites.pending);
	const [editVisible, setEditVisible] = React.useState(false);
	const [updateSiteLoading, setUpdateSiteLoading] = React.useState(false);
	const [editHasChanges, setEditHasChanges] = React.useState(false);
	const isPermittedUpdateSite = useManagePermittedRoles('updateSite', {
		orgID: [siteInfo.orgID],
		siteID: [siteInfo.siteID]
	});

	React.useEffect(() => {
		setLoading(true);
		setData(siteInfo);
		if (!sitesDataPending) {
			setSites(sitesData);
		}
		setLoading(false);
	}, [siteInfo, sitesData, sitesDataPending]);

	const siteListing = () => {
		return (
			<DropdownMenu>
				{sites.map((site, key) => {
					return (
						<MenuItem
							onClick={() => {
								history.push({
									pathname: currentPath,
									state: { siteID: site.siteID }
								});
							}}
							key={key}
						>
							{site.name}
						</MenuItem>
					);
				})}
			</DropdownMenu>
		);
	};
	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		if (
			(data.name === formData.name || formData.name === '') &&
			(data.abn === formData.abn || formData.abn === '') &&
			(data.acn === formData.acn || formData.acn === '')
		) {
			return false;
		} else {
			return true;
		}
	};
	const handleCancel = () => {
		setEditHasChanges(false);
		setEditVisible(false);
	};
	const onFinish = () => {
		let newSiteInfo = {};
		const formData = form.getFieldsValue(true);
		if (formData.name !== data.name && formData.name !== '') {
			newSiteInfo['name'] = formData.name;
		}
		if (formData.abn !== data.abn) {
			newSiteInfo['abn'] = formData.abn;
		}
		if (formData.acn !== data.acn) {
			newSiteInfo['acn'] = formData.acn;
		}
		if (newSiteInfo.name || newSiteInfo.acn || newSiteInfo.abn) {
			setUpdateSiteLoading(true);
			superFetch
				.patch('/sites/' + data.siteID, newSiteInfo)
				.then((res) => {
					if (res.statusCode === 200) {
						dispatch(loadSites());
						notification('success', 'Site Information Updated.');
					} else {
						notification('error', res.error.error.join('\n'));
					}
				})
				.catch((err) => {
					notification('error', 'Update Failed, Please try again.');
				});
			setUpdateSiteLoading(false);
		}
		setEditHasChanges(false);
		setEditVisible(false);
	};

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<IndividualSitesInfoWrapper>
				<Row className='siteInfoContainer'>
					<Col
						lg={{ span: 12, order: 1 }}
						md={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						xs={{ span: 24, order: 1 }}
						className='siteHeader'
					>
						<h2>{data.name}</h2>
					</Col>
					<Col
						lg={{ span: 24, order: 3 }}
						md={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						xs={{ span: 24, order: 2 }}
						className='siteDetails'
					>
						<div className='siteMap'>
							<div style={{ width: '100%', height: '100%' }}>
								<HybridMap
									data={{ url: data.mapUrl || 'n/a', ...data, markers: [] }}
									mapType={'Site'}
								/>
							</div>
						</div>
					</Col>
					<Col
						lg={{ span: 12, order: 2 }}
						md={{ span: 24, order: 3 }}
						sm={{ span: 24, order: 3 }}
						xs={{ span: 24, order: 3 }}
						className='actionButtonContainer'
					>
						{sites.length > 0 ? (
							<Dropdown className='switchSiteButton' overlay={siteListing()} trigger={['click']}>
								<Button>
									Sites <DownOutlined />
								</Button>
							</Dropdown>
						) : null}
						{isPermittedUpdateSite && (
							<Button
								type='primary'
								onClick={() => {
									setEditVisible(true);
									form.setFieldsValue({
										name: data.name,
										abn: data.abn,
										acn: data.acn
									});
								}}
								style={{ marginLeft: '10px' }}
							>
								Edit Details
							</Button>
						)}
					</Col>
				</Row>
			</IndividualSitesInfoWrapper>
			<Modal
				title={'Edit ' + data.name}
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				getContainer={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateSiteLoading}
						disabled={!editHasChanges}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					labelCol={{ lg: { span: 3, offset: 2 }, sm: { span: 3, offset: 0 } }}
					wrapperCol={{ lg: { span: 17 }, sm: { span: 16 }, xs: { span: 10 } }}
					onFinish={onFinish}
					autoComplete='off'
					onValuesChange={() => setEditHasChanges(updateChange())}
				>
					<Form.Item label='Name' colon name='name' rules={fieldsValidator.Name} style={colStyle}>
						<Input placeholder={'Please enter Name.'} />
					</Form.Item>

					<Form.Item label='ABN' name='abn' colon rules={fieldsValidator.ABN} style={colStyle}>
						<Input placeholder={'Please enter ABN'} />
					</Form.Item>
					<Form.Item label='ACN' colon name='acn' rules={fieldsValidator.ACN} style={colStyle}>
						<Input placeholder={'Please enter ACN'} />
					</Form.Item>
				</Form>
			</Modal>
		</IsoWidgetBox>
	);
};

export default IndividualSiteDetails;
