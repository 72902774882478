import { RevokePersonPermissionModal, SimpleTable } from '@Z/components/atoms';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { Table } from 'antd';
import { ComponentLabel } from '@Z/components/atoms';
import { uniqBy } from 'lodash';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SuperFetch from '@iso/lib/helpers/superFetch';

const ACPermittedPeopleTable = ({ lock }) => {
	const { floorsData } = useSelector((state) => state.floors);
	const [permittedPeople, setPermittedPeople] = useState([]);
	const [expandedRow, setExpandedRow] = useState(0);
	const [permissionStateUpdated, setPermissionStateUpdated] = useState(false);

	const permittedLocationsObjects =
		lock?.location?.length > 0
			? lock.location.map((item) => floorsData.find((floor) => floor.floorID === item.floorID))
			: [];

	const isPermittedRevokePermission = useManagePermittedRoles('aCPermissionManagement', {
		orgID: [lock.orgID],
		siteID: permittedLocationsObjects.map((item) => item.siteID).filter((item) => item),
		floorID: permittedLocationsObjects.map((item) => item.floorID).filter((item) => item)
	});

	useEffect(() => {
		if (lock.uID) {
			const getData = async () => {
				const { data: permittedUsers } = await SuperFetch.get(`/access`, {}, { deviceID: lock.uID });

				// Group by personID
				const groupedPerson = permittedUsers.reduce((result, current) => {
					const { person } = current;
					const key = person.personID;
					result[key] = result[key] || [];
					result[key].push(current);
					return result;
				}, {});

				// Convert to array
				const users = Object.keys(groupedPerson).map((key) => ({
					personID: key,
					displayName: groupedPerson[key][0].person.displayName,
					email: groupedPerson[key][0].person.email,
					permissions: groupedPerson[key]
				}));

				setPermittedPeople(users);
				setPermissionStateUpdated(false);
			};
			getData();
		}
	}, [lock.uID, permissionStateUpdated]);

	const NameWithlink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const columns = [
		{
			title: 'Person',
			dataIndex: 'displayName',
			key: 'Person',
			width: '70%',
			ellipsis: true,

			render: (text, record) => (
				<NameWithlink type='person' dataID={Number(record.personID)}>
					{text}
				</NameWithlink>
			),
			filters: uniqBy(
				permittedPeople.map((lock) => {
					return {
						text: lock['displayName'],
						value: lock['displayName']
					};
				}),
				'text'
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => record['displayName'] === value
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Derived Permissions',
			key: 'Permission',
			width: '50%',
			align: 'center',
			render: (_, record) => <span>{record.permissions.length}</span>
		},
		{
			key: 'revoke',
			width: '30%',
			dataIndex: ['permissions'],
			render: (record) => {
				const permissionsIDs = record.map((permission) => permission.permissionID);
				if (isPermittedRevokePermission) {
					return (
						<RevokePersonPermissionModal
							setPermissionStateUpdate={setPermissionStateUpdated}
							data={{
								entity: {
									permissionIDs: permissionsIDs,
									permissionNameOwner: record[0].person.displayName,
									name:
										record[0].deviceID && record[0].assetGroupID
											? 'Groups and Devices'
											: record[0].deviceID
											? record[0].device.name
											: record[0].assetGroup.name
								}
							}}
							revokeAllPermissions
						/>
					);
				}
			},
			ellipsis: true
		}
	];

	return (
		<SimpleTable
			columns={columns}
			pageSize={7}
			dataSource={permittedPeople}
			rowKey='personID'
			expandable={{
				expandedRowRender: (a) => (
					<PermissionTable
						permData={a.permissions}
						lock={lock}
						setPermissionStateUpdated={setPermissionStateUpdated}
					/>
				),
				expandedRowKeys: expandedRow,
				expandRowByClick: true,
				onExpand: (expanded, record) => {
					const keys = [];
					if (expanded) {
						keys.push(record.personID);
					}
					setExpandedRow(keys);
				}
			}}
		/>
	);
};

export const PermissionTable = ({ permData, setPermissionStateUpdated }) => {
	// HOC
	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			width: '10%',
			render: (_, record) => <span> {record.teamID || record.assetGroupID ? 'Inherited' : 'Direct'}</span>
		},
		{
			title: 'Team',
			dataIndex: ['team', 'name'],
			key: 'team',
			ellipsis: true,
			render: (_, record) => {
				return record.team ? (
					<NameWithLink type='team' dataID={record.team.teamID}>
						<ComponentLabel
							style={{
								fontSize: '14px',
								fontWeight: '600',
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
							text={record.team.name}
						/>
					</NameWithLink>
				) : (
					''
				);
			},

			filters: uniqBy(
				permData.map((i) => {
					if (i.team) {
						return {
							text: i.team.name,
							value: i.team.name
						};
					} else {
						return {
							text: 'Unassigned',
							value: null
						};
					}
				}),
				'text'
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => {
				return record?.team?.name === (value || undefined);
			}
		},
		{
			title: 'Group',
			dataIndex: ['assetGroup', 'name'],
			key: 'assetGroup',
			ellipsis: true,
			render: (_, record) =>
				record.assetGroup ? (
					<NameWithLink type='assetGroup' dataID={record.assetGroupID}>
						<ComponentLabel
							style={{
								fontSize: '14px',
								fontWeight: '600',
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
							text={record.assetGroup.name}
						/>
					</NameWithLink>
				) : (
					''
				),
			filters: uniqBy(
				permData.map((i) => {
					if (i.group) {
						return {
							text: i.group.name,
							value: i.group.name
						};
					} else {
						return {
							text: 'Unassigned',
							value: null
						};
					}
				}),
				'text'
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => {
				return record?.group?.name === (value || undefined);
			}
		},
		{
			key: 'revoke',
			render: (d) => {
				return (
					<RevokePersonPermissionModal
						setPermissionStateUpdate={setPermissionStateUpdated}
						data={{
							entity: {
								permissionIDs: [d.permissionID],
								name: d.device.name,
								permissionNameOwner: d.person.displayName
							}
						}}
					/>
				);
			},
			ellipsis: true
		}
	];
	return <SimpleTable columns={columns} dataSource={permData} pageSize={7} />;
};

export default ACPermittedPeopleTable;
