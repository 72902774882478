import { Collapse } from 'antd';
import { SimpleTable } from '@Z/components/atoms';
import React, { useState } from 'react';
import { RadioButton } from '@iso/components/uielements/radio';
import { useEffect } from 'react';
const { Panel } = Collapse;
const ACFloorDeviceTable = ({ locksByFloor, selectedFloor }) => {
	const [panelOpen, setPanelOpen] = useState(0);

	useEffect(() => {
		if (selectedFloor) {
			const index = locksByFloor.map((e) => e.floorID).indexOf(selectedFloor[0].floorID);
			setPanelOpen(index);
		}
	}, [selectedFloor, locksByFloor]);

	const columns = [
		{
			title: 'Door/Lock',
			dataIndex: 'name',
			key: 'name',
			width: 60,
			render: (text, record) => <div>{text}</div>,
			ellipsis: true
		},
		{
			key: 'preview',
			render: (text) => (
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<RadioButton
						style={{ height: '28px', lineHeight: '28px', fontSize: '12px', fontWeight: '500' }}
						value={text.uID}
					>
						Preview
					</RadioButton>
				</div>
			),
			ellipsis: true,
			width: 20
		}
	];

	const onCollapseClick = (key) => {
		setPanelOpen(key);
	};

	return (
		<Collapse activeKey={panelOpen} accordion onChange={onCollapseClick}>
			{locksByFloor.map((floor, index) => (
				<Panel header={floor.name} key={index}>
					<SimpleTable columns={columns} pageSize={15} dataSource={floor.locks} />
				</Panel>
			))}
		</Collapse>
	);
};

export default ACFloorDeviceTable;
