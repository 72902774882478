import styled from 'styled-components';
import { palette } from 'styled-theme';

const SwitchDropDown = styled.div`
	position: absolute;
	z-index: 300;
	top: 10px;
	right: 65px;

	.switchDropDownBtn {
		border-color: ${palette('primary', 5)};
	}

	.switchBtn {
		height: 28px;
		line-height: 28px;
		font-size: 12px;
		font-weight: 500;
	}
`;

export default SwitchDropDown;
