import { Modal } from '@Z/components/atoms';
import Icon from '@ant-design/icons';
import { ReactComponent as assetIconOutlined } from '@iso/assets/images/icon/AssetDeviceIconOutlined24x24.svg';
import { ReactComponent as healthNSIcon } from '@iso/assets/images/icon/Health&Safety_Icon.svg';
import { ReactComponent as gatewayIcon } from '@iso/assets/images/icon/SmartGateway_Icon.svg';
import { ReactComponent as lockIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import { notification } from '@iso/components';
import { Button, Form, Select } from '@iso/components/uielements';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import groupAction from '@iso/redux/assetGroups/actions';
import { Tooltip } from 'antd';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectAssetsWrapper, { TagWrapper } from './styles';

const { loadAssetGroups } = groupAction;

const EditAssetGroupModal = ({ visible, setVisible, children, data: group }) => {
	// Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	// Local States
	const [updateDeviceLoading, setUpdateDeviceLoading] = useState(false);
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

	// Global States
	const { devicesData } = useSelector((state) => state.devices);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	useEffect(() => {
		// Set initial value for the select of the form
		if (visible) {
			form.setFieldsValue({
				assets: group.assets.map((asset) => asset.assetID)
			});
		}
	}, [group, form, visible]);

	const handleCancel = () => {
		setVisible(false);
		setSubmitButtonDisable(true);
		setUpdateDeviceLoading(false);
		form.resetFields();
	};

	const onFinish = async () => {
		setUpdateDeviceLoading(true);
		// Get current asset from the group
		const currentAssets = group.assets.map((asset) => asset.assetID);

		// Updated Assets from the Form.
		const formData = form.getFieldsValue(true);

		// Filter assets that needs to be added or removed
		const addedAssets = formData.assets.filter((id) => !currentAssets.includes(id));
		const removedAssets = currentAssets.filter((id) => !formData.assets.includes(id));

		// Add all the selected devices to the group
		const addSuccess =
			addedAssets.length > 0
				? await superFetch.put(`/deviceGroups/${group.groupID}/assets`, { assets: addedAssets }).then((res) => {
						if (res.statusCode === 200) {
							return true;
						} else {
							notification('error', 'There is an Error! We are mending the problem, try again soon!.');
							return false;
						}
				  })
				: false;

		// Remove all the unselected devices from the group
		const removeSuccess =
			removedAssets.length > 0
				? await superFetch
						.delete(`/deviceGroups/${group.groupID}/assets`, { assets: removedAssets })
						.then((res) => {
							if (res.statusCode === 200) {
								return true;
							} else {
								notification(
									'error',
									'There is an Error! We are mending the problem, try again soon!.'
								);
								return false;
							}
						})
				: false;

		if (addSuccess || removeSuccess) {
			dispatch(loadAssetGroups());
			handleCancel();
		}
	};

	const handleChange = (value) => {
		setSubmitButtonDisable(isEqual(group.assets.map((asset) => asset.assetID).sort(), value.sort()));
	};

	const groupAssets = (devices) => {
		const options = [];
		if (group?.typeID === constants.ACCESS_CONTROL) {
			const locks = devicesData.filter((device) =>
				constants.ACCESS_CONTROL_CATEGORIES.includes(device.defaultCategoryID)
			);
			locks.forEach((dev) => {
				options.push({
					label: dev.name,
					value: dev.uID
				});
			});
		} else {
			devices.forEach((dev) => {
				options.push({
					label: dev.name,
					value: dev.uID
				});
			});
		}

		return options;
	};

	const tagRender = (props) => {
		const { label, value, onClose } = props;
		const device = devicesData.find((device) => device.uID === value);

		const onPreventMouseDown = (event) => {
			onClose();
			event.preventDefault();
			event.stopPropagation();
		};

		let icon = assetIconOutlined;
		if (device.defaultCategoryID === constants.HEALTH_SAFETY) {
			icon = healthNSIcon;
		} else if (device.defaultCategoryID === constants.SMART_GATEWAYS) {
			icon = gatewayIcon;
		} else if (device.defaultCategoryID === constants.SMART_LOCKS) {
			icon = lockIcon;
		}

		return (
			<TagWrapper
				icon={<Icon component={icon} className='tagIcons' />}
				onMouseDown={onPreventMouseDown}
				closable={true}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</TagWrapper>
		);
	};

	const isPermittedUpdateGroup = useManagePermittedRoles('updateGroup', {
		orgID: [group.orgID],
		siteID: [group.siteID],
		floorID: []
	});

	return (
		<div>
			<Tooltip
				title={!isPermittedUpdateGroup ? "You don't have permission" : 'Add/Remove Assets from this Group'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<Button
						disabled={!isPermittedUpdateGroup}
						onClick={() => {
							setVisible(true);
						}}
						size={'small'}
						style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						Add or Remove Assets
					</Button>
				)}
			</Tooltip>

			<Modal
				title='Add or Remove Assets'
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						disabled={submitButtonDisable}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateDeviceLoading}
					>
						Update
					</Button>
				]}
			>
				<Form form={form} id={form} onFinish={onFinish} autoComplete='off'>
					<Form.Item name='assets'>
						<Select
							allowClear
							tagRender={(e) => tagRender(e)}
							autoFocus
							mode='multiple'
							onChange={handleChange}
							placeholder='Select Devices'
							filterOption={(input, option) =>
								option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							defaultOpen
							getPopupContainer={() => document.getElementById('assetsSelectInput')}
							options={groupAssets(devicesData)}
							dropdownStyle={{ position: 'relative' }}
						/>
					</Form.Item>
					<SelectAssetsWrapper id='assetsSelectInput' />
				</Form>
			</Modal>
		</div>
	);
};

export default EditAssetGroupModal;
