import { remoteConfig } from './firebase';
import defaultConfig from '@iso/config/remoteConfig.default';

if (process.env.NODE_ENV !== 'production') {
	remoteConfig.settings.minimumFetchIntervalMillis = 360000; //! this is for development only.
}

export const getRemoteConfig = async () => {
	let remoteConfigValues = {};
	try {
		await remoteConfig.fetchAndActivate();
		const config = remoteConfig.getAll();

		for (let key in config) {
			if (typeof config[key]._value === 'string') {
				try {
					remoteConfigValues[key] = JSON.parse(config[key]._value);
				} catch (error) {
					console.error('Error parsing remote config value for key:', key, error);
					// Fallback to a default value for this specific key, if available
					remoteConfigValues[key] = defaultConfig[key] || null;
				}
			}
		}
	} catch (error) {
		console.error('Error fetching remote config:', error);
		// Fallback to the entire default config if there was an error fetching remote config
		remoteConfigValues = defaultConfig;
	}
	if (process.env.NODE_ENV !== 'production') {
		return defaultConfig;
	} else {
		return remoteConfigValues;
	}
};
