import React, { useEffect, useState } from 'react';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import deviceCategoryAction from '@iso/redux/deviceCategories/actions';
import { Modal } from '@Z/components/atoms';
import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import siteAction from '@iso/redux/sites/actions';
import ModifyCategoryFormWrapper from './ModifyCategoryFieldsButton.style';
import { MinusCircleOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { loadDeviceCategories } = deviceCategoryAction;
const { loadSites } = siteAction;

const ModifyCategoryFieldsButton = ({ category, children }) => {
	const dispatch = useDispatch();
	const [viewModal, setViewModal] = useState(false);
	const { sitesData, pending } = useSelector((state) => state.sites);
	const { fieldTypes } = useSelector((state) => state.types);
	const { deviceCategoriesData } = useSelector((state) => state.deviceCategories);

	const [form] = Form.useForm();
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [fieldsToDelete, setFieldsToDelete] = useState([]);
	const [fieldsToUpdate, setFieldsToUpdate] = useState([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (sitesData.length < 1 && pending) {
			dispatch(loadSites());
			dispatch(loadDeviceCategories());
		}
	}, [pending, sitesData, dispatch, deviceCategoriesData]);

	const handleCancel = () => {
		setViewModal(false);
		form.resetFields();
	};

	const handleSubmit = async () => {
		const fields = category.fields;
		const formData = form.getFieldsValue(true);

		// confirm deleted or updated fields before next step
		const deleted = fields.filter(({ fieldID: f1 }) => !formData.fields.some(({ fieldID: f2 }) => f1 === f2));
		const updated = formData.fields.filter(({ fieldID: f1, title: t1 }) =>
			fields.some(({ fieldID: f2, title: t2 }) => f1 === f2 && t1 !== t2)
		);

		setFieldsToDelete(deleted || []);
		setFieldsToUpdate(updated || []);

		// if there are no changes, give a notification and return
		if (isEqual(fields, formData.fields)) {
			notification('error', 'No changes found, please modify the fields.');
			return;
		}
		// else, show the confirmation modal
		setConfirmModalVisible(true);
	};

	const handleModify = async () => {
		setLoading(true);
		let deleteSuccess = [];
		let updateSuccess = [];

		// check if there are fields deleted
		if (fieldsToDelete.length > 0) {
			const promises = fieldsToDelete.map(
				async (field) =>
					await superFetch
						.delete(`/deviceCategories/${category.categoryID}/fields/${field.fieldID}`)
						.then((res) => {
							if (res) {
								if (res.statusCode === 200) {
									return true;
								}
							}
							return false;
						})
						.catch((err) => {
							return false;
						})
			);
			deleteSuccess = await Promise.all(promises);
		}

		// check if there are fields updated
		if (fieldsToUpdate.length > 0) {
			const promises = fieldsToUpdate.map(
				async (field) =>
					await superFetch
						.patch(`/deviceCategories/${category.categoryID}/fields/${field.fieldID}`, {
							title: field.title
						})
						.then((res) => {
							if (res) {
								if (res.statusCode === 200) {
									setConfirmModalVisible(false);
									return true;
								}
							}
							return false;
						})
						.catch((err) => {
							return false;
						})
			);
			updateSuccess = await Promise.all(promises);
		}

		// result of the delete and update and show notifications
		if (
			deleteSuccess.includes(false) ||
			updateSuccess.includes(false) ||
			(updateSuccess.length === 0 && deleteSuccess.length === 0)
		) {
			notification('error', 'Delete or update failed, Please try again.');
		} else {
			if (updateSuccess.length > 0) {
				notification('success', `${updateSuccess.length} fields updated successfully`);
			}
			if (deleteSuccess.length > 0) {
				notification('success', `${deleteSuccess.length} fields deleted successfully`);
			}
			dispatch(loadDeviceCategories());
			setConfirmModalVisible(false);
			setViewModal(false);
		}
		setLoading(false);
	};
	return (
		<div>
			<span
				onClick={() => {
					form.setFieldsValue({
						...category,
						siteID: category.sites.map((site) => site.siteID)
					});
					setViewModal(true);
				}}
			>
				{children}
			</span>
			<Modal
				title='Modify Category Fields'
				visible={viewModal}
				onCancel={handleCancel}
				width={650}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button form={form} key='submit' htmlType='submit' type='primary'>
						Modify Fields
					</Button>
				]}
			>
				<ModifyCategoryFormWrapper>
					<Form
						form={form}
						id={form}
						onFinish={handleSubmit}
						layout='vertical'
						initialValues={{
							fields: category.fields
						}}
					>
						<Form.List name='fields'>
							{(fields, { add, remove }) => (
								<div>
									{fields.map(({ key, name, ...restField }) => (
										<Row key={key} style={{ display: 'flex', marginBottom: 15 }} gutter={10}>
											<Col span={14}>
												<Form.Item
													{...restField}
													name={[name, 'title']}
													rules={fieldsValidator.Name}
													style={{ marginBottom: 0 }}
												>
													<Input placeholder='Field Name' />
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													{...restField}
													name={[name, 'fieldTypeID']}
													rules={fieldsValidator.Required}
													style={{ marginBottom: 0 }}
												>
													<Select disabled placeholder={'Type'}>
														{fieldTypes.map((type) => (
															<Option key={type.fieldTypeID} value={type.fieldTypeID}>
																{type.name}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col span={2} style={{ alignItems: 'center' }}>
												<Form.Item {...restField} style={{ marginBottom: 0 }}>
													<MinusCircleOutlined
														onClick={() => {
															remove(name);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									))}
								</div>
							)}
						</Form.List>
					</Form>
				</ModifyCategoryFormWrapper>
			</Modal>
			<Modal
				title={`Confirm Field Modification`}
				danger
				visible={confirmModalVisible}
				onCancel={() => setConfirmModalVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setConfirmModalVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleModify()}>
						Modify
					</Button>
				]}
				style={{ textAlign: 'center' }}
			>
				{fieldsToUpdate.length > 0 && (
					<div>
						<p>Rename:</p>
						<br />
					</div>
				)}
				{fieldsToUpdate.map((field) => (
					<Typography.Paragraph key={field.fieldID}>
						<Typography.Text delete type='danger' style={{ textAlign: 'center' }}>
							{category.fields.find((f) => f.fieldID === field.fieldID).title}
						</Typography.Text>
						{'  =>  '}
						<Typography.Text type='success' style={{ textAlign: 'center' }}>
							{field.title}
						</Typography.Text>
					</Typography.Paragraph>
				))}
				{fieldsToDelete.length > 0 && (
					<div>
						<p>Delete:</p>
						<p>If fields are deleted, all related field values of devices will also be deleted:</p>
						<br />
					</div>
				)}
				{fieldsToDelete.map((field) => (
					<Typography.Paragraph delete type='danger' style={{ textAlign: 'center' }} key={field.fieldID}>
						{field.title}
					</Typography.Paragraph>
				))}
			</Modal>
		</div>
	);
};
export default ModifyCategoryFieldsButton;
