import dummyProfile from '@iso/assets/images/zezamii/Profile5.png';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import ProfilePicUpload from '@iso/ui/UploadImagesToFirebaseComponent';
import React, { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ProfileImageWrapper from './profileImageWrapper.styles';

/**
 * @description
 * @author Kishan Virani
 * @date 18/03/2022
 * @param {*}  entityObject  Entity object e.g. People or Team
 * @param {*}  entityName  Entity object e.g. People or Team
 * @param {*}  size Size of the image/avatar
 * @return {*}
 */

const PersonProfileImage = ({ entityObject, size, entityName, shape, edit, uploadRef }) => {
	const [photoUrl, setPhotoUrl] = useState('');
	useMemo(async () => {
		if (entityObject.photoUrl && entityObject.photoUrl !== '' && entityObject.photoUrl !== 'N/A') {
			await getDownloadURL(entityObject.photoUrl)
				.then((res) => setPhotoUrl(res))
				.catch((e) => e);
		}
	}, [entityObject.photoUrl]);
	return (
		<ProfileImageWrapper size={size || 150} shape={shape || 'square'}>
			{edit !== false ? (
				<ProfilePicUpload
					cropperOptions={{ grid: true, rotate: true }}
					entityName={entityName}
					entityID={entityName === 'People' ? entityObject.personID : entityObject.teamID}
					uploadType={'profilePicture'}
					oldData={entityObject}
					uploadRef={uploadRef}
				>
					<div className='contentContainer'>
						<div className='editIconContainer'>
							<p className='editIconContent'>
								<i className='material-icons-outlined editIcon'>mode_edit</i>
								{entityObject.photoUrl && entityObject.photoUrl !== 'N/A' ? 'Update' : 'Upload'} Picture
							</p>
						</div>
						{entityObject.photoUrl && entityObject.photoUrl !== 'N/A' ? (
							<div className='imageLazy'>
								<LazyLoadImage src={photoUrl} PlaceholderSrc={'image'} effect='blur' />
							</div>
						) : (
							<div className='imageLazy'>
								<LazyLoadImage src={dummyProfile} PlaceholderSrc={'image'} effect='blur' />
							</div>
						)}
					</div>
				</ProfilePicUpload>
			) : (
				<span>
					{entityObject.photoUrl && entityObject.photoUrl !== 'N/A' ? (
						<div className='imageLazy'>
							<LazyLoadImage src={photoUrl} PlaceholderSrc={'image'} effect='blur' />
						</div>
					) : (
						<div className='imageLazy'>
							<LazyLoadImage src={dummyProfile} PlaceholderSrc={'image'} effect='blur' />
						</div>
					)}
				</span>
			)}
		</ProfileImageWrapper>
	);
};
export default PersonProfileImage;
