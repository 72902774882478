import Row from '@iso/ui/Antd/Grid/Row';
import { SearchBar } from '@Z/components/atoms';
import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeviceCardContainerWrapper from './DeviceCardContainerWrapper.styles';

const DeviceCardsContainer = ({ categoryID }) => {
	const { devicesData } = useSelector((state) => state.devices);

	const [filteredDevices, setFilteredDevices] = useState([]);
	const [devices, setDevices] = useState([]);

	// Get all the devices of the category selected and their customFields.
	useEffect(() => {
		if (devicesData.length > 0) {
			const selectedCategoryDevices = devicesData.filter((item) => item.categoryID === categoryID);

			if (selectedCategoryDevices.length > 0) {
				setDevices(selectedCategoryDevices);
				setFilteredDevices(selectedCategoryDevices);
			} else {
				setFilteredDevices([]);
				setDevices([]);
			}
		}
	}, [devicesData, categoryID]);

	// Searching the devices
	const filter = (array, searchTerm, columns) => {
		const searchResult = array.filter((row) => {
			return columns.some((column) => {
				return String(row[column]).toLowerCase().includes(searchTerm.toLowerCase());
			});
		});
		setFilteredDevices(searchResult);
	};

	return (
		<DeviceCardContainerWrapper>
			<div className='searchContainer'>
				<SearchBar
					placeholder='Search by Name or Unique Identification Number'
					enterButton='Search'
					onChange={(event) => {
						filter(devices, event.target.value, ['name', 'uID']);
					}}
					onSearch={(query) => {
						filter(devices, query, ['name', 'uID']);
					}}
				/>
			</div>
			{filteredDevices.length > 0 ? (
				<Row className='cardContainer'>
					{filteredDevices.map((item) => {
						return <item.cardComponent key={item.uID} device={item} />;
					})}
				</Row>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Device Found</span>} />
			)}
		</DeviceCardContainerWrapper>
	);
};

export default DeviceCardsContainer;
