import { notification } from '@iso/components/index';
import { settingFieldType } from '@iso/config/settingFieldType';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import { deleteFiles } from '@iso/lib/firebase/firebase.util';
import SuperFetch from '@iso/lib/helpers/superFetch';
import settingsActions from '@iso/redux/settings/actions';
import { Col, Layout, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	RenderFieldTypeSetting,
	RenderHeaderProfileSetting,
	RenderHeaderSetting,
	RenderLabelSetting
} from '../index.js';
import { StyleSettings } from './settings.style';

const { loadSettingsOrganisation, loadSettingsPerson } = settingsActions;

const RenderContentSetting = ({ settingGroupID, entityType }) => {
	const dispatch = useDispatch();
	const personSettings = useSelector((state) => state.settings.personSettings);
	const orgSettings = useSelector((state) => state.settings.orgSettings);
	const groupSettingsData = useSelector((state) => state.defaultSettings.groupSettingsData);
	const personID = useSelector((state) => state.Auth.userProfile.defaultProfile.personID);

	const [settings, setSettings] = useState({});
	const [selectedSettingGroup, setSelectedSettingGroup] = useState({});
	const [fieldChange, setFieldChange] = useState(false);
	const [loading, setLoading] = useState(false);

	const [previousSettings, setPreviousSettings] = useState([]);
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		if (entityType === CONSTANTS_SETTINGS.ORG_SETTING_TYPE && orgSettings.length > 0) {
			setSettings(orgSettings?.filter((a) => a.groupID === settingGroupID));
			setPreviousSettings(orgSettings?.filter((a) => a.groupID === settingGroupID));
			setSelectedSettingGroup(groupSettingsData?.find((a) => a.groupID === settingGroupID));
		} else {
			setSettings(personSettings);
			setPreviousSettings(personSettings);
		}

		return () => {
			setSettings([]);
		};
	}, [settingGroupID, orgSettings, personSettings, groupSettingsData, entityType]);

	const deleteFileFromFirebaseStorage = async (filePath) => {
		await deleteFiles(filePath);
	};

	const handleDiscardChanges = async () => {
		setSettings(previousSettings);
		const changedSettings = _.filter(settings, (current) => {
			const previous = previousSettings.find((prev) => prev.defaultSettingID === current.defaultSettingID);
			return !_.isEqual(current, previous);
		});
		const uploadField = changedSettings.find((setting) => setting.fieldTypeID === settingFieldType.FILEUPLOAD);
		if (uploadField) {
			deleteFileFromFirebaseStorage(uploadField.value);
			setFileList([]);
		}
	};

	const handleSaveChanges = async () => {
		const changedSettings = _.filter(settings, (current) => {
			const previous = previousSettings.find((prev) => prev.defaultSettingID === current.defaultSettingID);
			return !_.isEqual(current, previous);
		});

		// Create an array of promises for each unique setting
		const requests = changedSettings.map((setting) =>
			SuperFetch.post(
				entityType === CONSTANTS_SETTINGS.ORG_SETTING_TYPE ? '/settings' : '/settings/people',
				{
					defaultSettingID: setting.defaultSettingID,
					value: [settingFieldType.MULTIVALUE, settingFieldType.CREDENTIAL].includes(setting.fieldTypeID)
						? JSON.stringify(setting.value)
						: setting.value,
					...(entityType !== CONSTANTS_SETTINGS.ORG_SETTING_TYPE ? { personID: personID } : null)
				},
				{}
			)
		);

		try {
			setLoading(true);
			await Promise.all(requests);
			if (entityType === CONSTANTS_SETTINGS.ORG_SETTING_TYPE) {
				dispatch(loadSettingsOrganisation());
			} else {
				dispatch(loadSettingsPerson());
			}
			notification('success', 'Settings were saved successfully.');
			setLoading(false);
		} catch (error) {
			console.error('An error occurred while sending requests:', error);
		}
	};

	useLayoutEffect(() => {
		const changeData = _.isEqual(settings, previousSettings);
		setFieldChange(changeData);
	}, [settings, previousSettings]);

	return (
		<Layout className='mainContentContainer'>
			<StyleSettings>
				{entityType === CONSTANTS_SETTINGS.ORG_SETTING_TYPE ? (
					<>
						<RenderHeaderSetting
							title={selectedSettingGroup?.title}
							description={selectedSettingGroup.description}
							showDiscardButton={!fieldChange}
							showSaveButton={true}
							disableSaveButton={fieldChange}
							onClickDiscard={handleDiscardChanges}
							onClickSave={handleSaveChanges}
							loadingSaveButton={loading}
						/>
						<Row>
							{settings.length > 0 &&
								settings.map((item, index) => {
									return (
										<Row className='contentSetting' key={index}>
											<Row className='containerLabel'>
												<RenderLabelSetting title={item.title} description={null} />{' '}
											</Row>

											<Row className='containerField'>
												<Col span={16} className='containerLabel'>
													<RenderLabelSetting title={null} description={item.description} />{' '}
												</Col>
												<Col span={8}>
													<RenderFieldTypeSetting
														setting={item}
														index={index}
														settings={settings}
														setSettings={setSettings}
														setPreviousSettings={setPreviousSettings}
														fileList={fileList}
														setFileList={setFileList}
														handleRemoveUpload={handleDiscardChanges}
													/>
												</Col>
											</Row>
										</Row>
									);
								})}
						</Row>
					</>
				) : (
					<>
						<RenderHeaderProfileSetting />
						<Row className='containerSetting'>
							{settings.length > 0 &&
								settings.map((item, index) => {
									return (
										<Row className='contentSetting' key={index}>
											<Row className='containerLabel'>
												<RenderLabelSetting title={item.title} description={null} />{' '}
											</Row>

											<Row className='containerField'>
												<Col span={16} className='containerLabel'>
													<RenderLabelSetting title={null} description={item.description} />{' '}
												</Col>
												<Col span={8}>
													<RenderFieldTypeSetting
														setting={item}
														index={index}
														settings={settings}
														setSettings={setSettings}
														setPreviousSettings={setPreviousSettings}
														fileList={fileList}
														setFileList={setFileList}
														// handleRemoveUpload={handleDiscardChanges}
													/>
												</Col>
											</Row>
										</Row>
									);
								})}
						</Row>
						<RenderHeaderSetting
							title={null}
							description={null}
							showDiscardButton={!fieldChange}
							showSaveButton={true}
							disableSaveButton={fieldChange}
							onClickDiscard={handleDiscardChanges}
							onClickSave={handleSaveChanges}
							loadingSaveButton={loading}
						/>
					</>
				)}
			</StyleSettings>
		</Layout>
	);
};
export default RenderContentSetting;
