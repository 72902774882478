import Button from '@iso/components/uielements/button';
import Card from '@iso/components/uielements/card';
import React from 'react';
import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import SearchDeviceCardWrapper from './searchDeviceCardWrapper.styles';
import { Typography } from 'antd';
const SearchDeviceCard = ({ device }) => {
	const { Paragraph } = Typography;
	const [deviceModalVisible, setDeviceModalVisible] = React.useState(false);
	return (
		<SearchDeviceCardWrapper>
			<Card
				className='hoverCard'
				size='small'
				title={
					<IndividualDeviceInformationModal
						visible={deviceModalVisible}
						setVisible={setDeviceModalVisible}
						data={{
							deviceID: device.uID,
							categoryID: device.categoryID
						}}
					>
						<p
							className='titleText'
							style={{
								cursor: 'pointer'
							}}
							onClick={() => setDeviceModalVisible(true)}
						>
							{device.name}
						</p>
					</IndividualDeviceInformationModal>
				}
				hoverable
			>
				<div className='cardContainer'>
					<div className='infoContainer'>
						<div className='textValue'>
							<span>UID: </span>
							<Paragraph copyable>{device.uID}</Paragraph>
						</div>

						<div className='textValue'>
							<Paragraph
								ellipsis={{
									rows: 1
								}}
							>
								{device.categoryName}
							</Paragraph>
						</div>
					</div>

					<div className='textValue'>
						<Button size='small' onClick={() => setDeviceModalVisible(true)}>
							View
						</Button>
					</div>
				</div>
			</Card>
		</SearchDeviceCardWrapper>
	);
};

export default SearchDeviceCard;
