import L from 'leaflet';
import defaultMarkerIcon from '@iso/assets/images/icon/AssetDevice_default_Marker.svg';
import healthSafetyIcon from '@iso/assets/images/icon/Health&Safety_Marker.svg';
import SLMarker from '@iso/assets/images/icon/SmartLock_Marker.svg';
import SLSMarker from '@iso/assets/images/icon/SmartLockers_Marker.svg';
import SGMarker from '@iso/assets/images/icon/SmartGateway_Marker.svg';
import VCMarker from '@iso/assets/images/icon/VerkadaCamera_Icon_marker.svg';

const leafletMapDrawOptionsConfig = {
	position: 'topright',
	draw: {
		polyline: {
			shapeOptions: {
				color: '#f357a1',
				weight: 2
			}
		},
		polygon: {
			allowIntersection: false, // Restricts shapes to simple polygons
			drawError: {
				color: '#e1e100', // Color the shape will turn when intersects
				message: "<strong>Oh snap!<strong> you can't draw that!" // Message that will show when intersect
			},
			shapeOptions: {
				color: '#bada55'
			}
		},
		circle: false, // Turns off this drawing tool
		rectangle: {
			shapeOptions: {
				clickable: false
			}
		},
		marker: {
			icon: L.icon({
				iconUrl: defaultMarkerIcon,
				iconSize: [38, 55] // size of the icon
			})
		}
		// add edit functionality using the set draw options.
	}
};
export default leafletMapDrawOptionsConfig;
export const drawSingleMarkerOption = {
	position: 'topright',
	draw: {
		polyline: false,
		polygon: false,
		circle: false, // Turns off this drawing tool
		rectangle: false,
		circlemarker: false,
		marker: {
			icon: L.icon({
				iconUrl: defaultMarkerIcon,
				iconSize: [50, 55], // size of the icon
				iconAnchor: [26, 47]
			}),
			draggable: true
		}
		// add edit functionality using the set draw options.
	}
};

// Map the category markers with the default category ID.
export const defaultCategoryMarker = {
	1: healthSafetyIcon,
	12: SLMarker,
	13: SGMarker,
	14: SLSMarker,
	15: VCMarker
};
export const defaultMarker = defaultMarkerIcon;
