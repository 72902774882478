/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 01-03-2022  10:14:40
 * @desc [description]
 */

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { PageHeader, Loading, AddOrganisation } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const OrganisationHeader = () => {
	const [loadings, setLoading] = useState(false);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const organisationData = useSelector((state) => state.Organisation.organisationData);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const [data, setData] = useState([]);

	useEffect(() => {
		setLoading(true);
		setData(organisationData);
		setLoading(false);
	}, [organisationData]);

	const deletedNumber = data.filter((organisation) => organisation.deletedDate).length;
	const isAddOrgPermitted = useManagePermittedRoles('addOrg', { orgID: [orgID] });

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<PageHeader title='Organisations' counter={deletedNumber < 1 ? data.length : data.length - deletedNumber}>
			{isAddOrgPermitted && <AddOrganisation />}
		</PageHeader>
	);
};

export default OrganisationHeader;
