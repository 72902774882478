import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import ContentHolder from '@iso/components/utility/contentHolder';
import { Loading, Modal, ComponentLabel } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import React from 'react';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { useDispatch } from 'react-redux';
import FloorSummaryWrapper from './FloorSummaryWrapper.styles';
import floorsActions from '@iso/redux/floors/actions';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { DownOutlined } from '@ant-design/icons';
import Tag from '@iso/components/uielements/tag';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { TypographyParagraphWrapper } from '../DeviceCategoryDescription/DeviceCategoryDesc.styles';
import { EditOutlined } from '@ant-design/icons';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const FloorSummary = ({ data }) => {
	const { loadFloors } = floorsActions;
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const [loadings, setLoading] = React.useState(true);
	const [editVisible, setEditVisible] = React.useState(false);
	const [deleteVisible, setDeleteVisible] = React.useState(false);
	const [updateFloorLoading, setUpdateFloorLoading] = React.useState(false);
	const [deleteFloorLoading, setDeleteFloorLoading] = React.useState(false);
	const [buttonDisable, setButtonDisable] = React.useState(true);
	const [floorsList, setFloorsList] = React.useState({});

	const { floorsData } = useSelector((state) => state.floors);

	React.useEffect(() => {
		setLoading(true);
		if (data && Object.keys(data).length > 0) {
			setFloorsList(floorsData);
			setLoading(false);
		}
	}, [data, floorsData]);

	const handleCancel = () => {
		setButtonDisable(true);
		setEditVisible(false);
	};
	const handleDeleteCancel = () => {
		setDeleteVisible(false);
	};

	// HOC
	const BaseLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const FloorWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const SiteWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const floorsListing = () => {
		return (
			<DropdownMenu>
				{floorsList.map((floors, key) => {
					return (
						<MenuItem key={key}>
							<FloorWithLink type='floor' dataID={floors.floorID}>
								{floors.name}
							</FloorWithLink>
						</MenuItem>
					);
				})}
			</DropdownMenu>
		);
	};
	const onFinish = () => {
		let newFloorInfo = {};
		const formData = form.getFieldsValue(true);

		if (formData.name !== data.name && formData.name !== '') {
			newFloorInfo['name'] = formData.name;
		}

		if (formData.description !== data.description && formData.description !== '') {
			newFloorInfo['description'] = formData.description;
		}
		if (newFloorInfo.name || newFloorInfo.description) {
			setUpdateFloorLoading(true);
			superFetch
				.patch('/floors/' + data.floorID, newFloorInfo)
				.then((res) => {
					if (res.statusCode === 200) {
						dispatch(loadFloors());
						notification('success', 'Floor Information Updated.');
					}
					handleCancel();
					setUpdateFloorLoading(false);
				})
				.catch((err) => {
					setUpdateFloorLoading(false);
					notification('error', 'Update Failed, Please try again.');
				});
		}
	};
	const handleDelete = () => {
		setDeleteFloorLoading(true);
		superFetch
			.delete('/floors/' + data.floorID)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Floor Deleted.');
						dispatch(loadFloors());
						setDeleteVisible(false);
					}
					setDeleteFloorLoading(false);
				}
				return res;
			})
			.catch((err) => {
				setDeleteFloorLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};
	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		if (
			(data.name === formData.name || formData.name === '') &&
			(data.description === formData.description || formData.description === '')
		) {
			return true;
		} else {
			return false;
		}
	};

	const isPermittedUpdateFloor = useManagePermittedRoles('updateFloor', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: [data.floorID]
	});
	const isPermittedDeleteFloor = useManagePermittedRoles('deleteFloor', {
		orgID: [data.orgID],
		siteID: [data.siteID]
	});

	const onEdit = (value, field) => {
		if (field === 'name' && data.name !== value) {
			if (!/^[a-zA-Z0-9 -/]+$/.test(value)) {
				notification('error', 'Floor name can only contain letters, numbers and spaces.');
			} else if (value.length > 40) {
				notification('error', 'Floor name can only be 40 characters long.');
			} else {
				superFetch
					.patch('/floors/' + data.floorID, { name: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(loadFloors());
								notification('success', 'Floor Name Updated.');
							}
						}
						return res;
					})
					.catch(() => {
						notification('error', 'Update Failed, Please try again.');
					});
			}
		}

		if (field === 'description' && data.description !== value) {
			if (!/^[a-zA-Z0-9 \n.;/,-]+$/.test(value)) {
				notification('error', 'Floor description can only contain letters, numbers, ,/;. and spaces.');
			} else if (value.length > 320) {
				notification('error', 'Floor description can only be 320 characters long.');
			} else {
				superFetch
					.patch('/floors/' + data.floorID, { description: value })
					.then((res) => {
						if (res?.statusCode === 200) {
							dispatch(loadFloors());
							notification('success', 'Foor Description Updated.');
						} else {
							throw new Error('Update Failed, Please try again.');
						}
					})
					.catch(() => {
						notification('error', 'Update Failed, Please try again.');
					});
			}
		}
		return;
	};

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden', height: 'auto' }}>
			<FloorSummaryWrapper>
				<Row style={rowStyle} gutter={0} justify='start'>
					<Col lg={12} md={12} sm={24} xs={24} className='floorName' style={colStyle}>
						<ComponentLabel
							editable={isPermittedUpdateFloor}
							text={data.name}
							editableProps={{
								ellipsis: { rows: 1, expandable: false },
								editable: {
									tooltip: 'click to edit Floor name',
									icon: <EditOutlined style={{ color: '#4d4f5c' }} />,
									triggerType: ['text', 'icon'],
									onChange: (value) => onEdit(value, 'name')
								}
							}}
						/>
						<SiteWithLink type='site' dataID={data.siteID}>
							<Tag
								className='infoLabel'
								color='volcano'
								style={{ fontWeight: '600', fontSize: '11px', marginTop: '5px' }}
							>
								{data.siteName}
							</Tag>
						</SiteWithLink>
					</Col>
					<Col lg={12} md={12} sm={24} xs={24} className='actionButton'>
						{floorsList.length > 0 ? (
							<Dropdown className='switchFloorButton' overlay={floorsListing()} trigger={['click']}>
								<Button>
									Switch Floors <DownOutlined />
								</Button>
							</Dropdown>
						) : null}
						{data.deletedDate ? (
							<Tag color='red' className='deletedTag'>
								DELETED
							</Tag>
						) : (
							<div className='actionsContainer'>
								{isPermittedDeleteFloor && (
									<Button onClick={() => setDeleteVisible(true)} className='deleteButton'>
										Delete
									</Button>
								)}
							</div>
						)}
					</Col>
				</Row>
				<Row style={rowStyle} gutter={0} justify='start'>
					<Col lg={24} md={24} sm={24} xs={24}>
						<TypographyParagraphWrapper
							editable={
								isPermittedUpdateFloor
									? {
											tooltip: 'Click to edit Description',
											onChange: (value) => onEdit(value, 'description'),
											autoSize: { maxRows: 5 },
											maxLength: 600,
											icon: (
												<EditOutlined className='outlinedIcon' style={{ color: '#4d4f5c' }} />
											),
											triggerType: ['text', 'icon'],
											enterIcon: null
									  }
									: false
							}
							className='infoProp'
							ellipsis={{
								rows: 5,
								expandable: true,
								symbol: 'more'
							}}
						>
							{data.description}
						</TypographyParagraphWrapper>
					</Col>
				</Row>
			</FloorSummaryWrapper>

			<Modal
				title='Edit Floor'
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={updateFloorLoading}
						disabled={buttonDisable}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					labelCol={{ lg: { span: 6, offset: 0 }, sm: { span: 6, offset: 0 } }}
					wrapperCol={{ lg: { span: 17 }, sm: { span: 16 }, xs: { span: 10 } }}
					onFinish={onFinish}
					autoComplete='off'
					onValuesChange={() => setButtonDisable(updateChange())}
				>
					<Form.Item label='Name: ' name='name' rules={fieldsValidator.Name} style={colStyle}>
						<Input />
					</Form.Item>

					<Form.Item
						label='Description: '
						name='description'
						rules={fieldsValidator.Description}
						style={colStyle}
					>
						<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				danger
				title='Delete Floor'
				visible={deleteVisible}
				onCancel={handleDeleteCancel}
				footer={[
					<Button key='back' onClick={handleDeleteCancel}>
						Cancel
					</Button>,
					<Button
						key='submit'
						htmlType='submit'
						type='danger'
						loading={deleteFloorLoading}
						onClick={() => handleDelete()}
					>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete floor{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{data.name}'</span>?
				</p>
			</Modal>
		</IsoWidgetBox>
	);
};

export default FloorSummary;
