import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Dropdown, DropdownMenu } from '@iso/components/uielements';
import { DownOutlined } from '@ant-design/icons';
import RemoteUnlockButton from './RemoteUnlockButton';
import RemoteLockButton from './RemoteLockButton';
import { SpaceWrapper } from './ACRemoteActions.styles';

const ACRemoteActions = ({ data, onIndividualDeviceModal = true }) => {
	const [remoteUnlockVisible, setRemoteUnlockVisible] = useState(false);
	const [remoteLockVisible, setRemoteLockVisible] = useState(false);

	const actions = () => [
		{
			key: '1',
			label: <RemoteUnlockButton data={data} visible={remoteUnlockVisible} setVisible={setRemoteUnlockVisible} />
		},
		{
			key: '2',
			label: <RemoteLockButton data={data} visible={remoteLockVisible} setVisible={setRemoteLockVisible} />
		}
	];
	return (
		<React.Fragment>
			<Dropdown overlay={<DropdownMenu items={actions()} />}>
				{onIndividualDeviceModal ? (
					<Button onClick={(e) => e.preventDefault()}>
						Lock Actions <DownOutlined />
					</Button>
				) : (
					<SpaceWrapper>
						Lock Actions <i className='material-icons-outlined'>expand_more</i>
					</SpaceWrapper>
				)}
			</Dropdown>
		</React.Fragment>
	);
};

export default ACRemoteActions;
