import styled from 'styled-components';
import { palette } from 'styled-theme';

const TableBookingWrapper = styled.div`
	.row-cursor {
		cursor: pointer !important;
	}
	.bookingTime {
		display: flex;
		width: 100%;
		justify-content: space-between;
		@media only screen and (max-width: 1740px) {
			flex-direction: column;
			align-items: center;
			div {
				margin: 5px 0;
			}
		}
		div {
			display: flex;
			flex-direction: column;
			align-items: left;
			justify-content: center;
			max-width: 70px;
			h1 {
				font-size: 12px;
				color: ${palette('text', 3)};
				font-weight: 400;
			}
			h3 {
				font-size: 12px;
				color: ${palette('text', 3)};
				font-weight: 400;
			}
		}
	}
`;

export { TableBookingWrapper };
