const actions = {
	LOAD_INVENTORY_LOCKS_DATA: 'LOAD_INVENTORY_LOCKS_DATA',
	LOAD_INVENTORY_GATEWAYS_DATA: 'LOAD_INVENTORY_GATEWAYS_DATA',
	LOAD_INVENTORY_LOCKS_SUCCESS: 'LOAD_INVENTORY_LOCKS_SUCCESS',
	LOAD_INVENTORY_GATEWAYS_SUCCESS: 'LOAD_INVENTORY_GATEWAYS_SUCCESS',
	LOAD_INVENTORY_ERROR: 'LOAD_INVENTORY_ERROR',

	loadInventoryLocks: () => ({
		type: actions.LOAD_INVENTORY_LOCKS_DATA
	}),

	loadInventoryGateways: () => ({
		type: actions.LOAD_INVENTORY_GATEWAYS_DATA
	})
};
export default actions;
