import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntTimeline = (ComponentName) => styled(ComponentName)`
	&.ant-timeline {
		padding: 10px;
		margin-top: 10px;

		.ant-timeline-item-content {
			font-size: 10px;
			color: ${palette('text', 3)};
			/* padding: ${(props) => (props['data-rtl'] === 'rtl' ? '0 24px 10px 0' : '0 0 10px 24px')}; */
		}
		.ant-timeline-item-head-custom {
			left: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '5px')};
			right: ${(props) => (props['data-rtl'] === 'rtl' ? '5px' : 'inherit')};
		}
	}
`;

export default AntTimeline;
