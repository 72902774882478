import { smallTimeOptions } from '@iso/assets/styles/constants';
import { Row, Space } from 'antd';
import React from 'react';
import MetaContainerWrapper from './MetaContainer.styles';

const MetaInfoContainer = ({ createDate, modifiedDate, deleteDate }) => {
	return (
		<MetaContainerWrapper>
			<Row className='metaContainer' style={{ marginRight: '5px' }}>
				<Space wrap className='dateContainer'>
					<p className='metaField'>
						<span className='metaLabel'>Joined: </span>
						{new Date(createDate).toLocaleString('en-AU', smallTimeOptions)}
					</p>
					<p className='metaField'>
						<span className='metaLabel'>{deleteDate ? 'Deleted On: ' : 'Updated: '}</span>
						{deleteDate
							? new Date(deleteDate).toLocaleString('en-AU', smallTimeOptions)
							: new Date(modifiedDate).toLocaleString('en-AU', smallTimeOptions)}{' '}
					</p>
				</Space>
			</Row>
		</MetaContainerWrapper>
	);
};

export default MetaInfoContainer;
