import { RenderStatusTag } from '@Z/components/atoms';
import moment from 'moment';

export const getCurrentTime = () => {
	const currentDate = new Date();
	const currentHour = currentDate.getHours();
	const currentMinute = currentDate.getMinutes();

	const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
	return formattedTime;
};

export const formatDates = (dateStr) => {
	const date = moment(dateStr);
	return date.format('ddd, D MMM');
};

export function isDateInFuture(date) {
	const today = moment();
	return date.isAfter(today);
}

export function currentAcceptableDate() {
	const today = moment();
	return today;
}

const calculateBookingStatus = (startTime, endTime, deletedDate, isFilter, bookingStatusType) => {
	const startTime2 = moment(startTime + 'Z').local();
	const endTime2 = moment(endTime + 'Z').local();

	switch (true) {
		case deletedDate !== null:
			return isFilter ? bookingStatusType[4].title : RenderStatusTag(bookingStatusType[4].title, 'red');
		case moment().isBefore(startTime2):
			return isFilter ? bookingStatusType[2].title : RenderStatusTag(bookingStatusType[2].title, 'gold');
		case moment().isBetween(startTime2, endTime2):
			return isFilter ? bookingStatusType[1].title : RenderStatusTag(bookingStatusType[1].title, 'green');
		case moment().isAfter(endTime2):
			return isFilter ? bookingStatusType[3].title : RenderStatusTag(bookingStatusType[3].title, 'blue');
		default:
			return isFilter ? bookingStatusType[5].title : RenderStatusTag(bookingStatusType[5].title);
	}
};

export default calculateBookingStatus;
