import { Modal } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import Tooltip from '@iso/components/uielements/tooltip';
import { entityType } from '@iso/config/entityType';
import { getPermissionByEntityType } from '@iso/lib/helpers/getPrimary';
import SuperFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import floorsAction from '@iso/redux/floors/actions';
import organisationAction from '@iso/redux/organisation/actions';
import peopleAction from '@iso/redux/people/actions';
import siteAction from '@iso/redux/sites/actions';
import teamsAction from '@iso/redux/teams/actions';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { colStyle } = basicStyle;

const { loadTeams } = teamsAction;
const { loadFloors } = floorsAction;
const { loadOrganisation } = organisationAction;
const { loadSites } = siteAction;
const { loadPeople } = peopleAction;

const AddEntityContactModal = ({ children, visible, setVisible, data = {}, type, edit, setEdit }) => {
	const { contactTypes } = useSelector((state) => state.types);
	const { permissionName, rules } = getPermissionByEntityType(type, data);

	// Permission to add or update contact from entities
	const isPermittedUpdate = useManagePermittedRoles(permissionName, rules);

	const [availableContactTypes, setAvailableContactType] = useState([]);
	const [selectedCType, setSelectedCType] = useState('');
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		if (edit) {
			setAvailableContactType([{ contactTypeID: data?.contactTypeID, name: data?.contactType }]);
			form.setFieldsValue({
				contactTypeID: data?.contactTypeID,
				contactInformation: data?.contactInformation
			});
			setSelectedCType({ children: data?.contactType });
		} else {
			form.resetFields();
		}
	}, [data, form, edit, visible]);

	useEffect(() => {
		if (!edit && data.contact) {
			const contactTypeIdsToRemove = data.contact.map((info) => info.contactTypeID);
			const filteredContactTypes = contactTypes.filter(
				(type) => !contactTypeIdsToRemove.includes(type.contactTypeID)
			);
			setAvailableContactType(filteredContactTypes);
			form.setFieldsValue({
				contactTypeID: filteredContactTypes[0]?.contactTypeID
			});
			setSelectedCType({ children: filteredContactTypes[0]?.name });
		}
	}, [data, form, edit, contactTypes, visible]);

	const handleCancel = () => {
		form.resetFields();
		setVisible(false);
		setLoading(false);
		setEdit(false);
	};

	const dispatchActions = () => {
		switch (type) {
			case entityType.WORKSPACE:
				dispatch(loadOrganisation());
				break;
			case entityType.SITE:
				dispatch(loadSites());
				break;
			case entityType.FLOOR:
				dispatch(loadSites());
				dispatch(loadFloors());
				break;
			case entityType.TEAM:
				dispatch(loadTeams());
				break;
			case entityType.PERSON:
				dispatch(loadPeople());
				break;
			default:
				break;
		}
	};

	const onFinish = async () => {
		const formData = removeEmptyOrNull(form.getFieldsValue());

		switch (type) {
			case entityType.WORKSPACE:
				formData.orgID = data.orgID;
				break;
			case entityType.SITE:
				formData.siteID = data.siteID;
				break;
			case entityType.FLOOR:
				formData.floorID = data.floorID;
				break;
			case entityType.TEAM:
				formData.teamID = data.teamID;
				break;
			case entityType.PERSON:
				formData.personID = data.personID;
				break;
			default:
				break;
		}

		try {
			setLoading(true);

			if (edit) {
				const res = await SuperFetch.patch(`/contact`, {
					...formData,
					newContactTypeID: formData?.contactTypeID
				});
				if (res.statusCode === 200) {
					dispatchActions();
					handleCancel();
					notification('success', 'Contact Update Successful');
				} else if (res.error.statusCode === 400) {
					handleCancel();
					notification('error', 'Please fill out all required information with proper type.');
				}
			} else {
				const res = await SuperFetch.post(`/contact`, formData);

				if (res.statusCode === 201) {
					dispatchActions();
					handleCancel();
					notification('success', 'Contact Added Successful.');
				} else if (res.error.statusCode === 400) {
					handleCancel();
					notification('error', 'Please fill out all required information with proper type.');
				}
			}
		} catch (err) {
			setLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Tooltip
					title={!isPermittedUpdate ? "You don't have permission" : edit ? 'Edit Contact' : 'Add New Contact'}
					placement='topLeft'
				>
					<>
						{availableContactTypes.length > 0 && (
							<Button size='small' onClick={() => setVisible(true)} disabled={!isPermittedUpdate}>
								{edit ? 'Edit Contact' : 'Add Contact'}
							</Button>
						)}
					</>
				</Tooltip>
			)}
			<Modal
				title={edit ? 'Edit Contact' : 'Add New Contact'}
				visible={visible}
				onCancel={handleCancel}
				width={720}
				maskClosable={false}
				destroyOnClose={false}
				footer={[
					<Button key='reset' onClick={() => form.resetFields(['contactInformation'])}>
						Reset
					</Button>,
					<Button form={form} loading={loading} key='submit' htmlType='submit' type='primary'>
						{edit ? 'Edit Contact' : 'Add Contact'}
					</Button>
				]}
			>
				<Form
					id={form}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					onFinish={onFinish}
					autoComplete='off'
					form={form}
				>
					<Row>
						<Col span={8}>
							<Form.Item
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
								label='Contact Type'
								name={'contactTypeID'}
								rules={fieldsValidator.Required}
								style={colStyle}
							>
								<Select
									onChange={(x, y) => {
										setSelectedCType(y);
										form.resetFields(['contactInformation']);
									}}
									placeholder={'Type'}
									showSearch
									optionFilterProp='children'
									disabled={edit}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
								>
									{availableContactTypes.length > 0 &&
										availableContactTypes.map((type) => (
											<Option key={type.contactTypeID} value={type.contactTypeID}>
												{type.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={16}>
							<Form.Item
								name={'contactInformation'}
								wrapperCol={{ span: 23, offset: 1 }}
								style={colStyle}
								rules={fieldsValidator[selectedCType.children]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default AddEntityContactModal;
