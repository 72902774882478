import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useModuleSettings = (settingID) => {
	const { orgSettings } = useSelector((state) => state.settings);
	const [enabledAccessModule, setEnabledAccessModule] = useState(false);

	useEffect(() => {
		const accessControlSetting = orgSettings.find((setting) => setting.defaultSettingID === settingID)?.value === 1;

		setEnabledAccessModule(accessControlSetting);
	}, [orgSettings, settingID]);

	return enabledAccessModule;
};

export default useModuleSettings;
