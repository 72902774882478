export const searchAbleField = {
	sites: ['name', 'abn', 'acn', 'nameOfMap'],
	floors: ['name', 'description', 'mapName'],
	people: ['displayName', 'email', 'firstName', 'lastName', 'fullName', 'fBPersonID', 'employeeID'],
	teams: ['name', 'teamID', 'description'],
	devices: ['name', 'uID', 'categoryName'],
	deviceCategory: ['name', 'description', 'fields.title'],
	assetGroups: ['name', 'description', 'assets.assetName', 'typeName'],
	booking: ['name', 'device.name', 'person.displayName', 'description']
};
