/**
 * @author Kishan Virani
 * @email Kishan@Zezamii.com
 * @create date 14-01-2022  14:31:04
 * @modify date 11-08-2022  17:05:23
 * @desc [description]
 */

import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import { GoogleMaps, LeafletMap, Loading, UploadMapComponent } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';

const HybridMap = ({ data, mapType }) => {
	const [map, setMap] = React.useState('');
	const [pending, setPending] = React.useState(true);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	if (!mapType) notification('error', 'Please provide correct MapType Props');

	useEffect(
		() => {
			const loadFloorPlan = async () => {
				setPending(true);
				if (data.url && data.url.toString().toLowerCase() !== 'n/a') {
					await getDownloadURL(data.url)
						.then((url) => setMap(url))
						.then(() => setPending(false));
				} else if (data.latitude && data.longitude) {
					setPending(false);
				}
			};
			loadFloorPlan();
		},
		// eslint-disable-next-line
		[data.url]
	);

	return (
		<div style={{ height: '100%', alignContent: 'center', position: 'relative' }}>
			{pending ? (
				<Row style={(rowStyle, { height: '100%', alignContent: 'center' })} gutter={0} justify='space-around'>
					<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
						<div style={loadingStyle}>
							<Loading />
						</div>
					</Col>
				</Row>
			) : data.url && data.url.toString().toLowerCase() !== 'n/a' ? (
				<LeafletMap
					mapHeight={460}
					mapWidth={800}
					scrollWheelZoom={true}
					url={map}
					markerList={data.markerList}
				>
					<UploadMapComponent data={data} mapType={mapType} buttonLabel={'Update Plan'} />
				</LeafletMap>
			) : data.latitude && data.longitude ? (
				<GoogleMaps data={data}>
					<UploadMapComponent data={data} mapType={mapType} buttonLabel={'Upload Floor-plan'} />
				</GoogleMaps>
			) : (
				<Row style={(rowStyle, { height: '100%', alignContent: 'center' })} gutter={0} justify='space-around'>
					<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
						<div style={loadingStyle}>
							Map Loading Error. Please check your internet connection and try again.
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
};
export default HybridMap;
