import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Steps from '@iso/components/uielements/steps';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import devicesAction from '@iso/redux/devices/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import formSteps from './steps';

const { Step } = Steps;
const { loadDevices } = devicesAction;

const CreatePermissionModal = ({ visible, setVisible, children }) => {
	const dispatch = useDispatch();
	const [stepForm] = Form.useForm();

	const [current, setCurrent] = useState(0);
	const [grantLoading, setGrantLoading] = useState(false);

	const handleCancel = () => {
		// at the last of the function

		// set the current step to 0
		setCurrent(0);

		// reset the form
		stepForm.resetFields();

		// close the modal
		setVisible(false);
	};

	const onFinish = () => {
		const formData = removeEmptyOrNull(stepForm.getFieldsValue(true));

		if (!(formData.devices?.length > 0 || formData.groups?.length > 0)) {
			notification('error', 'Please select at least one device or group.', '', 'top');
			return;
		}

		// Grant Permission.
		setGrantLoading(true);

		const body = {
			deviceIDs: formData.devices,
			personIDs: formData.people,
			permissionTypeID: 1, // ? setting the permission Type 1: Default for every person
			assetGroupIDs: formData.groups,
			teamIDs: formData.teams
		};
		superFetch.post(`/access/grantPermission`, body).then((res) => {
			if (res.statusCode === 200) {
				notification('success', 'Permission Granted.');
				handleCancel();
				dispatch(loadDevices());
			} else {
				notification('error', 'Error while granting permission. Please try again.');
			}
			setGrantLoading(false);
		});
	};

	const reset = () => {
		if (current === 0) {
			stepForm.resetFields(['people', 'teams']);
		}
		if (current === 1) {
			stepForm.resetFields(['devices', 'groups']);
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const next = () => {
		const formValues = stepForm.getFieldsValue(true);
		if (current === 0 && (formValues.people?.length > 0 || formValues.teams?.length > 0)) {
			setCurrent(current + 1);
		} else {
			notification('error', 'Select People or Teams to continue.', '', 'top');
		}
	};

	return (
		<div>
			{children ? (
				children
			) : (
				<Button type='primary' onClick={() => setVisible(true)}>
					Create New Permission
				</Button>
			)}
			<Modal
				width={800}
				title={'Create Permission'}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='reset' onClick={reset}>
						Reset
					</Button>,
					current > 0 && (
						<Button key='previous' onClick={() => prev()}>
							Previous
						</Button>
					),
					current < formSteps.length - 1 && (
						<Button key='next' type='primary' onClick={() => next()}>
							Next
						</Button>
					),
					current === formSteps.length - 1 && (
						<Button form={stepForm} loading={grantLoading} onClick={onFinish} type='primary'>
							Grant Permission
						</Button>
					)
				]}
			>
				<Form layout='vertical' form={stepForm} id={'createPermissionForm'} autoComplete='off'>
					<Steps responsive current={current}>
						{formSteps.map((item) => (
							<Step key={item.title} title={item.title} />
						))}
					</Steps>

					<div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
						{formSteps[current].content}
					</div>
				</Form>
			</Modal>
		</div>
	);
};

export default CreatePermissionModal;
