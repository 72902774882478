const actions = {
	LOAD_ASSET_GROUPS_DATA: 'LOAD_ASSET_GROUPS_DATA',
	LOAD_ASSET_GROUPS_SUCCESS: 'LOAD_ASSET_GROUPS_SUCCESS',
	LOAD_ASSET_GROUPS_ERROR: 'LOAD_ASSET_GROUPS_ERROR',

	loadAssetGroups: () => ({
		type: actions.LOAD_ASSET_GROUPS_DATA
	})
};
export default actions;
