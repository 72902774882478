import styled from 'styled-components';
import { palette } from 'styled-theme';
import Form from '@iso/components/uielements/form';

const CellWrapper = styled.div`
	display: flex;

	.editable-field-value-wrap {
		padding: 5px 12px;
		display: flex;
		align-items: center;
		height: 32px;
		cursor: pointer;
		flex-grow: 100;
		width: 100px;
	}

	.editable-field-delete-btn {
		opacity: 0;
		margin-left: 8px;
		cursor: pointer;
	}

	:hover {
		.editable-field-value-wrap {
			padding: 4px 11px;
			border: 1px solid ${palette('border', 0)};
			border-radius: 2px;
		}
		.editable-field-delete-btn {
			opacity: 1;
		}
	}
`;

const FieldWrapper = styled(Form.Item)`
	.datePicker {
		.ant-picker-input {
			input {
				font-size: 12px;
				font-weight: 600;
				color: ${palette('secondary', 3)};
			}
		}
	}
	.number {
		.ant-input-number-input {
			font-size: 12px;
			font-weight: 600;
			color: ${palette('secondary', 3)};
		}
	}
	.text {
		max-width: 90%;
		font-size: 12px;
		font-weight: 600;
		color: ${palette('secondary', 3)};
	}

	.boolean {
		&.ant-select {
			font-size: 12px;
			font-weight: 600;
			color: ${palette('secondary', 3)};
		}
	}
`;

export { CellWrapper, FieldWrapper };
