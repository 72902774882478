import { ProfileImageComponent, RenderPeopleActions } from '@Z/components/atoms';
import React from 'react';
import { ActionContainer, ProfileCardContainer, ProfileImageContainer, UserEmail, UserInfo, UserName } from './styles';
import { getDisplayName } from '@iso/lib/helpers/utility';
/**
 * Renders a person profile card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.person - The person object containing information about the person.
 * @param {boolean} [props.inTableUse] - Indicates whether the profile card is used in a table.
 * @param {boolean} [props.showActions] - Indicates whether to show actions on the profile card.
 * @param {boolean} [props.highlightOnHover=true] - Indicates whether to highlight the profile card on hover.
 * @returns {JSX.Element} The rendered person profile card.
 */

const PersonProfileCard = (props) => {
	const { person, inTableUse, showActions, highlightOnHover = true } = props;
	const { displayName, title } = getDisplayName(person);

	// If person object is empty, return null
	if (!person || Object.keys(person).length === 0) {
		return null;
	}
	return (
		<ProfileCardContainer inTableUse={inTableUse} {...props} highlightOnHover={highlightOnHover}>
			<ProfileImageContainer>
				<ProfileImageComponent
					edit={false}
					entityObject={{
						personID: person?.personID,
						photoUrl: person?.photoUrl
					}}
					entityName={'People'}
					size={40}
					shape={'circle'}
				/>
			</ProfileImageContainer>
			<UserInfo>
				<UserName title={title}>{displayName}</UserName>
				<UserEmail title={person.email}>{person.email || 'Email Not Found'}</UserEmail>
			</UserInfo>
			<ActionContainer>{showActions && <RenderPeopleActions size={'small'} data={person} />}</ActionContainer>
		</ProfileCardContainer>
	);
};

export default PersonProfileCard;
