import React, { useState, useEffect } from 'react';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { SearchBar, Loading } from '@Z/components/atoms';
import { SiteCard } from '@Z/components/molecules';
import { useSelector } from 'react-redux';
import Row from '@iso/ui/Antd/Grid/Row';
import Col from '@iso/ui/Antd/Grid/Col';
import basicStyle from '@iso/assets/styles/constants';
import { Empty } from 'antd';

const SiteCardsWrap = () => {
	const { sitesData, pending } = useSelector((state) => state.sites);
	const [filteredSites, setFilteredSites] = useState([]);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	useEffect(() => {
		if (pending) {
		} else {
			setFilteredSites(sitesData);
		}
	}, [pending, sitesData]);

	const filter = (array, searchTerm, columns) => {
		const searchResult = array.filter((row) => {
			return columns.some((column) => {
				return String(row[column]).toLowerCase().includes(searchTerm.toLowerCase());
			});
		});
		setFilteredSites(searchResult);
	};
	return pending ? (
		<Row style={rowStyle}>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<div style={loadingStyle}>
					<Loading />
				</div>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox>
			<SearchBar
				style={{ marginBottom: '10px' }}
				placeholder='Search Site Name'
				enterButton='Search'
				onChange={(event) => {
					filter(sitesData, event.target.value, ['name']);
				}}
				onSearch={(query) => {
					filter(sitesData, query, ['name']);
				}}
			/>
			{filteredSites && filteredSites.length > 0 ? (
				<Row gutter={[16, 16]} style={{ marginLeft: '10px', marginRight: '10px' }}>
					{filteredSites.map((item) => (
						<SiteCard key={item.siteID} item={item} />
					))}
				</Row>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Site Found</span>} />
			)}
		</IsoWidgetBox>
	);
};

export default SiteCardsWrap;
