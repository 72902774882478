import styled from 'styled-components';

const ContainerRoles = styled.div`
	display: flex;
	align-items: center;
	margin: 5px 0;
	padding: 5px;
	border-radius: 4px;
	width: auto;
	.infoRoles {
		display: flex;
		flex-direction: column;
		max-width: 90px;
		overflow: hidden;
		h3 {
			margin-left: 5px;
		}
	}
	&:hover {
		background-color: #f0f0f0ff;
		cursor: pointer;
	}
`;

export default ContainerRoles;
