import { Loading } from '@Z/components/atoms';
import DeleteEntityContactModal from '@Z/components/atoms/DeleteEntityContactModal';
import { MoreOutlined } from '@ant-design/icons';
import basicStyle from '@iso/assets/styles/constants';
import { Button } from '@iso/components/uielements';
import Card from '@iso/components/uielements/card';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { getPermissionByEntityType } from '@iso/lib/helpers/getPrimary';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import Col from '@iso/ui/Antd/Grid/Col';
import Row from '@iso/ui/Antd/Grid/Row';
import { Empty, Tooltip } from 'antd';
import React, { useState } from 'react';

const EntityContactTab = ({ contact, handleContact, data, type }) => {
	const { permissionName, rules } = getPermissionByEntityType(type, data);
	const isPermittedUpdate = useManagePermittedRoles(permissionName, rules);

	const [deleteContactVisible, setDeleteContactVisible] = useState(false);
	const [selectedContact, setSelectedContact] = useState(false);
	const { rowStyle, loadingStyle } = basicStyle;

	const actions = (item) => [
		<Tooltip title={!isPermittedUpdate ? "You don't have permission" : 'Edit Contact'}>
			<>
				<Button
					size={'small'}
					onClick={() => handleContact(item)}
					disabled={!isPermittedUpdate}
					style={{ width: '100%' }}
				>
					Edit
				</Button>
			</>
		</Tooltip>,
		<Tooltip title={!isPermittedUpdate ? "You don't have permission" : 'Delete Contact'}>
			<>
				<Button
					size={'small'}
					onClick={() => {
						setDeleteContactVisible(true);
						setSelectedContact({ ...item, ...data });
					}}
					disabled={!isPermittedUpdate}
					style={{ width: '100%' }}
					danger
				>
					Delete
				</Button>
			</>
		</Tooltip>
	];

	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{/* filter removed the unauthorised values */}
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	return !contact ? (
		<div style={loadingStyle}>
			<Loading />
		</div>
	) : contact && contact.length > 0 ? (
		<Row style={rowStyle} gutter={[8, 8]}>
			{contact.map((item, index) => (
				<Col key={index} lg={12} md={12} sm={12} xs={12}>
					<Card
						size='small'
						title={item.contactType ? item.contactType : 'Default'}
						extra={
							<Dropdown overlay={() => actionMenu(actions(item))} trigger={['click']}>
								<MoreOutlined />
							</Dropdown>
						}
						style={{ height: '100%' }}
					>
						<p>{item.contactInformation}</p>
					</Card>
				</Col>
			))}

			<DeleteEntityContactModal
				visible={deleteContactVisible}
				setVisible={setDeleteContactVisible}
				data={selectedContact}
				type={type}
			>
				<div />
			</DeleteEntityContactModal>
		</Row>
	) : (
		<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Contact Added</span>} />
	);
};

export default EntityContactTab;
