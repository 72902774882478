import actions from './actions';

const initState = { locks: [], gatewaysData: [], pending: true, searchable: false };

const inventoryReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_INVENTORY_LOCKS_SUCCESS:
			return { ...state, locks: action.locks, pending: false };
		case actions.LOAD_INVENTORY_ERROR:
			return { ...state, pending: false };
		case actions.LOAD_INVENTORY_GATEWAYS_SUCCESS:
			return { ...state, gatewaysData: action.gatewaysData, pending: false };
		default:
			return state;
	}
};
export default inventoryReducer;
