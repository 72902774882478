import React, { useState } from 'react';
import { Avatar } from 'antd';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';

const RenderAvatarFromFirebase = ({ path }) => {
	const [url, setUrl] = useState('');
	if (!path) return null;

	getDownloadURL(path)
		.then((r) => {
			setUrl(r);
		})
		.catch((e) => e);

	return <Avatar size={'large'} shape='square' src={url} />;
};

export default RenderAvatarFromFirebase;
