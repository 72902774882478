import { lazy } from 'react';

// ?  LAZY LOAD OF PRIVATE PAGES IN THE PLATFORM WITH THEIR OWN NAME OF CHUNK
const Home = lazy(() => import(/* webpackChunkName: "Home" */ '@iso/containers/DashboardHome'));
const Organisation = lazy(() => import(/* webpackChunkName: "Organisation" */ '@iso/containers/Organisations'));
const Sites = lazy(() => import(/* webpackChunkName: "Sites" */ '@iso/containers/Sites'));
const Floors = lazy(() => import(/* webpackChunkName: "Floors" */ '@iso/containers/Floors'));
const Floor = lazy(() => import(/* webpackChunkName: "Floor" */ '@iso/containers/Floor'));
const People = lazy(() => import(/* webpackChunkName: "People" */ '@iso/containers/People'));
const Person = lazy(() => import(/* webpackChunkName: "Person" */ '@iso/containers/Person'));
const Teams = lazy(() => import(/* webpackChunkName: "Teams" */ '@iso/containers/Teams'));
const Team = lazy(() => import(/* webpackChunkName: "Team" */ '@iso/containers/Team'));
const Devices = lazy(() => import(/* webpackChunkName: "Devices" */ '@iso/containers/Devices'));
const AssetGroups = lazy(() => import(/* webpackChunkName: "AssetGroups" */ '@iso/containers/AssetGroups'));
const Booking = lazy(() => import(/* webpackChunkName: "Booking" */ '@iso/containers/Booking'));
const Site = lazy(() => import(/* webpackChunkName: "Site" */ '@iso/containers/Site'));
const Access = lazy(() => import(/* webpackChunkName: "AccessControl" */ '@iso/containers/Access'));
const Inventory = lazy(() => import(/* webpackChunkName: "Inventory" */ '@iso/containers/Inventory'));
const Catalogue = lazy(() => import(/* webpackChunkName: "Catalogue" */ '@iso/containers/Catalogue'));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ '@iso/containers/Settings'));

// ?  LAZY LOAD OF PUBLIC PAGES IN THE PLATFORM WITH THEIR OWN NAME OF CHUNK
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ '@iso/containers/Pages/SignUp'));
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ '@iso/containers/Pages/SignIn/SignIn'));
const ForgotPassword = lazy(() =>
	import(/* webpackChunkName: "ForgotPassword" */ '@iso/containers/Pages/ForgotPassword/ForgotPassword')
);
const CreateAccount = lazy(() =>
	import(/* webpackChunkName: "CreateAccount" */ '@iso/containers/Pages/CreateAccount/CreateAccount')
);
const ResetPassword = lazy(() =>
	import(/* webpackChunkName: "ResetPassword" */ '@iso/containers/Pages/ResetPassword/ResetPassword')
);

const PageError = lazy(() => import(/* webpackChunkName: "PageError" */ '@iso/containers/Pages/500/500'));
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ '@iso/containers/Pages/404/404'));
const PageNotAuthorized = lazy(() =>
	import(/* webpackChunkName: "PageNotAuthorized" */ '@iso/containers/Pages/401/401')
);

export const ERROR_PAGES = [
	{
		path: '/404',
		component: PageNotFound
	},
	{
		path: '/401',
		component: PageNotAuthorized
	},
	{
		path: '/500',
		component: PageError
	}
];

export const PUBLIC_ROUTES = [
	{
		path: '/',
		exact: true,
		component: SignUp
	},
	{
		path: '/signin',
		component: SignIn
	},
	{
		path: '/signup',
		component: SignUp
	},
	{
		path: '/forgotpassword',
		component: ForgotPassword
	},
	{
		path: '/createpassword',
		component: CreateAccount
	},
	{
		path: '/resetpassword',
		component: ResetPassword
	}
];

export const PRIVATE_ROUTES = [
	{
		path: '',
		component: Home,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Home',
		leftIcon: 'home',
		entityTypeID: 1,
		optionSidebar: true
	},
	{
		path: 'organisations',
		exact: true,
		component: Organisation,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'All Organisations'
	},
	{
		path: 'sites',
		component: Sites,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Sites',
		leftIcon: 'map',
		entityTypeID: 2,
		optionSidebar: true
	},
	{
		path: 'sites/site',
		component: Site,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Site'
	},
	{
		path: 'floors',
		component: Floors,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Floors',
		leftIcon: 'apartment',
		entityTypeID: 3,
		optionSidebar: true
	},
	{
		path: 'floors/floor',
		component: Floor,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Floor'
	},
	{
		path: 'people',
		component: People,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'People',
		leftIcon: 'group',
		entityTypeID: 4,
		optionSidebar: true
	},
	{
		path: 'people/person',
		component: Person,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Person'
	},
	{
		path: 'teams',
		component: Teams,
		exact: true,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Teams',
		leftIcon: 'groups',
		entityTypeID: 5,
		optionSidebar: true
	},
	{
		path: 'teams/team',
		component: Team,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Team'
	},
	{
		path: 'devices',
		component: Devices,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Assets & Devices',
		leftIcon: 'device',
		customIcon: true,
		entityTypeID: 6,
		optionSidebar: true
	},
	{
		path: 'assetGroups',
		component: AssetGroups,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Asset Groups',
		leftIcon: 'widgets',
		entityTypeID: 7,
		optionSidebar: true
	},
	{
		path: 'booking',
		component: Booking,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: 'Booking',
		leftIcon: 'date_range',
		entityTypeID: 8,
		optionSidebar: true
	},
	{
		path: 'access',
		component: Access,
		access: [201, 202, 101, 103, 104],
		name: 'Access',
		leftIcon: 'key',
		entityTypeID: 9,
		optionSidebar: true
	},
	{
		path: 'inventory',
		component: Inventory,
		exact: true,
		access: [201, 203],
		name: 'Inventory ',
		leftIcon: 'storage',
		optionSidebar: true
	},
	{
		path: 'catalogue',
		component: Catalogue,
		access: [201],
		name: 'Catalogue',
		leftIcon: 'menu',
		optionSidebar: true
	},
	{
		path: 'settings',
		component: Settings,
		access: [201, 202, 101, 102, 103],
		name: 'Settings',
		leftIcon: 'settings',
		entityTypeID: 10,
		optionSidebar: true
	},
	{
		path: '404',
		component: PageNotFound,
		access: [201, 202, 101, 102, 103, 104, 105, 2, 107],
		name: '404 Error'
	}
];
