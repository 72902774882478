import { Loading, PersonProfileCard, RenderPeopleActions, SearchBar, SimpleTable } from '@Z/components/atoms';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { getUserStatus } from '@iso/lib/hooks/useNamesFromEntities';
import { Col, Empty, Row } from 'antd';
import 'antd/dist/antd.min.css';
import { default as React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProfilePreview from './components/profilePreview';
import { PeopleTableWrapper } from './peopleTableInformation.styles';

const PeopleTableInformation = () => {
	const { peopleData, pending } = useSelector((state) => state.people);

	const [filteredData, setFilteredData] = useState([]);
	const [previewPerson, setPreviewPerson] = useState(null);

	const rowSelection = {
		type: 'select',
		selectedRowKeys: previewPerson?.key ? [previewPerson?.key] : [0],
		visible: false,
		columnWidth: 3,
		hideSelectAll: true,
		onChange: (selectedRowKeys) => {
			if (selectedRowKeys.length > 0 && filteredData[selectedRowKeys[1]]?.personID !== previewPerson?.personID) {
				setPreviewPerson(filteredData[selectedRowKeys[1]]);
			}
		}
	};

	useEffect(() => {
		if (!pending) {
			setFilteredData(peopleData);
			setPreviewPerson(previewPerson || peopleData[0]);
		}
	}, [peopleData, pending, previewPerson]);

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object.getDisplayName().displayName.toLowerCase().includes(query.toLowerCase()) ||
				object.email.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredData(searchResult);
	};

	const columns = [
		{
			title: 'PERSON',
			dataIndex: 'displayName',
			key: 'displayName',
			ellipsis: true,
			width: 40,
			render: (_, record) => <PersonProfileCard inTableUse={true} person={record} highlightOnHover={false} />
		},
		{
			title: 'STATUS',
			dataIndex: '',
			key: 'status',
			ellipsis: true,
			width: 20,
			render: (_, record) => getUserStatus(record)[0]
		},
		{
			title: 'USER TYPE',
			dataIndex: '',
			key: 'userType',
			ellipsis: true,
			width: 20,
			render: (_, record) => {
				return record.userType.name;
			}
		},
		//TODO: Dynamic Column for User Type
		// {
		// 	title: 'Dynamic Column',
		// 	dataIndex: '',
		// 	key: 'userType',
		// 	ellipsis: true,
		// 	width: 20,
		// 	render: (_, record) => {
		// 		return 'Still in Development';
		// 	}
		// },
		{
			key: 'ACTION',
			width: 30,
			align: 'center',
			render: (data) => (
				<div>
					<RenderPeopleActions data={data} />
				</div>
			),
			ellipsis: true
		}
	];

	return pending ? (
		<Loading />
	) : (
		<>
			<IsoWidgetBox>
				<SearchBar
					placeholder='Search Person Name'
					enterButton='Search'
					loading={false}
					onChange={(e) => {
						handleSearch(e.target.value, peopleData);
					}}
					onSearch={(query) => {
						handleSearch(query, peopleData);
					}}
				/>
			</IsoWidgetBox>
			<IsoWidgetBox>
				<Row gutter={10}>
					<Col xl={17} lg={24} md={24} sm={24} xs={24}>
						<PeopleTableWrapper>
							{filteredData && filteredData.length > 0 ? (
								<SimpleTable
									scroll={{ x: 'max-content' }}
									rowSelection={rowSelection}
									columns={columns}
									pageSize={15}
									dataSource={filteredData}
									onRow={(record) => ({
										onClick: () => {
											if (record?.personID !== previewPerson?.personID) {
												setPreviewPerson(record);
											}
										}
									})}
								/>
							) : (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No People Data</span>} />
							)}
						</PeopleTableWrapper>
					</Col>
					<Col xl={7} lg={0} md={0} sm={0} xs={0}>
						{previewPerson ? <ProfilePreview person={previewPerson} /> : <Loading />}
					</Col>
				</Row>
			</IsoWidgetBox>
		</>
	);
};

export default PeopleTableInformation;
