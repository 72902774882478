/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 21-04-2020  11:39:13
 * @modify date 07-12-2022  14:32:52
 * @desc [description]
 */

import React from 'react';
import { LabelWrapper, SearchBar, SimpleTable } from '@Z/components/atoms';

/**
 * This is the SearchableTable component, it is used to display
 * a table with search bar and pagination functionality using pageSize.
 *
 * @export SearchableTable - Searchable Table
 * @param {*} props, dataSource, columns, pageSize, filterColumn
 * @returns {JSX.Element} of a table with search bar
 */
const SearchableTable = (props) => {
	const { dataSource, columns, pageSize, filterColumn, title, placeholder } = props;
	const [data, setData] = React.useState([]);
	const [filterData, setFilterData] = React.useState([]);

	React.useEffect(() => {
		setData(dataSource);
		setFilterData(dataSource);
	}, [dataSource]);

	const filter = (array, searchTerm, columns) => {
		const searchResult = array.filter((row) => {
			return columns.some((column) => {
				return String(row[column]).toLowerCase().includes(searchTerm.toLowerCase());
			});
		});
		setFilterData(searchResult);
	};

	// const NameWithlink = withHyperLink((a) => {
	// 	return a.children;
	// });

	return (
		<div>
			{title && (
				<LabelWrapper
					typeLabel={'primary'}
					text={title}
					color={'secondary'}
					tonality={0}
					fontSize={20}
					fontWeight={500}
					style={{ marginLeft: '5px' }}
				/>
			)}
			{filterColumn && filterColumn.length > 0 ? (
				<SearchBar
					style={{ marginBottom: '10px', marginTop: '4px' }}
					placeholder={'Search ' + (placeholder || '')}
					enterButton='Search'
					loading={false}
					onChange={(event) => {
						filter(data, event.target.value, filterColumn);
					}}
					onSearch={(query) => {
						filter(data, query, filterColumn);
					}}
				/>
			) : null}

			<SimpleTable columns={columns} pageSize={pageSize} dataSource={filterData} />
		</div>
	);
};
export default SearchableTable;
