export const bookingStatusType = {
	1: {
		id: 1,
		title: 'Ongoing'
	},
	2: {
		id: 2,
		title: 'Upcoming'
	},
	3: {
		id: 3,
		title: 'Completed'
	},
	4: {
		id: 4,
		title: 'Deleted'
	},
	5: {
		id: 5,
		title: 'Unknown'
	}
};
