import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { useDispatch } from 'react-redux';
import { notification } from '@iso/components';
import teamsAction from '@iso/redux/teams/actions';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';

const TeamDeleteModal = ({ open = false, setOpen, team, children }) => {
	const dispatch = useDispatch();
	const { loadTeams } = teamsAction;
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const isPermittedDeleteTeam = useManagePermittedRoles('deleteTeam', {
		orgID: [team.orgID],
		siteID: [team.siteID],
		teamID: [team.teamID]
	});

	const handleDelete = async () => {
		setLoading(true);

		await superFetch
			.delete(`/teams/${team.teamID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Team Deleted.');
						setOpen(false);
					}
					dispatch(loadTeams());
					history.push('/dashboard/teams');
				}
				setLoading(false);
				return res;
			})
			.catch((err) => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	return (
		<React.Fragment>
			<Tooltip
				title={!isPermittedDeleteTeam ? "You don't have permission" : 'Delete this Team from your Workspace'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							danger
							onClick={() => {
								setOpen(true);
							}}
							disabled={!isPermittedDeleteTeam}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Delete
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Delete a Team`}
				danger
				visible={open}
				onCancel={() => setOpen(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setOpen(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to Team{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{team.name}' </span>from the organisation?
				</p>
			</Modal>
		</React.Fragment>
	);
};

export default TeamDeleteModal;
