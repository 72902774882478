import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const SiteCardWrapper = styled.div`
	.hoverCard:hover {
		border-color: ${palette('secondary', 0)};
	}
	.dividerStyle {
		display: flex;
		height: auto;
	}
	.title {
		font-size: 18px;
		color: ${palette('secondary', 0)};
	}
	.ant-card-body {
		padding: 10px;
	}
	.ant-card-bordered {
		border-color: ${palette('grayscale', 2)};

		${borderRadius()};
	}
	.ant-card-head {
		border-bottom-color: ${palette('grayscale', 3)};

		.ant-card-head-title {
			font-size: 18px;
			color: ${palette('secondary', 0)};
		}
		.ant-card-extra > span {
			font-size: 18px;
			margin-right: -15px;
		}
	}

	.site-card-content {
		display: flex;
		justify-content: start;
		flex-direction: column;

		.contact-container {
			font-size: 15px;
			font-weight: 500;
			text-transform: capitalize;
		}
		.address-container {
		}
	}

	.statContainer {
		display: flex;
		justify-content: space-evenly;
		align-items: space-evenly;
	}
	.statDiv {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.statRow {
		display: flex;
		align-items: center;
		flex-direction: column;
		row-gap: 10px;
	}
	.statValue {
		display: flex;
		align-items: center;
	}

	.ant-divider-horizontal {
		margin: 10px 0px;
	}

	.statTitle {
		font-size: 16px;
		font-weight: 450;
		color: ${palette('secondary', 0)};
	}
	.ant-avatar-group {
		display: flex;
		align-items: center;
	}
`;

export default SiteCardWrapper;
