import { all, takeEvery, put, fork } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';
import actions from './actions';

export function* loadAssetGroupData() {
	yield takeEvery('LOAD_ASSET_GROUPS_DATA', function* () {
		const data = yield superFetch.get('/deviceGroups').then((res) => {
			var result = {};
			if (res.data && res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data;
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;

				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_ASSET_GROUPS_SUCCESS,
				data: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_ASSET_GROUPS_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadAssetGroupData)]);
}
