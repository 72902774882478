import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { useDispatch } from 'react-redux';
import { notification } from '@iso/components';
import deviceCategoriesAction from '@iso/redux/deviceCategories/actions';
import devicesAction from '@iso/redux/devices/actions';

const CategoryDeleteModal = ({ deleteModalVisible, setDeleteModalVisible, categoryToBeDeleted }) => {
	const dispatch = useDispatch();
	const { loadDeviceCategories } = deviceCategoriesAction;
	const { loadDevices } = devicesAction;
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		setLoading(true);
		superFetch
			.delete(`/deviceCategories/${categoryToBeDeleted.categoryID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Category deleted.');
						notification('success', 'All devices in the category Deleted.');

						setDeleteModalVisible(false);
					}
					dispatch(loadDeviceCategories());
					dispatch(loadDevices());
				}
				setLoading(false);
				return res;
			})
			.catch((err) => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	return (
		<Modal
			title={`Delete a Category`}
			danger
			visible={deleteModalVisible}
			onCancel={() => setDeleteModalVisible(false)}
			maskClosable={false}
			destroyOnClose={true}
			footer={[
				<Button key='cancel' onClick={() => setDeleteModalVisible(false)}>
					Cancel
				</Button>,
				<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
					Delete
				</Button>
			]}
		>
			<p style={{ textAlign: 'center' }}>
				Are you sure you want to delete{' '}
				<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{categoryToBeDeleted.name}'</span> from your
				Organisation?
			</p>
			<br />
			<p style={{ textAlign: 'center' }}>This will also delete all devices in this category.</p>
		</Modal>
	);
};

export default CategoryDeleteModal;
