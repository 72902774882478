import { ProfileImageComponent, RenderTeamActions } from '@Z/components/atoms';
import Card from '@iso/components/uielements/card';
import { getPrimaryContact } from '@iso/lib/helpers/getPrimary';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import TeamCardWrapper from './teamCardWrapper.styles';
import withHyperLink from '@iso/lib/hoc/withHyperLink';

const TeamCard = ({ team }) => {
	const primaryContact = getPrimaryContact(team.contact);

	// Size of a profile picture on a team card
	const profilePictureSize = 130;
	const { Paragraph, Title } = Typography;

	// HOC
	const TeamWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	return (
		<TeamCardWrapper profileImageComponentSize={profilePictureSize}>
			<Card className='hoverCard'>
				<div className='divInfoStyle'>
					<ProfileImageComponent
						entityObject={team}
						size={profilePictureSize}
						entityName={'Team'}
						shape={'square'}
					/>

					<Row className='rowDivStyle'>
						<div className='personTitle'>
							<TeamWithLink type='team' dataID={team.teamID}>
								<Title
									className='title'
									style={{ fontSize: '22px', cursor: 'pointer' }}
									ellipsis={{ rows: 1 }}
								>
									{team.name}
								</Title>
							</TeamWithLink>
						</div>
						<div>
							<RenderTeamActions team={team} />
						</div>

						<div className='description'>
							<Paragraph ellipsis={{ rows: 3, expandable: false }}>{team.description}</Paragraph>
						</div>
					</Row>
				</div>
				<Row className='infoContainer'>
					<Col lg={8} md={8} sm={8} xs={8}>
						<div className='container'>
							<p className='value'>{team.totalMember}</p>
							<p className='label'>Total Members</p>
						</div>
					</Col>
					<Col lg={8} md={8} sm={8} xs={8}>
						{team.adminPerson && team.adminPerson.length > 0 && (
							<div className='container'>
								<TeamWithLink type='team' dataID={team.teamID}>
									<p className='value' key={team.adminPerson[0].teamID}>
										{team.adminPerson[0].displayName}
									</p>
								</TeamWithLink>
								<p className='label'>Team Admin</p>
							</div>
						)}
					</Col>
					<Col lg={8} md={8} sm={8} xs={8}>
						{primaryContact && (
							<div className='container'>
								<p className='value'>{primaryContact.contactInformation}</p>
								<p className='label'>{primaryContact.contactType}</p>
							</div>
						)}
					</Col>
				</Row>
			</Card>
		</TeamCardWrapper>
	);
};

export default TeamCard;
