import styled from 'styled-components';

const IndividualOrgInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;

	@media only screen and (max-width: 1000px) {
		flex-direction: column;
	}

	.infoDiv {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 15px;
		margin-top: 10px;
	}

	.infoABNACN {
		display: flex;
		flex-wrap: wrap;
	}
	.info {
		display: flex;
		align-items: center;
		p {
			margin-right: 3px;
		}
		span {
			line-height: normal !important;
		}
	}

	.header {
		width: 100%;
		margin-bottom: 15px;
	}

	.stat {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		column-gap: 15px;
	}

	.editButton {
		display: flex;
		justify-content: flex-end;
	}
	.editOrg {
		display: flex;
		justify-content: flex-end;
		background-color: red;

		width: 100%;
		@media only screen and (max-width: 1600px) {
			justify-content: center;
			align-content: center;
			width: 100%;
			padding-top: 10px;
		}
	}
`;

export default IndividualOrgInfoWrapper;
