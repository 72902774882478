import React, { useEffect } from 'react';
import MapUpload from '@iso/ui/UploadImagesToFirebaseComponent';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import { Row, Col } from 'antd';
import Input, { Textarea } from '@iso/components/uielements/input';
import Form from '@iso/components/uielements/form';
import UploadMapWrapper, { UploadModalWrapper } from './uploadMap.styles';
import { InfoCircleOutlined } from '@ant-design/icons';
import Checkbox from '@iso/components/uielements/checkbox';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
/**
 * @description
 * @author Kishan Virani
 * @date 17/03/2022
 * @param {*} props
 * @param {*} data Entity Data
 * @param {*} mapType Site | Floor
 * @param {*} buttonLabel Site | Floor
 * @return {*}
 */
const UploadMapComponent = (props) => {
	const { data, mapType, buttonLabel } = props;
	const [viewModal, setViewModal] = React.useState(false);
	const [form] = Form.useForm();
	const [confirmLoading, setConfirmLoading] = React.useState(false);
	const [uploadSuccess, setUploadSuccess] = React.useState(false);
	const [uploadEnabled, setUploadEnabled] = React.useState(false);
	const [disableCheckbox, setDisableCheckbox] = React.useState(true);
	const [mapData, setMapData] = React.useState({});
	const handleCancel = () => {
		setViewModal(false);
		setConfirmLoading(false);
		setUploadSuccess(false);
		setUploadEnabled(false);
		form.resetFields();
	};
	const handleSubmit = () => {
		setConfirmLoading(true);
		const formData = form.getFieldsValue();
		setMapData(formData);
		// setConfirmLoading(false);
	};
	useEffect(
		() => {
			// To prevent the Upload file modal being cancelled and upload loading to be reset
			if (confirmLoading) {
				setTimeout(() => {
					setConfirmLoading(false);
				}, 5000);
			}
			if (uploadSuccess) handleCancel();
		},
		// eslint-disable-next-line
		[confirmLoading, uploadSuccess]
	);
	const checkedWaiver = (e) => {
		const fieldsErrors = form.getFieldsError(['name', 'note', 'dimensionX', 'dimensionY']);
		const hasErrors = fieldsErrors.map((field) => field.errors).some((error) => error.length > 0);
		const valueAdded = form.isFieldsTouched(['name']);
		if (!hasErrors && valueAdded && e.target.checked) {
			setUploadEnabled(e.target.checked);
		} else {
			setUploadEnabled(false);
		}
	};

	return (
		<UploadMapWrapper>
			<Button onClick={() => setViewModal(true)} className='uploadButton'>
				{buttonLabel || 'Upload Map'}
			</Button>
			<Modal
				title={'Upload ' + mapType + ' Plan'}
				visible={viewModal}
				onCancel={handleCancel}
				width={700}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>
				]}
			>
				<UploadModalWrapper>
					<Row>
						<Col lg={12} md={12} sm={12} xs={12} className='formContainer'>
							<Form
								form={form}
								id={form}
								layout='vertical'
								onChange={() => setDisableCheckbox(!form.isFieldTouched('name'))}
							>
								<Row style={{ paddingBottom: '10px' }}>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 8 }}
											labelWrap={true}
											wrapperCol={{ span: 23 }}
											name='name'
											label='Map Name'
											rules={fieldsValidator.Name}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row style={{ paddingBottom: '10px' }}>
									<Col span={12}>
										<Form.Item
											labelWrap={true}
											wrapperCol={{ span: 22 }}
											name='dimensionX'
											label='X Dimension'
											tooltip={{
												title: 'Physical Dimension in meters',
												icon: <InfoCircleOutlined />
											}}
											rules={fieldsValidator.DimensionMap}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelWrap={true}
											wrapperCol={{ span: 22 }}
											name='dimensionY'
											label='Y Dimension'
											tooltip={{
												title: 'Physical Dimension in meters',
												icon: <InfoCircleOutlined />
											}}
											rules={fieldsValidator.DimensionMap}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Form.Item
											wrapperCol={{ span: 23 }}
											name='note'
											label='Note'
											rules={fieldsValidator.Description}
										>
											<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col lg={12} md={12} sm={12} xs={12} className='uploadMapButtonContainer'>
							<div className='warningContainer'>
								<div className='WarningText'>
									<Checkbox disabled={disableCheckbox} onChange={(e) => checkedWaiver(e)}>
										I understand, uploading a new Floor Plan will dissociate all devices linking to
										the floor.
									</Checkbox>
								</div>
								<MapUpload
									cropperOptions={{ grid: true, rotate: true }}
									entityName={mapType}
									entityID={data[`${mapType.toLowerCase()}ID`]}
									uploadType={'indoorMap'}
									setUploadLoading={setConfirmLoading}
									setUploadSuccessful={setUploadSuccess}
									mapData={mapData}
									oldData={data}
								>
									<Button
										loading={confirmLoading}
										form={form}
										disabled={!uploadEnabled}
										type='primary'
										onClick={() => handleSubmit()}
									>
										Upload
									</Button>
								</MapUpload>
							</div>
						</Col>
					</Row>
				</UploadModalWrapper>
			</Modal>
		</UploadMapWrapper>
	);
};
export default UploadMapComponent;
