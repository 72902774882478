const actions = {
	LOAD_PEOPLE_DATA: 'LOAD_PEOPLE_DATA',
	LOAD_PEOPLE_SUCCESS: 'LOAD_PEOPLE_SUCCESS',
	LOAD_PEOPLE_ERROR: 'LOAD_PEOPLE_ERROR',

	loadPeople: () => ({
		type: actions.LOAD_PEOPLE_DATA
	})
};
export default actions;
