import { LabelWrapper, Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import peopleAction from '@iso/redux/people/actions';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input } from '@iso/components/uielements';
import { AlertInfoMessage, EnrolPeopleModalWrapper } from './EnrolPeopleModal.styles';
import SuperFetch from '@iso/lib/helpers/superFetch';
import { DeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import AlertMessage from './AlertMessage';

const { loadPeople } = peopleAction;

const EnrolPersonButton = ({ children }) => {
	const { peopleData } = useSelector((state) => state.people);
	const { organisationData } = useSelector((state) => state.Organisation);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const dispatch = useDispatch();

	const [viewModal, setViewModal] = useState(false);
	const [checkInfo, setCheckInfo] = useState(true);
	const [emails, setEmails] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [errorEmail, setErrorEmail] = useState(false);
	const [messageError, setMessageError] = useState('');

	const [workSpace, setWorkSpace] = useState(false);
	useEffect(() => {
		const workspace = organisationData.find((org) => org.orgID === orgID);
		setWorkSpace(workspace);
	}, [orgID, organisationData]);

	const handleChange = (e) => {
		setInputValue(e.target.value);
		setErrorEmail(false); // Reset error state on input change
		setMessageError(''); // Reset error message on input change
	};

	const handlePressEnter = () => {
		const emailList = Array.from(
			new Set(
				inputValue
					.split(',')
					.map((email) => email.trim())
					.filter((email) => email !== '')
			)
		);

		const invalidEmails = emailList.filter((email) => !validateEmail(email));
		const existingEmails = emailList.filter((email) => peopleData.some((person) => person.email === email));
		const existingEmailsList = emailList.filter((email) => emails.some((person) => person.email === email));
		const validEmails = emailList.filter(
			(email) => validateEmail(email) && !peopleData.some((person) => person.email === email)
		);

		if (invalidEmails.length > 0) {
			setErrorEmail(true);
			setMessageError(
				invalidEmails.length === 1
					? `This email address is invalid: ${invalidEmails[0]}`
					: `These email addresses are invalid: ${invalidEmails.join(', ')}`
			);
		} else if (existingEmails.length > 0) {
			setErrorEmail(true);
			setMessageError(
				existingEmails.length === 1
					? `This email address already is a member: ${existingEmails[0]}`
					: `These email addresses are already members: ${existingEmails.join(', ')}`
			);
		} else if (existingEmailsList.length > 0) {
			setErrorEmail(true);
			setMessageError(
				existingEmailsList.length === 1
					? `This email address already is in the list: ${existingEmailsList[0]}`
					: `These email addresses are already in the list: ${existingEmailsList.join(', ')}`
			);
		} else {
			setEmails([...emails, ...validEmails.map((email) => ({ email, status: 'registered' }))]);
			setInputValue('');
			setErrorEmail(false);
			setMessageError('');
		}
	};

	const validateEmail = (email) => {
		const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
		return re.test(String(email).toLowerCase());
	};

	const handleDeleteEmail = (emailToDelete) => {
		setEmails(emails.filter(({ email }) => email !== emailToDelete));
	};

	const handleCancel = () => {
		setViewModal(false);
		setInputValue('');
		setErrorEmail(false);
		setMessageError('');
		setEmails([]);
	};

	const handleEnrollPeople = async () => {
		setLoading(true);
		if (emails.some((email) => email.status === 'registered')) {
			for (const emailObj of emails) {
				if (emailObj.status === 'registered') {
					const { email } = emailObj;
					try {
						setEmails((prevEmails) =>
							prevEmails.map((e) => (e.email === email ? { ...e, status: 'inviting' } : e))
						);
						const res = await SuperFetch.post('/people', { email });

						if (res.statusCode === 201) {
							setErrorEmail(false);
							setMessageError('');
							dispatch(loadPeople());
							setEmails((prevEmails) =>
								prevEmails.map((e) => (e.email === email ? { ...e, status: 'invited' } : e))
							);
							notification('success', `${email} has been invited to your workspace.`);
						} else {
							setEmails((prevEmails) =>
								prevEmails.map((e) => (e.email === email ? { ...e, status: 'registered' } : e))
							);
							notification('error', res.error);
						}
					} catch (error) {
						setEmails((prevEmails) =>
							prevEmails.map((e) => (e.email === email ? { ...e, status: 'registered' } : e))
						);
						notification('error', 'An error occurred while inviting the person.');
					}
				}
			}
		} else {
			notification('info', 'There are no emails to invite.');
		}
		setLoading(false);
	};

	const handleEnrollPerson = async (email) => {
		setLoading(true);
		try {
			setEmails((prevEmails) => prevEmails.map((e) => (e.email === email ? { ...e, status: 'inviting' } : e)));
			const res = await SuperFetch.post('/people', { email });

			if (res.statusCode === 201) {
				setErrorEmail(false);
				setMessageError('');
				dispatch(loadPeople());
				setEmails((prevEmails) => prevEmails.map((e) => (e.email === email ? { ...e, status: 'invited' } : e)));
				notification('success', `${email} has been invited to your workspace.`);
			} else {
				setEmails((prevEmails) =>
					prevEmails.map((e) => (e.email === email ? { ...e, status: 'registered' } : e))
				);
				notification('error', res.error);
			}
		} catch (error) {
			setEmails((prevEmails) => prevEmails.map((e) => (e.email === email ? { ...e, status: 'registered' } : e)));
			notification('error', 'An error occurred while inviting the person.');
		}
		setLoading(false);
	};

	return (
		<div>
			{children ? (
				children
			) : (
				<Button type='primary' onClick={() => setViewModal(true)}>
					Enrol people
				</Button>
			)}
			<Modal
				title={`Enrol People to ${workSpace?.name}`}
				visible={viewModal}
				onCancel={handleCancel}
				maskClosable={false}
				destroyOnClose={true}
				width='700px'
				footer={[
					<Button key='cancel' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button key='save' loading={loading} type='primary' onClick={handleEnrollPeople}>
						Invite All
					</Button>
				]}
			>
				<EnrolPeopleModalWrapper>
					<div className='containerEmail'>
						<LabelWrapper
							typeLabel='primary'
							text='Enter Email Addresses'
							fontSize={14}
							fontWeight={500}
							color='text'
							tonality={1}
						/>
						<div className='containerInput'>
							<div className='input'>
								<Input
									placeholder='e.g., user@domain.com, another@domain.com'
									value={inputValue}
									onChange={handleChange}
									onPressEnter={handlePressEnter}
								/>
							</div>

							<Tooltip title='Get help'>
								<a
									target='_blank'
									rel='noreferrer'
									href='https://support.zezamii.com/hc/en-us/articles/10008580255503-How-to-Enrol-Users-in-Zezamii'
								>
									<QuestionCircleOutlined />
								</a>
							</Tooltip>
						</div>
						{errorEmail && (
							<LabelWrapper
								text={messageError}
								fontSize={12}
								color='color'
								tonality={2}
								style={{ margin: '5px 0px' }}
							/>
						)}
						{checkInfo && (
							<AlertInfoMessage>
								<AlertMessage
									icon='error_outline'
									message="Please ensure that email addresses are correctly formatted with an '@' symbol and a valid domain name, followed by a comma."
									toggleDisplay={() => setCheckInfo(!checkInfo)}
								/>
							</AlertInfoMessage>
						)}

						<div>
							{emails.length > 0 && (
								<>
									<div className='containerList'>
										<LabelWrapper
											typeLabel='primary'
											text='Email Addresses List'
											fontSize={14}
											fontWeight={500}
											color='text'
											tonality={1}
										/>
										<Button size='small' onClick={() => setEmails([])}>
											Clear List
										</Button>
									</div>

									{emails.map(({ email, status }, index) => (
										<div key={index} className={`containerEmails ${status}`}>
											<p className=''>{email}</p>
											{
												{
													registered: (
														<div className='buttonInvited'>
															<Button
																icon={<UsergroupAddOutlined />}
																onClick={() => handleEnrollPerson(email)}
															>
																Invite
															</Button>
															<Button
																icon={<DeleteOutlined />}
																onClick={() => handleDeleteEmail(email)}
															/>
														</div>
													),
													inviting: (
														<div className='tag'>
															<i className='material-icons-outlined'>group</i>
															<LabelWrapper
																text={'Inviting'}
																fontSize={14}
																fontWeight={500}
																color='color'
																tonality={1}
															/>
														</div>
													),
													invited: (
														<div className='tag'>
															<i className='material-icons-outlined'>group</i>
															<LabelWrapper
																text={'Invited'}
																fontSize={14}
																fontWeight={500}
																color='color'
																tonality={9}
															/>
														</div>
													)
												}[status]
											}
										</div>
									))}
								</>
							)}
						</div>
					</div>
				</EnrolPeopleModalWrapper>
			</Modal>
		</div>
	);
};

export default EnrolPersonButton;
