import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '@iso/lib/helpers/style_utils';

const AntDatePicker = (ComponentName) => styled(ComponentName)`
	&.ant-picker {
		padding: 4px 10px;
		width: 100%;
		height: 35px;
		cursor: text;
		text-align: ${(props) => (props['data-rtl'] === 'rtl' ? 'right' : 'left')};
		line-height: 1.5;
		background-color: #fff;
		background-image: none;
		border: 1px solid ${palette('border', 0)};
		${borderRadius('4px')};
		${transition()};
		.ant-picker-input > input {
			color: ${palette('text', 1)};
			font-size: 13px;
		}
	}
`;

export default AntDatePicker;
