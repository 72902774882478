import { AddBooking } from '@Z/components/atoms';
import { DeleteBookingModal } from '@Z/components/molecules';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, DropdownMenu, MenuItem } from '@iso/components/uielements';
import Button from '@iso/components/uielements/button';
import React, { Fragment, useState } from 'react';

const RenderBookingActions = ({ data, activeButton }) => {
	const [viewDeleteModal, setViewDeleteModal] = useState(false);
	const [viewEditModal, setViewEditModal] = useState(false);
	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const actions = [
		<AddBooking isEdit editVisible={viewEditModal} setEditVisible={setViewEditModal} dataToUpdate={data} />,
		<DeleteBookingModal setViewModal={setViewDeleteModal} viewModal={viewDeleteModal} booking={data} />
	];

	return (
		<Fragment>
			<Dropdown arrow overlay={() => actionMenu(actions)} trigger={['click']} align={{ offset: [0, 0] }}>
				<Button size='small' type={activeButton ? 'primary' : null}>
					Actions <DownOutlined style={{ fontSize: '80%' }} />
				</Button>
			</Dropdown>
		</Fragment>
	);
};

export default RenderBookingActions;
