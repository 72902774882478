import { Modal } from '@Z/components/atoms';
import Icon from '@ant-design/icons';
import { ReactComponent as lockIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import superFetch from '@iso/lib/helpers/superFetch';
import devicesAction from '@iso/redux/devices/actions';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect, { SelectAssetsWrapper, TagWrapper, TeamGrantWrapper } from './ManagePermissionModal.styles';
import { Tooltip } from 'antd';
const { loadDevices } = devicesAction;

const ManagePermissionModal = ({ visible, setVisible, entityID, entityType, children }) => {
	const [grantButtonLoading, setGrantButtonLoading] = useState(false);
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
	const [initialTeamAccessData, setInitialTeamAccessData] = useState([]);
	const [initialAccessData, setInitialAccessData] = useState([]);

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const {
		remoteConfig: { constants, accessControlPermissionType }
	} = useSelector((state) => state.remoteConfig);
	const { teamsData } = useSelector((state) => state.teams);
	const { devicesData } = useSelector((state) => state.devices);
	const { assetGroupsData } = useSelector((state) => state.assetGroups);

	useEffect(() => {
		const getData = async () => {
			const tAccessData =
				entityType === constants.ENTITY_TYPE_ID_TEAM
					? await getAccessData({ teamID: entityID })
					: await getAccessData({ personID: entityID });

			const uniqueAssetGroupIDs = new Set();
			const structuredAccessData = {
				groups: tAccessData
					.map((group) => {
						if (group.entityTypeID === constants.ENTITY_TYPE_ID_ASSETGROUP) {
							return group.assetGroupID;
						}
						return null;
					})
					.filter((id) => {
						return id !== null && (uniqueAssetGroupIDs.has(id) ? false : uniqueAssetGroupIDs.add(id));
					}),
				assets: tAccessData
					.map((group) => {
						if (group.entityTypeID === constants.ENTITY_TYPE_ID_ASSETDEVICE) {
							return group.deviceID;
						}
						return null;
					})
					.filter((n) => n)
			};
			setInitialAccessData(tAccessData);
			setInitialTeamAccessData(structuredAccessData);
			form.setFieldsValue(structuredAccessData);
		};
		getData();
	}, [entityID, entityType, visible, form, constants]);

	const handleChange = () => {
		const formData = form.getFieldsValue(true);
		setSubmitButtonDisable(
			!(
				!isEqual(formData.groups.sort(), initialTeamAccessData.groups.sort()) ||
				!isEqual(formData.assets.sort(), initialTeamAccessData.assets.sort())
			)
		);
	};

	const handleCancel = () => {
		form.setFieldsValue(initialTeamAccessData);
		setSubmitButtonDisable(true);
		setVisible(false);
	};
	const onFinish = async () => {
		setGrantButtonLoading(true);
		const formData = form.getFieldsValue(true);
		let res = false;
		const addedAssets = formData['assets'].filter((x) => !initialTeamAccessData.assets.includes(x));
		const addedGroups = formData['groups'].filter((x) => !initialTeamAccessData.groups.includes(x));

		const removedAssets = initialTeamAccessData.assets.filter((x) => !formData['assets'].includes(x));
		const removedGroups = initialTeamAccessData.groups.filter((x) => !formData['groups'].includes(x));

		if (addedAssets.length > 0 || addedGroups.length > 0) {
			const permGrantBody = {
				...(entityType === constants.ENTITY_TYPE_ID_TEAM ? { teamIDs: [entityID] } : { personIDs: [entityID] }),
				permissionTypeID: accessControlPermissionType[1].id // Permanent Permission,
			};
			if (addedAssets.length > 0) {
				permGrantBody.deviceIDs = addedAssets;
			}
			if (addedGroups.length > 0) {
				permGrantBody.assetGroupIDs = addedGroups;
			}
			res = await superFetch.post(`/access/grantPermission`, permGrantBody);
		}

		if (removedAssets.length > 0) {
			const permissionIDs = initialAccessData
				.filter((asset) => removedAssets.includes(asset.deviceID))
				.map((asset) => asset.permissionID);
			res = await superFetch.post(`/access/revokePermissionByIDs`, { permissionIDs: permissionIDs });
		}

		if (removedGroups.length > 0) {
			const permRevokeBody = {
				teamIDs: [entityID],
				...(entityType === constants.ENTITY_TYPE_ID_TEAM ? { teamIDs: [entityID] } : { personIDs: [entityID] }),
				permissionTypeID: accessControlPermissionType[1].id, // Permanent Permission
				assetGroupIDs: removedGroups
			};
			res = await superFetch.post(`/access/revokePermission`, permRevokeBody);
		}

		if (res.statusCode === 200) {
			dispatch(loadDevices());
			setSubmitButtonDisable(true);
			setGrantButtonLoading(false);
			setVisible(false);
			notification('success', res.message);
		} else {
			setVisible(false);
			setSubmitButtonDisable(true);
			setGrantButtonLoading(false);
			notification('error', 'Error while revoking permission to Assets. Please try again.');
		}
	};

	const tagRender = (props) => {
		const { label, onClose } = props;
		const onPreventMouseDown = (event) => {
			onClose();
			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<TagWrapper
				icon={<Icon component={lockIcon} className='tagIcons' />}
				onMouseDown={onPreventMouseDown}
				closable={true}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</TagWrapper>
		);
	};

	// Get all the access control devices from the global state
	const getDropDown = (type) => {
		const options = [];
		if (type === 'assets') {
			const locks = devicesData.filter((device) =>
				constants.ACCESS_CONTROL_CATEGORIES.includes(device.defaultCategoryID)
			);
			locks.forEach((dev) => {
				options.push({
					label: dev.name,
					value: dev.uID
				});
			});
		} else if (type === 'groups') {
			const group = assetGroupsData.filter((gr) => gr.typeID === constants.ACCESS_CONTROL);
			group.forEach((g) => {
				options.push({
					label: g.name,
					value: g.groupID
				});
			});
		}

		return options;
	};

	// ! need to work on this. Currently not decided, who grants permission to the team.
	const isPermittedManageMember = useManagePermittedRoles('manageMembers', {
		orgID: [teamsData[0]?.orgID],
		teamID: [teamsData.map((item) => item.teamID)]
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedManageMember
						? "You don't have permission"
						: `Issue and/or Revoke Permissions to Devices for this ${
								entityType === constants.ENTITY_TYPE_ID_TEAM ? 'Team' : 'Person'
						  }`
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
							disabled={!isPermittedManageMember}
						>
							Grant Permissions
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={entityType === constants.ENTITY_TYPE_ID_TEAM ? `Update Permission To Team` : `Grant Permissions`}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						disabled={submitButtonDisable}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={grantButtonLoading}
					>
						Update
					</Button>
				]}
			>
				<TeamGrantWrapper>
					<Form layout='vertical' form={form} id={form} onFinish={onFinish} autoComplete='off'>
						<Form.Item name='assets' label={<span className='label'>Assets</span>}>
							<MultiSelect
								tagRender={tagRender}
								mode='multiple'
								style={{ width: '100%' }}
								onChange={(val) => handleChange(val, 'assets')}
								getPopupContainer={() => document.getElementById('assetsSelectInput')}
								placeholder='Select Locks'
								filterOption={(input, option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								options={getDropDown('assets')}
							/>
						</Form.Item>
						<SelectAssetsWrapper id='assetsSelectInput' />

						<Form.Item name='groups' label={<span className='label'>Access Groups</span>}>
							<MultiSelect
								tagRender={tagRender}
								mode='multiple'
								getPopupContainer={() => document.getElementById('assetsGroupSelectInput')}
								style={{ width: '100%' }}
								onChange={(val) => handleChange(val, 'groups')}
								placeholder='Select Groups'
								filterOption={(input, option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								options={getDropDown('groups')}
							/>
						</Form.Item>
						<SelectAssetsWrapper id='assetsGroupSelectInput' />
					</Form>
				</TeamGrantWrapper>
			</Modal>
		</div>
	);
};

export default ManagePermissionModal;
