import { ComponentLabel, Loading, RevokePersonPermissionModal, SimpleTable } from '@Z/components/atoms';
import { ExpandablePermissionTable } from '@Z/components/molecules/PersonTeamAccessControlTable/components/ExpandablePermissionTable';
import AccessControlTableWrapper from '@Z/components/molecules/PersonTeamAccessControlTable/personTeamAccessControlTable.styles';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const RenderPermissionByDevice = ({ deviceID }) => {
	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const { devicesData } = useSelector((state) => state.devices);
	const {
		remoteConfig: { accessControlPermissionType }
	} = useSelector((state) => state.remoteConfig);

	const [expandedRow, setExpandedRow] = useState(0);
	const [permittedDevices, setPermittedDevices] = useState([]);
	const [permissionStateUpdated, setPermissionStateUpdated] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const permittedDevices = await getAccessData({ deviceID: deviceID });
			setLoading(false);
			const devices = deviceGrouper(permittedDevices);
			setPermittedDevices(devices);
			setPermissionStateUpdated(false);
		};
		getData();
	}, [deviceID, permissionStateUpdated, devicesData]);

	//!!!WE NEED CHECK HOW WE WILL IMPLEMENT THE PERMISSION HERE

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			flex: 1,
			ellipsis: true,
			render: (text, record) => (
				<NameWithLink type='person' dataID={Number(record.uID)}>
					<ComponentLabel
						style={{
							fontSize: '12px',
							fontWeight: '500',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							paddingLeft: '10px'
						}}
						text={text}
					/>
				</NameWithLink>
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => record['displayName'] === value
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Derived Permissions',
			key: 'Permission',
			align: 'center',
			flex: 1,
			render: (_, record) => <span>{record.permissions.length}</span>
		},
		{
			title: 'Permission',
			dataIndex: ['permissions', '0', 'permissionType'],
			key: 'permissionType',
			ellipsis: true,
			flex: 1,
			align: 'center',
			filters: [
				{ text: accessControlPermissionType[1].title, value: accessControlPermissionType[1].id },
				{ text: accessControlPermissionType[2].title, value: accessControlPermissionType[2].id },
				{ text: accessControlPermissionType[3].title, value: accessControlPermissionType[3].id }
			],
			onFilter: (value, record) => record.permissions[0].permissionTypeID === value,
			render: (record) => {
				return <p title={record.shortDescription || 'N/A'}>{record.shortDescription || 'N/A'}</p>;
			}
		},
		{
			title: 'Actions',
			dataIndex: ['permissions', '0'],
			flex: 1,
			align: 'center',
			key: 'revoke',
			render: (record) => {
				return (
					<RevokePersonPermissionModal
						setPermissionStateUpdate={setPermissionStateUpdated}
						data={{
							entity: { ...record.device, permissionTypeID: record.permissionTypeID },
							person: record.person
						}}
						allPermissions
					/>
				);
			},
			ellipsis: true
		}
	];

	return (
		<AccessControlTableWrapper>
			{loading ? (
				<Loading />
			) : (
				<SimpleTable
					columns={columns}
					pageSize={7}
					dataSource={permittedDevices}
					rowKey='uID'
					expandable={{
						expandedRowRender: (a) => (
							<ExpandablePermissionTable
								permData={a.permissions}
								person={a.permissions[0].person}
								setPermissionStateUpdated={setPermissionStateUpdated}
							/>
						),
						expandedRowKeys: expandedRow,
						onExpand: (expanded, record) => {
							const keys = [];
							if (expanded) {
								keys.push(record.uID);
							}
							setExpandedRow(keys);
						}
					}}
				/>
			)}
		</AccessControlTableWrapper>
	);
};

const deviceGrouper = (permittedDevices) => {
	const groupedDevice = permittedDevices.reduce((result, current) => {
		const { person } = current;
		const key = person.personID;
		result[key] = result[key] || [];
		result[key].push(current);
		return result;
	}, {});

	// Convert to array
	const personPermissions = Object.keys(groupedDevice).map((key) => ({
		uID: key,
		name: groupedDevice[key][0].person.displayName,
		permissions: groupedDevice[key]
	}));

	const finalPermission = personPermissions;

	return finalPermission;
};

export default RenderPermissionByDevice;
