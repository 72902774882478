import { CompleteProfileModal, Loading, PersonProfileCard } from '@Z/components/atoms';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Button } from '@iso/components/uielements';
import { ReactComponent as EditSvg } from '@iso/assets/images/icon/edit.svg';
import { useSelector } from 'react-redux';

const RenderHeaderProfileSetting = () => {
	const peopleData = useSelector((state) => state.people.peopleData);
	const { personID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const [personData, setPersonData] = useState(null);
	const [editVisible, setEditVisible] = useState(false);

	useEffect(() => {
		const findPersonData = () => {
			const person = peopleData.find((p) => p.personID === personID);
			if (person) {
				setPersonData(person);
			}
		};
		findPersonData();
	}, [personID, peopleData, setPersonData]);

	return personData ? (
		<Row className='containerHeader'>
			<Col className='containerProfile'>
				<PersonProfileCard person={personData} highlightOnHover={false} />
				<CompleteProfileModal visible={editVisible} setVisible={setEditVisible} data={personData} edit={true}>
					<Button
						onClick={() => setEditVisible(true)}
						type='secondary'
						size='small'
						className='editButtonProfile'
					>
						<EditSvg /> Edit Profile
					</Button>
				</CompleteProfileModal>
			</Col>
		</Row>
	) : (
		<Loading />
	);
};

export default RenderHeaderProfileSetting;
