import { Col } from 'antd';
import React from 'react';
import LabelWrapper from '../LabelWrapper';
import StylesRenderButtonCardWrapper from './style';
import Icon from '@ant-design/icons';

/**
 * Renders a button card component with customizable title, subtitle, icon, and other properties.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the button card.
 * @param {string} props.subtitle - The subtitle of the button card.
 * @param {string} props.secondTitle - The second title of the button card.
 * @param {string} props.secondSubTitle - The second subtitle of the button card.
 * @param {string} props.icon - The name of the icon to be displayed.
 * @param {number} [props.iconSize=25] - The size of the icon.
 * @param {string} [props.iconName='email'] - The default icon name.
 * @param {Function} props.getIconByTitle - A function to get the icon based on the title.
 * @param {React.Component} props.customIcon - A custom icon component.
 * @param {number} props.maxWidth - The maximum width of the button card.
 * @param {number} props.padding - The padding of the button card.
 * @param {boolean} props.border - Whether to display a border around the button card.
 * @returns {JSX.Element} The rendered button card component.
 */
const RenderButtonCard = ({
	header,
	title,
	subtitle,
	secondTitle,
	secondSubTitle,
	icon,
	iconSize = 25,
	iconName = 'email',
	getIconByTitle,
	customIcon,
	maxWidth,
	padding,
	border,
	...props
}) => {
	const getIconBasedTitle = () => {
		switch (title) {
			case 'Mobile':
				return 'phone';
			case 'Home':
				return 'home';
			case 'Work/Office':
				return 'home_work';
			case 'Email':
				return 'mail';
			case 'Address':
				return 'location_on';
			default:
				return iconName; // Default to iconName if no match
		}
	};

	return (
		<StylesRenderButtonCardWrapper
			border={border}
			icon={icon}
			maxWidth={maxWidth}
			padding={padding}
			customIcon={customIcon}
			{...props}
		>
			{header && (
				<div className='header'>
					<LabelWrapper
						text={header}
						fontWeight={700}
						fontSize={15}
						color='secondary'
						tonality={0}
						typeLabel='primary'
						style={{ lineHeight: 1.5 }}
					/>
				</div>
			)}
			<div className='content'>
				{icon && (
					<Col>
						<i className='material-icons-outlined' style={{ fontSize: iconSize, marginTop: 5 }}>
							{getIconByTitle ? getIconBasedTitle() : iconName}
						</i>
					</Col>
				)}
				{customIcon && (
					<Col className='customIcon'>
						<Icon component={customIcon} className='tagIcons' />
					</Col>
				)}
				{(title || subtitle) && (
					<Col className='row'>
						<LabelWrapper
							text={title}
							fontWeight={500}
							fontSize={13}
							color='text'
							tonality={7}
							typeLabel='primary'
							style={{ lineHeight: 1.1 }}
						/>
						<LabelWrapper
							text={subtitle}
							fontWeight={700}
							color='secondary'
							tonality={0}
							fontSize={15}
							typeLabel='secondary'
						/>
					</Col>
				)}
				{secondTitle && secondSubTitle && (
					<Col className='secondRow'>
						<LabelWrapper
							text={secondTitle}
							fontWeight={500}
							fontSize={12.5}
							color='text'
							tonality={7}
							typeLabel='primary'
							style={{ lineHeight: 1.1 }}
						/>
						<LabelWrapper
							text={secondSubTitle}
							fontWeight={700}
							color='secondary'
							tonality={0}
							fontSize={15}
							typeLabel='secondary'
						/>
					</Col>
				)}
			</div>
		</StylesRenderButtonCardWrapper>
	);
};

export default RenderButtonCard;
