import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Tooltip from '@iso/components/uielements/tooltip';
import superFetch from '@iso/lib/helpers/superFetch';
import bookingActions from '@iso/redux/booking/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const { loadBooking } = bookingActions;

const DeleteBookingModal = ({ viewModal, setViewModal, booking = {}, children }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		await superFetch
			.delete(`/booking/${booking.bookingID}`)
			.then((res) => {
				if (res.statusCode === 200) {
					notification('success', 'Booking Deleted.');
					setViewModal(false);
					dispatch(loadBooking());
				}
				setLoading(false);
				return res;
			})
			.catch(() => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	return (
		<React.Fragment>
			{children ? (
				children
			) : (
				<Tooltip>
					<>
						<Button
							size={'small'}
							onClick={() => {
								setViewModal(true);
							}}
							disabled={false}
							style={{ width: '100%' }}
							danger
						>
							Delete Booking
						</Button>
					</>
				</Tooltip>
			)}
			<Modal
				title={`Delete a Booking`}
				danger
				visible={viewModal}
				onCancel={() => setViewModal(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setViewModal(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{booking.name}'</span> ?
				</p>
			</Modal>
		</React.Fragment>
	);
};

export default DeleteBookingModal;
