import styled from 'styled-components';

const TagStatusWrapper = styled.div`
	.containerTagStatus {
		display: flex;
		align-items: center;
		border: 0;
		font-weight: 600;
		padding: 2px 10px;
		font-size: 13px;
		border-radius: 5px;
		max-width: fit-content;
		.tagStatus {
			width: 7px;
			height: 7px;
			border-radius: 100%;
			margin-right: 10px;
		}
	}
`;

export { TagStatusWrapper };
