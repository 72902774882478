/**
 * @author Kishan Virani
 * @email Kishan@Zezamii.com
 * @create date 25-07-2021  09:48:37
 * @modify date 09-12-2021  15:37:32
 * @desc [description]
 */

import styled from 'styled-components';
import { palette } from 'styled-theme';
import { MoreOutlined } from '@ant-design/icons';

const EntityInformationTabsWrapper = styled.div`
	width: 100%;
	background-color: ${palette('whitescale', 0)};

	.ant-tabs-nav:before {
		border-bottom-color: ${palette('primary', 0)} !important;
		z-index: 1;
	}
	.ant-tabs-nav-wrap {
		justify-content: center;
		align-items: center;
	}
	.ant-tabs-nav-list {
		width: 100%;

		.ant-tabs-tab {
			padding-left: 10px;
			padding-right: 10px;
			border-top-left-radius: 6px !important;
			border-top-right-radius: 6px !important;

			&:hover {
				border-color: ${palette('primary', 0)};
				color: ${palette('primary', 0)};
			}
			&:active {
				border-color: ${palette('primary', 0)};
				color: ${palette('primary', 0)};
			}
		}
		.ant-tabs-tab-active {
			border-color: ${palette('primary', 0)} !important;
			background-color: ${palette('whitescale', 0)} !important;

			.ant-tabs-tab-btn {
				color: ${palette('primary', 0)} !important;
			}
		}
		.ant-tabs-ink-bar {
			background-color: ${palette('primary', 0)};
			color: ${palette('primary', 0)};
		}
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;
export { EntityInformationTabsWrapper, MoreOutlinedIcon };
