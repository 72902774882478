import styled from 'styled-components';

const AddCategoryFormWrapper = styled.div`
	margin: 0 50px;
	&.ant-space-item {
		width: 100%;
	}
	.title {
		margin-bottom: 18px;

		.ant-divider-inner-text {
			font-weight: 500;
			padding-left: 0;
		}
		&.ant-divider-horizontal.ant-divider-with-text-left::before {
			width: 0;
		}
		&.ant-divider-horizontal.ant-divider-with-text-left::after {
			width: 100%;
		}
	}
`;

export default AddCategoryFormWrapper;
