import styled from 'styled-components';
import { palette } from 'styled-theme';

const UploadMapWrapper = styled.div`
	position: absolute;
	z-index: 500;
	top: 10px;
	right: 10px;

	.uploadButton {
		border-color: ${palette('primary', 5)};
	}
`;

const UploadModalWrapper = styled.div`
	.formContainer {
		border-right: 1px solid ${palette('border', 0)};

		.ant-form-item-label {
			padding-bottom: 4px;
		}
	}
	.uploadMapButtonContainer {
		padding-left: 16px;
		display: flex;
		align-items: center;
		justify-content: center;

		.warningContainer {
			display: flex;
			flex-direction: column;
			align-items: center;

			.WarningText {
				font-size: 13px;
				margin-bottom: 8px;

				.ant-checkbox-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
`;
export { UploadModalWrapper };
export default UploadMapWrapper;
