import styled from 'styled-components';
import { palette } from 'styled-theme';
const DeviceCustomFieldsWrapper = styled.div`
	border-radius: 4px;
	margin: 10px;

	.customInfo {
		display: flex;
		justify-content: space-evenly;
	}

	.componentTitle {
		display: flex;
		align-items: center;
	}
	.infoLabel {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
		margin-right: 5px;
	}
	.ant-descriptions-item-label {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: block !important;
	}
	.propValueContainer {
		display: flex;
		flex-grow: 100;
		max-width: 200px;
	}
	.infoProp {
		font-size: 12px;
		font-weight: 600;
		color: ${palette('secondary', 3)};
		overflow: hidden;
		text-overflow: ellipsis;
		width: inherit;
		white-space: nowrap;
	}
	.editable {
		width: 100% !important;
	}
`;
export default DeviceCustomFieldsWrapper;

const DividerWrapper = styled.div`
	.ant-divider-inner-text {
		font-weight: 500;
		align-items: center;
	}
	margin: 0px;
`;

const DescriptionsWrapper = styled.div`
	.ant-descriptions-item-content {
		display: flex !important;
		align-items: center !important;
		margin-right: 20px;
	}
	.ant-descriptions-item-label {
		display: flex !important;
		align-items: center !important;
	}
`;
export { DividerWrapper, DescriptionsWrapper };
