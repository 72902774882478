import React, { useState, useEffect } from 'react';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { SimpleTable, SearchBar } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import Button from '@iso/components/uielements/button';
import { DownOutlined } from '@ant-design/icons';
import Tag from '@iso/components/uielements/tag';
import { uniqBy } from 'lodash';
import { CompactPeopleTableWrapper } from './compactPeopleTableInformation.styles';
import theme from '@iso/config/theme/default';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import ComponentButton from '@Z/components/atoms/ComponentButton';
import { LabelWrapper } from '@Z/components/atoms';

const { palette } = theme;
const CompactPeopleTable = ({ peopleData }) => {
	const [filteredData, setFilteredData] = useState([]);
	const { sitesData } = useSelector((state) => state.sites);
	const [cleanPeopleData, setCleanPeopleData] = useState([]);

	useEffect(() => {
		setFilteredData(peopleData.filter((person) => !person.deletedDate));
		setCleanPeopleData(peopleData.filter((person) => !person.deletedDate));
	}, [peopleData]);

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object.getDisplayName().displayName.toLowerCase().includes(query.toLowerCase()) ||
				object.lastName?.toLowerCase().includes(query.toLowerCase()) ||
				object.firstName?.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredData(searchResult);
	};

	const ButtonWithLink = withHyperLink(() => <ComponentButton text={'View'} />);

	// HOC
	const BaseLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const SiteWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const NameWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const TeamWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const actionMenu = (record) => (
		<DropdownMenu>
			<NameWithLink type='person' dataID={record.personID}>
				<MenuItem style={{ fontSize: '12px' }} key='1'>
					View Person
				</MenuItem>
			</NameWithLink>
		</DropdownMenu>
	);

	const columns = [
		{
			title: 'Person',
			dataIndex: 'displayName',
			key: 'displayName',
			width: 20,
			ellipsis: true,
			render: (text, record) => {
				return (
					<NameWithLink type='person' dataID={record.personID} title={record.getDisplayName().title}>
						{record.getDisplayName().displayName}
					</NameWithLink>
				);
			}
		},
		{
			title: 'Site',
			dataIndex: 'siteID',
			key: 'site',
			width: 20,
			ellipsis: true,
			render: (text, record) => {
				const siteInfo = sitesData.find((site) => site.siteID === text);
				return (
					<SiteWithLink type='site' dataID={record.siteID} key={record.siteID}>
						{siteInfo && siteInfo.name}
					</SiteWithLink>
				);
			},
			filters: Array.from(new Set(peopleData.map((person) => person.siteID)))
				.map((siteID) => {
					const siteInfo = sitesData.find((site) => site.siteID === siteID);
					return siteInfo ? { text: siteInfo.name, value: siteID } : null;
				})
				.filter((item) => item !== null),
			onFilter: (value, record) => record.siteID === value
		},
		{
			title: 'Team',
			dataIndex: 'team',
			key: 'team',
			width: 55,
			ellipsis: true,
			render: (text) => {
				return (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							flexDirection: 'row'
						}}
					>
						{text.map((team) => (
							<TeamWithLink type='team' dataID={team.teamID} key={team.teamID}>
								<Tag
									size='small'
									color={palette.tagColor[team.teamID % 11]}
									style={{
										lineHeight: '13px',
										fontSize: '11px',
										marginTop: '4px',
										marginBottom: '4px'
									}}
								>
									{team.name}
								</Tag>
							</TeamWithLink>
						))}
					</div>
				);
			},

			filters: uniqBy(filteredData.map((person) => person.team).flat(), 'teamID').map((team) => ({
				text: team.name,
				value: team.teamID
			})),
			onFilter: (value, record) => record.team.some((t) => t.teamID === value)
		},

		{
			key: 'View',
			render: (record) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ButtonWithLink type='person' dataID={record.personID} />
					<Dropdown className='actionButton' overlay={actionMenu(record)} trigger={['click']}>
						<Button size='small'>
							Actions <DownOutlined />
						</Button>
					</Dropdown>
				</div>
			),
			ellipsis: true,
			width: 15
		}
	];

	return (
		<IsoWidgetBox style={{ overflow: 'hidden', width: '100%' }}>
			<Row gutter={16} justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<NameWithLink type={'people'}>
						<LabelWrapper
							typeLabel={'primary'}
							text={'People'}
							color={'secondary'}
							tonality={0}
							fontSize={20}
							fontWeight={500}
						/>
					</NameWithLink>
					<SearchBar
						style={{ marginBottom: '10px', marginTop: '4px' }}
						placeholder='Search Person Name'
						enterButton='Search'
						loading={false}
						onChange={(query) => {
							handleSearch(query.target.value, cleanPeopleData);
						}}
						onSearch={(query) => {
							handleSearch(query, cleanPeopleData);
						}}
					/>
					<Row gutter={24} justify='start'>
						<Col lg={24} md={24} sm={24} xs={24}>
							<CompactPeopleTableWrapper>
								<div className='table'>
									<SimpleTable columns={columns} pageSize={7} dataSource={filteredData} />
								</div>
							</CompactPeopleTableWrapper>
						</Col>
					</Row>
				</Col>
			</Row>
		</IsoWidgetBox>
	);
};

export default CompactPeopleTable;
