import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import { IndividualAssetGroupModal } from '@Z/components/molecules';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * WithHyperLink is higher order component to render the link on the entity names.
 * it will convert existing component into clickable component with the url of the Zezamii's major entity.
 * - Use linkGenerator() function to provide the map of the entity.
 */

const WithHyperLink = (WrappedComponent) => {
	const history = useHistory();

	// Higher function component
	const EntityLinkHOC = ({ ...props }) => {
		const [deviceModalVisible, setDeviceModalVisible] = useState(false);
		const [groupModalVisible, setGroupModalVisible] = useState(false);

		// If the Link type is device then it will open the modal instead of redirecting to the page.
		if (props.type === 'device') {
			return (
				<IndividualDeviceInformationModal
					visible={deviceModalVisible}
					setVisible={setDeviceModalVisible}
					data={{
						deviceID: props.dataID,
						...props.suppData
					}}
				>
					<div style={{ display: 'grid', cursor: 'pointer' }} onClick={() => setDeviceModalVisible(true)}>
						<WrappedComponent {...props} />
					</div>
				</IndividualDeviceInformationModal>
			);
		} else if (props.type === 'assetGroup') {
			return (
				<IndividualAssetGroupModal
					data={{
						groupID: props.dataID,
						...props.suppData
					}}
					visible={groupModalVisible}
					setVisible={setGroupModalVisible}
				>
					<div style={{ display: 'grid', cursor: 'pointer' }} onClick={() => setGroupModalVisible(true)}>
						<WrappedComponent {...props} />
					</div>
				</IndividualAssetGroupModal>
			);
		} else {
			return (
				<div
					style={{ display: 'grid', cursor: 'pointer' }}
					onClick={() => {
						props.type &&
							history.push({
								pathname: linkGenerator()[props.type].pathname,
								state: linkGenerator(props.dataID)[props.type].state
							});
					}}
					{...props}
				>
					<WrappedComponent {...props} />
				</div>
			);
		}
	};
	EntityLinkHOC.prototype = {
		dataID: PropTypes.number,
		type: PropTypes.string,
		suppData: PropTypes.object
	};
	return EntityLinkHOC;
};
export default WithHyperLink;
const linkGenerator = (dataID) => ({
	workspace: {
		pathname: `/dashboard/organisations/organisation`,
		state: { orgID: dataID }
	},
	site: {
		pathname: `/dashboard/sites/site`,
		state: { siteID: dataID }
	},
	floor: {
		pathname: `/dashboard/floors/floor`,
		state: { floorID: dataID }
	},
	person: {
		pathname: `/dashboard/people/person`,
		state: { personID: dataID }
	},
	team: {
		pathname: `/dashboard/teams/team`,
		state: { teamID: dataID }
	},
	home: {
		pathname: `/dashboard`
	},
	sites: {
		pathname: `/dashboard/sites`
	},
	floors: {
		pathname: `/dashboard/floors`
	},
	people: {
		pathname: `/dashboard/people`
	},
	teams: {
		pathname: `/dashboard/teams`
	},
	deviceCategory: {
		pathname: `/dashboard/devices`,
		state: { categoryID: dataID }
	},
	settings: {
		pathname: `/dashboard/settings`
	}
});

export { linkGenerator };
