import styled from 'styled-components';
import { font } from 'styled-theme';

const DeviceTimelineWrapper = styled.div`
	display: flex;
	width: 100%;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 700px;

	.timeItemContainer {
		margin-bottom: 10px !important;
	}
	.containerTagStatus {
		text-transform: capitalize !important;
		font-size: 11px !important;
	}

	.leftContainer {
		display: flex;
		flex-direction: column;
		span {
			font-style: normal;
			letter-spacing: 0.15px;
			font-family: ${font('secondary', 0)};
		}

		span:last-child {
			margin-top: 10px;
		}
	}
	.rightContainer {
		display: flex;
		margin-top: 10px;
		width: 250px;
		word-wrap: break-word;

		span {
			font-family: ${font('secondary', 0)};
			white-space: normal !important;
		}
	}
	.cstmTimeline {
		overflow: auto;
	}
`;

export default DeviceTimelineWrapper;
