import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
const MarkerPopUpCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.values {
		display: flex;
		justify-content: center;
	}
	.customIcon {
		display: inline-block;
		margin-left: 5px;
		font-size: 24px;
	}
	.imageIcon {
		width: 15px;
		display: inline-block;
		margin-left: 5px;
		font-size: 14px;
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;
export { MoreOutlinedIcon };
export default MarkerPopUpCardWrapper;
