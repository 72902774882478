import styled from 'styled-components';

const UserStatusAlertWrapper = styled.div`
	.alertContainer {
		margin: 0 10px 10px;
	}

	.alertMessage {
		padding: 10px;
		text-align: left;
		width: 100%;
		p {
			font-size: 13px;
			margin-top: 5px;
			min-width: 360px;
		}

		a {
			font-size: 12px;
			white-space: nowrap;
			font-weight: 600;
			display: inline-flex;
			align-items: center;

			i {
				font-size: 14px;
				margin-left: 2px; /* Adjust as needed */
			}
		}
	}

	.alertTitle {
		font-size: 16px;
		font-weight: 600;
	}

	.alertIcon {
		font-size: 24px;
	}
`;
export default UserStatusAlertWrapper;
