import React, { useState, useEffect } from 'react';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { SearchBar, Loading, TeamCard } from '@Z/components/atoms';
import { useSelector } from 'react-redux';
import Row from '@iso/ui/Antd/Grid/Row';
import Col from '@iso/ui/Antd/Grid/Col';
import basicStyle from '@iso/assets/styles/constants';
import { Empty } from 'antd';

const AllTeamsWithComponent = () => {
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const [filteredTeams, setFilteredTeams] = useState([]);
	const { teamsData, pending } = useSelector((state) => state.teams);

	useEffect(() => {
		if (!pending) {
			setFilteredTeams(teamsData);
		}
	}, [pending, teamsData]);

	const filter = (array, searchTerm, columns) => {
		const searchResult = array.filter((row) => {
			return columns.some((column) => {
				return String(row[column]).toLowerCase().includes(searchTerm.toLowerCase());
			});
		});
		setFilteredTeams(searchResult);
	};

	return pending ? (
		<Row style={rowStyle}>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<div style={loadingStyle}>
					<Loading />
				</div>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox>
			<SearchBar
				style={{ marginBottom: '15px' }}
				placeholder='Search Team Name'
				enterButton='Search'
				onChange={(event) => {
					filter(teamsData, event.target.value, ['name']);
				}}
				onSearch={(query) => {
					filter(teamsData, query, ['name']);
				}}
			/>
			{filteredTeams && filteredTeams.length > 0 ? (
				<Row gutter={[16, 16]} style={{ marginLeft: '10px', marginRight: '10px' }}>
					{filteredTeams.map((team, key) => (
						<Col lg={12} md={24} sm={24} xs={24} key={key}>
							<TeamCard team={team} />
						</Col>
					))}
				</Row>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Team Found</span>} />
			)}
		</IsoWidgetBox>
	);
};

export default AllTeamsWithComponent;
