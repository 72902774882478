import styled from 'styled-components';

const MetaContainer = styled.div`
	position: absolute;
	right: 10px;
	bottom: 0px;

	.metaContainer {
		font-size: 9px;
		font-weight: 400;
		opacity: 0.6;
		justify-content: flex-end;

		.metaField {
			width: max-content;
			font-weight: 500;
			.metaLabel {
				font-weight: 400;
			}
		}

		.dateContainer {
			row-gap: 1px !important;

			.ant-space-item {
				justify-content: flex-end;
			}
		}
	}
`;
export default MetaContainer;
