import { DownOutlined } from '@ant-design/icons';
import { notification } from '@iso/components';
import Alerts from '@iso/components/Feedback/Alert';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import suggestions from '@iso/config/suggestions.config';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import superFetch from '@iso/lib/helpers/superFetch';
import inventoryActions from '@iso/redux/inventory/actions';
import { LeafletMap, Loading, Modal } from '@Z/components/atoms';
import { Empty, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceLocationWrapper } from './styles';

const { EditDeviceLocationTips } = suggestions;
const { loadInventoryGateways, loadInventoryLocks } = inventoryActions;

const ModifyLocationModal = ({ editVisible, setEditVisible, children, data, getControlLocksAndGateways }) => {
	const dispatch = useDispatch();
	const MAP_HEIGHT = 460;
	const MAP_WIDTH = 800;

	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	// basic information for this modal.
	const { orgID, asset } = data;

	const [loading, setLoading] = useState(true);
	const [device, setDevice] = React.useState({});
	const [floors, setFloors] = React.useState([]);

	const [selectedFloor, setSelectedFloor] = useState({});

	const [mapUrl, setMapUrl] = useState('');
	const [markersData, setMarkersData] = useState([]);
	const [updateLocationLoading, setUpdateLocationLoading] = useState(false);
	const [updateDisable, setUpdateDisable] = useState(true);
	const [marker, setMarker] = useState({});

	const loadInventory = (categoryID) => {
		if (categoryID === constants.SMART_LOCKS) {
			dispatch(loadInventoryLocks());
		} else if (categoryID === constants.SMART_GATEWAYS) {
			dispatch(loadInventoryGateways());
		} else {
			getControlLocksAndGateways();
		}
	};

	// Get Device informations.
	const loadData = useCallback(async () => {
		setLoading(true);
		await superFetch.get('/floors', {}, {}, orgID).then((res) => {
			if (res.data && res.statusCode === 200) {
				const result = res.data.map((row) => ({
					floorID: row.floorID,
					name: row.name,
					mapID: row.mapID ? row.mapID : 'N/A',
					mapName: row.mapName ? row.mapName : 'N/A',
					dimensionX: row.dimensionX ? row.dimensionX : 'N/A',
					dimensionY: row.dimensionY ? row.dimensionY : 'N/A',
					note: row.note ? row.note : 'N/A',
					url: row.url ? row.url : 'N/A'
				}));
				setFloors(result);
			} else {
				notification('error', 'Error Loading Floors. Please contact Developers.');
			}
		});
		await superFetch.get('/devices/' + asset.assetID, {}, {}, orgID).then((res) => {
			if (res.statusCode === 200 && res?.data?.length > 0) {
				setDevice(res.data[0]);
			} else {
				notification('error', 'Error Loading Device Information. Please contact Developers.');
			}
		});
		setLoading(false);
	}, [orgID, asset]);

	// Get all floors from the organisation
	useEffect(() => {
		if (editVisible) {
			loadData();
		}
		return () => {
			setFloors([]);
			setDevice({});
		};
	}, [editVisible, loadData]);

	// Initially set floors information from the store.
	// Get Map Path / URL
	useEffect(() => {
		// Setting default selected floor
		if (device?.location && device.location.length > 0 && editVisible && floors?.length > 0) {
			const deviceLocation = device.location[0];
			const floor = floors.find((floor) => floor.floorID === deviceLocation.floorID);
			if (floor) {
				setSelectedFloor(floor);
				getDownloadURL(floor.url).then((url) => setMapUrl(url));
				setMarkersData([
					{ x: deviceLocation.xAxis, y: deviceLocation.yAxis, defaultCategoryID: device?.defaultCategoryID }
				]);
			}
		} else {
			setSelectedFloor(floors[0] || {});
			setMarkersData([]);
		}
	}, [editVisible, device, floors]);

	// changing the map based on the selected floor
	useEffect(() => {
		if (device?.location && Object.keys(selectedFloor).length > 0) {
			// if the device has a location, showing that location on the map.
			const selectedLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (selectedLocation) {
				setMarkersData([
					{
						x: selectedLocation.xAxis,
						y: selectedLocation.yAxis,
						defaultCategoryID: device?.defaultCategoryID
					}
				]);
			} else {
				setMarkersData([]);
			}

			// Setting the map url based on the selected floor.
			if (selectedFloor.url && selectedFloor.url !== 'N/A') {
				getDownloadURL(selectedFloor.url)
					.then((url) => setMapUrl(url))
					.catch(() => setMapUrl(''));
			} else {
				setMapUrl('');
			}
		}
	}, [selectedFloor, device]);

	// changing marker location based on the selected floor
	useEffect(() => {
		if (Object.keys(selectedFloor).length > 0 && Object.keys(device).length > 0) {
			const selectedLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (selectedLocation) {
				setMarkersData([
					{
						x: selectedLocation.xAxis,
						y: selectedLocation.yAxis,
						defaultCategoryID: device?.defaultCategoryID
					}
				]);
			}
		}
	}, [device, selectedFloor]);

	// updating the disable state of the update button
	useEffect(() => {
		// properly set up  Disable based on the form values.
		if (editVisible && Object.keys(device).length > 0) {
			const deviceLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (deviceLocation && marker.lng === deviceLocation.xAxis && marker.lat === deviceLocation.yAxis) {
				setUpdateDisable(true);
			} else if (!marker.lat && !marker.lng) {
				setUpdateDisable(true);
			} else {
				setUpdateDisable(false);
			}
		}
	}, [marker, selectedFloor, editVisible, device]);

	const renderFloors = () => (
		<DropdownMenu>
			{floors.map((floor) => {
				return (
					<MenuItem key={floor.id} onClick={() => setSelectedFloor(floor)}>
						{floor.name}
					</MenuItem>
				);
			})}
		</DropdownMenu>
	);

	const handleCancel = () => {
		setEditVisible(false);
		setUpdateLocationLoading(false);
	};

	const onFinish = () => {
		setUpdateLocationLoading(true);
		const locationBody = {};

		// prepare location body
		locationBody.floorID = selectedFloor.floorID;
		locationBody.mapID = selectedFloor.mapID;

		if (
			marker.lat > MAP_HEIGHT ||
			marker.lat < 0 ||
			marker.lng > MAP_WIDTH ||
			marker.lng < 0 ||
			!marker.lng ||
			!marker.lat ||
			selectedFloor.mapID === 'N/A'
		) {
			setUpdateLocationLoading(false);
			return notification('error', 'Please place marker within the map');
		}
		locationBody.xAxis = marker.lng;
		locationBody.yAxis = marker.lat;
		superFetch.patch(`/devices/${device.uID}`, locationBody).then((res) => {
			if (res.statusCode === 200) {
				notification('success', 'Device Location Updated.');
				loadInventory(device?.defaultCategoryID);
			} else {
				notification('error', 'Device Location Update Failed.');
			}
			handleCancel();
		});
	};

	return (
		<React.Fragment>
			{children}
			<Modal
				title='Edit Device Location'
				open={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button disabled={updateDisable} onClick={onFinish} type='primary' loading={updateLocationLoading}>
						Update
					</Button>
				]}
			>
				<DeviceLocationWrapper>
					<div className='selectFloorContainer'>
						<div className='floorLabel'>Select Floor:</div>

						<Dropdown overlay={() => renderFloors()} trigger={['click']}>
							<Button size='medium'>
								{selectedFloor.name}
								<DownOutlined />
							</Button>
						</Dropdown>
					</div>
					<div className='locationInfoContainer'>
						<label className='infoActivityLabel'>Location:</label>
						<Tooltip title='Click and Drag the marker to the desired location.' placement='right'>
							<i className='material-icons-outlined customIcon'>help</i>
						</Tooltip>
					</div>
				</DeviceLocationWrapper>
				{loading ? (
					<Loading />
				) : (
					<React.Fragment>
						<div
							style={{
								height: 300,
								position: 'relative',
								marginBottom: '20px'
							}}
						>
							{mapUrl !== '' ? (
								<LeafletMap
									mapHeight={MAP_HEIGHT}
									mapWidth={MAP_WIDTH}
									scrollWheelZoom={true}
									url={mapUrl} // map url if selected floor
									setMarker={setMarker}
									editMarkerLocation={markersData}
									draggableMarkerLocation={markersData.length > 0}
									drawSingleMarkerEnabled={markersData.length < 1}
									iconType={device.categoryName}
								/>
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										floors.length === 0 ? (
											<span>
												Please create a floor on the floors page and upload a floor plan
											</span>
										) : (
											<span>Please upload a floor plan from the floors page</span>
										)
									}
								/>
							)}
						</div>
						<Alerts
							message='Editing Location Tips'
							description={<EditDeviceLocationTips />}
							type='info'
							showIcon
							closable
						/>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default ModifyLocationModal;
