/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:09:36
 * @modify date 24-04-2020  10:09:36
 * @desc [description]
 */

import { Input } from 'antd';
import { InputSearchWrapper } from './input.style';
import WithDirection from '@iso/lib/helpers/rtl';
const { Search } = Input;

const WDInputSearch = InputSearchWrapper(Search);
const SearchBar = WithDirection(WDInputSearch);

export default SearchBar;
