import styled from 'styled-components';
import { palette } from 'styled-theme';

const DeviceCardActionTuple = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;

	.delete {
		.icon {
			color: #ffffff !important;
		}
	}
	.icon {
		font-size: 20px;
		color: ${palette('text', 4)};

		&:hover {
			color: ${palette('secondary', 0)};
		}
	}
	.iconCenter {
		justify-content: center;
	}

	.iconMargin {
		margin-right: 10px;
		display: flex;
		align-items: center;
	}
`;

const DropdownSpaceWrapper = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1d3557;
	font-weight: 500;
	padding: 10px 0;

	&:hover {
		outline: #457b9d 2px solid;
	}
`;
export { DeviceCardActionTuple, DropdownSpaceWrapper as SpaceWrapper };
