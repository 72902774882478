import styled from 'styled-components';
import { borderRadius, boxShadow } from '@iso/lib/helpers/style_utils';

const WidgetWrapperStyle = styled.div`
	margin: 7px 7px;
	width: ${(props) => props.width}px;
	margin-top: ${(props) => props.gutterTop}px;
	margin-right: ${(props) => props.gutterRight}px;
	margin-bottom: ${(props) => props.gutterBottom}px;
	margin-left: ${(props) => props.gutterLeft}px;
	padding: ${(props) => props.padding};
	background-color: ${(props) => props.bgColor}px;
	@media only screen and (max-width: 767) {
		margin-right: 0 !important;
	}
`;

const WidgetBoxStyle = styled.div`
	width: 100%;
	height: ${(props) => (props.height ? `${props.height}px` : '100%')};
	padding: ${(props) => (props.padding ? props.padding : '10px')};
	background-color: #ffffff;
	${borderRadius()};
	${boxShadow('0px 0px 8px -1px rgba(0,0,0,0.18)')};

	canvas {
		width: 100% !important;
		height: 100% !important;
	}
`;
const getAlignContent = (align = 'flex-start') => {
	if (align === 'start') return 'flex-start';
	if (align === 'end') return 'flex-end';
	return align;
};
const WidgetColumnStyle = styled.div`
	align-content: ${(props) => getAlignContent(props.align)};
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin-top: ${(props) => props.gutterTop}px;
	margin-right: ${(props) => props.gutterRight}px;
	margin-bottom: ${(props) => props.gutterBottom}px;
	margin-left: ${(props) => props.gutterLeft}px;
	padding: ${(props) => props.padding}px;
	width: ${(props) => props.width}px;
`;

export { WidgetWrapperStyle, WidgetBoxStyle, WidgetColumnStyle };
