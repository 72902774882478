import actions from './actions';

const initState = {
	contactTypes: [],
	addressTypes: [],
	adminTypes: [],
	categoryTypes: [],
	fieldTypes: [],
	bookingTypes: [],
	entityTypes: [],
	pending: true
};

const typesReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_CONTACT_TYPES_SUCCESS:
			return {
				...state,
				contactTypes: action.contactTypesData,
				pending: false
			};
		case actions.LOAD_CONTACT_TYPES_ERROR:
			return {
				...state,
				contactTypes: [],
				pending: false
			};
		case actions.LOAD_ADDRESS_TYPES_SUCCESS:
			return {
				...state,
				addressTypes: action.addressTypesData,
				pending: false
			};
		case actions.LOAD_ADDRESS_TYPES_ERROR:
			return {
				...state,
				addressTypes: [],
				pending: false
			};
		case actions.LOAD_ADMIN_TYPES_SUCCESS:
			return {
				...state,
				adminTypes: action.adminTypesData,
				pending: false
			};
		case actions.LOAD_ADMIN_TYPES_ERROR:
			return {
				...state,
				adminTypes: [],
				pending: false
			};
		case actions.LOAD_CATEGORY_TYPES_SUCCESS:
			return {
				...state,
				categoryTypes: action.categoryTypesData,
				pending: false
			};
		case actions.LOAD_CATEGORY_TYPES_ERROR:
			return {
				...state,
				categoryTypes: [],
				pending: false
			};
		case actions.LOAD_FIELD_TYPES_SUCCESS:
			return {
				...state,
				fieldTypes: action.fieldTypesData,
				pending: false
			};
		case actions.LOAD_FIELD_TYPES_ERROR:
			return {
				...state,
				fieldTypes: [],
				pending: false
			};
		case actions.LOAD_BOOKING_TYPES_SUCCESS:
			return {
				...state,
				bookingTypes: action.bookingTypesData,
				pending: false
			};
		case actions.LOAD_BOOKING_TYPES_ERROR:
			return {
				...state,
				bookingTypes: [],
				pending: false
			};
		case actions.LOAD_ENTITY_TYPES_SUCCESS:
			return {
				...state,
				entityTypes: action.entityTypesData,
				pending: false
			};
		case actions.LOAD_ENTITY_TYPES_ERROR:
			return {
				...state,
				entityTypes: [],
				pending: false
			};

		default:
			return state;
	}
};
export default typesReducer;
