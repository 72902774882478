import { ManagePermissionModal, ManageTeamMemberModal, TeamDeleteModal, TeamEditModal } from '@Z/components/molecules';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import useModuleSettings from '@iso/lib/hooks/useModuleSettings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const RenderTeamActions = ({ team, onIndividualTeam }) => {
	const enabledAccessModule = useModuleSettings(CONSTANTS_SETTINGS.ACCESS_MODULE);

	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const [deleteOpen, setDeleteOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [memberEdit, setMemberEdit] = useState(false);
	const [managePermissionModal, setManagePermissionModal] = useState(false);

	// HOC
	const ButtonWithLink = withHyperLink((props) => (
		<Button {...props} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
	));

	// Basic Action menu Render.
	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{/* filter removed the unauthorised values */}
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const actions = [
		!onIndividualTeam && (
			<ButtonWithLink type='team' dataID={team.teamID} style={{ width: '100%' }} size={'small'}>
				View
			</ButtonWithLink>
		),
		<ManageTeamMemberModal open={memberEdit} setOpen={setMemberEdit} teamID={team.teamID} />,
		...(enabledAccessModule
			? [
					<ManagePermissionModal
						visible={managePermissionModal}
						setVisible={setManagePermissionModal}
						entityID={team.teamID}
						entityType={constants.ENTITY_TYPE_ID_TEAM}
					/>
			  ]
			: []),
		<TeamEditModal open={editOpen} setOpen={setEditOpen} team={team} />,
		<TeamDeleteModal open={deleteOpen} setOpen={setDeleteOpen} team={team} />
	];
	return (
		<Dropdown arrow overlay={() => actionMenu(actions)} trigger={['click']} placement='bottom'>
			<Button>Actions</Button>
		</Dropdown>
	);
};

export default RenderTeamActions;
