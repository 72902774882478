import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';
const IndividualSitesInfoWrapper = styled.div`
	.siteInfoContainer {
		.siteHeader {
			display: flex;
			justify-content: flex-start;
			padding: 0px 0px 10px 0px;

			@media only screen and (max-width: 1000px) {
				justify-content: center;
			}
		}

		.siteDetails {
			display: flex;
			flex-direction: column;

			.siteMap {
				overflow: hidden;
				border: 2px solid ${palette('primary', 0)};
				height: 250px;
				width: 100%;
				${borderRadius('10px')};
			}
		}
		.actionButtonContainer {
			display: flex;
			justify-content: flex-end;
			padding-bottom: 10px;

			@media only screen and (max-width: 1000px) {
				padding-top: 10px;
				padding-bottom: 0px;
				justify-content: center;
			}

			.switchSiteButton {
				@media only screen and (max-width: 1300px) {
					margin-right: 0px;
					display: none;
				}
			}
		}
	}
`;

export default IndividualSitesInfoWrapper;
