import { Loading } from '@Z/components/atoms';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { notification } from '@iso/components';
import Form from '@iso/components/uielements/form';
import { SelectOption as Option } from '@iso/components/uielements/select';
import Tag from '@iso/components/uielements/tag';
import theme from '@iso/config/theme/default';
import superFetch from '@iso/lib/helpers/superFetch';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from './select.style';

import deviceCategoriesAction from '@iso/redux/deviceCategories/actions';
const { editDeviceCategory } = deviceCategoriesAction;

const { palette } = theme;

const ManageCategoryEditableSelect = ({ sites = [], categoryID }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const { sitesData } = useSelector((state) => state.sites);
	const { currentCategory } = useSelector((state) => state.deviceCategories);
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	// HOC
	const SiteWithlink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	useEffect(() => {
		form.setFieldsValue({ siteID: sites.map((site) => site.siteID) });
	}, [form, sites]);

	const handleCancel = () => {
		setIsEditing(false);
	};

	const tagRender = (props) => {
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				size='small'
				key={value}
				color={palette.tagColor[value % 11]}
				className='siteTag'
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		);
	};
	const toggleEdit = () => {
		setIsEditing(!isEditing);
	};

	const onFinish = async () => {
		setLoading(true);
		const formData = form.getFieldsValue(true);

		const added = [];
		const deleted = [];

		// Check for deleted properties
		sites.forEach((item) => {
			const found = formData.siteID.find((modItem) => modItem === item.siteID);
			if (!found) {
				deleted.push(item.siteID);
			}
		});

		// Check for added properties
		formData.siteID.forEach((item) => {
			const found = sites.find((origItem) => origItem.siteID === item);
			if (!found) {
				added.push(item);
			}
		});

		try {
			// Check for added properties of global state
			const addedState = sitesData
				.filter((site) => added.includes(site.siteID))
				.map((item) => {
					return {
						siteID: item.siteID,
						siteName: item.name
					};
				});

			// Check for removed properties of global state
			const removedState = sites.filter((site) => !deleted.includes(site.siteID));

			if (added.length > 0) {
				await superFetch.patch(`/deviceCategories/${categoryID}/associations`, {
					siteIDs: added
				});
				notification('success', `Site Association${added.length > 1 && 's'} Added `);
				dispatch(
					editDeviceCategory({
						...currentCategory,
						sites: [...currentCategory.sites, ...addedState]
					})
				);
			} else if (deleted.length > 0) {
				await superFetch.delete(`/deviceCategories/${categoryID}/associations`, {
					siteIDs: deleted
				});
				notification('success', `Site Association${deleted.length > 1 && 's'} Removed`);
				dispatch(
					editDeviceCategory({
						...currentCategory,
						sites: removedState
					})
				);
			}
			setLoading(false);
			handleCancel();
		} catch (error) {
			setLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	return (
		<div style={{ width: '100%', paddingRight: 50 }}>
			<label className='infoLabel'>Association: {!isEditing && <EditOutlined onClick={toggleEdit} />}</label>
			{loading ? (
				<div>
					<Loading />
				</div>
			) : isEditing ? (
				<Form form={form} id={form} onFinish={onFinish} autoComplete='off' style={{ width: '100%' }}>
					<Form.Item name='siteID'>
						<MultiSelect
							tagRender={tagRender}
							mode='multiple'
							style={{ width: '90%' }}
							showArrow
							placeholder='Select Associated Sites'
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{sitesData.map((site, key) => (
								<Option color='red' key={key} value={site.siteID}>
									{site.name}
								</Option>
							))}
						</MultiSelect>
					</Form.Item>
					<span style={{ position: 'absolute', top: 25, right: '12%' }}>
						<CheckOutlined onClick={() => onFinish()} /> <CloseOutlined onClick={() => handleCancel()} />
					</span>
				</Form>
			) : sites && sites.length === 0 ? (
				<p className='infoProp'>N/A</p>
			) : (
				<div>
					{sites.map((site) => (
						<Tag
							size='small'
							key={site.siteID}
							color={palette.tagColor[site.siteID % 11]}
							className='siteTag'
						>
							<SiteWithlink type='site' dataID={site.siteID}>
								{site.siteName}
							</SiteWithlink>
						</Tag>
					))}
				</div>
			)}
		</div>
	);
};
export default ManageCategoryEditableSelect;
