import { AddEntityAddressModal, AddEntityAdminModal, AddEntityContactModal } from '@Z/components/atoms';
import { EntityAddressTab, EntityAdminTab, EntityContactTab } from '@Z/components/molecules';
import Tabs, { TabPane } from '@iso/components/uielements/tabs';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { entityType } from '@iso/config/entityType';
import React, { useEffect, useState } from 'react';
import { EntityInformationTabsWrapper } from './EntityInformationTabs.style';

const EntityInformationTabs = ({ data, type }) => {
	const [tabKey, setTabKey] = useState(type === entityType.PERSON ? 'contact' : 'adminPerson');

	const [adminVisible, setAdminVisible] = useState(false);
	const [contactVisible, setContactVisible] = useState(false);
	const [addressVisible, setAddressVisible] = useState(false);

	const [admins, setAdmins] = useState(false);
	const [addresses, setAddresses] = useState(false);
	const [contacts, setContacts] = useState(false);

	const [selectedContact, setSelectedContact] = useState({});
	const [selectedAddress, setSelectedAddress] = useState({});

	const [editAdmin, setEditAdmin] = useState(false);
	const [editContact, setEditContact] = useState(false);
	const [editAddress, setEditAddress] = useState(false);

	useEffect(() => {
		setAdmins(data.adminPerson);
		setAddresses(data.address);
		setContacts(data.contact);
	}, [data]);

	const handleContact = (contact) => {
		setEditContact(true);
		setSelectedContact(contact);
		setContactVisible(true);
	};

	const handleAddress = (address) => {
		setEditAddress(true);
		setSelectedAddress(address);
		setAddressVisible(true);
	};

	return (
		<IsoWidgetBox>
			<EntityInformationTabsWrapper>
				<Tabs
					size='small'
					defaultActiveKey={tabKey}
					type='card'
					animated={{ inkBar: true, tabPane: true }}
					tabBarExtraContent={{
						right:
							tabKey === 'adminPerson' ? (
								type !== entityType.PERSON && (
									<AddEntityAdminModal
										visible={adminVisible}
										setVisible={setAdminVisible}
										data={data}
										type={type}
										edit={editAdmin}
										setEdit={setEditAdmin}
									/>
								)
							) : tabKey === 'contact' ? (
								<AddEntityContactModal
									visible={contactVisible}
									setVisible={setContactVisible}
									data={{ ...data, ...selectedContact }}
									type={type}
									edit={editContact}
									setEdit={setEditContact}
								/>
							) : tabKey === 'address' && type !== entityType.FLOOR ? (
								type !== entityType.TEAM && (
									<AddEntityAddressModal
										visible={addressVisible}
										setVisible={setAddressVisible}
										data={{ ...data, ...selectedAddress }}
										type={type}
										edit={editAddress}
										setEdit={setEditAddress}
									/>
								)
							) : (
								<div />
							)
					}}
					onChange={(activeKey) => setTabKey(activeKey)}
				>
					{type !== entityType.PERSON && (
						<TabPane tab='Admin' key='adminPerson'>
							<EntityAdminTab admin={admins} type={type} data={data} />
						</TabPane>
					)}

					<TabPane tab='Contact' key='contact'>
						<EntityContactTab
							contact={contacts}
							handleContact={(item) => handleContact(item)}
							data={data}
							type={type}
						/>
					</TabPane>

					{type !== entityType.TEAM && (
						<TabPane tab='Address' key='address'>
							<EntityAddressTab
								address={addresses}
								handleAddress={(item) => handleAddress(item)}
								data={data}
								type={type}
							/>
						</TabPane>
					)}
				</Tabs>
			</EntityInformationTabsWrapper>
		</IsoWidgetBox>
	);
};

export default EntityInformationTabs;
