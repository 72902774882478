import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Tooltip from '@iso/components/uielements/tooltip';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import peopleAction from '@iso/redux/people/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const PersonDeleteModel = ({ visible, setVisible, data, children }) => {
	const dispatch = useDispatch();
	const { loadPeople } = peopleAction;
	const [loading, setLoading] = useState(false);

	const isPermittedDeletePerson = useManagePermittedRoles('deletePerson', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: [],
		teamID: []
	});

	const handleDelete = () => {
		setLoading(true);
		superFetch
			.delete(`/people/${data.personID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Person Deleted.');
						setVisible(false);
					}
					dispatch(loadPeople());
				}
				setLoading(false);
				return res;
			})
			.catch((err) => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	return (
		<div>
			<Tooltip
				title={!isPermittedDeletePerson ? "You don't have permission" : 'Remove this Person’s Profile'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							danger
							disabled={!isPermittedDeletePerson}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Delete
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Delete a Person`}
				danger
				visible={visible}
				onCancel={() => setVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{data.getDisplayName().displayName}'</span>{' '}
					from the organisation?
				</p>
			</Modal>
		</div>
	);
};

export default PersonDeleteModel;
