import { notification } from '@iso/components';
import superFetch from '@iso/lib/helpers/superFetch';
import { Button } from 'antd';
import React from 'react';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

export function* loadDefaultSettingsData() {
	yield takeEvery(actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_DATA, function* () {
		try {
			const data = yield superFetch.get('/settings/defaultSettings').then((res) => {
				if (res.data && res.statusCode === 200) {
					return res.data;
				}
			});

			if (data) {
				yield put({
					type: actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_SUCCESS,
					payload: data
				});
			} else {
				yield put({
					type: actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_ERROR,
					error: 'Error'
				});
			}
		} catch (error) {
			notification(
				'error',
				'There is an Error! We are mending the problem, try again soon!.',
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Try again!
				</Button>
			);
		}
	});

	yield takeEvery(actions.LOAD_DEFAULT_PERSON_SETTINGS_DATA, function* () {
		try {
			const data = yield superFetch.get(`/settings/people/defaultSettings`).then((res) => {
				if (res.data && res.statusCode === 200) {
					return res.data;
				}
			});

			if (data) {
				yield put({
					type: actions.LOAD_DEFAULT_PERSON_SETTINGS_SUCCESS,
					payload: data
				});
			} else {
				yield put({
					type: actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_ERROR,
					error: data.error
				});
			}
		} catch (error) {
			notification(
				'error',
				'There is an Error! We are mending the problem, try again soon!.',
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Try again!
				</Button>
			);
		}
	});

	yield takeEvery(actions.LOAD_GROUP_SETTINGS_DATA, function* () {
		const data = yield superFetch.get('/settings/group').then((res) => {
			const result = {};
			if (res.data && res.statusCode === 200) {
				return res.data;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;
				return result;
			}
		});

		if (data) {
			yield put({
				type: actions.LOAD_GROUP_SETTINGS_SUCCESS,
				payload: data
			});
		} else {
			yield put({
				type: actions.LOAD_GROUP_SETTINGS_ERROR,
				error: 'Error'
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadDefaultSettingsData)]);
}
