import { useSelector } from 'react-redux';

/**
 *
 * This is the usePermission custom hook, it is used to find if
 * the user has the permission to view a component
 *
 * @param {*} permittedRoles an object of rules that were permitted to view the component
 * @param {boolean} [checkEntityID=true] a boolean value to check if the user has the permission to the entity
 * @return {Boolean}  If the user has the permission, then return true, else return false
 */

const useManagePermittedRoles = (permissionName, { orgID, siteID, floorID, teamID }) => {
	const { remoteConfig } = useSelector((state) => state.remoteConfig);
	const { managePermittedRolesConfig: config } = remoteConfig;

	// Get the user roles and userTypeID from the redux store
	const { roles: userRoles, userTypeID } = useSelector((state) => state.Auth.userProfile.defaultProfile) || {};

	// get the permitted roles from the config
	const { roles: permittedRoles, userType: permittedUserType, entityID: checkEntityID } = config[permissionName];

	// Check if the user has Developer role
	const hasDeveloperPermission = Object.keys(userRoles).map(Number).includes(201);
	// if the user is a Developer, return true
	if (hasDeveloperPermission) {
		return true;
	}
	//? Order admin permissions
	const roleMapping = {
		101: orgID,
		103: siteID,
		104: floorID,
		105: teamID,
		107: siteID
	};

	//? Order admin permissions and fix the object
	const rolesPermitted = permittedRoles.reduce((acc, role) => {
		acc[role] = roleMapping[role] !== undefined ? roleMapping[role] : false;
		return acc;
	}, {});

	// check if adminRolesPermitted are valid required object
	if (
		Object.keys(rolesPermitted)
			.map(Number)
			.some((key) => !rolesPermitted[key])
	) {
		return false;
	}

	// Check if the user has permitted user type
	const hasPermittedUserType = permittedUserType?.includes(userTypeID);

	// If permittedRoles matches with current user roles, return true
	const matchedPermittedRoles = Object.keys(userRoles)
		.map(Number)
		.filter((key) => Object.keys(rolesPermitted).map(Number).includes(key));

	// Return if the user has the permission to view the component and no entityID is required
	if (matchedPermittedRoles.length > 0 && !checkEntityID) {
		// 101 is always checked with the EntityID
		if (matchedPermittedRoles.includes(101)) {
			const result = matchedPermittedRoles.map((rID) => {
				return userRoles[rID].some((entityID) => rolesPermitted[rID].includes(entityID));
			});
			return result.includes(true);
		} else {
			return true;
		}
	}

	// Check permissions for the specific entityID
	const resArray = matchedPermittedRoles.map((rID) => {
		return checkEntityID && userRoles[rID].some((entityID) => rolesPermitted[rID].includes(entityID));
	});

	if (hasPermittedUserType || resArray.includes(true)) {
		return true;
	} else {
		return false;
	}
};

const usePermittedUpdatePerson = (personID) => {
	const roles = useSelector((state) => state.Auth.userProfile.defaultProfile.roles) || {};
	const { personID: permittedPersonID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	let globalPermission = false;

	// Developers Or OrgAdmin can view Update Person components
	if (Object.keys(roles).map(Number).includes(201) || Object.keys(roles).map(Number).includes(101)) {
		globalPermission = true;
	}

	return permittedPersonID === personID || globalPermission;
};

export default useManagePermittedRoles;

export { usePermittedUpdatePerson };
