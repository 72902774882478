/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 08-12-2022  11:03:29
 * @desc [description]
 */
import Tag from '@iso/components/uielements/tag';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { SearchableTable } from '@Z/components/atoms';
import ComponentButton from '@Z/components/atoms/ComponentButton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const TeamMembersTable = ({ teamID }) => {
	const ButtonWithLink = withHyperLink(() => <ComponentButton text={'View Person'} />);
	const NameWithlink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});
	const { sitesData } = useSelector((state) => state.sites);
	const peopleData = useSelector((state) => state.people.peopleData);
	const [members, setTeamMembers] = useState([]);

	useEffect(() => {
		const personWithTeamID = peopleData.filter((person) => person.team.some((user) => user.teamID === teamID));

		const orderedTeamMembers = _.orderBy(personWithTeamID, 'deletedDate', 'desc');

		setTeamMembers(orderedTeamMembers);
	}, [peopleData, teamID]);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'displayName',
			key: 'displayName',
			width: 25,
			ellipsis: true,
			render: (text, record) => (
				<NameWithlink type='person' dataID={record.personID} title={record.getDisplayName().title}>
					{record.getDisplayName().displayName}
				</NameWithlink>
			)
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 25,
			ellipsis: true
		},
		{
			title: 'Site',
			dataIndex: 'siteID',
			key: 'site',
			width: 15,
			ellipsis: true,
			filters: Array.from(new Set(members.map((person) => person.siteID))).map((siteID) => {
				const siteInfo = sitesData.find((site) => site.siteID === siteID);
				return {
					text: siteInfo && siteInfo.name,
					value: siteID
				};
			}),
			onFilter: (value, record) => record.siteID === value,
			render: (text, record) => {
				const siteInfo = sitesData.find((site) => site.siteID === text);
				return (
					<NameWithlink type='site' dataID={record.siteID} key={record.siteID}>
						{siteInfo && siteInfo.name}
					</NameWithlink>
				);
			}
		},
		{
			key: 'action',
			align: 'center',
			render: (text, record) =>
				record.deletedDate ? (
					<Tag
						color='red'
						style={{
							height: '28px',
							lineHeight: '28px',
							fontSize: '12px',
							fontWeight: '500',
							borderRadius: '4px'
						}}
					>
						DELETED
					</Tag>
				) : (
					<ButtonWithLink type='person' dataID={record.personID} />
				),
			ellipsis: true,
			width: 20
		}
	];
	return (
		<SearchableTable
			columns={columns}
			pageSize={7}
			dataSource={members}
			filterColumn={['displayName', 'firstName', 'lastName']}
			placeholder='Person Name'
		/>
	);
};

export default TeamMembersTable;
