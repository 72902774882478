import { ComponentLabel, RevokePersonPermissionModal, SimpleTable } from '@Z/components/atoms';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { uniqBy } from 'lodash';
import React from 'react';

export const ExpandablePermissionTable = ({ permData, setPermissionStateUpdated }) => {
	// HOC
	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			width: '10%',
			render: (_, record) => <span> {record.teamID || record.assetGroupID ? 'Inherited' : 'Direct'}</span>
		},
		{
			title: 'Team',
			dataIndex: ['team', 'name'],
			key: 'team',
			ellipsis: true,
			render: (_, record) => {
				return record.team ? (
					<NameWithLink type='team' dataID={record.team.teamID}>
						<ComponentLabel
							style={{
								fontSize: '14px',
								fontWeight: '600',
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
							text={record.team.name}
						/>
					</NameWithLink>
				) : (
					''
				);
			},
			filters: uniqBy(
				permData.map((i) => {
					if (i.team) {
						return {
							text: i.team.name,
							value: i.team.name
						};
					} else {
						return {
							text: 'Unassigned',
							value: null
						};
					}
				}),
				'text'
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => {
				return record?.team?.name === (value || undefined);
			}
		},
		{
			title: 'Device',
			dataIndex: ['device', 'name'],
			key: 'device',
			ellipsis: true,
			render: (_, record) =>
				record.assetGroup ? (
					<NameWithLink type='device' dataID={Number(record.deviceID)}>
						<ComponentLabel
							style={{
								fontSize: '14px',
								fontWeight: '600',
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
							text={record.device.name}
						/>
					</NameWithLink>
				) : (
					''
				),
			filters: uniqBy(
				permData.map((i) => {
					if (i.group) {
						return {
							text: i.group.name,
							value: i.group.name
						};
					} else {
						return {
							text: 'Unassigned',
							value: null
						};
					}
				}),
				'text'
			),
			filterSearch: true,
			filterMode: 'menu',
			onFilter: (value, record) => {
				return record?.group?.name === (value || undefined);
			}
		},
		{
			key: 'revoke',
			render: (d) => {
				return (
					<RevokePersonPermissionModal
						setPermissionStateUpdate={setPermissionStateUpdated}
						data={{
							entity: {
								permissionIDs: [d.permissionID],
								name: d.device.name,
								permissionNameOwner: d.person.displayName
							}
						}}
					/>
				);
			},
			ellipsis: true
		}
	];
	return <SimpleTable columns={columns} dataSource={permData} pageSize={7} />;
};
