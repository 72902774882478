import { LabelWrapper, SimpleTable } from '@Z/components/atoms';
import { smallTimeOptions } from '@iso/assets/styles/constants';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const RenderGroupPermissions = ({ groupID }) => {
	const {
		remoteConfig: { constants, accessControlPermissionType }
	} = useSelector((state) => state.remoteConfig);

	const [teamAccessData, setTeamAccessData] = useState([]);

	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});
	const getData = useCallback(async () => {
		const res = await getAccessData({ assetGroupID: groupID });
		setTeamAccessData(res);
	}, [groupID]);

	useEffect(() => {
		getData();
	}, [getData]);

	const getTextGroup = (text) => {
		return <p>{accessControlPermissionType[text].shortTitle}</p>;
	};

	const getName = (data) => {
		return (
			<NameWithLink
				dataID={data.entityID}
				type={data.entityTypeID === constants.ENTITY_TYPE_ID_PERSON ? 'person' : 'team'}
			>
				<LabelWrapper
					typeLabel={'primary'}
					text={
						data.entityTypeID === constants.ENTITY_TYPE_ID_PERSON ? data.person.displayName : data.team.name
					}
					color={'secondary'}
					tonality={0}
					fontSize={14}
					fontWeight={600}
				/>
			</NameWithLink>
		);
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'entityID',
			key: 'entityID',
			ellipsis: true,
			render: (_text, record) => {
				return getName(record);
			},
			filters: uniqBy(
				teamAccessData.map((data) => data),
				'name'
			).map((team) => ({
				text: team.name,
				value: team.entityID
			})),
			onFilter: (value, record) => record.entityID === value,
			filterSearch: true
		},
		{
			title: 'Permission',
			dataIndex: 'permissionTypeID',
			key: 'permissionTypeID',
			ellipsis: true,
			sorter: (a, b) => a.permissionTypeID - b.permissionTypeID,
			render: (text) => {
				return getTextGroup(text);
			}
		},
		{
			title: 'Type',
			dataIndex: ['entityType', 'name'],
			key: 'entityTypeName',
			ellipsis: true,
			sorter: (a, b) => a.entityType.name.length - b.entityType.name.length
		},
		{
			title: 'Granted On',
			dataIndex: 'createdDate',
			key: 'createdDate',
			ellipsis: true,
			render: (text) => {
				return <p title={text}>{new Date(text).toLocaleString('en-AU', smallTimeOptions)}</p>;
			}
		}
	];

	return (
		<SimpleTable
			columns={columns}
			pageSize={10}
			dataSource={teamAccessData}
			filterColumn={['entityID']}
			placeholder='Entity Type or Permission'
		/>
	);
};

export default RenderGroupPermissions;
