import React from 'react';
import Button from '@iso/components/uielements/button';

const defaulStyle = {
	height: '28px',
	lineHeight: '28px',
	fontSize: '12px',
	fontWeight: '500'
};
const ComponentButton = ({ value, text, style }) => {
	return (
		<Button size='small' className='previewButton' style={{ ...defaulStyle, ...style }} value={value}>
			{text}
		</Button>
	);
};

export default ComponentButton;
