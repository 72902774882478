import {
	ManagePeopleMemberModal,
	ManagePermissionModal,
	PersonDeleteModel,
	PersonDisabledModal,
	PersonEnabledModal,
	ResetPasswordModal,
	SetUserTypeModal
} from '@Z/components/molecules';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, DropdownMenu, MenuItem } from '@iso/components/uielements';
import Button from '@iso/components/uielements/button';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import useModuleSettings from '@iso/lib/hooks/useModuleSettings';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import CompleteProfileModal from '../CompleteProfileModal';

const RenderPeopleActions = ({ data, hideView, size, placement, showIcon = true, shape }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const enabledAccessModule = useModuleSettings(CONSTANTS_SETTINGS.ACCESS_MODULE);

	const [passwordVisible, setPasswordVisible] = useState(false);
	const [assignVisibleModal, setAssignVisibleModal] = useState(false);
	const [editVisible, setEditVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [disabledVisible, setDisabledVisible] = useState(false);
	const [enabledVisible, setEnabledVisible] = useState(false);
	const [manageTeamModal, setManageTeamModal] = useState(false);
	const [managePermissionModal, setManagePermissionModal] = useState(false);

	const NameWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const actions = [
		...(!hideView
			? [
					<NameWithLink type='person' dataID={data.personID}>
						<Button
							size={'small'}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							View
						</Button>
					</NameWithLink>
			  ]
			: []),
		<SetUserTypeModal visible={assignVisibleModal} setVisible={setAssignVisibleModal} data={data} />,
		<ManagePeopleMemberModal open={manageTeamModal} setOpen={setManageTeamModal} personData={data} />,
		...(enabledAccessModule
			? [
					<ManagePermissionModal
						visible={managePermissionModal}
						setVisible={setManagePermissionModal}
						entityID={data.personID}
						entityType={constants.ENTITY_TYPE_ID_PERSON}
					/>
			  ]
			: []),
		<ResetPasswordModal visible={passwordVisible} setVisible={setPasswordVisible} data={data} />,
		<CompleteProfileModal visible={editVisible} setVisible={setEditVisible} edit={true} data={data} />,
		...(!data.deletedDate
			? [
					!data.isActive ? (
						<PersonEnabledModal visible={enabledVisible} setVisible={setEnabledVisible} data={data} />
					) : (
						<PersonDisabledModal visible={disabledVisible} setVisible={setDisabledVisible} data={data} />
					)
			  ]
			: []),
		<PersonDeleteModel visible={deleteVisible} setVisible={setDeleteVisible} data={data} />
	];

	return (
		<Fragment>
			<Dropdown arrow overlay={() => actionMenu(actions)} trigger={['click']} placement={placement || 'bottom'}>
				<Button
					style={{
						borderRadius: shape === 'circle' ? '10px' : null
					}}
					size={size || 'small'}
				>
					Actions
					{showIcon && <DownOutlined />}
				</Button>
			</Dropdown>
		</Fragment>
	);
};

export default RenderPeopleActions;
