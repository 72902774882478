import { ComponentLabel, Loading, ProfileImageComponent, RenderPeopleActions, RolesSummary } from '@Z/components/atoms';
import { DownOutlined } from '@ant-design/icons';
import basicStyle, { smallTimeOptions } from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import Tag from '@iso/components/uielements/tag';
import MetaInfoContainer from '@iso/components/utility/MetaInfoContainer';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import PeopleSummaryWrapper from './PS.styles';

const IndividualPersonSummary = ({ data }) => {
	const [loadings, setLoading] = React.useState(true);
	const { peopleData } = useSelector((state) => state.people);
	const [peopleList, setPeopleList] = React.useState([]);
	const { teamsData } = useSelector((state) => state.teams);
	const displayNames = useGetPersonDisplayName(true);

	React.useEffect(() => {
		setLoading(true);
		if (data && Object.keys(data).length > 0) {
			setPeopleList(peopleData);
			setLoading(false);
		}
	}, [data, peopleData]);

	// HOC
	const PersonWithLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const isPermittedManageMember = useManagePermittedRoles('manageMembers', {
		orgID: [teamsData[0]?.orgID],
		teamID: [teamsData.map((item) => item.teamID)]
	});

	const peopleListing = () => {
		return (
			<DropdownMenu>
				{peopleList.map((persons, key) => {
					return (
						<MenuItem key={key}>
							<PersonWithLink
								type='person'
								dataID={persons.personID}
								title={displayNames[persons.personID].title}
							>
								{displayNames[persons.personID].displayName}
							</PersonWithLink>
						</MenuItem>
					);
				})}
			</DropdownMenu>
		);
	};

	const { rowStyle } = basicStyle;

	return loadings ? (
		<Loading />
	) : (
		<IsoWidgetBox>
			<PeopleSummaryWrapper>
				<ProfileImageComponent entityObject={data} entityName={'People'} />
				<Row lg={12} md={12} sm={12} xs={12}>
					<div className=' personHeaderCol'>
						<div className='personTitle'>
							<ComponentLabel
								style={{
									fontSize: '25px',
									whiteSpace: 'no-wrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden'
								}}
								text={data.displayName}
							/>
							<p className='fBID'>
								ID: <span className='idLabel'>{data.fBPersonID}</span>
							</p>
						</div>
						<div className='actionsContainer'>
							{peopleList.length > 0 ? (
								<Dropdown overlay={peopleListing()} trigger={['click']}>
									<Button className='switchPersonButton' size='small'>
										Switch Profile <DownOutlined />
									</Button>
								</Dropdown>
							) : null}
							{data.deletedDate ? (
								<Tooltip
									key={data.personID}
									title={new Date(data.deletedDate).toLocaleString('en-AU', smallTimeOptions)}
									placement='top'
									color='red'
								>
									<div>
										<Tag color='red' className='deletedTag'>
											DELETED
										</Tag>
									</div>
								</Tooltip>
							) : isPermittedManageMember ? (
								<RenderPeopleActions data={data} hideView />
							) : null}
						</div>
					</div>
					<Row lg={12} md={12} sm={24} xs={24} style={rowStyle}>
						<div className='infoContainer'>
							<div className='infoDiv'>
								<div className='info'>
									<label className='infoLabel'>First Name: </label>
									<p className='infoProp'>{data.firstName}</p>
								</div>

								<div className='info'>
									<label className='infoLabel'>Last Name: </label>
									<p className='infoProp'>{data.lastName}</p>
								</div>
								<div className='info'>
									<label className='infoLabel'>Employee ID: </label>
									<p className='infoProp'>{data.employeeID ? data.employeeID : 'N/A'}</p>
								</div>
								<div className='info'>
									<label className='infoLabel'>Sex: </label>
									<p className='infoProp'>{data.sex ? data.sex : 'N/A'}</p>
								</div>
								<div className='info'>
									<label className='infoLabel'>Email: </label>
									<p className='infoProp'>{data.email}</p>
								</div>
								<div className='info'>
									<label className='infoLabel'>Date Of Birth: </label>
									<p className='infoProp'>
										{data.dOB
											? new Date(data.dOB).toLocaleString('en-AU', smallTimeOptions)
											: 'N/A'}
									</p>
								</div>
							</div>
						</div>
						{data.roles && Object.keys(data.roles).length > 0 && (
							<Col lg={24} md={24} sm={24} xs={24}>
								<div className='personRole'>Roles</div>
								<div>
									<RolesSummary roles={data.roles} />
								</div>
							</Col>
						)}
					</Row>
				</Row>
			</PeopleSummaryWrapper>
			<MetaInfoContainer
				createDate={data.createdDate}
				modifiedDate={data.modifiedDate}
				deleteDate={data.deletedDate}
			/>
		</IsoWidgetBox>
	);
};

export default IndividualPersonSummary;
