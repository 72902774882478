import styled from 'styled-components';
import { palette } from 'styled-theme';

export const SetUserTypeWrapper = styled.div`
	padding: 0 10px;
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: 1px solid ${palette('grayscale', 1)};
		background-color: ${palette('grayscale', 10)};
		padding: 10px;
		width: 100%;
		margin: 10px 0;

		i {
			margin-right: 10px;
			font-size: 20px;
			color: ${palette('primary', 0)};
		}
	}
`;
