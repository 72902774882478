import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import floorAction from '@iso/redux/floors/actions';
import { Modal } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import siteAction from '@iso/redux/sites/actions';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { loadSites } = siteAction;
const { loadFloors } = floorAction;

const AddFloor = () => {
	const dispatch = useDispatch();
	const [viewModal, setViewModal] = React.useState(false);
	const [confirmLoading, setConfirmLoading] = React.useState(false);
	const [contactInfoDisable, setContactInfoDisable] = React.useState(true);
	const [selectedCType, setSelectedCType] = React.useState(null);
	const { sitesData, pending } = useSelector((state) => state.sites);
	const { contactTypes } = useSelector((state) => state.types);
	const { colStyle } = basicStyle;
	const [form] = Form.useForm();
	React.useEffect(() => {
		if (sitesData.length < 1 && pending) {
			dispatch(loadSites());
		}
	}, [pending, sitesData, dispatch]);

	const handleCancel = () => {
		setViewModal(false);
		setContactInfoDisable(true);
		form.resetFields();
	};

	const handleSubmit = () => {
		setConfirmLoading(true);
		const formData = removeEmptyOrNull(form.getFieldsValue(true));

		superFetch.post('/floors', formData).then((res) => {
			if (res.statusCode === 201) {
				dispatch(loadFloors());
				handleCancel();
			} else if (res.error.statusCode === 400) {
				notification('error', 'Please fill out all required information.');
			} else {
				notification('error', 'There is an Error! We are mending the problem, try again soon!.');
			}
			setConfirmLoading(false);
		});
	};

	return (
		<div>
			<Button type='primary' onClick={() => setViewModal(true)}>
				Add New Floor
			</Button>
			<Modal
				title='Add New Floor'
				visible={viewModal}
				onCancel={handleCancel}
				width={650}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button loading={confirmLoading} form={form} key='submit' htmlType='submit' type='primary'>
						Add Floor
					</Button>
				]}
			>
				<div style={{ margin: '0 50px' }}>
					<Form form={form} id={form} onFinish={handleSubmit}>
						<Row style={{ paddingBottom: '10px' }}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 10 }}
									wrapperCol={{ span: 16 }}
									name='name'
									label='Floor Name'
									rules={fieldsValidator.Name}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 8 }}
									wrapperCol={{ span: 16 }}
									name='siteID'
									label='Site'
									rules={fieldsValidator.Required}
								>
									<Select placeholder={'Select Site'}>
										{sitesData.map((site) => (
											<Option key={site.siteID} value={site.siteID}>
												{site.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '10px' }}>
							<Col span={24}>
								<Form.Item
									labelCol={{ span: 5 }}
									wrapperCol={{ span: 20 }}
									name='description'
									label='Description'
									rules={fieldsValidator.Description}
								>
									<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 10 }}
									wrapperCol={{ span: 12 }}
									label='Contact Type'
									name={['contact', 0, 'contactTypeID']}
									style={colStyle}
								>
									<Select
										onSelect={(x, y) => {
											setSelectedCType(y);
											setContactInfoDisable(false);
										}}
										placeholder={'Type'}
									>
										{contactTypes.map((type) => (
											<Option key={type.contactTypeID} value={type.contactTypeID}>
												{type.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name={['contact', 0, 'contactInformation']}
									labelCol={{ span: 12 }}
									wrapperCol={{ span: 24 }}
									style={colStyle}
									rules={selectedCType && fieldsValidator[selectedCType.children]}
								>
									<Input disabled={contactInfoDisable} placeholder='Enter Contact Information' />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
};

export default AddFloor;
