import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components/index';
import { Input, Select, SelectOption as Option } from '@iso/components/uielements';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Tooltip from '@iso/components/uielements/tooltip';
import SuperFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import React, { useState } from 'react';

const AddDefaultCategoryModal = ({ visible, setVisible, data, children }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const defaultCategories = [
		{ id: 1, name: 'Health & Safety' },
		{ id: 12, name: 'Smart Locks' },
		{ id: 13, name: 'Smart Gateways' },
		{ id: 14, name: 'Smart Locker' },
		{ id: 15, name: 'Verkada Cameras' },
		{ id: 17, name: 'Zezamii Access' },
		{ id: 18, name: 'Zezamii Gateways' },
		{ id: 19, name: 'Zezamii Lockers' }
	];

	const handleCreateDefaultCategory = async () => {
		setLoading(true);
		try {
			const formData = removeEmptyOrNull(form.getFieldsValue(true));
			const res = await SuperFetch.post('/deviceCategories/defaultCategories/createManualCategory', {
				categoryName: formData.categoryName,
				defaultCategoryID: formData.defaultCategoryID,
				orgID: data.orgID
			});

			if (res.statusCode === 201) {
				notification('success', 'The default category has been created.');
				setLoading(false);
				handleCancel();
			}
		} catch (error) {
			setLoading(false);
			notification('error', 'Failed, Please try again.');
		}
	};

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
	};

	return (
		<>
			<Tooltip title={'Create a default category'} placement='rightBottom'>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
						>
							Create Default Category
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Create default category`}
				visible={visible}
				onCancel={() => handleCancel()}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => handleCancel()}>
						Cancel
					</Button>,
					<Button type='primary' loading={loading} key='create' onClick={() => handleCreateDefaultCategory()}>
						Create
					</Button>
				]}
			>
				<Form form={form} layout='vertical' style={{ margin: 10 }}>
					<Form.Item label='Name' name='categoryName' rules={fieldsValidator.Name}>
						<Input placeholder='Enter name' />
					</Form.Item>
					<Form.Item
						label='Default Category'
						name='defaultCategoryID'
						rules={[{ required: true, message: 'Please select a default category' }]}
					>
						<Select placeholder='Select a default category' allowClear>
							{defaultCategories.map(({ id, name }) => (
								<Option key={id} value={id}>
									{name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddDefaultCategoryModal;
