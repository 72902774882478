import styled from 'styled-components';
import { palette } from 'styled-theme';

import { MoreOutlined } from '@ant-design/icons';
const SearchCategoryWrapper = styled.div`
	width: calc(100% - 60px);
	.headerText {
		font-size: 16px;
		font-weight: 500;
		color: ${palette('secondary', 0)};
		display: flex !important;
		align-items: baseline !important;
		cursor: pointer;
	}

	.paragraphContainer {
		display: flex;
		align-items: normal;
		justify-content: space-between;
		border-bottom: 1px solid ${palette('border', 0)};
	}

	.paragraphText {
		margin-right: 20px;
	}

	.buttonContainer {
		margin: 0px 4px 0px 4px;
	}

	.customText {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export { MoreOutlinedIcon };
export default SearchCategoryWrapper;
