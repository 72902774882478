const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	DISPATCH_USER: 'DISPATCH_USER',
	USER_DISPATCHED: 'USER_DISPATCHED',
	USER_DISPATCHED_ERROR: 'USER_DISPATCHED_ERROR',
	SWITCH_PERSON: 'SWITCH_PERSON',
	SWITCH_PERSON_SUCCESS: 'SWITCH_PERSON_SUCCESS',
	SWITCH_PERSON_ERROR: 'SWITCH_PERSON_ERROR',
	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	switchPerson: (orgID) => ({
		type: actions.SWITCH_PERSON,
		payload: { orgID }
	}),
	login: (token = false) => ({
		type: actions.LOGIN_REQUEST,
		payload: { token }
	}),
	dispatchUser: () => ({
		type: actions.DISPATCH_USER
	}),
	logout: () => ({
		type: actions.LOGOUT
	})
};
export default actions;
