import { EditOutlined } from '@ant-design/icons';
import { smallTimeOptions } from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Tag from '@iso/components/uielements/tag';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import deviceCategoriesAction from '@iso/redux/deviceCategories/actions';
import { BuySmartLockButton, ManageCategoryEditableSelect } from '@Z/components/atoms';
import { Col, Collapse, List, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RenderActionMenu from './components/renderActionMenu';
import RenderSampleDevices from './components/renderSampleDevices';
import DeviceCategoryWrapper, { TypographyParagraphWrapper, TypographyTitleWrapper } from './DeviceCategoryDesc.styles';
const titlesMap = {
	12: 'Locks',
	13: 'Gateways'
};
const { Panel } = Collapse;
const { editDeviceCategory } = deviceCategoriesAction;
const DeviceCategoryDescription = ({ data = {} }) => {
	const dispatch = useDispatch();

	const [buySmartLockVisible, setBuySmartLockVisible] = useState(false);
	// get data from store
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const isPermittedUpdateCategory = useManagePermittedRoles('updateCategory', {
		orgID: [data?.orgs[0]?.orgID],
		siteID: [],
		floorID: []
	});

	// get the default state of the category.
	const { isDefault, defaultCategoryID } = data;

	const onEditName = (value) => {
		if (data.name === value) {
			return;
		} else {
			if (/^[a-zA-Z0-9 /]+$/.test(value)) {
				superFetch
					.patch('/deviceCategories/' + data.categoryID, { name: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(editDeviceCategory({ ...data, name: value }));
								notification('success', 'Device category name updated.');
							}
						}
						return res;
					})
					.catch((err) => {
						notification('error', 'Update Failed, Please try again.');
					});
			} else {
				notification('error', 'Device category name can only contain letters, numbers and spaces.');
			}
		}
	};

	const onEditDesc = (value) => {
		if (data.description === value) {
			return;
		} else {
			if (/^[a-zA-Z0-9 \n.;/,]+$/.test(value)) {
				superFetch
					.patch('/deviceCategories/' + data.categoryID, { description: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(editDeviceCategory({ ...data, description: value }));
								notification('success', 'Device category description updated.');
							}
						}
						return res;
					})
					.catch((err) => {
						notification('error', 'Update Failed, Please try again.');
					});
			} else {
				notification(
					'error',
					'Device category description may only contain letters, numbers, ,/;. and spaces.'
				);
			}
		}
	};

	return (
		<div>
			<Collapse>
				<Panel header={'Category Details - ' + data.name} key='1'>
					<DeviceCategoryWrapper>
						<Row>
							{/* Category Name and type related information */}
							<Col lg={12} md={12} sm={24} xs={24} className='categoryHeaderCol'>
								<div
									className='categoryTitle'
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<TypographyTitleWrapper
										className='titleEdit'
										editable={
											!isDefault && isPermittedUpdateCategory
												? {
														tooltip: 'Click to edit Title',
														onChange: (value) => onEditName(value),
														maxLength: 100,
														icon: <EditOutlined className='outlinedIcon' />
												  }
												: false
										}
									>
										{data.name}
									</TypographyTitleWrapper>
									<label className='infoActivityLabel' style={{ marginLeft: 15 }}>
										Type:
									</label>
									<p className='infoActivityProp'>{data.typeName}</p>
								</div>

								{data.fields && data.fields.length > 0 ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Tooltip
											key={data.categoryID}
											placement='top'
											trigger={['click']}
											color='white'
											style={{ borderRadius: '6px' }}
											title={
												<div>
													<List
														size='small'
														grid='xs'
														header={
															<span style={{ fontSize: '14px', fontWeight: '500' }}>
																Additional Fields
															</span>
														}
														style={{
															maxHeight: '250px',
															overflow: 'auto',
															minWidth: '200px',
															borderRadius: '4px'
														}}
													>
														{data.fields.map((customFields) => (
															<List.Item key={customFields.fieldID}>
																<List.Item.Meta
																	title={
																		<span style={{ fontSize: '11px' }}>
																			{customFields.title}
																		</span>
																	}
																	description={
																		<p style={{ fontSize: '11px' }}>
																			{customFields.fieldTypeName}
																		</p>
																	}
																/>
															</List.Item>
														))}
													</List>
												</div>
											}
										>
											<i
												className='material-icons-outlined customFieldIcon'
												style={{ cursor: 'pointer' }}
											>
												info
											</i>
										</Tooltip>
									</div>
								) : null}
							</Col>

							{/* Category related actions and buttons.*/}
							<Col lg={12} md={12} sm={24} xs={24} className='actionButton categoryHeaderCol'>
								{data.deletedDate ? (
									<Tooltip
										key={data.categoryID}
										title={new Date(data.deletedDate).toLocaleString('en-AU', smallTimeOptions)}
										placement='top'
										color='red'
									>
										{/* Deleted category Section.  */}
										<Tag color='red' className='deletedTag'>
											DELETED
										</Tag>
									</Tooltip>
								) : (
									<div className='actionButtonDiv'>
										{constants.SMART_CATEGORIES.includes(data.defaultCategoryID) ? (
											<BuySmartLockButton
												visible={buySmartLockVisible}
												setVisible={setBuySmartLockVisible}
												type={titlesMap[data.defaultCategoryID]}
											/>
										) : null}
										{/* Sample Devices Button.  */}
										<RenderSampleDevices
											defaultCategoryID={defaultCategoryID ? defaultCategoryID : null}
										/>
										{/*  Action Menu DropDown */}
										<RenderActionMenu category={data} />
									</div>
								)}
							</Col>
						</Row>
						{/* Category Description Section.  */}
						<Row className='summary' gutter={10}>
							<Col lg={18} md={18} sm={24} xs={24}>
								<TypographyParagraphWrapper
									editable={
										!isDefault && isPermittedUpdateCategory
											? {
													tooltip: 'Click to edit Description',
													onChange: (value) => onEditDesc(value),
													autoSize: { maxRows: 5 },
													maxLength: 600,
													icon: <EditOutlined className='outlinedIcon' />,

													enterIcon: null
											  }
											: false
									}
									className='infoProp'
								>
									{data.description}
								</TypographyParagraphWrapper>
							</Col>
							{/* Associate Category with multiple sites.  */}
							{!isDefault && isPermittedUpdateCategory && (
								<Col lg={6} md={6} sm={24} xs={24}>
									<ManageCategoryEditableSelect sites={data.sites} categoryID={data.categoryID} />
								</Col>
							)}
						</Row>

						{/* Category Meta Data. Created Date/ Updated Date. */}
						{!isDefault && (
							<Row gutter={0} lg={24} md={24} sm={24} xs={24} className='suppInfo'>
								<div>
									<label className='infoLabel'>Date Created:</label>
									<p className='infoActivityProp'>
										{new Date(data.createdDate).toLocaleString('en-AU', smallTimeOptions)}
									</p>
								</div>
								<div>
									<label className='infoLabel'>
										{data.deletedDate ? 'Deleted On:' : 'Last Updated:'}
									</label>
									<p className='infoActivityProp'>
										{data.deletedDate
											? new Date(data.deletedDate).toLocaleString('en-AU', smallTimeOptions)
											: new Date(data.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
									</p>
								</div>
							</Row>
						)}
						<Row />
					</DeviceCategoryWrapper>
				</Panel>
			</Collapse>
		</div>
	);
};

export default DeviceCategoryDescription;
