import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input, { Textarea } from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import teamAction from '@iso/redux/teams/actions';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EditModal = ({ open = false, setOpen, team, children }) => {
	const dispatch = useDispatch();
	const { loadTeams } = teamAction;
	const [form] = Form.useForm();
	const { sitesData } = useSelector((state) => state.sites);
	const [loading, setLoading] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(true);

	const isPermittedUpdateTeam = useManagePermittedRoles('updateTeam', {
		orgID: [team.orgID],
		siteID: [team.siteID],
		teamID: [team.teamID]
	});

	const handleCancel = () => {
		setOpen(false);
		setLoading(false);
		form.resetFields();
	};

	const onFinish = async () => {
		const formData = removeEmptyOrNull(form.getFieldsValue(true));

		const body = {
			name: formData.name,
			description: formData.description,
			siteID: formData.siteID
		};
		try {
			const res = await superFetch.patch('/teams/' + team.teamID, body);
			if (res) {
				if (res.statusCode === 200) {
					notification('success', 'Team Information Updated.');
				}
				dispatch(loadTeams());
				handleCancel();
			}
		} catch (error) {
			notification('error', 'Update Failed, Please try again.');
		}
	};

	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		return (
			(team.name === formData.name || formData.name === '') &&
			(team.description === formData.description || formData.description === '') &&
			(team.siteID === formData.siteID || formData.siteID === '')
		);
	};

	return (
		<React.Fragment>
			<Tooltip
				title={
					!isPermittedUpdateTeam
						? "You don't have permission"
						: 'Edit this Team’s Information for their Profile'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setOpen(true);
							}}
							disabled={!isPermittedUpdateTeam}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Edit Team
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title='Edit Team'
				visible={open}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={loading}
						disabled={buttonDisable}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					labelCol={{ lg: { span: 6, offset: 0 }, sm: { span: 6, offset: 0 } }}
					wrapperCol={{ lg: { span: 17 }, sm: { span: 16 }, xs: { span: 10 } }}
					onFinish={onFinish}
					initialValues={team}
					autoComplete='off'
					onValuesChange={() => setButtonDisable(updateChange())}
				>
					<Form.Item label='Name' name='name' rules={fieldsValidator.Name}>
						<Input />
					</Form.Item>
					<Form.Item label='Description' name='description' rules={fieldsValidator.Description}>
						<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
					</Form.Item>
					<Form.Item name='siteID' label='Site'>
						<Select placeholder={'Select Site'}>
							{sitesData.map((site) => (
								<Option key={site.siteID} value={site.siteID}>
									{site.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</React.Fragment>
	);
};

export default EditModal;
