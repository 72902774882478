export const defaultCategory = {
	1: {
		name: 'Health & Safety',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FHealth%26Safety_Icon.svg?alt=media&token=82fb51b0-86b3-4037-8682-6db0cfbb8f72',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FHealth%26Safety_Marker.svg?alt=media&token=f1a73e58-8587-4b86-9c80-0b52f212da20',
		showCustomFieldOnCard: [
			'Last Service Date',
			'Next Service',
			'Dimensions',
			'Weight',
			'Brand',
			'Color',
			'Item Type',
			'Product Code',
			'Date of Purchase'
		],
		showOnAdditionalFields: [],
		exportAbleDeviceCustomFields: [],
		component: 'GenericDefaultCategoryDeviceCard'
	},
	12: {
		name: 'Smart Locks',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLock_Icon.svg?alt=media&token=7ca8f3b6-eb21-4bd9-a25d-3d1c96129276',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLock_Marker.svg?alt=media&token=43c8645e-9148-4890-af72-2c80f191d40c',
		showCustomFieldOnCard: ['Floor', 'Electric Quantity'],
		showOnAdditionalFields: [
			'LockID',
			'Lock Mac',
			'Lock Alias',
			'Has Gateway',
			'Electric Quantity',
			'Lock GroupID',
			'Lock Group Name'
		],
		exportAbleDeviceCustomFields: [
			'LockID',
			'Lock Mac',
			'Lock Alias',
			'Electric Quantity Update Date',
			'Feature Value',
			'Has Gateway',
			'Wireless Keypad Feature Value',
			'Floor Number',
			'Building Number',
			'Lock Name',
			'Special Value',
			'Sector',
			'No Key Pwd',
			'Passage Mode',
			'Date',
			'Timezone Raw Offset',
			'Electric Quantity',
			'Bind Date',
			'Keyboard Pwd Version',
			'Lock Version Show Admin Kbpwd Flag',
			'Lock Version Group ID',
			'Lock Version Protocol Version',
			'Lock Version Protocol Type',
			'Lock Version Org ID',
			'Lock Version Logo URL',
			'Lock Version Scene'
		],
		editableCustomFields: [],
		component: 'SmartLockDeviceCard'
	},
	13: {
		name: 'Smart Gateway',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartGateway_Icon.svg?alt=media&token=11524845-c28c-4ddc-a8ca-06e3baed87f3',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartGateway_Marker.svg?alt=media&token=6fdd1f85-16f2-4aab-a141-2bb3ddbda6d6',
		showCustomFieldOnCard: ['Floor', 'Number Of Locks', 'Wifi Network Name'],
		showOnAdditionalFields: [],
		exportAbleDeviceCustomFields: [],
		editableCustomFields: [],
		component: 'SmartLockDeviceCard'
	},
	14: {
		name: 'Smart Locker',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLocker_Icon.svg?alt=media&token=a2dfb177-7cce-439d-a72a-c38904ecff50',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLocker_Marker.svg?alt=media&token=17aee55e-0d77-4205-860c-c79b502acea8',
		showCustomFieldOnCard: ['Floor', 'Electric Quantity', 'Size'],
		editableCustomFields: ['Size', 'Feature'],
		showOnAdditionalFields: [
			'LockID',
			'Lock Mac',
			'Lock Alias',
			'Has Gateway',
			'Electric Quantity',
			'Lock GroupID',
			'Lock Group Name'
		],
		exportAbleDeviceCustomFields: [
			'LockID',
			'Lock Mac',
			'Lock Alias',
			'Electric Quantity Update Date',
			'Feature Value',
			'Has Gateway',
			'Wireless Keypad Feature Value',
			'Floor Number',
			'Building Number',
			'Lock Name',
			'Special Value',
			'Sector',
			'No Key Pwd',
			'Passage Mode',
			'Date',
			'Timezone Raw Offset',
			'Electric Quantity',
			'Bind Date',
			'Keyboard Pwd Version',
			'Lock Version Show Admin Kbpwd Flag',
			'Lock Version Group ID',
			'Lock Version Protocol Version',
			'Lock Version Protocol Type',
			'Lock Version Org ID',
			'Lock Version Logo URL',
			'Lock Version Scene'
		],
		component: 'SmartLockDeviceCard'
	},
	15: {
		name: 'Verkada Cameras',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FVerkadaCamera_Icon_dark.svg?alt=media&token=0d1a5933-cddb-424f-acb4-cfa5d38e28a0',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FVerkadaCamera_Icon_marker.svg?alt=media&token=44fd4610-7cc7-402e-aa8b-5afdd39ccfb8',
		showCustomFieldOnCard: ['Model', 'Serial Number', 'Camera Type', 'Site', 'Location', 'Camera Status'],
		showOnAdditionalFields: [],
		exportAbleDeviceCustomFields: [],
		editableCustomFields: [],
		component: 'GenericDefaultCategoryDeviceCard'
	},
	17: {
		name: 'Zezamii Access',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLock_Icon.svg?alt=media&token=7ca8f3b6-eb21-4bd9-a25d-3d1c96129276',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLock_Marker.svg?alt=media&token=43c8645e-9148-4890-af72-2c80f191d40c',
		showCustomFieldOnCard: ['Floor', 'Electric Quantity'],
		showOnAdditionalFields: [
			'Lock ID',
			'Alias',
			'Lock Mac',
			'Electric Quantity',
			'Electric Quantity Update Date',
			'Timezone Offset',
			'Automatic Lock Time(in Sec)',
			'Double Lock Flag',
			'Alarm Flag',
			'Sound Flag',
			'Name',
			'Remote Control Flag',
			'Created Date',
			'Updated Date'
		],
		exportAbleDeviceCustomFields: [
			'Lock ID',
			'Alias',
			'Lock Mac',
			'Electric Quantity',
			'Electric Quantity Update Date',
			'Timezone Offset',
			'Automatic Lock Time(in Sec)',
			'Double Lock Flag',
			'Alarm Flag',
			'Sound Flag',
			'Name',
			'Remote Control Flag',
			'Created Date',
			'Updated Date'
		],
		editableCustomFields: [],
		component: 'SmartLockDeviceCard'
	},
	18: {
		name: 'Zezamii Gateway',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartGateway_Icon.svg?alt=media&token=11524845-c28c-4ddc-a8ca-06e3baed87f3',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartGateway_Marker.svg?alt=media&token=6fdd1f85-16f2-4aab-a141-2bb3ddbda6d6',
		showCustomFieldOnCard: ['Floor'],
		showOnAdditionalFields: [],
		exportAbleDeviceCustomFields: [],
		editableCustomFields: [],
		component: 'SmartLockDeviceCard'
	},
	19: {
		name: 'Zezamii Locker',
		icon: 'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLocker_Icon.svg?alt=media&token=a2dfb177-7cce-439d-a72a-c38904ecff50',
		markerIcon:
			'https://firebasestorage.googleapis.com/v0/b/zezamii-3f2a8.appspot.com/o/remoteConfig%2FSmartLocker_Marker.svg?alt=media&token=17aee55e-0d77-4205-860c-c79b502acea8',
		showCustomFieldOnCard: ['Floor', 'Electric Quantity', 'Size'],
		editableCustomFields: ['Size', 'Feature'],
		showOnAdditionalFields: [
			'Lock ID',
			'Alias',
			'Lock Mac',
			'Electric Quantity',
			'Electric Quantity Update Date',
			'Timezone Offset',
			'Automatic Lock Time(in Sec)',
			'Double Lock Flag',
			'Alarm Flag',
			'Sound Flag',
			'Name',
			'Size',
			'Feature',
			'Remote Control Flag',
			'Created Date',
			'Updated Date'
		],
		exportAbleDeviceCustomFields: [
			'Lock ID',
			'Alias',
			'Lock Mac',
			'Electric Quantity',
			'Electric Quantity Update Date',
			'Timezone Offset',
			'Automatic Lock Time(in Sec)',
			'Double Lock Flag',
			'Alarm Flag',
			'Sound Flag',
			'Name',
			'Remote Control Flag',
			'Created Date',
			'Updated Date'
		],
		component: 'SmartLockDeviceCard'
	}
};
