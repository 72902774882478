import { borderRadius } from '@iso/lib/helpers/style_utils';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const StylesRenderButtonCardWrapper = styled.div`
	cursor: pointer;
	flex: ${(props) => (props.maxWidth ? '1 1 40%' : 0)};
	border: ${(props) => (props.border ? `1px solid #b6b6b6` : 'none')};
	padding: ${(props) => (props.padding ? `0px 10px !important` : '5px 10px !important')};
	${borderRadius('5px')}

	.header {
		display: flex;
		margin: 0px 0 20px;
		justify-content: safe;
	}
	.content {
		display: flex;
		align-items: center !important;
		justify-content: space-between;

		&:hover {
			color: ${palette('color', 10)};
			border-color: ${palette('color', 10)};
		}

		.customIcon {
			svg {
				margin-top: 5px;
				width: 20px;
				height: 20px;
			}
		}

		.row,
		.secondRow {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			margin-left: ${(props) => (props.icon || props.customIcon ? '10px' : 'none')};
		}
		.row {
			align-items: start;
		}

		.secondRow {
			align-items: end;
		}
	}
`;

export default StylesRenderButtonCardWrapper;
