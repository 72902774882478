import styled from 'styled-components';
import { Typography } from 'antd';
import { Modal } from '@Z/components/atoms';
import { palette } from 'styled-theme';

const GroupTitleWrapper = styled(Typography.Text)`
	display: flex !important;
	align-items: center !important;
	max-width: 100% !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	font-size: 22px !important;
	font-weight: 500 !important;
	opacity: 0.85 !important;
	margin: 0 !important;
	i {
		margin-top: 5px;
		font-size: 20px !important;
	}

	&.ant-typography-edit-content {
		.ant-input {
			font-size: 22px;
			font-weight: 500;
		}
		.ant-typography-edit-content-confirm {
			display: none;
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;

export const GroupModalWrapper = styled(Modal)`
	.groupInfoContainer {
		padding: 5px;
		background-color: ${palette('secondary', 7)};
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-content: space-between;
		padding: 15px;

		.infoHeaderCont {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
		.infoProp {
			font-size: 11px;
			font-weight: 600;
			margin-bottom: 10px;
			color: ${palette('text', 1)};
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.descContainer {
			padding-top: 2px;
		}

		.metaContainer {
			font-size: 9px;
			font-weight: 400;
			opacity: 0.6;
			justify-content: flex-end;

			.metaField {
				width: max-content;
				font-weight: 500;
				.metaLabel {
					font-weight: 400;
				}
			}

			.dateContainer {
				row-gap: 1px !important;

				.ant-space-item {
					justify-content: flex-end;
				}
			}
		}
	}
	.groupAssetsContainer {
		.deviceCardContainer {
			padding: 10px 0;
			column-width: 200px;
			column-gap: 4px;
			width: 100%;
			display: flow-root;
		}
		.containerHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
		}
		.noDevice {
			width: 100%;
			display: flex;
			min-height: 100px;
		}
	}
`;

export default GroupTitleWrapper;
