import styled from 'styled-components';
import { Divider } from 'antd';

export default styled(Divider)`
	.ant-divider-inner-text {
		font-weight: 500;
		padding-left: 0;
	}
	&.ant-divider-horizontal.ant-divider-with-text-left::before {
		width: 0;
	}
	&.ant-divider-horizontal.ant-divider-with-text-left::after {
		width: 100%;
	}
`;
