import React from 'react';
import { WidgetWrapperStyle } from './Widgets.styles';

const WidgetWrapper = ({ children, ...props }) => {
	return (
		<WidgetWrapperStyle className='isoWidgetsWrapper' {...props}>
			{children}
		</WidgetWrapperStyle>
	);
};

export default WidgetWrapper;
