import React, { useState, useEffect } from 'react';
import { smallTimeOptions } from '@iso/assets/styles/constants'; //ToDO: For Time Format
import { SimpleTable, SearchBar } from '@Z/components/atoms';
import { RenderActionOnInventory } from '@Z/components/molecules';
import { Col, Row } from 'antd';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import Button from '@iso/components/uielements/button';

const InventoryLocksTable = ({ workspaces }) => {
	const [filteredData, setFilteredData] = useState([]);
	const [filteredInfo, setFilteredInfo] = useState({});

	const inventoryLocks = useSelector((state) => state.inventory.locks);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	useEffect(() => {
		setFilteredData(inventoryLocks);
	}, [inventoryLocks]);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'lockID',
			key: 'lockID',
			width: 70,
			ellipsis: true,
			fixed: 'left',
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['lockID'],
						value: lock['lockID']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['lockID'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['lockID'] || null
		},
		{
			title: 'Asset ID',
			dataIndex: 'assetID',
			key: 'assetID',
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['assetID'],
						value: lock['assetID']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['assetID'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['assetID'] || null
		},
		{
			title: 'Inventory ID',
			dataIndex: 'inventoryUID',
			key: 'inventoryUID',
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['inventoryUID'],
						value: lock['inventoryUID']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['inventoryUID'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['inventoryUID'] || null
		},
		{
			title: 'Serial Number',
			dataIndex: 'lock Name',
			key: 'lock Name',
			ellipsis: true,

			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['lock Name'],
						value: lock['lock Name']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['lock Name'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['lock Name'] || null
		},
		{
			title: 'Electric Charge',
			dataIndex: 'electric Quantity',
			key: 'Electric Charge',
			ellipsis: true
		},
		{
			title: 'Lock Name',
			dataIndex: 'inventoryName',
			key: 'inventoryName',
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['inventoryName'],
						value: lock['inventoryName']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['inventoryName'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['inventoryName'] || null
		},
		{
			title: 'Lock Mac',
			dataIndex: 'lock Mac',
			key: 'lock Mac',
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['lock Mac'],
						value: lock['lock Mac']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['lock Mac'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['lock Mac'] || null
		},
		{
			title: 'Lock Group',
			dataIndex: 'lock Group Name',
			key: 'lock Group Name',
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['lock Group Name'],
						value: lock['lock Group Name']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['lock Group Name'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['lock Group Name'] || null
		},
		{
			title: 'Gateway',
			dataIndex: 'has Gateway',
			key: 'has Gateway',
			ellipsis: true,

			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: lock['has Gateway'],
						value: lock['has Gateway']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['has Gateway'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['has Gateway'] || null
		},
		{
			title: 'Date Added',
			dataIndex: 'addedOn',
			key: 'addedOn',

			render: (text) => <p> {new Date(text).toLocaleString('en-AU', smallTimeOptions)} </p>,
			ellipsis: true,
			filters: uniqBy(
				inventoryLocks.map((lock) => {
					return {
						text: new Date(lock.addedOn).toLocaleString('en-AU', smallTimeOptions),
						value: new Date(lock.addedOn).toLocaleString('en-AU', smallTimeOptions)
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => new Date(record.addedOn).toLocaleString('en-AU', smallTimeOptions) === value,
			filterSearch: true,
			filteredValue: filteredInfo.addedOn || null
		},
		{
			title: 'Workspace',
			dataIndex: 'orgName',
			key: 'orgName',
			ellipsis: true,
			fixed: 'right',
			width: 120,
			filters: uniqBy(
				inventoryLocks.map((gateway) => {
					return {
						text: gateway['orgName'],
						value: gateway['orgName']
					};
				}),
				'text'
			),
			filterMode: 'menu',
			onFilter: (value, record) => record['orgName'] === value,
			filterSearch: true,
			filteredValue: filteredInfo['orgName'] || null
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'actions',
			fixed: 'right',
			width: 250,
			render: (text, record) => (
				<RenderActionOnInventory
					key={record.inventoryUID}
					data={{ ...record, defaultCategoryID: constants.SMART_LOCKS }}
					type={'lock'}
					workspaces={workspaces}
				/>
			)
		}
	];

	const clearFilters = () => {
		setFilteredInfo({});
		setFilteredData(inventoryLocks);
	};

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object['lock Name'].toLowerCase().includes(query.toLowerCase()) ||
				object['inventoryName'].toLowerCase().includes(query.toLowerCase()) ||
				object['lock Mac'].toLowerCase().includes(query.toLowerCase()) ||
				object['lockID'].toString().toLowerCase().includes(query.toLowerCase())
		);
		setFilteredData(searchResult);
	};
	const handleChange = (_pagination, filters) => {
		setFilteredInfo(filters);
	};
	return (
		<div style={{ overflow: 'hidden', padding: '0px 10px 10px 10px' }}>
			<Row justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
						<Button size={'small'} onClick={clearFilters}>
							Clear Filter(s)
						</Button>
					</div>
					<SearchBar
						style={{ marginBottom: '10px' }}
						placeholder='Search Lock Name, ID Or Mac Address'
						enterButton='Search'
						loading={false}
						onChange={(e) => {
							handleSearch(e.target.value, inventoryLocks);
						}}
						onSearch={(query) => {
							handleSearch(query, inventoryLocks);
						}}
					/>
				</Col>
			</Row>
			<Row gutter={24} justify='start'>
				<Col lg={24} md={24} sm={24} xs={24}>
					<SimpleTable
						columns={columns}
						pageSize={500}
						dataSource={filteredData}
						onChange={handleChange}
						scroll={{ x: 1000 }}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default InventoryLocksTable;
