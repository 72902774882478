import React, { useState, useEffect } from 'react';
import Form from '@iso/components/uielements/form';
import { useSelector } from 'react-redux';
import Select, { SelectOption as Option, SelectDropDownWrapper } from '@iso/components/uielements/select';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';

const SelectPeopleDropdown = ({ key }) => {
	const { peopleData } = useSelector((state) => state.people);

	const [people, setPeople] = useState([]);
	const displayNames = useGetPersonDisplayName(true);

	useEffect(() => {
		const nonDeletedPeople = peopleData.filter((person) => !person.deletedDate);
		setPeople(nonDeletedPeople);

		return () => {
			setPeople([]);
		};
	}, [peopleData]);
	return (
		<React.Fragment>
			<Form.Item name='people' label='People'>
				<Select
					mode='multiple'
					style={{ width: '100%' }}
					showSearch
					placeholder={'Select People'}
					filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
					filterSort={(optionA, optionB) =>
						optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
					}
					getPopupContainer={() => document.getElementById('selectPeopleDropdown' + key)}
					dropdownStyle={{ position: 'relative' }}
				>
					{people.map((person, key) => (
						<Option key={key} value={person.personID} title={displayNames[person.personID].title}>
							{displayNames[person.personID].displayName}
						</Option>
					))}
				</Select>
			</Form.Item>
			<SelectDropDownWrapper id={'selectPeopleDropdown' + key} />
		</React.Fragment>
	);
};

export default SelectPeopleDropdown;
