import { notification } from '@iso/components';
import theme from '@iso/config/theme/default';
import { Map } from 'immutable';

export function clearToken() {
	localStorage.removeItem('od');
	localStorage.removeItem('id_token');
}

export function getToken() {
	try {
		const idToken = localStorage.getItem('id_token');
		return new Map({ idToken });
	} catch (err) {
		clearToken();
		return new Map();
	}
}

export function arrayEqual(array1, array2) {
	return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
	givenTime = new Date(givenTime);
	const milliseconds = new Date().getTime() - givenTime.getTime();
	const numberEnding = (number) => {
		return number > 1 ? 's' : '';
	};
	const number = (num) => (num > 9 ? '' + num : '0' + num);
	const getTime = () => {
		let temp = Math.floor(milliseconds / 1000);
		const years = Math.floor(temp / 31536000);
		if (years) {
			const month = number(givenTime.getUTCMonth() + 1);
			const day = number(givenTime.getUTCDate());
			const year = givenTime.getUTCFullYear() % 100;
			return `${day}-${month}-${year}`;
		}
		const days = Math.floor((temp %= 31536000) / 86400);
		if (days) {
			if (days < 28) {
				return days + ' day' + numberEnding(days);
			} else {
				const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				const month = months[givenTime.getUTCMonth()];
				const day = number(givenTime.getUTCDate());
				return `${day} ${month}`;
			}
		}
		const hours = Math.floor((temp %= 86400) / 3600);
		if (hours) {
			return `${hours} hour${numberEnding(hours)} ago`;
		}
		const minutes = Math.floor((temp %= 3600) / 60);
		if (minutes) {
			return `${minutes} minute${numberEnding(minutes)} ago`;
		}
		return 'a few seconds ago';
	};
	return getTime();
}

export function stringToInt(value, defValue = 0) {
	if (!value) {
		return 0;
	} else if (!isNaN(value)) {
		return parseInt(value, 10);
	}
	return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
	const val = stringToInt(value, defValue);
	return val > -1 ? val : defValue;
}

export const removeEmptyOrNull = (obj) => {
	Object.keys(obj).forEach((k) => {
		if (obj[k] && typeof obj[k] === 'object') {
			if (Array.isArray(obj[k])) {
				if (obj[k].length === 0) {
					delete obj[k];
				} else {
					obj[k].forEach((item) => removeEmptyOrNull(item));
				}
			} else {
				removeEmptyOrNull(obj[k]);
			}
		} else if (!obj[k] && obj[k] !== undefined) {
			delete obj[k];
		} else if (obj[k] === undefined) {
			delete obj[k];
		} else if (typeof obj[k] === 'string') {
			obj[k] = obj[k].trim();
		}
	});
	return obj;
};
/**
 * @description
 * @author Kishan Virani
 * @date 27/04/2022
 * @param {*} textToCopy - text to copy
 * @param {*} label - label to show in toast
 **/
export const copyToClipBoard = async (textToCopy, label) => {
	try {
		await navigator.clipboard.writeText(textToCopy);
		notification('success', `Copied ${label ? label : ''}` + textToCopy);
	} catch (err) {
		notification('error', `Failed to Copy ${label ? label : ''}`);
	}
};

/**
 * @description Simple Function to search on the single dimensional array and setting it to the state
 * @author Kishan Virani
 * @date 31/10/2022
 * @param {*} array
 * @param {*} searchTerm
 * @param {*} columns
 * @param {*} setState
 */
export const filterFromArray = (array, searchTerm, columns, setState) => {
	const searchResult = array.filter((row) => {
		return columns.some((column) => {
			return String(row[column]).toLowerCase().includes(searchTerm.toLowerCase());
		});
	});
	setState(searchResult);
};

export const RemoveDuplicateElementsArray = (array1, array2, property) => {
	const newArray = [];
	for (let i = 0; i < array1.length; i++) {
		let same = false;
		if (property) {
			for (let j = 0; (j < array2?.length) & !same; j++) {
				if (array1[i][property] === array2[j][property] && array1[i][property] === array2[j][property])
					same = true;
			}
		} else {
			for (let j = 0; (j < array2?.length) & !same; j++) {
				if (array1[i][property] === array2[j][property] && array1[i][property] === array2[j][property])
					same = true;
			}
		}
		if (!same) newArray.push(array1[i]);
	}
	return newArray;
};

//Generate random color
export const getRandomColor = (index) => {
	const filteredTagColor = theme.palette.tagColor.filter((color) => color !== 'yellow' && color !== 'green');
	const colors = filteredTagColor;
	const colorIndex = index % colors.length; // Use modulo to cycle through colors
	return colors[colorIndex];
};

//Get display name of the person and email
export const getDisplayName = (person) => {
	// Get the display name of the person
	const displayName = person?.displayName || (person?.isNewUser ? person?.email : 'person name missing. ');

	// Get the title of the person
	const title = person?.isNewUser
		? "Person hasn't hopped on Zezamii yet, but their name will pop up once they accept your invite!"
		: person?.email;

	return { displayName, title };
};
