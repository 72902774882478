import Button from '@iso/components/uielements/button';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { Typography } from 'antd';
import React from 'react';
import SearchCategoryWrapper from './searchCategoryWrapper.styles';

const { Paragraph } = Typography;

const SearchCategoryCard = ({ category, setVisibility }) => {
	const viewIndividualCategory = () => {
		if (setVisibility) {
			setVisibility(false);
		}
	};

	const CategoryLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	return (
		<SearchCategoryWrapper>
			<CategoryLink type='deviceCategory' dataID={category.categoryID}>
				<p className='headerText' onClick={() => viewIndividualCategory()}>
					{category.name}
				</p>
			</CategoryLink>
			<div className='paragraphContainer'>
				<Paragraph
					className='paragraphText'
					ellipsis={{
						rows: 2
					}}
				>
					{category.description}
				</Paragraph>
				<CategoryLink type='deviceCategory' dataID={category.categoryID}>
					<Button className='buttonContainer' size='small' onClick={() => viewIndividualCategory()}>
						View
					</Button>
				</CategoryLink>
			</div>
		</SearchCategoryWrapper>
	);
};

export default SearchCategoryCard;
