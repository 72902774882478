import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from '@iso/components';
import devicesAction from '@iso/redux/devices/actions';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { Tooltip } from 'antd';

const DeviceDeleteModel = ({ deleteVisible, setDeleteVisible, children, data }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const dispatch = useDispatch();
	const { loadDevices } = devicesAction;
	const [loading, setLoading] = useState(false);
	const { deviceID, name, defaultCategoryID, orgID } = data;

	const handleDelete = () => {
		setLoading(true);
		superFetch
			.delete(`/devices/${deviceID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Device Deleted.');
						setDeleteVisible(false);
					}
					dispatch(loadDevices());
				}
				setLoading(false);
				return res;
			})
			.catch((err) => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};

	const isPermittedDeleteDevice = useManagePermittedRoles('deleteDevice', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});
	return (
		<div>
			<Tooltip
				title={
					!isPermittedDeleteDevice ? "You don't have permission" : 'Delete this Device from your Workspace'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							danger
							onClick={() => {
								setDeleteVisible(true);
							}}
							disabled={
								!isPermittedDeleteDevice && !constants.SMART_CATEGORIES.includes(defaultCategoryID)
							}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Delete
						</Button>
					</>
				)}
			</Tooltip>

			<Modal
				title={`Delete a Device`}
				danger
				visible={deleteVisible}
				onCancel={() => setDeleteVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setDeleteVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete
					<span style={{ fontWeight: 600, color: '#ff5b58' }}> '{name} '</span> from the organisation?
				</p>
			</Modal>
		</div>
	);
};

export default DeviceDeleteModel;
