const actions = {
	LOAD_TEAMS_DATA: 'LOAD_TEAMS_DATA',
	LOAD_TEAMS_SUCCESS: 'LOAD_TEAMS_SUCCESS',
	LOAD_TEAMS_ERROR: 'LOAD_TEAMS_ERROR',

	loadTeams: () => ({
		type: actions.LOAD_TEAMS_DATA
	})
};
export default actions;
