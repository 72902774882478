import infoWindowImg1 from '@iso/assets/images/image1.jpg'; // Dummy image for info window
import defaultDrawOptions, {
	defaultCategoryMarker,
	defaultMarker,
	drawSingleMarkerOption
} from '@iso/config/leafletMapDrawOptions.config';
import {
	Control,
	CRS,
	Draw,
	featureGroup,
	Icon,
	ImageOverlay,
	LatLngBounds,
	Map,
	marker as leafletMarker,
	Marker
} from 'leaflet';
import 'leaflet-draw';
import { DeviceMarkerContent } from './deviceMarker';

const mapRenderer = ({
	mapRef,
	map,
	url,
	markerList,
	drawControlEnabled,
	drawSingleMarkerEnabled,
	editMarkerLocation,
	draggableMarkerLocation,
	setMap,
	setMarker,
	scrollWheelZoom,
	mapHeight,
	mapWidth,
	center,
	minZoom,
	zoom,
	setSelectedDevice,
	setVisible
}) => {
	// Clean the map when unmounting
	if (mapRef.current !== null && map === null) {
		// Render a New Map Instance
		const mapInstance = new Map(mapRef.current, {
			crs: CRS.Simple,
			scrollWheelZoom: scrollWheelZoom,
			center: center ? center : [mapHeight / 2, mapWidth / 2],
			minZoom: minZoom,
			zoom: zoom,
			attributionControl: false
		});
		if (center != null && zoom != null) {
			mapInstance.setView(center, zoom);
		}
		setMap(mapInstance);
	}

	// renders the map image
	if (url && map) {
		const bounds = new LatLngBounds([
			[0, 0],
			[mapHeight, mapWidth]
		]);
		const imageLayer = new ImageOverlay(url ? url : infoWindowImg1, [
			[0, 0],
			[mapHeight, mapWidth]
		]);
		map.eachLayer((layer) => {
			map.removeLayer(layer);
		});
		setMarker && setMarker({});
		imageLayer.addTo(map);
		map.setMaxBounds(bounds);
		map.fitBounds(bounds);
	}

	//
	//  Markers and other additional layers are added to the map
	//
	// Render all the marker on the map if any
	if (markerList && markerList.length > 0 && map) {
		markerList.forEach((marker) => {
			const m = leafletMarker([marker.y, marker.x], {
				icon: new Icon({
					iconUrl: marker.markerIcon,
					iconSize: marker.selectedDevice ? [70, 75] : [50, 55],
					iconAnchor: [26, 47]
				}),
				bubblingMouseEvents: true,
				riseOnHover: true
			});
			m.addTo(map);

			// Attach marker click, Mouse Over, Mouse Out events
			if (marker.uID && marker.name) {
				m.bindPopup(DeviceMarkerContent(marker));
				m.on('click', () => {
					setSelectedDevice(marker);
					setVisible(true);
				});
				m.on('mouseover', function (e) {
					this.openPopup();
				});
				m.on('mouseout', function (e) {
					this.closePopup();
				});
			}
		});
	}

	// Add draw layer to the map
	if (drawControlEnabled && map) {
		// This will allow you to draw on the map using the Line, marker, polygon, circle, rectangle, polyline.
		var drawnItems = featureGroup();
		map.addLayer(drawnItems);

		// draw control options
		var drawControl = new Control.Draw(defaultDrawOptions);

		// prevent multiple render of draw control
		if (map.getContainer().querySelector('.leaflet-draw') === null) map.addControl(drawControl);

		// Listen to the Map draw events and handle them
		map.on(Draw.Event.CREATED, function (e) {
			var layer = e.layer;
			layer.addTo(map);
		});
	} else if (drawSingleMarkerEnabled && map) {
		// This will allow you to draw on the map using one marker.
		var markerDrawControl = new Control.Draw(drawSingleMarkerOption);

		// prevent multiple render of draw control
		if (map.getContainer().querySelector('.leaflet-draw') === null) map.addControl(markerDrawControl);

		// Listen to the Map draw events and handle them
		map.on(Draw.Event.CREATED, function (e) {
			// remove the draw control
			map.removeControl(markerDrawControl);

			// get all the layers
			let layers = [];
			map.eachLayer((l) => layers.push(l));

			// if marker not added, then add the marker on the map.
			if (!layers.some((l) => l instanceof Marker)) {
				var layer = e.layer;
				const marker = new Marker(layer.getLatLng(), {
					draggable: true,
					icon: new Icon({
						iconUrl: defaultMarker,
						iconSize: [50, 55],
						iconAnchor: [26, 47]
					})
				});

				// add marker to the maps and record the marker position
				marker.addTo(map);
				setMarker(layer.getLatLng());

				// register events on marker
				marker.on('click', () => {
					marker.remove();
					setMarker({});
					map.addControl(markerDrawControl);
				});

				// set latest marker latlong to state
				marker.on('dragend', (e) => {
					setMarker(marker.getLatLng());
				});
			}
		});
	} else if (draggableMarkerLocation && map && editMarkerLocation) {
		// This will allow you to drag the marker on the map after adding marker on the map.
		const currentDrawControl = map.getContainer().querySelector('.leaflet-draw');
		if (currentDrawControl) {
			map.removeControl(currentDrawControl);
		}

		// Add marker and listen to the drag event for editing device location
		const marker = new Marker([editMarkerLocation[0].y, editMarkerLocation[0].x], {
			draggable: true,
			icon: new Icon({
				iconUrl: defaultCategoryMarker[editMarkerLocation[0].defaultCategoryID] || defaultMarker,
				iconSize: [50, 55],
				iconAnchor: [26, 47]
			})
		}).addTo(map);

		// init marker
		setMarker({ ...marker.getLatLng(), defaultCategoryID: editMarkerLocation[0].defaultCategoryID });

		// register events on marker
		marker.on('dragend', (e) => {
			setMarker({ ...marker.getLatLng(), defaultCategoryID: editMarkerLocation[0].defaultCategoryID });
		});
	}
};

export default mapRenderer;
