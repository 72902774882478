import React from 'react';
import NoAPIKey from '@iso/components/utility/noApiKey';
import googleConfig from '@iso/config/google_map.config';
import WrappedMaps from './wrappedMaps';

const GoogleMap = (props) => {
	return (
		<div>
			{props.children}
			{googleConfig.apiKey ? <WrappedMaps data={props.data} /> : <NoAPIKey />}
		</div>
	);
};
export default GoogleMap;
