import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const AntInputNumber = (ComponentName) => styled(ComponentName)`
	&.ant-input-number {
		padding: 4px 10px;
		width: 100%;
		height: 35px;
		color: ${palette('text', 1)};
		padding: 0;
		font-size: 13px;
		border: 1px solid ${palette('border', 0)};
		width: 100%;
		max-width: 80px;
		${borderRadius('10px')};

		.ant-input-number-input {
			height: 35px;
			color: ${palette('text', 1)};
			padding: 0 10px;
			text-align: ${(props) => (props['data-rtl'] === 'rtl' ? 'right' : 'left')};
		}

		&.ant-input-number-focused {
			// for consistency with antd input
			border-color: #40a9ff;
		}

		.ant-input-number-handler-wrap {
			left: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
			right: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
			border-radius: ${(props) => (props['data-rtl'] === 'rtl' ? '4px 0 0 4px' : '0 4px 4px 0')};
			/* border-left: ${(props) => (props['data-rtl'] === 'rtl' ? 0 : 1)}px solid
        ${palette('border', 1)};
      border-right: ${(props) => (props['data-rtl'] === 'rtl' ? 1 : 0)}px solid
        ${palette('border', 1)}; */
			border-radius: 0 10px 10px 0;
		}

		.ant-input-number-handler:hover {
			.ant-input-number-handler-up-inner,
			.ant-input-number-handler-down-inner {
				color: ${palette('primary', 0)};
			}
		}

		.ant-input-number-handler {
			:active {
				background-color: transparent;
			}
		}

		.ant-input-number-handler-up-inner,
		.ant-input-number-handler-down-inner {
			/* font-size: 23px; */
			color: ${palette('text', 2)};

			:hover {
				color: ${palette('primary', 0)} !important;
			}
			&:before {
				display: block;
				font-family: 'ionicons' !important;
			}
		}

		/* .ant-input-number-handler-up-inner {
      &:before {
        content: '\f365';
      }
    }

    .ant-input-number-handler-down-inner {
      &:before {
        content: '\f35f';
      }
    } */
	}
`;

export default AntInputNumber;
