import React from 'react';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import Button from '@iso/components/uielements/button';
import { DownOutlined } from '@ant-design/icons';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import {
	EditDeviceCategoryButton,
	ExportDeviceCategoryCSVButton,
	ModifyCategoryFieldsButton
} from '@Z/components/atoms';
import { CategoryDeleteModal } from '@Z/components/molecules';

const RenderActionMenu = ({ category }) => {
	// local state
	const [deleteVisible, setDeleteVisible] = React.useState(false);

	// permission check for edit and delete actions
	const isPermittedDeleteCategory = useManagePermittedRoles('deleteCategory', {
		orgID: [category?.orgs[0]?.orgID],
		siteID: []
	});
	const isPermittedUpdateCategory = useManagePermittedRoles('updateCategory', {
		orgID: [category?.orgs[0]?.orgID],
		siteID: [],
		floorID: []
	});

	// return null if category is not defined
	if (!category) return null;

	// get the default state of the category.
	const { isDefault } = category;

	const actionMenu = () => (
		<DropdownMenu>
			{!isDefault && isPermittedUpdateCategory && (
				<EditDeviceCategoryButton category={category} key='1'>
					<MenuItem className='deleteButton' style={{ fontSize: '12px' }} eventKey='1'>
						Edit Category
					</MenuItem>
				</EditDeviceCategoryButton>
			)}
			{!isDefault && isPermittedDeleteCategory && (
				<MenuItem
					className='deleteButton'
					style={{ fontSize: '12px' }}
					onClick={() => {
						setDeleteVisible(true);
					}}
					key='2'
				>
					Delete Category
				</MenuItem>
			)}
			{!isDefault && category.fields && category.fields.length > 0 && (
				<ModifyCategoryFieldsButton category={category} key='4'>
					<MenuItem className='deleteButton' style={{ fontSize: '12px' }} eventKey='4'>
						Modify Category Fields
					</MenuItem>
				</ModifyCategoryFieldsButton>
			)}
			<MenuItem className='exportButton' style={{ fontSize: '12px' }} eventKey='3'>
				<ExportDeviceCategoryCSVButton category={category} />
			</MenuItem>
		</DropdownMenu>
	);

	return (
		<React.Fragment>
			<Dropdown overlay={actionMenu()} trigger={['click']}>
				<Button>
					Actions <DownOutlined />
				</Button>
			</Dropdown>
			<CategoryDeleteModal
				deleteModalVisible={deleteVisible}
				setDeleteModalVisible={setDeleteVisible}
				categoryToBeDeleted={category}
			/>
		</React.Fragment>
	);
};

export default RenderActionMenu;
