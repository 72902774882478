import { Loading } from '@Z/components/atoms';
import Menu from '@iso/components/uielements/menu';
import { CONSTANTS_SETTINGS } from '@iso/config/settingsContainer';
import settingsActions from '@iso/redux/settings/actions';
import { Empty, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RenderContentSetting } from './components';
import { MenuWrapper } from './settings.style';
const { updateSelectedSetting } = settingsActions;

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const RenderOrganisationSettings = () => {
	const dispatch = useDispatch();

	const { entityTypes } = useSelector((state) => state.types);

	const { pending, selectedSettingGroupID, personSettings } = useSelector((state) => state.settings);
	const { groupSettingsData } = useSelector((state) => state.defaultSettings);

	const [defaultSelectedSetting, setDefaultSelectedSetting] = useState(null);

	useEffect(() => {
		if (selectedSettingGroupID) {
			setDefaultSelectedSetting(selectedSettingGroupID);
		} else if (personSettings.length > 0) {
			setDefaultSelectedSetting(CONSTANTS_SETTINGS.PERSON_SETTING_TYPE);
		} else {
			setDefaultSelectedSetting(groupSettingsData[0]?.groupID);
		}
	}, [selectedSettingGroupID, personSettings, groupSettingsData]);

	return pending ? (
		<Loading />
	) : (
		<MenuWrapper>
			<Layout>
				{entityTypes && entityTypes.length > 0 && (
					<Sider collapsible={false} width={210} className='siderContainer' theme='light'>
						<Menu
							className='menuContainer'
							mode='inline'
							selectedKeys={defaultSelectedSetting}
							defaultOpenKeys={entityTypes.map((type) => type.entityTypeID.toString())}
						>
							{personSettings.length > 0 && (
								<Menu.Item
									key={CONSTANTS_SETTINGS.PERSON_SETTING_TYPE}
									onClick={() => {
										dispatch(updateSelectedSetting(CONSTANTS_SETTINGS.PERSON_SETTING_TYPE));
									}}
								>
									Profile
								</Menu.Item>
							)}

							{entityTypes.map((type) =>
								groupSettingsData.filter((a) => a.entityTypeID === type.entityTypeID).length > 0 ? (
									<SubMenu key={type.entityTypeID} title={type.name} className='menuGroup'>
										{groupSettingsData
											.filter((a) => a.entityTypeID === type.entityTypeID)
											.map((i) => (
												<Menu.Item
													eventKey={i.groupID}
													key={i.groupID}
													onClick={() => {
														dispatch(updateSelectedSetting(i.groupID));
													}}
												>
													{i.title}
												</Menu.Item>
											))}
									</SubMenu>
								) : null
							)}
						</Menu>
					</Sider>
				)}

				{defaultSelectedSetting ? (
					<RenderContentSetting
						settingGroupID={defaultSelectedSetting}
						entityType={
							defaultSelectedSetting === CONSTANTS_SETTINGS.PERSON_SETTING_TYPE
								? CONSTANTS_SETTINGS.PERSON_SETTING_TYPE
								: CONSTANTS_SETTINGS.ORG_SETTING_TYPE
						}
					/>
				) : (
					<Content className='noCategoriesContainer'>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Settings created.</span>} />
					</Content>
				)}
			</Layout>
		</MenuWrapper>
	);
};
export default RenderOrganisationSettings;
