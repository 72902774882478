import { ComponentLabel, Loading, ProfileImageComponent, RenderTeamActions } from '@Z/components/atoms';
import { DownOutlined } from '@ant-design/icons';
import basicStyle, { smallTimeOptions } from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import ContentHolder from '@iso/components/utility/contentHolder';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import teamAction from '@iso/redux/teams/actions';
import { Col, Row, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamSummaryWrapper, { MetaContainer, TypographyParagraphWrapper } from './TS.styles';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
const { loadTeams } = teamAction;

const IndividualTeamSummary = ({ data }) => {
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const dispatch = useDispatch();
	const [loadings, setLoading] = React.useState(true);
	const { teamsData } = useSelector((state) => state.teams);
	const [teamList, setTeamList] = React.useState([]);
	const { sitesData } = useSelector((state) => state.sites);

	React.useEffect(() => {
		setLoading(true);
		if (data && Object.keys(data).length > 0) {
			setTeamList(teamsData);
			setLoading(false);
		}
	}, [data, teamsData]);

	const isPermittedUpdateTeam = useManagePermittedRoles('updateTeam', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		teamID: [data.teamID]
	});
	const onEdit = (value, field) => {
		if (field === 'name' && data.name !== value) {
			if (!/^[a-zA-Z0-9 -/]+$/.test(value)) {
				notification('error', 'Team name can only contain letters, numbers and spaces.');
			} else if (value.length > 40) {
				notification('error', 'Team name can only be 40 characters long.');
			} else {
				superFetch
					.patch('/teams/' + data.teamID, { name: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(loadTeams());
								notification('success', 'Team Name Updated.');
							}
						}
						return res;
					})
					.catch(() => {
						notification('error', 'Update Failed, Please try again.');
					});
			}
		}

		if (field === 'description' && data.description !== value) {
			if (!/^[a-zA-Z0-9 \n.;/,-]+$/.test(value)) {
				notification('error', 'Team description can only contain letters, numbers, ,/;. and spaces.');
			} else if (value.length > 320) {
				notification('error', 'Team description can only be 320 characters long.');
			} else {
				superFetch
					.patch('/teams/' + data.teamID, { description: value })
					.then((res) => {
						if (res?.statusCode === 200) {
							dispatch(loadTeams());
							notification('success', 'Team Description Updated.');
						} else {
							throw new Error('Update Failed, Please try again.');
						}
					})
					.catch(() => {
						notification('error', 'Update Failed, Please try again.');
					});
			}
		}
		return;
	};

	// HOC
	const BaseLink = withHyperLink((a) => {
		return <p>{a.children}</p>;
	});

	const NameWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const SiteWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const teamListing = () => {
		return (
			<DropdownMenu>
				{teamList.map((teams, key) => {
					return (
						<NameWithLink type='team' dataID={teams.teamID}>
							<MenuItem key={key}>{teams.name}</MenuItem>
						</NameWithLink>
					);
				})}
			</DropdownMenu>
		);
	};

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden', position: 'relative' }}>
			<TeamSummaryWrapper>
				<Row lg={12} md={12} sm={24} xs={24} style={rowStyle}>
					<div className=' teamHeaderCol'>
						<Col>
							<ProfileImageComponent entityObject={data} size={130} entityName={'Team'} />
						</Col>
						<Col className='teamInfoContainer'>
							<Row style={{ justifyContent: 'space-between' }}>
								<div style={{ flexGrow: 1 }}>
									<ComponentLabel
										editable={isPermittedUpdateTeam}
										text={data.name}
										editableProps={{
											editable: {
												tooltip: 'click to edit Team name',
												icon: (
													<i
														className='material-icons-outlined'
														style={{ color: '#4d4f5c', fontSize: '20px' }}
													>
														mode_edit
													</i>
												),
												triggerType: ['text', 'icon'],
												onChange: (value) => onEdit(value, 'name')
											}
										}}
									/>
								</div>

								<div className='actionButton'>
									{teamList.length > 0 ? (
										<Dropdown overlay={teamListing()} trigger={['click']}>
											<Button className='switchTeamButton'>
												Switch Team <DownOutlined />
											</Button>
										</Dropdown>
									) : null}
									<RenderTeamActions team={data} onIndividualTeam />
								</div>
							</Row>
							<Row gutter={[0, 7]}>
								<div className='infoContainer'>
									<TypographyParagraphWrapper
										editable={
											isPermittedUpdateTeam && {
												tooltip: 'Click to edit Description',
												onChange: (value) => onEdit(value, 'description'),
												autoSize: { maxRows: 5 },
												icon: (
													<i
														className='material-icons-outlined'
														style={{ color: '#4d4f5c', fontSize: '15px' }}
													>
														mode_edit
													</i>
												),
												triggerType: ['text', 'icon']
											}
										}
										className='infoProp'
									>
										{data.description}
									</TypographyParagraphWrapper>
									<div className='infoDiv'>
										<label className='infoLabel'>Site: </label>
										<p className='infoProp'>
											{sitesData.find((site) => site.siteID === data.siteID) ? (
												<SiteWithLink type='site' dataID={data.siteID}>
													<p
														key={data.siteID}
														style={{
															cursor: 'pointer'
														}}
													>
														{sitesData.find((site) => site.siteID === data.siteID).name}
													</p>
												</SiteWithLink>
											) : (
												'N/A'
											)}
										</p>
									</div>
								</div>
							</Row>
						</Col>
					</div>
				</Row>
			</TeamSummaryWrapper>
			<MetaContainer>
				<Row className='metaContainer'>
					<Space wrap className='dateContainer'>
						<p
							className='metaField'
							title={new Date(data.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
						>
							<span className='metaLabel'>Updated: </span>
							{new Date(data.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
						</p>
						<p
							className='metaField'
							title={new Date(data.createdDate).toLocaleString('en-AU', smallTimeOptions)}
						>
							<span className='metaLabel'>Created: </span>
							{new Date(data.createdDate).toLocaleString('en-AU', smallTimeOptions)}
						</p>
					</Space>
				</Row>
			</MetaContainer>
		</IsoWidgetBox>
	);
};

export default IndividualTeamSummary;
