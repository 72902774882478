import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Tooltip from '@iso/components/uielements/tooltip';
import { entityType } from '@iso/config/entityType';
import SuperFetch from '@iso/lib/helpers/superFetch';
import floorsAction from '@iso/redux/floors/actions';
import organisationAction from '@iso/redux/organisation/actions';
import peopleAction from '@iso/redux/people/actions';
import siteAction from '@iso/redux/sites/actions';
import teamsAction from '@iso/redux/teams/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const { loadTeams } = teamsAction;
const { loadFloors } = floorsAction;
const { loadOrganisation } = organisationAction;
const { loadSites } = siteAction;
const { loadPeople } = peopleAction;

const DeleteEntityAddressModal = ({ visible, setVisible, children, data = {}, type }) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const dispatchActions = () => {
		switch (type) {
			case entityType.WORKSPACE:
				dispatch(loadOrganisation());
				break;
			case entityType.SITE:
				dispatch(loadSites());
				break;
			case entityType.FLOOR:
				dispatch(loadSites());
				dispatch(loadFloors());
				break;
			case entityType.TEAM:
				dispatch(loadTeams());
				break;
			case entityType.PERSON:
				dispatch(loadPeople());
				break;
			default:
				break;
		}
	};

	const handleDelete = async () => {
		const formData = {};

		switch (type) {
			case entityType.WORKSPACE:
				formData.orgID = data.orgID;
				break;
			case entityType.SITE:
				formData.siteID = data.siteID;
				break;
			case entityType.FLOOR:
				formData.floorID = data.floorID;
				break;
			case entityType.TEAM:
				formData.teamID = data.teamID;
				break;
			case entityType.PERSON:
				formData.personID = data.personID;
				break;
			default:
				break;
		}

		try {
			setLoading(true);

			const res = await SuperFetch.delete(`/addresses/${data.addressID}`);
			if (res.statusCode === 200) {
				dispatchActions();
				handleCancel();
				notification('success', 'Address Deleted Successful.');
			} else if (res.error.statusCode === 400) {
				handleCancel();
				notification('error', 'Please fill out all required information with proper type.');
			}
		} catch (err) {
			setLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	const handleCancel = () => {
		setVisible(false);
		setLoading(false);
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Tooltip title={'Delete Address'}>
					<>
						<Button
							size='small'
							onClick={() => setVisible(true)}
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							Delete Address
						</Button>
					</>
				</Tooltip>
			)}
			<Modal
				title={`Delete Address`}
				danger
				visible={visible}
				onCancel={handleCancel}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => handleCancel()}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>Are you sure you want to permanently delete the address?</p>
			</Modal>
		</>
	);
};

export default DeleteEntityAddressModal;
