// Use the default category ID to get the sample devices.
export const sampleDevicesConfig = {
	'1': [
		{
			name: 'First Aid',
			icon: ''
		},
		{
			name: 'Instruction Booklet/Plans',
			icon: ''
		},
		{
			name: 'PPE Disposable',
			icon: ''
		},
		{
			name: 'PPE Reusable',
			icon: ''
		},
		{
			name: 'Electronics',
			icon: ''
		},
		{
			name: 'Emergency Buttons',
			icon: ''
		},
		{
			name: 'Medical Pendants',
			icon: ''
		}
	],
	'Fire & Emergency': [
		{
			name: 'Fire alarms',
			icon: ''
		},
		{
			name: 'Fire Extinguishers',
			icon: ''
		},
		{
			name: 'Fire Blankets',
			icon: ''
		},
		{
			name: 'Fire Hydrants',
			icon: ''
		},
		{
			name: 'Fire Hose Reels',
			icon: ''
		},
		{
			name: 'Sprinkler systems',
			icon: ''
		},
		{
			name: 'Fire Doors/Hardware',
			icon: ''
		},
		{
			name: 'Fire Alarm Panel (control panel)',
			icon: ''
		},
		{
			name: 'Emergency Lighting',
			icon: ''
		},
		{
			name: 'Radiation Monitors',
			icon: ''
		},
		{
			name: 'Air Quality Monitors',
			icon: ''
		},
		{
			name: 'Leak Detectors',
			icon: ''
		},
		{
			name: 'Barrier',
			icon: ''
		}
	],
	'Security': [
		{
			name: 'Security Software',
			icon: ''
		},
		{
			name: 'Locks',
			icon: ''
		},
		{
			name: 'Safes',
			icon: ''
		},
		{
			name: 'Cameras',
			icon: ''
		},
		{
			name: 'Movement Sensors',
			icon: ''
		},
		{
			name: 'Key Cards',
			icon: ''
		}
	],
	'12': [
		{
			name: 'Passcode Lock',
			icon: 'DashboardAssets/TTLockImages/Passcode_Lock.png'
		},
		{
			name: 'Fingerprint Lock',
			icon: 'DashboardAssets/TTLockImages/Fingerprint_Lock.png'
		},
		{
			name: 'Glass Door Lock',
			icon: 'DashboardAssets/TTLockImages/Glass_Door_Lock.png'
		},
		{
			name: 'Deadbolt Lock',
			icon: 'DashboardAssets/TTLockImages/Deadbolt.png'
		},
		{
			name: 'Locker Lock',
			icon: 'DashboardAssets/TTLockImages/Locker_Lock.png'
		},
		{
			name: 'Handle Lock',
			icon: 'DashboardAssets/TTLockImages/Handle_Lock.png'
		},
		{
			name: 'Remote Controller',
			icon: 'DashboardAssets/TTLockImages/Remote_Controller.png'
		},
		{
			name: 'Latch Lock',
			icon: 'DashboardAssets/TTLockImages/Latch_Lock.png'
		},
		{
			name: 'Wireless Keypad',
			icon: 'DashboardAssets/TTLockImages/Wireless_Keypad.png'
		},
		{
			name: 'Parking Lock',
			icon: 'DashboardAssets/TTLockImages/Parking_Lock.png'
		},
		{
			name: 'Wall Reader',
			icon: 'DashboardAssets/TTLockImages/Wall_Reader.png'
		},
		{
			name: 'U Lock',
			icon: 'DashboardAssets/TTLockImages/U_Lock.png'
		},
		{
			name: 'Padlock',
			icon: 'DashboardAssets/TTLockImages/Padlock.png'
		}
	]
};
