import React from 'react';
import Menu from '@iso/components/uielements/menu';
import { Layout } from 'antd';
import { MenuWrapper } from './settings.style';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import PropTypes from 'prop-types';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const index = ({ categoryTypes, categoryData }) => {
	return (
		<MenuWrapper>
			<Layout>
				<Sider collapsible={false} width={210} className='siderContainer' theme='light'>
					<Menu
						className='menuContainer'
						mode='inline'
						defaultOpenKeys={categoryTypes.map((type) => type.typeID.toString())}
					>
						{
							// Category Type Render
							categoryTypes.map((type) => (
								<SubMenu key={type.typeID} title={type.name} className='menuGroup'>
									<div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
										{/* // Options Depends Category  */}
										{categoryData
											.filter((item) => item.typeID === type.typeID)
											.map((item) => (
												<Menu.Item eventKey={item.categoryID} key={item.categoryID}>
													{item.name}
												</Menu.Item>
											))}
									</div>
								</SubMenu>
							))
						}
					</Menu>
				</Sider>
				<Layout className='devicesMainContentContainer'>
					<Content className='deviceContentContainer'>
						<IsoWidgetBox
							style={{
								display: 'flex',
								flexDirection: 'column'
							}}
						></IsoWidgetBox>
					</Content>
				</Layout>
			</Layout>
		</MenuWrapper>
	);
};
export default index;

index.propTypes = {
	categoryTypes: PropTypes.array,
	categoryData: PropTypes.array
};
