import React, { useEffect, useState } from 'react';
import OrgInfoWrapper from './OrgInfoWrapper.styles';
import Row from '@iso/ui/Antd/Grid/Row';
import Col from '@iso/ui/Antd/Grid/Col';
import { Empty } from 'antd';
import withHyperLink from '@iso/lib/hoc/withHyperLink';

const OrgInfoContainer = ({ data }) => {
	const [primaryAddress, setPrimaryAddress] = useState({});
	const [primaryContact, setPrimaryContact] = useState({});
	const [primaryAdmin, setPrimaryAdmin] = useState({});
	const [localData, setLocalData] = useState(false);

	useEffect(() => {
		// set props to local state
		setLocalData(data);
		// Set Primary support 	info
		if (Object.keys(data).length > 0) {
			setPrimaryAddress(data.address.find((item) => item.addressTypeID === 4) || {});
		}
		if (Object.keys(data).length > 0) {
			setPrimaryContact(data.contact.find((item) => item.contactTypeID === 5) || {});
		}
		if (Object.keys(data).length > 0) {
			setPrimaryAdmin(data.adminPerson.find((item) => item.adminTypeID === 101) || {});
		}
	}, [data]);

	// HOC
	const BaseLink = withHyperLink((a) => {
		return <p style={{ margin: 'auto' }}>{a.children}</p>;
	});

	// const OrgWithLink = (props) => {
	// 	return <BaseLink {...props} />;
	// };

	const PersonWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	return Object.keys(localData).length > 0 ? (
		<OrgInfoWrapper>
			<Row className='orgInfoContainer'>
				<Col>
					<div className='primaryInfo'>
						<div className='nameRowContainer'>
							<p>{localData.name}</p>
						</div>
						<div className='credRowContainer'>
							<p>
								<label className='infoLabel'>ABN:</label>
								{localData.abn}
							</p>
							<p>
								<label className='infoLabel'>ACN:</label>
								{localData.acn}
							</p>
						</div>
						<div className='aliasRowContainer'>
							<p>
								<label className='infoLabel'>Alias:</label>
								{localData.alias}
							</p>
						</div>
					</div>
				</Col>

				<Col lg={12} md={12} sm={12} xs={12}>
					{Object.keys(primaryAddress).length > 0 ? (
						<div className='infoRowContainer'>
							<label className='infoLabel'>Address:</label>
							<p className='infoValue'>{primaryAddress.addressLine1}</p>
							{primaryAddress.addressLine2 !== 'null' && (
								<p className='infoValue'>{primaryAddress.addressLine2}</p>
							)}
							<p className='infoValue'>
								{primaryAddress.suburb} {primaryAddress.state} {primaryAddress.postCode}
							</p>
						</div>
					) : null}
					{Object.keys(primaryContact).length > 0 ? (
						<div className='infoRowContainer'>
							<label className='infoLabel'>Contact:</label>
							<p className='infoValue'>{primaryContact.contactInformation}</p>
						</div>
					) : null}
					{Object.keys(primaryAdmin).length > 0 ? (
						<div className='infoRowContainer'>
							<label className='infoLabel'>Admin Person:</label>
							<PersonWithLink type='person' dataID={primaryAdmin.personID} key={primaryAdmin.personID}>
								{primaryAdmin.displayName}
							</PersonWithLink>
							<p className='infoValue'>{primaryAdmin.email}</p>
						</div>
					) : null}
				</Col>
			</Row>
		</OrgInfoWrapper>
	) : (
		<OrgInfoWrapper style={{ height: '100%' }} className='empty'>
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Organisation Data</span>} />
		</OrgInfoWrapper>
	);
};
export default OrgInfoContainer;
