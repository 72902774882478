import { LabelWrapper, Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button, Form, Input, Select } from '@iso/components/uielements';
import Datepicker, { Timepicker } from '@iso/components/uielements/datePicker';
import { Textarea } from '@iso/components/uielements/input';
import Tooltip from '@iso/components/uielements/tooltip';
import { currentAcceptableDate, isDateInFuture } from '@iso/lib/helpers/bookingHelpers';
import { getCurrentTime } from '@iso/lib/helpers/bookingHelpers';
import superFetch from '@iso/lib/helpers/superFetch';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import bookingActions from '@iso/redux/booking/actions';
import { Col, Row } from 'antd';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import _ from 'lodash';
import moment from 'moment';
import { tz } from 'moment-timezone';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { loadBooking } = bookingActions;

const INITIAL_HOURS_DATA = {
	startHour: moment().format('HH:mm'),
	endHour: moment().add(1, 'hour').format('HH:mm')
};

const INITIAL_FORM_DATA = {
	startTime: moment(new Date(), 'YYYY-MM-DD'),
	endTime: moment(new Date(), 'YYYY-MM-DD'),
	startHour: moment(getCurrentTime(), 'HH:mm'),
	endHour: moment(getCurrentTime(), 'HH:mm').add(1, 'hour'),
	description: null,
	name: '',
	deviceID: null
};

const INITIAL_ERROR_DATA = {
	error: false,
	message: '',
	type: ''
};

const AddBooking = ({ isEdit = false, editVisible, setEditVisible, dataToUpdate = {}, children }) => {
	const { peopleData } = useSelector((state) => state.people);
	const { userProfile } = useSelector((state) => state.Auth);
	const { devicesData } = useSelector((state) => state.devices);
	const {
		remoteConfig: { constants, bookingType }
	} = useSelector((state) => state.remoteConfig);

	const user = peopleData.find((person) => person.fBPersonID === userProfile.FBPersonID);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const [viewModal, setViewModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const displayNames = useGetPersonDisplayName(true);

	const [devices, setDevices] = useState([]);
	const [hours, setHours] = useState(INITIAL_HOURS_DATA);
	const [formData, setFormData] = useState(INITIAL_FORM_DATA);
	const [error, setError] = useState(INITIAL_ERROR_DATA);
	const [disabledEditData, setDisabledEditData] = useState(true);
	const oldState = useRef();

	useEffect(() => {
		setError(INITIAL_ERROR_DATA);
		const EDIT_DATA = {
			startTime: moment(dataToUpdate.startTime, 'YYYY-MM-DD'),
			endTime: moment(dataToUpdate.endTime, 'YYYY-MM-DD'),
			startHour: moment(dataToUpdate.startTime + 'Z').local(),
			endHour: moment(dataToUpdate.endTime + 'Z').local(),
			description: dataToUpdate.description,
			name: dataToUpdate.name,
			deviceID: dataToUpdate.deviceID,
			assignedToPerson: dataToUpdate.assignedToPerson
		};
		oldState.current = EDIT_DATA;
		form.setFieldsValue(!isEdit ? INITIAL_FORM_DATA : EDIT_DATA);
		setFormData(!isEdit ? INITIAL_FORM_DATA : EDIT_DATA);
	}, [
		form,
		isEdit,
		dataToUpdate.startTime,
		dataToUpdate.endTime,
		dataToUpdate.description,
		dataToUpdate.name,
		dataToUpdate.deviceID,
		dataToUpdate.assignedToPerson,
		dataToUpdate.assignedToTeam,
		dataToUpdate.recurringEndDate,
		dataToUpdate.recurringFrequency,
		dataToUpdate.recurringInterval,
		viewModal,
		editVisible
	]);

	useEffect(() => {
		setDevices(devicesData.filter((device) => device.typeID === 3));
	}, [devicesData]);

	useLayoutEffect(() => {
		let startTime = moment(formData.startTime, 'YYYY-MM-DD');
		let endTime = moment(formData.endTime, 'YYYY-MM-DD');
		let startHour = moment(hours.startHour);
		let endHour = moment(hours.endHour);

		let finalStartTime = startTime.clone();
		finalStartTime.set({
			hour: startHour.hour(),
			minutes: startHour.minute()
		});

		//Set the end date
		let finalEndTime = endTime.clone();
		finalEndTime.set({
			hour: endHour.hour(),
			minutes: endHour.minute()
		});

		if (startTime.isAfter(endTime)) {
			setError({
				error: true,
				message: 'The end date must be greater than or equal to the start date.',
				type: 'date'
			});
		} else if (finalStartTime.isAfter(finalEndTime)) {
			setError({
				error: true,
				message: 'The end hour must be greater than the start hour.',
				type: 'hour'
			});
		}
		return () => {
			setError(INITIAL_ERROR_DATA);
		};
	}, [formData.startTime, formData.endTime, hours.endHour, hours.startHour, isEdit, editVisible]);

	useEffect(() => {
		//set initial hours
		setHours({
			startHour: !isEdit ? INITIAL_HOURS_DATA.startHour : moment(dataToUpdate.startTime + 'Z').local(),
			endHour: !isEdit ? INITIAL_HOURS_DATA.endHour : moment(dataToUpdate.endTime + 'Z').local()
		});
	}, [dataToUpdate.startTime, dataToUpdate.endTime, isEdit]);

	const handleSubmit = async (values) => {
		setConfirmLoading(true);
		//Set the start date
		let finalStartTime = values.startTime.clone();
		finalStartTime.set({
			hour: values.startHour.hour(),
			minutes: values.startHour.minute()
		});

		//if the start date is the same of current time, return current time plus 1 minute
		finalStartTime = !isDateInFuture(finalStartTime) ? currentAcceptableDate() : finalStartTime;

		//Set the end date
		let finalEndTime = values.endTime.clone();
		finalEndTime.set({
			hour: values.endHour.hour(),
			minutes: values.endHour.minute()
		});

		const body = {
			name: values.name,
			...(values.description ? { description: values.description } : null),
			bookingTypeID: formData.bookingTypeID,
			deviceID: values.deviceID,
			startTime: finalStartTime,
			endTime: finalEndTime,
			timezone: tz.guess(),
			assignedToPerson: values.assignedToPerson ? values.assignedToPerson : user.personID,
			...(values.assignedToTeam ? { assignedToTeam: values.assignedToTeam } : null)
		};

		// send data to the database depends of the method being called
		let res;
		if (!isEdit) {
			res = await superFetch.post('/booking', body, {});
		} else {
			delete body.bookingTypeID;
			res = await superFetch.patch(`/booking/${dataToUpdate.bookingID}`, body, {});
		}

		if (res.statusCode === 200) {
			notification('success', !isEdit ? res.message : 'Updated booking');
			dispatch(loadBooking());
			setConfirmLoading(false);
			!isEdit ? setViewModal(false) : setEditVisible(false);
			form.resetFields();
		} else {
			setConfirmLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	const handleCancel = () => {
		isEdit ? setEditVisible(false) : setViewModal(false);
		form.resetFields();
		setError(INITIAL_ERROR_DATA);
		setDisabledEditData(true);
	};

	return (
		<div>
			{children ? (
				children
			) : !isEdit ? (
				<Tooltip>
					<>
						<Button
							onClick={() => {
								setViewModal(true);
							}}
							disabled={false}
							type='primary'
						>
							Create a Booking
						</Button>
					</>
				</Tooltip>
			) : (
				<Tooltip>
					<>
						<Button
							size={'small'}
							onClick={() => {
								setEditVisible(true);
								setFormData(dataToUpdate);
							}}
							style={{ width: '100%' }}
						>
							Update Booking
						</Button>
					</>
				</Tooltip>
			)}
			<Modal
				title={!isEdit ? 'Add New Booking' : 'Edit Booking'}
				visible={!isEdit ? viewModal : editVisible}
				onCancel={() => handleCancel()}
				width={650}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='reset' onClick={() => handleCancel()}>
						Cancel
					</Button>,
					<Button
						loading={confirmLoading}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						disabled={disabledEditData}
					>
						{!isEdit ? 'Add Booking' : 'Edit Booking'}
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					onFinish={handleSubmit}
					layout='vertical'
					onValuesChange={(props, values) => {
						const res = _.isEqual(oldState.current, {
							...values,
							description: values.description ? values.description : null
						});
						setDisabledEditData(res);
					}}
				>
					<Row>
						<Col lg={24} md={24} sm={24} xs={24} style={{ padding: 20 }}>
							<Form.Item name='name' label='Booking Name' rules={fieldsValidator.Required}>
								<Input />
							</Form.Item>
							<Row gutter={5}>
								<Col lg={24} md={24} sm={24} xs={24}>
									<Form.Item
										name='assignedToPerson'
										label='Select User'
										rules={fieldsValidator.Required}
									>
										<Select
											showSearch
											placeholder={'Select User'}
											options={peopleData.map((person) => {
												return {
													label: displayNames[person.personID]?.displayName,
													value: person.personID,
													title: displayNames[person.personID]?.title
												};
											})}
											filterOption={(input, option) =>
												String(option?.label ?? '')
													.toLowerCase()
													.includes(String(input).toLowerCase())
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Form.Item name='deviceID' label='Device' rules={fieldsValidator.Required}>
								<Select
									showSearch
									placeholder={'Select device'}
									options={devices.map((device) => {
										return {
											label: device.name,
											value: device.uID,
											defaultCategoryID: device.defaultCategoryID
										};
									})}
									disabled={!isEdit ? false : true}
									onChange={(e, props) =>
										setFormData({
											...formData,
											bookingTypeID: constants.ACTIONABLE_SMART_LOCKS.includes(
												props.defaultCategoryID
											)
												? bookingType.LOCKER
												: bookingType.STANDARD
										})
									}
									filterOption={(input, option) =>
										String(option?.label ?? '')
											.toLowerCase()
											.includes(String(input).toLowerCase())
									}
								/>
							</Form.Item>
							<Row lg={24} md={24} sm={24} xs={24} gutter={5}>
								<Col lg={12} md={12} sm={12} xs={24}>
									<Row gutter={5}>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Item
												name='startTime'
												label={<span>Start Time </span>}
												rules={fieldsValidator.Required}
												validateStatus={error.error && error.type === 'date' ? 'error' : null}
											>
												<Datepicker
													format='DD/MM/YYYY'
													allowClear={false}
													disabledDate={(d) => !d || d.isBefore(moment().add(-1, 'day'))}
													placeholder={'Date start'}
													onChange={(e) =>
														setFormData({
															...formData,
															startTime: e
														})
													}
												/>
											</Form.Item>
										</Col>

										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Item
												name={'startHour'}
												label={<span>Start Hour</span>}
												rules={fieldsValidator.Required}
												validateStatus={error.error && error.type === 'hour' ? 'error' : null}
											>
												<Timepicker
													placeholder={'Start hour'}
													format='HH:mm'
													onChange={(e) =>
														setHours({
															...hours,
															startHour: e
														})
													}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={5}>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Item
												name='endTime'
												label={<span>End Time</span>}
												rules={fieldsValidator.Required}
												validateStatus={error.error && error.type === 'date' ? 'error' : null}
											>
												<Datepicker
													format='DD/MM/YYYY'
													allowClear={false}
													placeholder={'Date end'}
													disabledDate={(d) => d.isBefore(formData.startTime)}
													onChange={(e) =>
														setFormData({
															...formData,
															endTime: e
														})
													}
												/>
											</Form.Item>
										</Col>

										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Item
												rules={fieldsValidator.Required}
												validateStatus={error.error && error.type === 'hour' ? 'error' : null}
												name={'endHour'}
												label={<span>End Hour</span>}
											>
												<Timepicker
													format='HH:mm'
													placeholder={'End hour'}
													onChange={(e) =>
														setHours({
															...hours,
															endHour: e
														})
													}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Col>
							</Row>
							{error.error && (
								<LabelWrapper
									text={error.message || ''}
									color='error'
									tonality={0}
									fontSize={14}
									style={{ marginBottom: '15px' }}
								/>
							)}
							<Col lg={24} md={24} sm={24} xs={24} gutter={5}>
								<Form.Item name='description' label='Description' rules={fieldsValidator.Description}>
									<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
								</Form.Item>
							</Col>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default AddBooking;
