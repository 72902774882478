import actions from './actions';

const initState = {
	orgDefaultSettings: [],
	personDefaultSettings: [],
	groupSettingsData: [],
	pending: true
};

const defaultSettingsReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_SUCCESS:
			return { ...state, orgDefaultSettings: action.payload, pending: false };
		case actions.LOAD_DEFAULT_ORGANISATION_SETTINGS_ERROR:
			return { ...state, orgDefaultSettings: [], pending: false };
		case actions.LOAD_DEFAULT_PERSON_SETTINGS_SUCCESS:
			return { ...state, personDefaultSettings: action.payload, pending: false };
		case actions.LOAD_DEFAULT_PERSON_SETTINGS_ERROR:
			return { ...state, personDefaultSettings: [], pending: false };
		case actions.LOAD_GROUP_SETTINGS_SUCCESS:
			return { ...state, groupSettingsData: action.payload, pending: false };
		case actions.LOAD_GROUP_SETTINGS_ERROR:
			return { ...state, groupSettingsData: [], pending: false };
		default:
			return state;
	}
};
export default defaultSettingsReducer;
