import { all, takeEvery, put, fork } from 'redux-saga/effects';
import React from 'react';
import superFetch from '@iso/lib/helpers/superFetch';
import { notification } from '@iso/components';
import { Button } from 'antd';
import actions from './actions';

export function* loadInventoryLocksData() {
	yield takeEvery('LOAD_INVENTORY_LOCKS_DATA', function* () {
		var type = 'ttlock';
		const data = yield superFetch.get('/inventories/TTlock', {}, { type }).then((res) => {
			var result = {};
			if (res.statusCode === 200) {
				if (res.data.length >= 0) {
					result['data'] = res.data;
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;

				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_INVENTORY_LOCKS_SUCCESS,
				locks: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_INVENTORY_ERROR,
				error: data.error
			});
		}
	});
}

export function* loadInventoryGatewaysData() {
	yield takeEvery('LOAD_INVENTORY_GATEWAYS_DATA', function* () {
		var type = 'gateway';
		const data = yield superFetch.get('/inventories/TTlock', {}, { type }).then((res) => {
			var result = {};
			if (res.data && (res.statusCode === 200 || 204 || 304)) {
				//ToDo: Change to just 200
				if (res.data.length >= 0) {
					result['data'] = res.data;
				} else {
					result['data'] = [];
				}
				return result;
			} else {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);

				result['error'] = res.error;

				return result;
			}
		});
		if (data.data) {
			yield put({
				type: actions.LOAD_INVENTORY_GATEWAYS_SUCCESS,
				gatewaysData: data.data
			});
		} else {
			yield put({
				type: actions.LOAD_INVENTORY_ERROR,
				error: data.error
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadInventoryLocksData), fork(loadInventoryGatewaysData)]);
}
