const actions = {
	LOAD_DEVICES_DATA: 'LOAD_DEVICES_DATA',
	LOAD_DEVICES_SUCCESS: 'LOAD_DEVICES_SUCCESS',
	LOAD_DEVICES_ERROR: 'LOAD_DEVICES_ERROR',
	LOAD_DEVICES_CUSTOM_FIELDS_SUCCESS: 'LOAD_DEVICES_CUSTOM_FIELDS_SUCCESS',

	loadDevices: () => ({
		type: actions.LOAD_DEVICES_DATA
	})
};
export default actions;
