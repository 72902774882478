import styled from 'styled-components';
import { palette } from 'styled-theme';

const Forms = (ComponentName) => styled(ComponentName)`
	.ant-form-item-explain.ant-form-item-explain-error {
		color: ${palette('error', 0)};
		font-size: 11px;
		font-weight: 400;
		line-height: 1;
		padding-top: 4px;
		padding-left: 10px;
	}
`;

export default Forms;
