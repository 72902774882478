import styled from 'styled-components';
import { palette } from 'styled-theme';
import { Typography } from 'antd';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const DeviceCategoryWrapper = styled.div`
	background-color: ${palette('secondary', 7)};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	padding: 25px;

	@media only screen and (max-width: 991px) {
		border-right: 0px;
		margin-bottom: 5px;
		margin-left: 0px;
		/* flex-direction: row; */
	}

	.actionButtonDiv {
		display: flex;
	}

	.suppInfo {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-end;

		@media only screen and (max-width: 991px) {
			justify-content: flex-start;

			/* flex-direction: row; */
		}
	}

	.tagStyle {
		line-height: 14px;
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 5px;
		display: flex;
		align-items: end;
	}
	.customFieldIcon {
		font-size: 12px;
		margin-right: 5px;
	}

	.outlinedIcon {
		color: ${palette('primary', 0)};
	}
	.descriptionInfo {
		padding-left: 10px;
	}
	.titleEdit {
		font-size: 25px;
		margin-bottom: 0;
	}
	.deleteButton {
		&:hover {
			border-color: ${palette('error', 2)};
			color: ${palette('error', 2)};
		}
	}
	.siteTagDiv {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
	}
	.siteTag {
		cursor: pointer;
		line-height: 14px;
		font-size: 12px;
		margin-top: 4px;
		margin-bottom: 4px;
	}
	.categoryHeaderCol {
		padding-bottom: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: flex-start;

		.categoryTitle {
			font-size: 10px;
			font-weight: 700;
			opacity: 0.8;
			color: ${palette('secondary', 5)};
			align-self: center;
		}
		.idLabel {
			font-size: 10px;
			font-weight: 500;
			opacity: 1;
			color: ${palette('secondary', 5)};
			padding-left: 10;
		}
	}

	.actionButton {
		display: flex;
		justify-content: flex-end;

		.ant-btn {
			margin-right: 10px;

			&:last-child {
				margin-right: 0px;
			}
		}
		.switchCategoryButton {
			margin-right: 10px;

			@media only screen and (max-width: 991px) {
				margin-right: 0px;
				display: none;
			}
		}
		.deletedTag {
			height: 36px;
			line-height: 36px;
			font-size: 12px;
			font-weight: 500;
			${borderRadius('4px')};
			&:last-child {
				margin-right: 0px;
			}
		}
	}

	.summary {
		display: flex;

		.ant-typography-expand {
			color: ${palette('primary', 2)};
		}
	}

	.infoLabel {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
	}
	.infoSum {
		font-size: 12px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
	}

	.infoSumProp {
		font-size: 12px;
		font-weight: 600;
		color: ${palette('secondary', 3)};
	}

	.infoActivityLabel {
		font-size: 10;
		font-weight: 300;
		opacity: 0.7;
	}

	.infoTitle {
		font-size: 20px;
		font-weight: 700;
		opacity: 0.8;
		color: black;
		margin-left: 20px;
		vertical-align: middle;
	}
	.infoProp {
		font-size: 12px;
		font-weight: 600;
		//margin-bottom: 10px;

		color: ${palette('secondary', 3)};
	}

	.ant-typography .ant-typography-edit-content {
		textarea.ant-input {
			font-size: 12px !important;
			font-weight: 600 !important;
			//margin-bottom: 10px
			color: ${palette('secondary', 3)};
		}
	}
	.infoActivityProp {
		font-size: 10px;
		font-weight: 400;
		opacity: 0.7;
		display: inline-block;
		margin-left: 4px;
		&:last-child {
			margin-right: 10px;
		}
	}

	.editButton {
		display: flex;
		justify-content: flex-end;
	}
	.descriptionArea {
		display: 'block';
	}
	.editCategory {
		display: flex;
		justify-content: center;

		@media only screen and (max-width: 1000px) {
			padding-top: 10px;
		}
	}
`;

const TypographyParagraphWrapper = styled(Typography.Paragraph)`
	&.ant-typography-edit-content {
		.ant-input {
			font-size: 12px;
			font-weight: 600;
			color: ${palette('secondary', 3)};
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;

const TypographyTitleWrapper = styled(Typography.Title)`
	margin-bottom: 0;

	&.ant-typography-edit-content {
		.ant-input {
			font-size: 25px;
			font-weight: 600;
		}
		.ant-typography-edit-content-confirm {
			display: none;
		}
	}
	.ant-typography-edit {
		margin-left: 10px;
	}
`;

export { TypographyParagraphWrapper, TypographyTitleWrapper };

export default DeviceCategoryWrapper;
