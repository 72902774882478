/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 28-03-2022  14:47:47
 * @desc [description]
 */

import React from 'react';
import { Row, Col } from 'antd';
import { Loading, PageHeader, AddDeviceCategoryButton, AddDeviceButton } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { useSelector } from 'react-redux';
import DeviceCategoriesHeaderWrapper from './deviceCategoriesHeader.styles';

const DeviceCategoriesHeader = () => {
	const { pending } = useSelector((state) => state.deviceCategories);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	return pending ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<DeviceCategoriesHeaderWrapper>
			<PageHeader title='Device Categories'>
				<div className='btnRow'>
					<div className='btnButton'>
						<AddDeviceCategoryButton />
					</div>
					<div className='btnButton'>
						<AddDeviceButton />
					</div>
				</div>
			</PageHeader>
		</DeviceCategoriesHeaderWrapper>
	);
};

export default DeviceCategoriesHeader;
