import { LabelWrapper } from '@Z/components/atoms';
import Tag from '@iso/components/uielements/tag';
import { getRandomColor } from '@iso/lib/helpers/utility';
import { default as React } from 'react';
import ContainerRoles from './RolesSummary.styles';
import Popover from '@iso/components/uielements/popover';
import { getInitials } from '@iso/lib/helpers/getInitials';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import withHyperLink from '@iso/lib/hoc/withHyperLink';

const RolesSummary = ({ roles }) => {
	const { sitesData } = useSelector((state) => state.sites);
	const { teamsData } = useSelector((state) => state.teams);
	const { floorsData } = useSelector((state) => state.floors);
	const { organisationData } = useSelector((state) => state.Organisation);

	const NavigateWithLink = withHyperLink((a) => {
		return <div>{a.children}</div>;
	});

	const infoRoles = Object.keys(roles)
		.map((role) => {
			switch (role) {
				case '1':
					return {
						title: 'User',
						showPopover: false,
						nameRole: 'User',
						showButton: true,
						role: 1
					};
				case '6':
					return {
						title: 'Cleaner',
						showPopover: false,
						nameRole: 'Cleaner',
						showButton: true,
						role: 6
					};
				case '101':
					const organisationAdmin = roles['101'];
					const finalOrganisationAdmin = organisationData.filter((item) =>
						organisationAdmin.some((team) => item.orgID === team)
					);
					return {
						title: 'Admin',
						teams: finalOrganisationAdmin,
						nameRole: 'Admin',
						showPopover: true,
						showButton: false,
						role: 101
					};
				case '201':
					return {
						title: 'Developer',
						showPopover: false,
						nameRole: 'Developer',
						showButton: true,
						role: 201
					};
				case '103':
					const sites = roles['103'];
					const finalSites = sitesData.filter((item) => sites.some((site) => item.floorID === site));
					return {
						title: 'Sites',
						sites: finalSites,
						nameRole: 'Sites Admin',
						showButton: true,
						showPopover: true,
						role: 103
					};
				case '104':
					const floors = roles['104'];
					const finalFloors = floorsData.filter((item) => floors.some((floor) => item.floorID === floor));
					return {
						title: 'Floors',
						floors: finalFloors,
						nameRole: 'Floors Admin',
						showButton: true,
						showPopover: true,
						role: 104
					};
				case '105':
					const teamsAdmin = roles['105'];
					const finalTeamsAdmin = teamsData.filter((item) => teamsAdmin.some((team) => item.teamID === team));

					return {
						title: 'Teams',
						teams: finalTeamsAdmin,
						nameRole: 'Team Admin',
						showButton: true,
						showPopover: true,
						role: 105
					};
				default:
					return null;
			}
		})
		.filter((role) => role);

	const contentBody = (name, index, type, dataID) => {
		return (
			<NavigateWithLink type={type} dataID={dataID} key={index}>
				<ContainerRoles>
					<Avatar style={{ backgroundColor: getRandomColor(index), marginRight: 5 }}>
						{name && getInitials(name)}
					</Avatar>
					<div className='infoRoles'>
						<LabelWrapper text={name} typeLabel='primary' fontSize={14} color='text' tonality={5} />
					</div>
				</ContainerRoles>
			</NavigateWithLink>
		);
	};

	const renderContent = (rol) => {
		if (rol.role === 105) {
			return (
				rol.teams &&
				rol.teams.map((team, index) => {
					return contentBody(team?.name, index, 'team', team?.teamID, rol.showButton);
				})
			);
		} else if (rol.role === 103) {
			return (
				rol.sites &&
				rol.sites.map((site, index) => {
					return contentBody(site?.name, index, 'site', site?.siteID, rol.showButton);
				})
			);
		} else if (rol.role === 104) {
			return (
				rol.floors &&
				rol.floors.map((floor, index) => {
					return contentBody(floor?.name, index, 'floors', floor?.floorID, rol.showButton);
				})
			);
		} else if (rol.role === 101) {
			return (
				rol.teams &&
				rol.teams.map((team, index) => {
					return contentBody(team?.name, index, 'home', team?.teamID, rol.showButton);
				})
			);
		}
	};

	return infoRoles.map((rol, index) => {
		if (rol.showPopover && (rol.teams?.length > 0 || rol.sites?.length > 0 || rol.floors?.length > 0)) {
			return (
				<Popover placement='top' title={rol.title} content={() => renderContent(rol)} trigger={['click']}>
					<Tag color={getRandomColor(index)}>{rol.nameRole}</Tag>
				</Popover>
			);
		} else {
			return <Tag color={getRandomColor(index)}>{rol.nameRole}</Tag>;
		}
	});
};

export default RolesSummary;
