import actions from './actions';

const initState = { teamsData: [], pending: true, searchable: true };

const teamsReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_TEAMS_SUCCESS:
			return { ...state, teamsData: action.teamsData, pending: false };
		case actions.LOAD_TEAMS_ERROR:
			return { ...state, teamsData: [], pending: false };
		default:
			return state;
	}
};
export default teamsReducer;
