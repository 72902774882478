export const managePermittedRolesConfig = {
	addOrg: { roles: [201], entityID: false },
	updateOrg: { roles: [101], entityID: true },
	deleteOrg: { roles: [201], entityID: false },
	orgInformationCUD: { roles: [101], entityID: true },

	addSite: { roles: [101], entityID: false },
	updateSite: { roles: [101, 103], entityID: true },
	deleteSite: { roles: [101], entityID: true },
	siteInformationCUD: { roles: [101, 103], entityID: true },

	addFloor: { roles: [101, 103], entityID: false },
	updateFloor: { roles: [101, 103, 104], entityID: true },
	deleteFloor: { roles: [101, 103], entityID: true },
	floorInformationCUD: { roles: [101, 103, 104], entityID: true },

	addPerson: { roles: [101, 103, 104, 105], entityID: false },
	updatePerson: { roles: [1], entityID: true },
	deletePerson: { roles: [201], entityID: true },
	enablePerson: { roles: [101, 103, 104, 105], entityID: true },
	disablePerson: { roles: [101, 103, 104, 105], entityID: true },
	resetPasswordPerson: { roles: [101, 103], entityID: true },
	assignUserType: { roles: [101, 103, 104], entityID: true },
	personInformationCUD: { roles: [1], entityID: true },

	addTeam: { roles: [101, 103, 104, 105], entityID: false },
	updateTeam: { roles: [101, 103, 105], entityID: true },
	deleteTeam: { roles: [101, 103, 105], entityID: true },
	manageMembers: { roles: [101, 105], entityID: true },
	teamInformationCUD: { roles: [101, 103, 105], entityID: true },

	deleteDevice: { roles: [101, 103, 104, 107], entityID: false },
	updateDevice: { roles: [101, 103, 104, 107], entityID: false },
	addCategory: { roles: [101, 103, 104, 107], entityID: false },
	updateCategory: { roles: [101, 103, 104, 107], entityID: false },
	deleteCategory: { roles: [101, 103, 107], entityID: false },

	createGroup: { roles: [101, 103, 104, 107], entityID: false },
	updateGroup: { roles: [101, 103, 104, 107], entityID: false },
	deleteGroup: { roles: [101, 103, 104, 107], entityID: false },

	aCRemoteUnlock: { roles: [101, 103, 104], entityID: true },
	aCPermissionManagement: { roles: [101, 103, 104], entityID: true },

	demoReporting: { roles: [201], entityID: false },

	editJsonSetting: { roles: [201], entityID: false },

	editCredentialSetting: { roles: [201], entityID: false }
};
