import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button, Form, Input, SelectOption as Option, Select, Textarea } from '@iso/components/uielements';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import groupAction from '@iso/redux/assetGroups/actions';
import { Col, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { loadAssetGroups } = groupAction;

const EditAssetGroupModal = ({ visible, setVisible, children, data: group }) => {
	// Hooks
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	// Local States
	const [updateGroupLoading, setUpdateGroupLoading] = useState(false);
	const [saveDisable, setSaveDisable] = useState(true);

	// Global State
	const { sitesData, pending: sitesPending } = useSelector((state) => state.sites);

	const handleCancel = () => {
		setVisible(false);
	};
	const handleSubmit = () => {
		setUpdateGroupLoading(true);
		const inputs = removeEmptyOrNull(form.getFieldsValue(true));
		const body = {
			name: inputs.name,
			description: inputs.description,
			siteID: inputs.siteID
		};
		superFetch
			.patch('/deviceGroups/' + group.groupID, body)
			.then((res) => {
				if (res.statusCode === 200) {
					notification('success', `Successfully Updated ${body.name}.`);
					dispatch(loadAssetGroups());
					handleCancel();
				} else if (res.error.statusCode === 400) {
					notification('error', 'Please fill out all required information.');
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
				setUpdateGroupLoading(false);
			})
			.catch((e) => {
				notification('error', 'Something went wrong, try again soon.');
				setUpdateGroupLoading(false);
				handleCancel();
			});
	};
	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		return (
			(group.name === formData.name || formData.name === '') &&
			(group.description === formData.description || formData.description === '') &&
			group.siteID === formData.siteID
		);
	};

	const isPermittedUpdateGroup = useManagePermittedRoles('updateGroup', {
		orgID: [group.orgID],
		siteID: [group.siteID],
		floorID: []
	});

	return (
		<div>
			<Tooltip
				title={!isPermittedUpdateGroup ? "You don't have permission" : 'Edit this Group’s Information'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<Button
						disabled={!isPermittedUpdateGroup}
						onClick={() => {
							setVisible(true);
						}}
						size={'small'}
						style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						Modify Group
					</Button>
				)}
			</Tooltip>

			<Modal
				title={'Update ' + group.name}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				width={600}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						disabled={saveDisable}
						loading={updateGroupLoading}
					>
						Update
					</Button>
				]}
			>
				<Form
					form={form}
					id={form}
					layout='vertical'
					onFinish={handleSubmit}
					initialValues={group}
					autoComplete='off'
					onValuesChange={() => setSaveDisable(updateChange())}
				>
					<Row gutter={16}>
						<Col sm={24} xs={24} lg={12} md={12}>
							<Form.Item name='name' label='Name' rules={fieldsValidator.Name}>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={24} xs={24} lg={12} md={12}>
							<Form.Item name='siteID' label='Site'>
								<Select placeholder={'Select Site'} loading={sitesPending}>
									{sitesData.map((site) => (
										<Option key={site.siteID} value={site.siteID}>
											{site.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label='Description' name='description' rules={fieldsValidator.Description}>
								<Textarea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default EditAssetGroupModal;
