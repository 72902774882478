import { ComponentLabel, RenderSiteName } from '@Z/components/atoms';
import { ManageAssetsInGroupModal } from '@Z/components/molecules';
import { smallTimeOptions } from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import { Button, Dropdown, DropdownMenu } from '@iso/components/uielements';
import RenderGroupActions from '@Z/components/atoms/AssetGroupCard/renderActions';
import Tabs from '@iso/components/uielements/tabs';
import superFetch from '@iso/lib/helpers/superFetch';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import groupAction from '@iso/redux/assetGroups/actions';
import { Divider, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RenderAssetsForAssetGroup from './components/RenderAssets';
import RenderGroupPermissions from './components/RenderGroupPermissions';
import GroupTitleWrapper, { GroupModalWrapper } from './styles';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const { loadAssetGroups } = groupAction;
const { Paragraph } = Typography;

const DEFAULT_TAB = 'Assets';
const IndividualAssetGroupModal = ({ visible, setVisible, children, data }) => {
	// Hooks
	const dispatch = useDispatch();

	// HOC
	const SiteNameWithLink = withHyperLink(RenderSiteName);

	// basic information for this modal to be used in the form.
	let { groupID } = data;

	// Global Data
	const { assetGroupsData } = useSelector((state) => state.assetGroups);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	// Local States
	const [allGroups, setAllGroups] = useState([]);
	const [group, setGroup] = useState({});
	const [manageModalVisible, setManageModalVisible] = useState(false);
	const [tabKey, setTabKey] = useState(DEFAULT_TAB);

	// get Groups data from the store. (this is the data that is used in the modal)
	useEffect(() => {
		if (assetGroupsData.length > 0 && visible) {
			setAllGroups(assetGroupsData);
			setGroup(assetGroupsData.find((g) => g.groupID === groupID) || {});
		}
	}, [assetGroupsData, groupID, visible]);

	const handleCancel = () => {
		setVisible(false);
	};

	const onEditName = (value) => {
		if (group.name === value) {
			return;
		} else {
			if (/^[a-zA-Z0-9 ]+$/.test(value)) {
				const oldGroup = group;
				setGroup({ ...group, name: value });
				superFetch
					.patch('/deviceGroups/' + group.groupID, { name: value })
					.then((res) => {
						if (res) {
							if (res.statusCode === 200) {
								dispatch(loadAssetGroups());
								notification('success', 'Group Name Updated.');
							} else {
								setGroup(oldGroup);
								notification('error', 'Update Failed, Please try again.');
							}
						}
						return res;
					})
					.catch((err) => {
						notification('error', 'Update Failed, Please try again.');
					});
			} else {
				notification('error', 'Asset Group Name can only contain letters, numbers and spaces.');
			}
		}
	};
	const renderGroups = () =>
		allGroups.map((group, key) => {
			return {
				key: key,
				label: <p onClick={() => setGroup(group)}>{group.name}</p>,
				icon: <i className='material-icons-outlined'>widgets</i>
			};
		});

	const tabs = [
		{
			label: 'Assets',
			key: 'Assets',
			children: <RenderAssetsForAssetGroup group={group} />
		},
		{
			label: 'Permissions',
			key: 'Permissions',
			children: <RenderGroupPermissions groupID={groupID} />
		}
	];

	const actions = {
		Assets: (
			<ManageAssetsInGroupModal data={group} visible={manageModalVisible} setVisible={setManageModalVisible}>
				<Button size={'small'} onClick={() => setManageModalVisible(true)}>
					Manage
				</Button>
			</ManageAssetsInGroupModal>
		)
	};

	const isPermittedUpdateGroup = useManagePermittedRoles('updateGroup', {
		orgID: [group.orgID],
		siteID: [group.siteID],
		floorID: []
	});

	return (
		<div>
			{children ? (
				children
			) : (
				<Button
					onClick={() => {
						setVisible(true);
					}}
					size={'small'}
					style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				>
					View
				</Button>
			)}

			<GroupModalWrapper
				title={
					<Row justify='space-between'>
						<div style={{ width: '70%' }}>
							<GroupTitleWrapper
								editable={
									isPermittedUpdateGroup
										? {
												tooltip: 'click to edit Group name',
												maxLength: 100,
												autoSize: { maxRows: 2 },
												icon: (
													<i className='material-icons-outlined' style={{ color: '#4d4f5c' }}>
														mode_edit
													</i>
												),
												triggerType: ['text', 'icon'],
												onChange: (value) => onEditName(value)
										  }
										: false
								}
							>
								{group.name || 'Group Not Found'}
							</GroupTitleWrapper>
						</div>
						{allGroups.length > 0 ? (
							<Dropdown overlay={<DropdownMenu items={renderGroups(group)} />} trigger={['click']}>
								<Button
									icon={<i className='material-icons-outlined'>expand_more</i>}
									style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}
								>
									Change Group
								</Button>
							</Dropdown>
						) : null}
					</Row>
				}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				width={1200}
				footer={null}
				closable={false}
			>
				<div className='groupInfoContainer'>
					<div className='infoHeaderCont'>
						<ComponentLabel
							style={{
								fontSize: '16px',
								color: 'black',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								wordBreak: 'break-all',
								display: 'inline-block'
							}}
							text='About Group'
						/>
						<RenderGroupActions group={group} hideView>
							<Button
								icon={<i className='material-icons-outlined'>expand_more</i>}
								style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}
							>
								Actions
							</Button>
						</RenderGroupActions>
					</div>

					{group.description && (
						<div className='descContainer'>
							<Paragraph className='description'>{group.description}</Paragraph>
						</div>
					)}
					<Space split={<Divider type='vertical' />}>
						<div>
							<label className='infoLabel'>Type</label>
							<p className='infoProp'>{group.typeName}</p>
						</div>
						<div>
							<label className='infoLabel'>Site</label>
							<SiteNameWithLink dataID={group.siteID} type='site' />
						</div>
						<div>
							<label className='infoLabel'>Assets</label>
							<p className='infoProp'>{group?.assets?.length}</p>
						</div>
					</Space>
					<Row className='metaContainer'>
						<Space wrap className='dateContainer'>
							<p
								className='metaField'
								title={new Date(group.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
							>
								<span className='metaLabel'>Last updated: </span>
								{new Date(group.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
							</p>
							<p
								className='metaField'
								title={new Date(group.createdDate).toLocaleString('en-AU', smallTimeOptions)}
							>
								<span className='metaLabel'>Created on: </span>
								{new Date(group.createdDate).toLocaleString('en-AU', smallTimeOptions)}
							</p>
						</Space>
					</Row>
				</div>
				<Divider style={{ margin: '10px 0px' }} />
				{group.typeID === constants.ACCESS_CONTROL ? (
					<Tabs
						items={tabs}
						size='small'
						defaultActiveKey={'Assets'}
						type='card'
						animated={{ inkBar: true, tabPane: true }}
						tabBarExtraContent={actions[tabKey]}
						onChange={(activeKey) => setTabKey(activeKey)}
					/>
				) : (
					<RenderAssetsForAssetGroup group={group} showHeader />
				)}
			</GroupModalWrapper>
		</div>
	);
};

export default IndividualAssetGroupModal;
