export const CONSTANTS_SETTINGS = {
	PERSON_SETTING_TYPE: 'person',
	ORG_SETTING_TYPE: 'org',
	BOOKING_CONFIGURATION: 81,
	ACCESS_MODULE: 82,
	LOCKER_MODULE: 83,
	SCIM_TOKEN: 84,
	WORKSPACE_DOMAIN: 86,
	MASTER_CREDENTIAL: 87,
	ENABLE_CARD_ENROLMENT: 89,
	UNLOCK_METHOD: 90,
	ROOMS_CONFIGURATION: 91,
	ENFORCE_DOMAIN_CONTROL: 93
};
