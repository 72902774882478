/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 09-08-2022  10:43:23
 * @desc [description]
 */

import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { PageHeader, Loading, AddSite } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const SiteHeader = () => {
	const [loadings, setLoading] = React.useState(true);
	const sitesData = useSelector((state) => state.sites.sitesData);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		setLoading(true);
		setData(sitesData);
		setLoading(false);
	}, [sitesData]);

	const deletedNumber = sitesData.filter((sites) => sites.deletedDate).length;

	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const isPermittedAddSite = useManagePermittedRoles('addSite', { orgID: [sitesData[0]?.orgID] });

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<PageHeader title='Sites' counter={deletedNumber < 1 ? data.length : data.length - deletedNumber}>
			{isPermittedAddSite && <AddSite />}
		</PageHeader>
	);
};

export default SiteHeader;
