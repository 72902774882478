import React from 'react';
import isoModal from '@iso/components/Feedback/Modal';
import Modals from './Modal.styles';
import WithDirection from '@iso/lib/helpers/rtl';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const WDModal = Modals(isoModal);
const OriginModal = WithDirection(WDModal);

const Modal = (props) => {
	const { danger, footer } = props;
	return danger ? (
		<WarningModal footer={footer ? footer : 'Action button is needed'} {...props} />
	) : (
		<OriginModal footer={footer ? footer : footer === null ? null : 'Action button is needed'} {...props} />
	);
};

const WarningModal = styled(OriginModal)`
	.ant-modal-header {
		border-top: 5px solid ${palette('error', 0)};
		color: ${palette('error', 0)};

		.ant-modal-title {
			color: ${palette('error', 0)};
		}
	}
`;

export default Modal;
