import styled from 'styled-components';
import { Modal } from '@Z/components/atoms';

const CompleteProfileModal = styled(Modal)`
	width: 1200px !important;
	.ant-modal-header {
		border-radius: 10px !important;
	}
	.ant-modal-footer {
		border-radius: 10px !important;
	}
	.ant-modal-content {
		border-radius: 10px !important;
		.colCenter {
			display: flex;
			justify-content: center;
			div {
				transition: transform 0.3s ease-in-out; /* Changed transition property for smoother animation */
				&:hover {
					transform: scale(1.1); /* Enlarge the avatar on hover */
				}
			}
		}
		.containerImages {
			display: flex;
			flex-wrap: wrap;

			.avatar {
				transition: transform 0.3s ease-in-out; /* Changed transition property for smoother animation */
				cursor: pointer;
				margin: 5px;

				&:hover {
					transform: scale(1.1); /* Enlarge the avatar on hover */
				}
			}
		}

		.buttonUploadImage {
			color: #3c6e71;
			border: 1px solid #3c6e71;
			margin-left: 10px;
			transition: all 0.3s ease-in;
			&:hover {
				background-color: #3c6e71;
				color: #ffffff;
				transform: scale(1.05);
			}
		}

		.ant-form-item-label {
			label {
				font-weight: 500;
			}
		}
		.ant-input-password,
		.ant-form-item-control-input {
			outline: none;
			border: 1px solid #f0f0f0;
			border-radius: 4px;
			height: 35px;
			transition: border-color 0.3s ease; /* Smooth transition for border color */
		}

		/* Hover effect */
		.ant-input-password:hover,
		.ant-picker:hover {
			border-color: #3c6e71;
		}
		.ant-form-item-control-input:hover {
			input {
				border-color: #3c6e71;
			}
		}

		@media (max-width: 500px) {
			/* Styles for mobile devices */
			width: 100% !important;
			.colCenter {
				div {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 150px !important;
					height: 150px !important;
					.contentContainer {
						span {
							width: 100% !important;
							height: 100% !important;
						}
					}
				}
			}
			.containerImages {
				.avatar {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}
`;

export default CompleteProfileModal;
