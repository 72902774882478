import Button from '@iso/components/uielements/button';
import { Loading, Modal } from '@Z/components/atoms';
import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import { Col, Row } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import MapRenderer from './components/mapRenderer';
import MapWrapper, { MapModalWrapper } from './leafletMap.styles';

/**
 *	This is the Leaflet Map component. allowing you to add static custom floor plan.
 *
 * @export
 * @param {*} {
 * 	id = 'map', Optional fields
 * 	center, mandatory fields
 * 	mapHeight = 1000, height of the map image
 * 	mapWidth = 1000, width of the map image
 * 	scrollWheelZoom = false, enable or disable scroll wheel zoom
 * 	url, mandatory fields, URL of the map image
 * 	minZoom = -3, minimum zoom level
 * 	drawControlEnabled = false, enable or disable draw control
 * 	zoom = 0, default zoom level
 * 	markerList = [], list of markers
 *  draggableMarkerLocation = false, enable or disable draggable marker location
 *  editMarkerLocation =[] array of markers to be editable
 * }
 * @returns
 */
const LeafletMap = (props) => {
	const {
		id = 'map',
		center,
		mapHeight = 1000,
		mapWidth = 1000,
		scrollWheelZoom = false,
		url,
		drawControlEnabled = false,
		minZoom = -3,
		zoom = 0,
		markerList,
		drawSingleMarkerEnabled = false,
		draggableMarkerLocation = false,
		setMarker,
		editMarkerLocation,
		expandMapControl = true
	} = props;
	const mapRef = useRef(null);
	const [map, setMap] = useState(null);
	const mapModalRef = useRef(null);
	const [mapModal, setMapModal] = useState(null);
	const [visible, setVisible] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState({});
	const [mapModalVisible, setMapModalVisible] = useState(false);

	// Render the map on a screen
	useEffect(() => {
		MapRenderer({
			mapRef,
			map,
			url,
			markerList,
			drawControlEnabled,
			drawSingleMarkerEnabled,
			editMarkerLocation,
			draggableMarkerLocation,
			setMap,
			setMarker,
			scrollWheelZoom,
			mapHeight,
			mapWidth,
			center,
			minZoom,
			zoom,
			setSelectedDevice,
			setVisible
		});
	}, [
		url,
		map,
		markerList,
		selectedDevice,
		drawControlEnabled,
		drawSingleMarkerEnabled,
		editMarkerLocation,
		draggableMarkerLocation,
		scrollWheelZoom,
		mapHeight,
		mapWidth,
		center,
		minZoom,
		zoom,
		setMarker
	]);

	// Render the map on a modal.
	useEffect(() => {
		MapRenderer({
			mapRef: mapModalRef,
			map: mapModal,
			url,
			markerList,
			drawControlEnabled,
			drawSingleMarkerEnabled,
			editMarkerLocation,
			draggableMarkerLocation,
			setMap: setMapModal,
			setMarker,
			scrollWheelZoom,
			mapHeight,
			mapWidth,
			center,
			minZoom,
			zoom,
			setSelectedDevice,
			setVisible
		});
	}, [
		url,
		mapModal,
		markerList,
		selectedDevice,
		drawControlEnabled,
		drawSingleMarkerEnabled,
		editMarkerLocation,
		draggableMarkerLocation,
		scrollWheelZoom,
		mapHeight,
		mapWidth,
		center,
		minZoom,
		zoom,
		mapModalVisible,
		setMarker
	]);

	return url !== undefined && url !== null && url !== '' ? (
		<Fragment>
			{/* Render all the children first. */}
			{props.children}

			{/* Render the map */}
			<MapWrapper
				className='isoLeafletMap'
				ref={mapRef}
				id={id}
				style={{
					width: '100%',
					height: '100%'
				}}
			/>

			{/* Map in the Modal functionality. */}
			{/* Button to expand the map  */}
			{expandMapControl && (
				<Button
					title='Expand Map'
					onClick={() => setMapModalVisible(mapModalVisible ? false : true)}
					style={{
						position: 'absolute',
						zIndex: 500,
						bottom: '10px',
						left: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					icon={
						<span class='material-icons-outlined' style={{ fontSize: '30px' }}>
							fullscreen
						</span>
					}
				/>
			)}

			{/* Modal to render bigger map. */}
			<Modal visible={mapModalVisible} onCancel={() => setMapModalVisible(false)} footer={null} width={'80vw'}>
				<MapModalWrapper style={{ height: '80vh', width: '100%' }}>
					<MapWrapper
						className='isoLeafletMap'
						ref={mapModalRef}
						id={`${id}_modal`}
						style={{
							width: '100%',
							height: '100%'
						}}
					/>
				</MapModalWrapper>
			</Modal>

			{/* Individual Device Modal  */}
			{selectedDevice.uID && (
				<IndividualDeviceInformationModal
					visible={visible}
					setVisible={setVisible}
					data={{
						deviceID: selectedDevice.uID,
						categoryID: selectedDevice.categoryID
					}}
				/>
			)}
		</Fragment>
	) : (
		<Row style={{ height: '100%', alignContent: 'center' }} gutter={0} justify='space-around'>
			<Col style={{ display: 'flex', justifyContent: 'center' }} lg={24} md={24} sm={24} xs={24}>
				<Loading />
			</Col>
		</Row>
	);
};
export default LeafletMap;
