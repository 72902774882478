import { ComponentLabel, SearchBar } from '@Z/components/atoms';
import { filterFromArray } from '@iso/lib/helpers/utility';
import { Empty, Row } from 'antd';
import { Button } from '@iso/components/uielements';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ManageAssetsInGroupModal } from '../..';

const RenderAssetsForAssetGroup = ({ group, showHeader }) => {
	// Global Data
	const { devicesData } = useSelector((state) => state.devices);

	// Local States
	const [filteredAssets, setFilteredAssets] = useState([]);
	const [assets, setAssets] = useState([]);
	const [manageModalVisible, setManageModalVisible] = useState(false);

	useEffect(() => {
		if (devicesData.length > 0 && Object.keys(group).length > 0) {
			const groupAssets = group.assets.map((a) => devicesData.find((b) => a.assetID === b.uID));
			setAssets(groupAssets);
			setFilteredAssets(groupAssets);
		}
		return () => {
			setAssets([]);
			setFilteredAssets([]);
		};
	}, [devicesData, group]);

	return (
		<div className='groupAssetsContainer'>
			{showHeader && (
				<div className='containerHeader'>
					<ComponentLabel
						style={{
							fontSize: '16px',
							color: 'black'
						}}
						text='Assets'
					/>
					<ManageAssetsInGroupModal
						data={group}
						visible={manageModalVisible}
						setVisible={setManageModalVisible}
					>
						<Button size='small' onClick={() => setManageModalVisible(true)}>
							Manage
						</Button>
					</ManageAssetsInGroupModal>
				</div>
			)}
			<SearchBar
				placeholder='Search by Name or Unique Identification Number'
				enterButton='Search'
				onChange={(event) => {
					filterFromArray(assets, event.target.value, ['name', 'uID'], setFilteredAssets);
				}}
				onSearch={(query) => {
					filterFromArray(assets, query.target.value, ['name', 'uID'], setFilteredAssets);
				}}
			/>
			{assets.length > 0 ? (
				<Row className='deviceCardContainer'>
					{filteredAssets.map((item) => (
						<item.cardComponent key={item.uID} device={item} />
					))}
				</Row>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Add Assets to the Group.</span>}>
					<Button size='small' onClick={() => setManageModalVisible(true)}>
						Manage
					</Button>
				</Empty>
			)}
		</div>
	);
};

export default RenderAssetsForAssetGroup;
