import { DownOutlined } from '@ant-design/icons';
import { Dropdown, DropdownMenu, MenuItem } from '@iso/components/uielements';
import Button from '@iso/components/uielements/button';
import React, { Fragment, useState } from 'react';
import AddDefaultCategoryModal from '../AddDefaultCategoryModal';

const RenderOrganisationActions = ({ data, size, placement, showIcon = true, shape }) => {
	const [defaultCategoryVisibleModal, setDefaultCategoryVisibleModal] = useState(false);

	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	const actions = [
		<AddDefaultCategoryModal
			visible={defaultCategoryVisibleModal}
			setVisible={setDefaultCategoryVisibleModal}
			data={data}
		/>
	];

	return (
		<Fragment>
			<Dropdown arrow overlay={() => actionMenu(actions)} trigger={['click']} placement={placement || 'bottom'}>
				<Button
					style={{
						borderRadius: shape === 'circle' ? '10px' : null
					}}
					size={size || 'small'}
				>
					Actions
					{showIcon && <DownOutlined />}
				</Button>
			</Dropdown>
		</Fragment>
	);
};

export default RenderOrganisationActions;
