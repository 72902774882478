import actions from './actions';

const initState = {
	pending: true,
	idToken: null,
	userProfile: { defaultProfile: {}, userPermissions: [] }
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				idToken: action.token
			};
		case actions.USER_DISPATCHED:
			return {
				...state,
				userProfile: action.profile,
				pending: false
			};
		case actions.SWITCH_PERSON_SUCCESS:
			return {
				...state,
				userProfile: {
					...state.userProfile,
					defaultProfile: action.newProfile
				},
				pending: false
			};
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
};
export default authReducer;
