import { ComponentLabel, RenderSiteName } from '@Z/components/atoms';
import { IndividualAssetGroupModal } from '@Z/components/molecules';
import Icon from '@ant-design/icons';
import { ReactComponent as SLIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import { smallTimeOptions } from '@iso/assets/styles/constants';
import { Button, Tooltip } from '@iso/components/uielements';
import Popover from '@iso/components/uielements/popover';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import Col from '@iso/ui/Antd/Grid/Col';
import Row from '@iso/ui/Antd/Grid/Row';
import { Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RenderActions from './renderActions';
import AssetGroupCardWrapper, { QuickViewWrapper } from './styles';

const { Paragraph } = Typography;

const AssetGroupCard = ({ group }) => {
	// HOC
	const SiteNameWithLink = withHyperLink(RenderSiteName);

	// Local States
	const [viewModalVisible, setViewModalVisible] = useState(false);
	const [groupDevices, setGroupDevices] = useState([]);

	// Redux States
	const { devicesData } = useSelector((state) => state.devices);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	useEffect(() => {
		if (group.assets) {
			// find the devices that are in the group.assets is an array of uIDs
			const filteredDevices = devicesData.filter((item) => {
				// check if the group.asset array of object containers the uID of the item
				return group.assets.some((asset) => asset.assetID === item.uID);
			});

			setGroupDevices(filteredDevices);
		}

		return () => {
			setGroupDevices([]);
		};
	}, [devicesData, group.assets]);

	return (
		<AssetGroupCardWrapper
			title={
				<IndividualAssetGroupModal
					data={{ groupID: group.groupID }}
					visible={viewModalVisible}
					setVisible={setViewModalVisible}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<ComponentLabel
							style={{
								fontSize: '18px',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								cursor: 'pointer'
							}}
							text={group.name}
							onClick={() => setViewModalVisible(true)}
						/>

						{group.typeID === constants.ACCESS_CONTROL && (
							<Tooltip title='Access Group'>
								<Icon style={{ fontSize: '25px' }} component={SLIcon} />
							</Tooltip>
						)}
					</div>
				</IndividualAssetGroupModal>
			}
			actions={[RenderActions({ group })]}
		>
			<Row>
				<Col lg={24} md={24} sm={24} xs={24} className='groupInfoContainer'>
					<div className='subInfoContainer'>
						<div>
							<label className='infoLabel'>Type</label>
							<p className='infoProp'>{group.typeName}</p>
						</div>
						<div>
							<label className='infoLabel'>Site</label>
							<SiteNameWithLink dataID={group.siteID} type='site' />
						</div>
						<div>
							<label className='infoLabel'>Assets</label>
							<p className='infoProp'>{group.assets.length}</p>
						</div>
					</div>
					{group.description && (
						<div className='descContainer'>
							<Paragraph
								ellipsis={{ rows: 2, expandable: false, tooltip: group.description }}
								className='description'
							>
								{group.description}
							</Paragraph>
						</div>
					)}
				</Col>
			</Row>
			<Row>
				<Popover
					key={group.groupID}
					placement='right'
					trigger={['click']}
					color='white'
					overlayStyle={{
						width: '700px',
						zIndex: '999'
					}}
					overlayInnerStyle={{
						borderRadius: '4px',
						width: '100%'
					}}
					destroyTooltipOnHide
					title={
						<ComponentLabel
							style={{
								fontSize: '14px',
								fontWeight: '600'
							}}
							text={'Assets in this group'}
						/>
					}
					content={() => (
						<QuickViewWrapper>
							<div className='cardContainer'>
								{groupDevices.map((item) => {
									return <item.cardComponent key={item.uID} device={item} />;
								})}
							</div>
						</QuickViewWrapper>
					)}
				>
					<Button className='viewAssetButton'>Quick View</Button>
				</Popover>
			</Row>
			<Row className='metaContainer'>
				<Space wrap className='dateContainer'>
					<p
						className='metaField'
						title={new Date(group.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
					>
						<span className='metaLabel'>Last updated: </span>
						{new Date(group.modifiedDate).toLocaleString('en-AU', smallTimeOptions)}
					</p>
					<p
						className='metaField'
						title={new Date(group.createdDate).toLocaleString('en-AU', smallTimeOptions)}
					>
						<span className='metaLabel'>Created on: </span>
						{new Date(group.createdDate).toLocaleString('en-AU', smallTimeOptions)}
					</p>
				</Space>
			</Row>
		</AssetGroupCardWrapper>
	);
};

export default AssetGroupCard;
