/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 09-08-2022 15:59:20
 * @desc [description]
 */

import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import ContentHolder from '@iso/components/utility/contentHolder';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { Loading, SearchBar, SimpleTable } from '@Z/components/atoms';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const DevicesGroupTable = () => {
	const history = useHistory();
	const currentPath = history.location.pathname;
	const [loadings, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const [filteredata, setFilteredData] = React.useState([]);
	const deviceData = useSelector((state) => state.devices.deviceData);
	const deviceDataPending = useSelector((state) => state.devices.pending);

	React.useEffect(() => {
		setLoading(true);
		if (!deviceDataPending) {
			setData(deviceData);
			setFilteredData(deviceData);
			setLoading(false);
		}
	}, [deviceData, deviceDataPending]);

	const actionMenu = (text, record) => (
		<DropdownMenu>
			<MenuItem
				onClick={() => {
					history.push({
						pathname: currentPath + '/employee',
						state: { userData: { userID: record.uid } }
					});
				}}
				key='1'
			>
				View Device
			</MenuItem>
			<MenuItem
				onClick={() => {
					console.log('Edit');
				}}
				key='2'
			>
				Edit Device
			</MenuItem>
		</DropdownMenu>
	);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const columns = [
		{
			title: 'Device Group ID',
			dataIndex: 'deviceID',
			key: 'deviceID',
			width: 40,
			ellipsis: true
		},
		{
			title: 'Group Device Name',
			dataIndex: 'hardwareName',
			key: 'hardwareName',
			width: 40,
			ellipsis: true
		},
		{
			title: 'Group Company',
			dataIndex: 'companyID',
			key: 'companyID',
			width: 30,
			ellipsis: true
		},
		{
			title: 'Group Site',
			dataIndex: 'companySiteID',
			key: 'companySiteID',
			width: 30,
			ellipsis: true
		},
		{
			title: 'Group Floor',
			dataIndex: 'floorID',
			key: 'floorID',
			width: 30,
			ellipsis: true
		},
		{
			title: 'Group Status',
			dataIndex: 'syncStatus',
			key: 'syncStatus',
			width: 40,
			ellipsis: true
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Dropdown overlay={actionMenu(text, record)} trigger={['click']}>
					<Button>
						Actions <DownOutlined />
					</Button>
				</Dropdown>
			),
			ellipsis: true,
			width: 50
		}
	];

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object.hardwareName.toLowerCase().includes(query.toLowerCase()) ||
				object.syncStatus.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredData(searchResult);
	};

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<div style={{ marginBottom: '10px', marginTop: '10px' }}>
				<SearchBar // The location and query needs to be changed
					placeholder='Search'
					enterButton='Search'
					loading={false}
					//ICON FIND
					onChange={(query) => {
						handleSearch(query.target.value, data);
					}}
					onSearch={(query) => {
						handleSearch(query, data);
					}}
				/>
			</div>

			<SimpleTable columns={columns} pageSize={7} dataSource={filteredata} />
		</IsoWidgetBox>
	);
};

export default DevicesGroupTable;
