import App from '@iso/redux/app/reducer';
import assetGroups from '@iso/redux/assetGroups/reducer';
import Auth from '@iso/redux/auth/reducer';
import booking from '@iso/redux/booking/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import defaultSettings from '@iso/redux/defaultSettings/reducer';
import deviceCategories from '@iso/redux/deviceCategories/reducer';
import devices from '@iso/redux/devices/reducer';
import drawer from '@iso/redux/drawer/reducer';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import remoteConfig from '@iso/redux/fbRemoteConfig/reducer';
import floors from '@iso/redux/floors/reducer';
import inventory from '@iso/redux/inventory/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import modal from '@iso/redux/modal/reducer';
import Organisation from '@iso/redux/organisation/reducer';
import people from '@iso/redux/people/reducer';
import settings from '@iso/redux/settings/reducer';
import sites from '@iso/redux/sites/reducer';
import teams from '@iso/redux/teams/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Todos from '@iso/redux/todos/reducer';
import types from '@iso/redux/types/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
	App,
	assetGroups,
	Auth,
	booking,
	Contacts,
	defaultSettings,
	devices,
	deviceCategories,
	drawer,
	DynamicChartComponent,
	floors,
	inventory,
	LanguageSwitcher,
	modal,
	Organisation,
	people,
	remoteConfig,
	settings,
	sites,
	teams,
	ThemeSwitcher,
	Todos,
	types
});
