import { notification } from '@iso/components';
import superFetch from '@iso/lib/helpers/superFetch';
import { Button } from 'antd';
import React from 'react';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

export function* loadPeopleData() {
	yield takeEvery('LOAD_PEOPLE_DATA', function* () {
		try {
			const data = yield superFetch.get('/people');
			if (data.data) {
				const peopleData = data.data.map((person) => {
					return {
						...person,
						getDisplayName: function () {
							const displayName =
								this.displayName || (this.isNewUser ? this.email : 'person name missing.');
							const title = this.isNewUser
								? "Person hasn't hopped on Zezamii yet, but their name will pop up once they accept your invite!"
								: this.email;
							return { displayName, title };
						}
					};
				});

				yield put({
					type: actions.LOAD_PEOPLE_SUCCESS,
					peopleData: peopleData
				});
			} else {
				yield put({
					type: actions.LOAD_PEOPLE_ERROR,
					error: data.error
				});
			}
		} catch (error) {
			notification(
				'error',
				'There is an Error! We are mending the problem, try again soon!.',
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Try again!
				</Button>
			);
			return error;
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadPeopleData)]);
}
