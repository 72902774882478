import { LeafletMap, ComponentLabel, Loading } from '@Z/components/atoms';
import { RemoteActionsButton } from '@Z/components/molecules';
import React, { useState, useEffect } from 'react';
import ACLockDeviceCardWrapper from './ACLockDeviceCardWrapper.styles';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import { useSelector } from 'react-redux';
import { copyToClipBoard } from '@iso/lib/helpers/utility';
import { GrantPermissionModal, ACPermittedPeopleTable } from '@Z/components/molecules';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import Button from '@iso/components/uielements/button';

import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
const ACLockDeviceCard = ({ data }) => {
	const MAP_HEIGHT = 460;
	const MAP_WIDTH = 800;
	const [mapUrl, setMapUrl] = useState('');
	const { devicesData } = useSelector((state) => state.devices);
	const { floorsData } = useSelector((state) => state.floors);
	const [selectedLock, setSelectedLock] = useState({});
	const [currentFloor, setCurrentFloor] = useState({});
	const [grantPermissionModalVisible, setGrantPermissionModalVisible] = useState(false);
	const [locksByFloor, setLocksByFloor] = useState([]);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const [remoteUnlockVisible, setRemoteUnlockVisible] = useState(false);

	// HOC
	const LockNameWithLink = withHyperLink(() => <ComponentLabel text={selectedLock.name} />);

	useEffect(() => {
		setSelectedLock(data);
	}, [data]);

	useEffect(() => {
		if (selectedLock?.location?.length > 0) {
			const lockLocation = selectedLock.location[0];
			const locations = [];
			const floor = floorsData.find((floor) => floor.floorID === lockLocation.floorID);
			setCurrentFloor(floor);
			getDownloadURL(floor.url).then((url) => setMapUrl(url));

			devicesData.forEach((device) => {
				const location = device?.location?.map((location) => {
					if (location.floorID === floor.floorID) return location;
					return null;
				});
				const cleanLocation = location.filter((item) => item);
				if (cleanLocation.length > 0)
					locations.push({
						...device,
						x: cleanLocation[0].xAxis,
						y: cleanLocation[0].yAxis,
						selectedDevice: device.uID === selectedLock.uID
					});
			});
			setLocksByFloor(locations.filter((item) => item?.defaultCategoryID === constants.SMART_LOCKS));
		}

		return () => {
			setMapUrl('');
			setCurrentFloor({});
			setLocksByFloor([]);
		};
	}, [floorsData, selectedLock, devicesData, currentFloor, constants]);

	const permittedLocationsObjects =
		selectedLock?.location?.length > 0
			? selectedLock.location.map((item) => floorsData.find((floor) => floor.floorID === item.floorID))
			: [];

	const isPermittedRemoteUnlock = useManagePermittedRoles('aCRemoteUnlock', {
		orgID: [data.orgID],
		siteID: permittedLocationsObjects.map((item) => item.siteID).filter((item) => item),
		floorID: permittedLocationsObjects.map((item) => item.floorID).filter((item) => item)
	});
	const isPermittedGrantPermission = useManagePermittedRoles('aCPermissionManagement', {
		orgID: [data.orgID],
		siteID: permittedLocationsObjects.map((item) => item.siteID).filter((item) => item),
		floorID: permittedLocationsObjects.map((item) => item.floorID).filter((item) => item)
	});

	return !data.uID ? (
		<Loading />
	) : (
		<ACLockDeviceCardWrapper>
			<div className='deviceInformationContainer'>
				<div className='deviceInfoContainer'>
					<LockNameWithLink
						dataID={selectedLock.uID}
						type='device'
						suppData={{
							categoryID: selectedLock.categoryID
						}}
					/>
					<p className='uidContainer' onClick={() => copyToClipBoard(selectedLock.uID)}>
						<span className='IDLabel'>UID: </span>
						{selectedLock.uID}
						<i className='material-icons-outlined copyIcon'>copy_content</i>
					</p>
				</div>
				<div className='actionContainer'>
					{isPermittedGrantPermission ? (
						<GrantPermissionModal
							visible={grantPermissionModalVisible}
							setVisible={setGrantPermissionModalVisible}
							data={{
								deviceID: selectedLock.uID,
								deviceName: selectedLock.name
							}}
						>
							<Button className='permissionButton' onClick={() => setGrantPermissionModalVisible(true)}>
								Grant Permission
							</Button>
						</GrantPermissionModal>
					) : null}
					{isPermittedRemoteUnlock && (
						<RemoteActionsButton
							data={{
								deviceID: selectedLock.uID,
								name: selectedLock.name
							}}
							visible={remoteUnlockVisible}
							setVisible={setRemoteUnlockVisible}
						/>
					)}
				</div>
			</div>
			<div className='mapContainer'>
				{mapUrl !== '' ? (
					<LeafletMap
						markerList={locksByFloor}
						mapHeight={MAP_HEIGHT}
						mapWidth={MAP_WIDTH}
						scrollWheelZoom={true}
						url={mapUrl}
					/>
				) : null}
			</div>
			<ACPermittedPeopleTable lock={selectedLock} />
		</ACLockDeviceCardWrapper>
	);
};

export default ACLockDeviceCard;
