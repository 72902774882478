import { notification } from '@iso/components';
import { mergedSettings } from '@iso/lib/helpers/settingsHelper';
import superFetch from '@iso/lib/helpers/superFetch';
import { Button } from 'antd';
import React from 'react';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import actionsDefaultSetting from '../defaultSettings/actions';
import actions from './actions';

const defaultSettings = (state) => state.defaultSettings;
const defaultProfile = (state) => state.Auth.userProfile.defaultProfile;

export function* loadSettingsData() {
	yield takeEvery(
		[actionsDefaultSetting.LOAD_DEFAULT_ORGANISATION_SETTINGS_SUCCESS, actions.LOAD_ORGANISATION_SETTINGS_DATA],
		function* () {
			const { orgDefaultSettings } = yield select(defaultSettings);

			if (orgDefaultSettings.length > 0) {
				try {
					const organisationSettings = yield superFetch.get('/settings').then((res) => {
						if (res.data && res.statusCode === 200) {
							return res.data;
						}
					});

					const data = mergedSettings(orgDefaultSettings, organisationSettings);

					if (data) {
						yield put({
							type: actions.LOAD_ORGANISATION_SETTINGS_SUCCESS,
							payload: data
						});
					} else {
						yield put({
							type: actions.LOAD_ORGANISATION_SETTINGS_ERROR,
							error: 'Error'
						});
					}
				} catch (error) {
					notification(
						'error',
						'There is an Error! We are mending the problem, try again soon!.',
						<Button
							onClick={() => {
								window.location.reload();
							}}
						>
							Try again!
						</Button>
					);
				}
			}
		}
	);

	yield takeEvery(
		[actionsDefaultSetting.LOAD_DEFAULT_PERSON_SETTINGS_SUCCESS, actions.LOAD_PERSON_SETTINGS_DATA],
		function* () {
			const { personDefaultSettings } = yield select(defaultSettings);

			const { personID } = yield select(defaultProfile);

			try {
				const personSettings = yield superFetch.get(`/settings/people`, {}, { personID }).then((res) => {
					if (res.data && res.statusCode === 200) {
						return res.data;
					}
				});

				const data = mergedSettings(personDefaultSettings, personSettings);

				if (data) {
					yield put({
						type: actions.LOAD_PERSON_SETTINGS_SUCCESS,
						payload: data
					});
				} else {
					yield put({
						type: actions.LOAD_PERSON_SETTINGS_ERROR,
						error: 'Error'
					});
				}
			} catch (error) {
				notification(
					'error',
					'There is an Error! We are mending the problem, try again soon!.',
					<Button
						onClick={() => {
							window.location.reload();
						}}
					>
						Try again!
					</Button>
				);
			}
		}
	);
}

export default function* rootSaga() {
	yield all([fork(loadSettingsData)]);
}
