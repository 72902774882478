/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 21-04-2020  11:39:13
 * @modify date 09-05-2023  17:02:47
 * @desc [description]
 */

import React from 'react';
import TableWrapper from './Tables.styles';

const SimpleTable = ({ dataSource, columns, pageSize, pagination, ...props }) => {
	dataSource &&
		dataSource.length > 0 &&
		dataSource.map((item, index) => (!dataSource[index].key ? (dataSource[index].key = index) : null));

	return (
		<TableWrapper
			rowClassName={(record) => (record.deletedDate ? 'deleted-row' : '')}
			pagination={{
				defaultPageSize: pageSize,
				hideOnSinglePage: true,
				showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
				showSizeChanger: true,
				...pagination
			}}
			columns={columns}
			dataSource={dataSource}
			size='middle'
			{...props}
		/>
	);
};
export default SimpleTable;
