export const entityInfo = {
	Site: {
		typeID: 2,
		apiSlug: 'sites',
		entityDir: 'Sites'
	},
	Floor: {
		typeID: 3,
		apiSlug: 'floors',
		entityDir: 'FloorPlans'
	},
	People: {
		typeID: 4,
		apiSlug: 'people',
		entityDir: 'People/Profile'
	},
	Team: {
		typeID: 5,
		apiSlug: 'teams',
		entityDir: 'Teams'
	},
	Settings: {
		typeID: 6,
		apiSlug: 'setting',
		entityDir: 'Settings'
	}
};
