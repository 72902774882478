import { notification } from '@iso/components';
import { Button } from 'antd';
import React from 'react';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRemoteConfig } from '@iso/lib/firebase/firebase.remoteConfig.utils';

export function* loadRemoteConfigData() {
	yield takeEvery(actions.LOAD_REMOTE_CONFIG_DATA, function* () {
		const rConfig = yield getRemoteConfig().catch((error) => {
			return { error: error };
		});
		if (rConfig.error) {
			yield put({
				type: actions.LOAD_REMOTE_CONFIG_ERROR,
				error: rConfig.error
			});
			notification(
				'error',
				'Error loading data! Please refresh the page and try again.',
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Try again!
				</Button>
			);
		} else {
			yield put({
				type: actions.LOAD_REMOTE_CONFIG_SUCCESS,
				remoteConfigData: rConfig
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(loadRemoteConfigData)]);
}
