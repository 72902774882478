import React from 'react';
import { List, Tooltip } from 'antd';
import Button from '@iso/components/uielements/button';
import { RenderAvatarFromFirebase } from '@Z/components/atoms';
import { useSelector } from 'react-redux';

const RenderSampleDevices = ({ defaultCategoryID }) => {
	const {
		remoteConfig: { sampleDevicesConfig }
	} = useSelector((state) => state.remoteConfig);

	if (!defaultCategoryID) return null;

	return (
		Object.keys(sampleDevicesConfig).includes(defaultCategoryID?.toString()) && (
			<Tooltip
				key={defaultCategoryID}
				placement='bottom'
				trigger={['click']}
				color='white'
				title={
					<List
						size='small'
						grid='xs'
						style={{
							maxHeight: '250px',
							overflowY: 'auto'
						}}
						dataSource={sampleDevicesConfig[defaultCategoryID?.toString()]}
						renderItem={(device) => (
							<List.Item
								style={{
									minWidth: '200px',
									margin: '5px 0px'
								}}
							>
								<List.Item.Meta
									style={{
										alignItems: 'baseline'
									}}
									avatar={device.icon ? <RenderAvatarFromFirebase path={device.icon} /> : null}
									title={device.name}
								/>
							</List.Item>
						)}
					/>
				}
			>
				<Button>Sample devices</Button>
			</Tooltip>
		)
	);
};

export default RenderSampleDevices;
