import basicStyle from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import { RadioButton, RadioGroup } from '@iso/components/uielements/radio';
import Tag from '@iso/components/uielements/tag';
import ContentHolder from '@iso/components/utility/contentHolder';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { getPrimaryAddress, getPrimaryContact } from '@iso/lib/helpers/getPrimary';
import { HybridMap, Loading, SearchBar, SimpleTable, ProfileImageComponent } from '@Z/components/atoms';
import { Avatar, Col, Collapse, Empty, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FloorPreviewWrapper from './floorsTableInformation.styles';
import RenderActionMenu from './renderActionMenu';
import { FloorAdditionalInfoComponent } from '@Z/components/molecules';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import { getInitials } from '@iso/lib/helpers/getInitials';

const FloorsTableInformation = () => {
	const { floorsData, pending } = useSelector((state) => state.floors);
	const [floorsBySite, setFloorsBySite] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [previewFloor, setPreviewFloor] = useState({});
	const displayNames = useGetPersonDisplayName(true);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const { Panel } = Collapse;

	useEffect(() => {
		if (!pending && filteredData.length > 0) {
			const floorsBySite = [];
			filteredData.map((floor) => {
				const res = floorsBySite.some((item) => {
					if (item.siteID === floor.siteID) {
						item.floors.push(floor);
						return true;
					}
					return false;
				});
				if (!res) {
					floorsBySite.push({
						siteID: floor.siteID,
						name: floor.siteName,
						floors: [floor]
					});
				}
				return true;
			});
			filteredData[0] &&
				setPreviewFloor({
					...filteredData[0],
					primaryAddress: getPrimaryAddress(filteredData[0].siteAddress),
					primaryContact: getPrimaryContact(filteredData[0].contact)
				});
			setFloorsBySite(floorsBySite);
		}
	}, [floorsData, pending, filteredData]);

	useEffect(() => {
		setFilteredData(floorsData);
	}, [floorsData]);

	const handleSearch = (query, array) => {
		const searchResult = array.filter(
			(object) =>
				object.name.toLowerCase().includes(query.toLowerCase()) ||
				(object.description !== null && object.description.toLowerCase().includes(query.toLowerCase())) ||
				object.siteName.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredData(searchResult);
	};

	const onPreviewChange = (e) => {
		const selectedFloor = floorsData.find((floor) => floor.floorID === e.target.value);
		setPreviewFloor({
			...selectedFloor,
			primaryAddress: getPrimaryAddress(selectedFloor.siteAddress),
			primaryContact: getPrimaryContact(selectedFloor.contact)
		});
	};

	const BaseLink = withHyperLink((a) => {
		return (
			<div
				style={{
					cursor: 'pointer'
				}}
			>
				{a.children}
			</div>
		);
	});

	const ViewIndividualFloorWithlink = (props) => {
		return <BaseLink {...props} />;
	};

	const ViewPersonWithLink = (props) => {
		return <BaseLink {...props} />;
	};

	const columns = [
		{
			title: 'Floor',
			dataIndex: 'name',
			key: 'name',
			width: 60,
			ellipsis: true,
			render: (text, record) => (
				<ViewIndividualFloorWithlink type='floor' dataID={record.floorID}>
					{text}
				</ViewIndividualFloorWithlink>
			)
		},
		{
			key: 'actions',
			align: 'center',
			render: (record) =>
				record.deletedDate ? (
					<Tag
						color='red'
						style={{
							height: '28px',
							lineHeight: '28px',
							fontSize: '12px',
							fontWeight: '500',
							borderRadius: '4px'
						}}
					>
						DELETED
					</Tag>
				) : (
					<RenderActionMenu record={record} />
				),
			ellipsis: true,
			width: 20
		},
		{
			key: 'preview',
			render: (text) => (
				<RadioButton
					style={{
						height: '28px',
						lineHeight: '28px',
						fontSize: '12px',
						fontWeight: '500'
					}}
					value={text.floorID}
				>
					Preview
				</RadioButton>
			),
			ellipsis: true,
			width: 20
		}
	];

	return pending ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<SearchBar
				style={{ marginBottom: '10px' }}
				placeholder='Search Floor Name or Description'
				enterButton='Search'
				loading={false}
				onChange={(query) => {
					handleSearch(query.target.value, floorsData);
				}}
				onSearch={(query) => {
					handleSearch(query, floorsData);
				}}
			/>
			{filteredData && filteredData.length > 0 ? (
				<RadioGroup
					buttonStyle='solid'
					onChange={(e) => onPreviewChange(e)}
					style={{ width: '100%' }}
					value={previewFloor.floorID}
				>
					<Row gutter={24} justify='start'>
						<Col lg={12} md={12} sm={24} xs={24}>
							<Collapse defaultActiveKey={0} accordion>
								{floorsBySite.map((site, index) => (
									<Panel header={site.name} key={index}>
										<SimpleTable columns={columns} pageSize={10} dataSource={site.floors} />
									</Panel>
								))}
							</Collapse>
						</Col>
						{previewFloor && Object.keys(previewFloor).length > 0 && (
							<Col lg={12} md={12} sm={24} xs={24}>
								<FloorPreviewWrapper style={{ borderRadius: '4px' }}>
									<div className='floorMap' styles={{ overflow: 'hidden' }}>
										<div
											style={{
												width: '100%',
												height: '100%',
												overflow: 'hidden'
											}}
										>
											<HybridMap data={{ ...previewFloor, markers: [] }} mapType={'Floor'} />
										</div>
									</div>
									<div
										className='floorTitle'
										style={{
											cursor: 'pointer'
										}}
									>
										<ViewIndividualFloorWithlink type='floor' dataID={previewFloor.floorID}>
											<div className='floorName'>{previewFloor.name}</div>
										</ViewIndividualFloorWithlink>
										<ViewIndividualFloorWithlink type='floor' dataID={previewFloor.floorID}>
											<Button>View Details</Button>
										</ViewIndividualFloorWithlink>
									</div>

									<div className='floorDescription'>{previewFloor.description}</div>
									<Row
										gutter={[8, 8]}
										style={{
											marginTop: '15px'
										}}
									>
										<Col lg={10} md={10} sm={24} xs={24} className='floor-card-content'>
											{previewFloor.primaryContact && (
												<div className='contact-container'>
													<p>{previewFloor.primaryContact.contactInformation}</p>
												</div>
											)}
											{previewFloor.primaryAddress && (
												<div className='address-container'>
													<p>{previewFloor.primaryAddress.addressLine1}</p>
													{previewFloor.primaryAddress.addressLine2 !== 'null' && (
														<p>{previewFloor.primaryAddress.addressLine2}</p>
													)}
													<p>
														{previewFloor.primaryAddress.suburb}{' '}
														{previewFloor.primaryAddress.state}{' '}
														{previewFloor.primaryAddress.postCode}
													</p>
												</div>
											)}
										</Col>
										<Col lg={10} md={10} sm={24} xs={24}>
											<Avatar.Group
												maxCount={2}
												size='large'
												style={{
													position: 'absolute',
													bottom: 0,
													right: 0
												}}
											>
												{previewFloor.adminPerson &&
													previewFloor.adminPerson.map((admin) => (
														<Tooltip
															key={admin.personID}
															title={
																<ViewPersonWithLink
																	type='person'
																	DataID={admin.personID}
																>
																	{`${admin.displayName} - ${admin.adminType}`}
																</ViewPersonWithLink>
															}
															placement='top'
														>
															{admin.photoUrl === 'n/a' || !admin.photoUrl ? (
																<ViewPersonWithLink
																	type='person'
																	dataID={admin.personID}
																>
																	<Avatar
																		key={admin.personID}
																		style={{
																			cursor: 'pointer'
																		}}
																	>
																		{getInitials(
																			displayNames[admin.personID]?.displayName
																		)}
																	</Avatar>
																</ViewPersonWithLink>
															) : (
																<span
																	style={{
																		cursor: 'pointer'
																	}}
																>
																	<ViewPersonWithLink
																		type='person'
																		dataID={admin.personID}
																	>
																		<ProfileImageComponent
																			entityObject={admin}
																			size={40}
																			entityName={admin.displayName}
																			shape='circle'
																			edit={false}
																		/>
																	</ViewPersonWithLink>
																</span>
															)}
														</Tooltip>
													))}
											</Avatar.Group>
										</Col>
									</Row>
									<div style={{ marginLeft: '-10px' }}>
										<FloorAdditionalInfoComponent data={previewFloor} />
									</div>
								</FloorPreviewWrapper>
							</Col>
						)}
					</Row>
				</RadioGroup>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Floor Data</span>} />
			)}
		</IsoWidgetBox>
	);
};

export default FloorsTableInformation;
