import { Loading, SearchBar } from '@Z/components/atoms';
import { ACFloorDeviceTableComponent, ACLockDeviceCard } from '@Z/components/molecules';
import basicStyle from '@iso/assets/styles/constants';
import { RadioGroup } from '@iso/components/uielements/radio';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import ContentHolder from '@iso/components/utility/contentHolder';
import { Col, Empty, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ACInformationComponent = () => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const { floorsData, pending } = useSelector((state) => state.floors);
	const { devicesData } = useSelector((state) => state.devices);
	const [filteredData, setFilteredData] = useState([]);
	const [previewLock, setPreviewLock] = useState({});
	const [locksByFloor, setLocksByFloor] = useState([]);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	const [devices, setDevices] = useState([]);
	const [floors, setFloors] = useState([]);

	// Get all the data from the redux store and set it to the state
	useEffect(() => {
		const locks = devicesData.filter(
			(lock) => lock.isDefault && constants.ACCESS_CONTROL_CATEGORIES.includes(lock.defaultCategoryID)
		);

		setDevices(locks);
		setFloors(floorsData);
	}, [devicesData, floorsData, constants]);

	useEffect(() => {
		// infuse floor data with the locks
		const floorsDataWithLocks = floors
			.map((floor) => {
				const floors = devices.filter(
					(lock) => lock.location.length > 0 && lock.location[0].floorID === floor.floorID
				);
				if (floors.length > 0) {
					return { ...floor, locks: floors };
				}
				return null;
			})
			.filter((l) => l);

		// set all the floors and locks data to the state
		setLocksByFloor(floorsDataWithLocks);
		setFilteredData(floorsDataWithLocks);

		// set the first lock to the preview lock
		setPreviewLock(Object.keys(previewLock).length > 0 ? previewLock : floorsDataWithLocks[0]?.locks[0] || {});
		return () => {
			setLocksByFloor([]);
			setPreviewLock({});
		};
		// eslint-disable-next-line
	}, [devices, floors]);

	const handleSearch = (query) => {
		const searchResult = locksByFloor.filter((o) => {
			const floorLocks = devices.filter(
				(lock) => lock.location.length > 0 && lock.location[0].floorID === o.floorID
			);
			return (
				o.name.toLowerCase().includes(query.toLowerCase()) ||
				floorLocks?.find((lock) => lock.name.toLowerCase().includes(query.toLowerCase()))
			);
		});

		setFilteredData(searchResult);
		setPreviewLock(searchResult[0]?.locks[0] || previewLock);
	};

	const onPreviewChange = (e) => {
		const selectedLock = devices.find((device) => device.uID === e.target.value);
		setPreviewLock(selectedLock);
	};

	return pending ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<Row gutter={10}>
			{devices.length > 0 ? (
				<React.Fragment>
					<Col lg={12} md={24} sm={24} xs={24}>
						<IsoWidgetBox>
							<SearchBar
								style={{ marginBottom: '10px' }}
								placeholder='Search by Door/Lock Name'
								enterButton='Search'
								loading={false}
								onChange={(e) => {
									handleSearch(e.target.value);
								}}
								onSearch={(query) => {
									handleSearch(query);
								}}
							/>
							{filteredData?.length > 0 ? (
								<RadioGroup
									buttonStyle='solid'
									onChange={(e) => onPreviewChange(e)}
									style={{ width: '100%' }}
									value={previewLock.uID}
								>
									<ACFloorDeviceTableComponent
										locksByFloor={filteredData}
										selectedFloor={previewLock.location}
									/>
								</RadioGroup>
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={<span>No Locks Found.</span>}
								/>
							)}
						</IsoWidgetBox>
					</Col>
					{previewLock.uID && (
						<Col lg={12} md={24} sm={24} xs={24}>
							<IsoWidgetBox>
								<ACLockDeviceCard data={previewLock} />
							</IsoWidgetBox>
						</Col>
					)}
				</React.Fragment>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Access Setup Missing.</span>} />
			)}
		</Row>
	);
};

export default ACInformationComponent;
