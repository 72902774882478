import styled from 'styled-components';
import Card from '@iso/components/uielements/card';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const AssetGroupCardWrapper = styled(Card)`
	width: 200px;
	max-width: 300px;
	flex: 1;
	&,
	.ant-card-bordered {
		border-color: ${palette('grayscale', 2)} !important;
	}

	&:hover {
		outline: 2px solid ${palette('secondary', 0)};
		border-radius: 10px;

		.ant-card-head-title > h3 {
			font-weight: bold;
		}
	}

	.ant-card-actions > li {
		&:hover {
			border-top: 2px solid ${palette('secondary', 8)};
		}

		margin: 0;
		& > span:hover {
			color: ${palette('primary', 0)};
			font-weight: 500;
		}
	}

	.ant-card-head {
		min-height: auto;
		padding: 5px 10px;

		.ant-card-head-title {
			padding: 0;
		}
	}

	.ant-card-body {
		padding: 10px 10px 5px 10px;
		/* height: inherit; */
	}

	.groupInfoContainer {
		.groupName {
			margin-bottom: 5px;
			white-space: nowrap;
		}

		.descContainer {
			${borderRadius('6px')}
			min-height: 50px;

			.description {
				font-size: 11px;
				font-weight: 300;
				.ant-typography-expand {
					color: ${palette('primary', 0)};
					font-weight: 500;
				}
			}
		}

		.subInfoContainer {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
			column-gap: 15px;
			margin-bottom: 5px;
		}
		.infoLabel {
			font-size: 11px;
			font-weight: 500;
			opacity: 0.7;
		}
		.infoProp {
			font-size: 11px;
			font-weight: 600;
			margin-bottom: 10px;
			color: ${palette('text', 1)};
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.viewAssetButton {
		width: 100%;
		border: 1px solid ${palette('primary', 5)};
	}
	.metaContainer {
		font-size: 9px;
		font-weight: 400;
		opacity: 0.6;
		float: right;
		padding-top: 10px;

		.metaField {
			width: max-content;
			font-weight: 500;
			.metaLabel {
				font-weight: 400;
			}
		}

		.dateContainer {
			row-gap: 1px !important;

			.ant-space-item {
				justify-content: flex-end;
			}
		}
	}
`;

const DropdownSpaceWrapper = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0px;
	color: #1d3557;
	font-weight: 500;
`;

const QuickViewWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 650px;
	height: 450px;
	overflow-y: auto;

	.cardContainer {
		padding: 10px;
		column-width: 200px;
		column-gap: 4px;
		width: 100%;
		display: flow-root;
	}
`;
export default AssetGroupCardWrapper;
export { DropdownSpaceWrapper as SpaceWrapper, QuickViewWrapper };
