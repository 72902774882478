import React from 'react';
import Input from '@iso/components/uielements/input';
import Form from '@iso/components/uielements/form';
import { Row, Col } from 'antd';
import basicStyle from '@iso/assets/styles/constants';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import { useSelector } from 'react-redux';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';

const { colStyle } = basicStyle;

const Step1Form = () => {
	const aliasValidation = fieldsValidator.Sex;
	return (
		<div>
			<Form.Item label='Organisation Name' name='name' rules={fieldsValidator.Name} style={colStyle}>
				<Input />
			</Form.Item>

			<Form.Item label='Organisation Alias' name='alias' rules={aliasValidation} style={colStyle}>
				<Input />
			</Form.Item>
			<Row>
				<Col span={12}>
					<Form.Item
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						label='ABN'
						name='abn'
						style={colStyle}
						rules={fieldsValidator.ABN}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						label='ACN'
						name='acn'
						rules={fieldsValidator.ACN}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

const Step2Form = () => {
	const { contactTypes } = useSelector((state) => state.types);
	return (
		<div>
			<Row>
				<Col span={8}>
					<Form.Item
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						label='Contact Type'
						name={['contact', 0, 'contactTypeID']}
						style={colStyle}
					>
						<Select>
							{contactTypes.map((type) => (
								<Option key={type.contactTypeID} value={type.contactTypeID}>
									{type.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={16}>
					<Form.Item
						name={['contact', 0, 'contactInformation']}
						wrapperCol={{ span: 23, offset: 1 }}
						style={colStyle}
						rules={fieldsValidator.Home}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

const Step3Form = () => {
	const { addressTypes } = useSelector((state) => state.types);
	return (
		<div>
			<Form.Item
				label='Address Type'
				name={['address', 0, 'addressTypeID']}
				style={colStyle}
				wrapperCol={{ span: 6 }}
			>
				<Select>
					{addressTypes.map((type) => (
						<Option key={type.addressTypeID} value={type.addressTypeID}>
							{type.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label='Address Line 1'
				name={['address', 0, 'addressLine1']}
				style={colStyle}
				rules={fieldsValidator.AddressLine1}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label='Address Line 2'
				name={['address', 0, 'addressLine2']}
				style={colStyle}
				rules={fieldsValidator.AddressLine2}
			>
				<Input />
			</Form.Item>
			<Row>
				<Col span={12}>
					<Form.Item
						label='Suburb'
						name={['address', 0, 'suburb']}
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						style={colStyle}
						rules={fieldsValidator.Suburb}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label='State'
						name={['address', 0, 'state']}
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 12 }}
						style={colStyle}
					>
						<Select>
							<Option value='NSW'>NSW</Option>
							<Option value='QLD'>QLD</Option>
							<Option value='SA'>SA</Option>
							<Option value='TAS'>TAS</Option>
							<Option value='VIC'>VIC</Option>
							<Option value='WA'>WA</Option>
							<Option value='ACT'>ACT</Option>
							<Option value='NT'>NT</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label='Post Code'
						name={['address', 0, 'postCode']}
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						style={colStyle}
						rules={fieldsValidator.PostCode}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item label='Country' name={['address', 0, 'country']} wrapperCol={{ span: 6 }} style={colStyle}>
				<Select>
					<Option value='Australia'>Australia</Option>
				</Select>
			</Form.Item>
		</div>
	);
};
const steps = [
	{
		title: 'Basic Information',
		content: <Step1Form />
	},
	{
		title: 'Contact',
		content: <Step2Form />
	},
	{
		title: 'Address',
		content: <Step3Form />
	}
];

export default steps;
