import { AddAssetGroupButton, AddTeam } from '@Z/components/atoms';
import Icon from '@ant-design/icons';
import { ReactComponent as lockIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import Form from '@iso/components/uielements/form';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import React, { useEffect, useState } from 'react';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import { useSelector } from 'react-redux';
import { SelectDropDownWrapper, TagWrapper } from './styles';

const Step1Form = () => {
	const [people, setPeople] = useState([]);
	const [teams, setTeams] = useState([]);
	const { peopleData } = useSelector((state) => state.people);
	const { teamsData } = useSelector((state) => state.teams);
	const displayNames = useGetPersonDisplayName(true);

	useEffect(() => {
		const notDeletedPeople = peopleData.filter((person) => !person.deletedDate);
		setPeople(notDeletedPeople);
		setTeams(teamsData);

		return () => {
			setPeople([]);
			setTeams([]);
		};
	}, [peopleData, teamsData]);
	return (
		<React.Fragment>
			<Form.Item name='people' label='People'>
				<Select
					mode='multiple'
					style={{ width: '100%' }}
					showSearch
					placeholder={'Select People'}
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					filterSort={(optionA, optionB) =>
						optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
					}
					getPopupContainer={() => document.getElementById('selectPeopleDropdown')}
					dropdownStyle={{ position: 'relative' }}
				>
					{people.map((person, key) => (
						<Option key={key} value={person.personID} title={displayNames[person.personID].title}>
							{displayNames[person.personID].displayName}
						</Option>
					))}
				</Select>
			</Form.Item>
			<SelectDropDownWrapper id='selectPeopleDropdown' />
			<div style={{ display: 'flex', alignItems: 'flex-end' }}>
				<Form.Item name='teams' label='Teams' style={{ width: '100%', margin: '0 10px 0 0' }}>
					<Select
						style={{ width: '100%', marginRight: 10 }}
						mode='multiple'
						showSearch
						placeholder={'Select Teams'}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
						getPopupContainer={() => document.getElementById('selectTeamsDropdown')}
						dropdownStyle={{ position: 'relative' }}
					>
						{teams.map((team) => (
							<Option key={team.teamID} value={team.teamID}>
								{team.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<AddTeam />
			</div>

			<SelectDropDownWrapper id='selectTeamsDropdown' />
		</React.Fragment>
	);
};
const Step2Form = () => {
	const { devicesData } = useSelector((state) => state.devices);
	const { assetGroupsData } = useSelector((state) => state.assetGroups);

	const [devices, setDevices] = useState([]);
	const [groups, setGroups] = useState([]);

	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	useEffect(() => {
		setDevices(devicesData.filter((d) => constants.ACCESS_CONTROL_CATEGORIES.includes(d.defaultCategoryID)));
		setGroups(assetGroupsData.filter((g) => g.typeID === constants.ACCESS_CONTROL));

		return () => {
			setDevices([]);
			setGroups([]);
		};
	}, [devicesData, assetGroupsData, constants]);
	const tagRender = (props) => {
		const { label, onClose } = props;
		const onPreventMouseDown = (event) => {
			onClose();
			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<TagWrapper
				icon={<Icon component={lockIcon} className='tagIcons' />}
				onMouseDown={onPreventMouseDown}
				closable={true}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</TagWrapper>
		);
	};
	return (
		<React.Fragment>
			<Form.Item name='devices' label='Devices'>
				<Select
					tagRender={tagRender}
					mode='multiple'
					style={{ width: '100%' }}
					showSearch
					placeholder={'Select Devices'}
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					filterSort={(optionA, optionB) =>
						optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
					}
					getPopupContainer={() => document.getElementById('selectDeviceDropdown')}
					dropdownStyle={{ position: 'relative' }}
				>
					{devices.map((d) => (
						<Option key={d.uID} value={d.uID}>
							{d.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<SelectDropDownWrapper id='selectDeviceDropdown' />
			<div style={{ display: 'flex', alignItems: 'flex-end' }}>
				<Form.Item name='groups' label='Groups' style={{ width: '100%', margin: '0 10px 0 0' }}>
					<Select
						tagRender={tagRender}
						mode='multiple'
						style={{ width: '100%', marginRight: 10 }}
						showSearch
						placeholder={'Select Asset Groups'}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
						getPopupContainer={() => document.getElementById('selectGroupDropdown')}
						dropdownStyle={{ position: 'relative' }}
					>
						{groups.map((g) => (
							<Option key={g.groupID} value={g.groupID}>
								{g.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<AddAssetGroupButton forAccessControl />
			</div>
			<SelectDropDownWrapper id='selectGroupDropdown' />
		</React.Fragment>
	);
};

const steps = [
	{
		title: 'People & Teams',
		content: <Step1Form />
	},
	{
		title: 'Device & Groups',
		content: <Step2Form />
	}
];

export default steps;
