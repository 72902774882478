import { boxShadow } from '@iso/lib/helpers/style_utils';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const StyleSettings = styled.div`
	width: 100%;
	height: auto;
	background-color: #ffffff;
	margin-left: 10px;
	border-radius: 4px;
	${boxShadow('0px 0px 8px -1px rgba(0,0,0,0.18)')};

	.containerHeader {
		display: flex;
		width: 100%;
		padding: 15px;
		border-bottom: 1px solid ${palette('border', 0)};

		.containerProfile {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;

			.containerImage {
				margin-right: 20px;
			}

			.containerSummary {
				display: flex;
			}

			.contentContainer {
				margin: 0px !important;
				border: none !important;
				box-shadow: none !important;
				border-radius: 50px;
				overflow: hidden;

				.editIconContainer {
					font-size: 8px;
					text-align: center;
				}
			}

			.editButtonProfile {
				display: flex;
				align-items: center;
				box-shadow:
					rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
					rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
				padding: 15px 20px;
				svg {
					margin-right: 5px;
					width: 12px;
					height: 12px;
				}
				span {
					margin-top: 2px;
					font-weight: 600 !important;
					color: ${palette('settings', 3)};
					font-style: normal;
					line-height: 21.551px;
					letter-spacing: -0.35px;
					font-size: 11.5px;
				}
			}
		}

		.containerLabel {
			h3 {
				font-size: 20px !important;
				line-height: 1.5;
				margin-bottom: 3px;
			}
			span {
				display: inline-block;
				width: 100%;
				white-space: normal;
				color: #7e8089 !important;
			}
		}

		.containerButtons {
			display: flex;
			align-items: start;
			justify-content: flex-end;
			button {
				padding: 0 16px !important;
			}
		}
	}

	.contentSetting {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		align-items: start;
		padding: 15px;

		.containerLabel {
			h3 {
				margin: 0px 0px 5px 0px;
				text-transform: capitalize;
			}
			span {
				display: inline-block;
				width: 80%;
				white-space: normal;
				color: #7e8089 !important;
			}
		}

		.containerField {
			display: flex;
			width: 100%;
			.containerSwitch {
				display: flex;
				width: 100%;
				justify-content: flex-end;
			}
			.fieldNumber,
			.fieldDate,
			.ant-select,
			.ant-upload,
			.ant-upload button,
			.fieldButton,
			.fieldString,
			.ant-segmented {
				width: 100% !important;
			}

			.ant-input,
			.ant-picker-input input,
			.ant-picker-input input,
			.ant-upload button,
			.fieldNumber {
				font-weight: 500 !important;
				font-size: 14px !important;
				color: ${palette('settings', 3)} !important;
				::placeholder {
					font-weight: 300;
				}
			}

			.ant-select-selector .ant-select-selection-item {
				color: ${palette('settings', 3)};
				border-color: transparent;
			}

			.ant-segmented {
				background-color: transparent;

				.ant-segmented-item {
					border: 1px solid #e9e9e9;
					font-weight: 500;
					padding: 2px;
					font-size: 12px;
					width: 100%;
				}
				.ant-segmented-item:first-child {
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				.ant-segmented-item:last-child {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}

				.ant-segmented-item-selected {
					border: 1px solid ${palette('primary', 0)};
					color: ${palette('primary', 0)};
				}
			}
		}
	}
`;
export { StyleSettings };
