import styled from 'styled-components';
import { palette } from 'styled-theme';

const DeviceCardContainerWrapper = styled.div`
	.cardContainer {
		padding: 10px;
		column-width: 200px;
		column-gap: 4px;
		width: 100%;
		display: flow-root;
	}
	.headerStyle {
		font-size: 20px;
		font-weight: 600;
		opacity: 0.8;
		background-color: ${palette('secondary', 7)};
		border-radius: 6px;
		padding: 5px;
	}
`;

export default DeviceCardContainerWrapper;
