import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import { LeafletMap } from '@Z/components/atoms';
import { DeviceLocationDeleteModal } from '@Z/components/molecules';
import { Col, Tooltip } from 'antd';
import Button from '@iso/components/uielements/button';
import { DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { DownOutlined } from '@ant-design/icons';
import { LeafletMapStyleWrapper, MapActionWrapper } from './MapStyleWrapper.styles';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

/**
 * @description
 * @author Kishan Virani
 * @date 24/05/2022
 * @param {*} { deviceID }
 * @param {*} props, Columns props can be passed here.
 * @return {*}
 */
const DeviceLocationMapComponent = (props) => {
	const { deviceID } = props;
	const { devicesData } = useSelector((state) => state.devices);
	const { floorsData } = useSelector((state) => state.floors);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const [device, setDevice] = React.useState({});
	const [floors, setFloors] = React.useState([]);
	const [selectedFloor, setSelectedFloor] = useState({});
	const [selectedLocation, setSelectedLocation] = useState({});
	const [deleteLocationVisible, setDeleteLocationVisible] = useState(false);

	const [mapUrl, setMapUrl] = useState('');
	const [markersData, setMarkersData] = useState([]);

	const isPermittedUpdateDevice = useManagePermittedRoles('updateDevice', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	const floorsListing = () => {
		return (
			<DropdownMenu>
				{device.location.map((location, key) => {
					const floor = floors.find((floor) => floor.floorID === location.floorID) || {};
					return (
						<MenuItem
							onClick={() => {
								setSelectedLocation(location);
								setSelectedFloor(floor);
							}}
							key={key}
						>
							{floor.name ? floor.name : 'Floor Not Found'}
						</MenuItem>
					);
				})}
			</DropdownMenu>
		);
	};

	// Get Device information from the store.
	useEffect(() => {
		if (devicesData.length > 0) {
			setDevice(devicesData.find((device) => device.uID === deviceID) || {});
		}
	}, [devicesData, deviceID]);

	// initially set floors information from the store.
	// Get Map Path / URL
	useEffect(() => {
		setFloors(floorsData);

		// setting default selected floor
		if (device.location && device.location.length > 0) {
			const deviceLocation = device.location[0];
			const floor = floorsData.find((floor) => floor.floorID === deviceLocation.floorID);
			if (floor) {
				setSelectedLocation(deviceLocation);
				setSelectedFloor(floor);
				getDownloadURL(floor.url).then((url) => setMapUrl(url));
				setMarkersData([{ x: deviceLocation.xAxis, y: deviceLocation.yAxis, markerIcon: device.markerIcon }]);
			}
		}
	}, [floorsData, device]);

	// changing the map based on the selected floor
	useEffect(() => {
		if (device.location && device.location.length > 0) {
			const selectedLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (selectedLocation)
				setMarkersData([
					{ x: selectedLocation.xAxis, y: selectedLocation.yAxis, markerIcon: device.markerIcon }
				]);
			if (selectedFloor.url) getDownloadURL(selectedFloor.url).then((url) => setMapUrl(url));
		}
	}, [selectedFloor, device]);

	return device.location && device.location.length > 0 ? (
		<Col {...props}>
			<LeafletMapStyleWrapper>
				<LeafletMap
					url={mapUrl}
					markerList={markersData}
					mapHeight={460}
					mapWidth={800}
					scrollWheelZoom={true}
					iconType={device.categoryName}
				>
					<MapActionWrapper>
						<Dropdown overlay={floorsListing()} trigger={['click']}>
							<Button size='medium' className='switchBtn'>
								{selectedFloor.name || 'Select Floor'}
								<DownOutlined />
							</Button>
						</Dropdown>

						{isPermittedUpdateDevice &&
							selectedLocation &&
							!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && (
								<DeviceLocationDeleteModal
									data={{
										locationID: selectedLocation.locationID,
										deviceID
									}}
									setDeleteLocationVisible={setDeleteLocationVisible}
									deleteLocationVisible={deleteLocationVisible}
								>
									<Tooltip title='Delete This Location'>
										<Button
											onClick={() => {
												setDeleteLocationVisible(true);
											}}
											icon={<DeleteOutlined />}
											size='medium'
										/>
									</Tooltip>
								</DeviceLocationDeleteModal>
							)}
					</MapActionWrapper>
				</LeafletMap>
			</LeafletMapStyleWrapper>
		</Col>
	) : null;
};
export default DeviceLocationMapComponent;
