import styled from 'styled-components';
import { palette } from 'styled-theme';
import { textEllipsis } from '@iso/lib/helpers/style_utils';

// Styled components
const ProfileCardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 5px;
	border-radius: 8px;
	background-color: ${(props) => (props.inTableUse ? 'none' : '#fff')};
	gap: 5px;

	${(props) =>
		props.highlightOnHover &&
		`
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s;
    }
  `}
`;

const ProfileImageContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 50%;
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 0;
`;

const UserName = styled.div`
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	color: ${palette('text', 0)};
	${textEllipsis()}
`;

const UserEmail = styled.div`
	font-size: 13px;
	color: ${palette('text', 1)};
	line-height: 20px;
	font-weight: 400;
	${textEllipsis()}
`;
const ActionContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

export { UserInfo, UserName, UserEmail, ProfileImageContainer, ProfileCardContainer, ActionContainer };
