import React from 'react';
import { Row, Col } from 'antd';
import { Loading, PageHeader } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';

const InventoryLocksHeader = () => {
	const [loadings] = React.useState(false); //ToDO: change back to true -- Testing until api is done & add setLoading

	// const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);

	const { rowStyle, colStyle, loadingStyle } = basicStyle;

	//ToDO:get fresh data eg. Counter = Number of G and D

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<div>
			<PageHeader title='Inventory Locks'>{/* // add Device/Gateway ETC */}</PageHeader>
		</div>
	);
};

export default InventoryLocksHeader;
