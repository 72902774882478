export const accessControlPermissionType = {
	1: {
		title: 'Permanent Permission',
		shortTitle: 'Permanent',
		description: 'TTLock Default Permanent Permission',
		id: 1
	},
	2: {
		title: 'Guest Permission',
		shortTitle: 'Guest',
		description: 'Guest Temporary Permission',
		id: 2
	},
	3: {
		title: 'Locker Permission',
		shortTitle: 'Locker',
		description: 'Locker Booking Permission',
		id: 3
	}
};
