const siteConfig = {
	siteName: 'Zezamii',
	footerText: `Zezamii @ ${new Date().getFullYear()} Created by Zezamii Pty Ltd.`,
	enableAnimatedRoute: false,
	mainServerBaseDomain: '', // base url for the main Backend server.
	applicationServerBaseDomain: 'api.zezamii.com', //WIP - DO NOT INCLUDE HTTP:// OR HTTPS:// IN THE URL. e.g.value "dte.com "
	google: {
		analyticsKey: 'UA-xxxxxxxxx-1'
	},
	dashboard: '/dashboard'
};
export default siteConfig;
