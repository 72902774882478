import { Modal } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import Input from '@iso/components/uielements/input';
import Select, { SelectOption as Option } from '@iso/components/uielements/select';
import Tooltip from '@iso/components/uielements/tooltip';
import { entityType } from '@iso/config/entityType';
import { getPermissionByEntityType } from '@iso/lib/helpers/getPrimary';
import SuperFetch from '@iso/lib/helpers/superFetch';
import { removeEmptyOrNull } from '@iso/lib/helpers/utility';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import floorsAction from '@iso/redux/floors/actions';
import organisationAction from '@iso/redux/organisation/actions';
import peopleAction from '@iso/redux/people/actions';
import siteAction from '@iso/redux/sites/actions';
import teamsAction from '@iso/redux/teams/actions';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { colStyle } = basicStyle;

const { loadTeams } = teamsAction;
const { loadFloors } = floorsAction;
const { loadOrganisation } = organisationAction;
const { loadSites } = siteAction;
const { loadPeople } = peopleAction;

const AddEntityAddressModal = ({ children, visible, setVisible, data = {}, type, edit, setEdit }) => {
	const { addressTypes } = useSelector((state) => state.types);
	const { permissionName, rules } = getPermissionByEntityType(type, data);

	// Permission to add or update contact from entities
	const isPermittedUpdate = useManagePermittedRoles(permissionName, rules);

	const [availableAddressType, setAvailableAddressType] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		if (edit) {
			const nameAddress = addressTypes?.find((address) => address.addressTypeID === data.addressTypeID).name;
			setAvailableAddressType([{ addressTypeID: data?.addressTypeID, name: nameAddress }]);
			form.setFieldsValue({
				addressLine1: data?.addressLine1,
				addressLine2: data?.addressLine2,
				addressTypeID: data?.addressTypeID,
				country: data?.country,
				postCode: data?.postCode,
				state: data?.state,
				suburb: data?.suburb
			});
		} else {
			form.resetFields();
		}
	}, [data, form, edit, visible, addressTypes]);

	useEffect(() => {
		if (!edit && data.address) {
			const addressTypeIdsToRemove = data.address.map((info) => info.addressTypeID);
			const filteredAddressTypes = addressTypes.filter(
				(type) => !addressTypeIdsToRemove.includes(type.addressTypeID)
			);
			setAvailableAddressType(filteredAddressTypes);
			form.setFieldsValue({
				addressTypeID: filteredAddressTypes[0]?.addressTypeID
			});
		}
	}, [data, form, edit, addressTypes, visible]);

	const handleCancel = () => {
		form.resetFields();
		setVisible(false);
		setLoading(false);
		setEdit(false);
	};

	const dispatchActions = () => {
		switch (type) {
			case entityType.WORKSPACE:
				dispatch(loadOrganisation());
				break;
			case entityType.SITE:
				dispatch(loadSites());
				break;
			case entityType.FLOOR:
				dispatch(loadSites());
				dispatch(loadFloors());
				break;
			case entityType.TEAM:
				dispatch(loadTeams());
				break;
			case entityType.PERSON:
				dispatch(loadPeople());
				break;
			default:
				break;
		}
	};

	const onFinish = async () => {
		const formData = removeEmptyOrNull(form.getFieldsValue());

		if (!edit) {
			switch (type) {
				case entityType.WORKSPACE:
					formData.entityID = data.orgID;
					formData.entityTypeID = entityType.WORKSPACE;
					break;
				case entityType.SITE:
					formData.entityID = data.siteID;
					formData.entityTypeID = entityType.SITE;
					break;
				case entityType.FLOOR:
					formData.entityID = data.floorID;
					formData.entityTypeID = entityType.FLOOR;
					break;
				case entityType.TEAM:
					formData.entityID = data.teamID;
					formData.entityTypeID = entityType.TEAM;
					break;
				case entityType.PERSON:
					formData.entityID = data.personID;
					formData.entityTypeID = entityType.PERSON;
					break;
				default:
					break;
			}
		}

		try {
			setLoading(true);

			if (edit) {
				delete formData.addressTypeID;
				const res = await SuperFetch.patch(`/addresses/${data.addressID}`, formData);

				if (res.statusCode === 200) {
					dispatchActions();
					handleCancel();
					notification('success', 'Address Update Successful');
				} else if (res.error.statusCode === 400) {
					handleCancel();
					notification('error', 'Please fill out all required information with proper type.');
				}
			} else {
				const res = await SuperFetch.post(`/addresses`, formData);
				if (res.statusCode === 201) {
					dispatchActions();
					handleCancel();
					notification('success', 'Address Added Successful.');
				} else if (res.error.statusCode === 400) {
					handleCancel();
					notification('error', 'Please fill out all required information with proper type.');
				}
			}
		} catch (err) {
			setLoading(false);
			notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		}
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Tooltip
					title={!isPermittedUpdate ? "You don't have permission" : edit ? 'Edit Address' : 'Add New Address'}
					placement='topLeft'
				>
					<>
						<Button size='small' onClick={() => setVisible(true)} disabled={!isPermittedUpdate}>
							{edit ? 'Edit Address' : 'Add Address'}
						</Button>
					</>
				</Tooltip>
			)}
			<Modal
				title={edit ? 'Edit Address' : 'Add New Address'}
				visible={visible}
				onCancel={handleCancel}
				width={720}
				maskClosable={false}
				destroyOnClose={false}
				footer={[
					<Button key='reset' onClick={() => form.resetFields(['contactInformation'])}>
						Reset
					</Button>,
					<Button form={form} loading={loading} key='submit' htmlType='submit' type='primary'>
						{edit ? 'Edit Address' : 'Add Address'}
					</Button>
				]}
			>
				<Form
					id={form}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					onFinish={onFinish}
					autoComplete='off'
					form={form}
				>
					<Form.Item
						label='Address Type'
						name={'addressTypeID'}
						rules={fieldsValidator.Required}
						style={colStyle}
						wrapperCol={{ span: 6 }}
					>
						<Select
							showSearch
							optionFilterProp='children'
							disabled={edit}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{availableAddressType.length > 0 &&
								availableAddressType.map((type) => (
									<Option key={type.addressTypeID} value={type.addressTypeID}>
										{type.name}
									</Option>
								))}
						</Select>
					</Form.Item>
					<Form.Item
						label='Address Line 1'
						name={'addressLine1'}
						style={colStyle}
						rules={fieldsValidator.AddressLine1}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label='Address Line 2'
						name={'addressLine2'}
						style={colStyle}
						rules={fieldsValidator.AddressLine2}
					>
						<Input />
					</Form.Item>
					<Row>
						<Col span={12}>
							<Form.Item
								label='Suburb'
								name={'suburb'}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
								style={colStyle}
								rules={fieldsValidator.Suburb}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label='State'
								name={'state'}
								labelCol={{ span: 10 }}
								wrapperCol={{ span: 12 }}
								rules={fieldsValidator.State}
								style={colStyle}
							>
								<Select
									showSearch
									optionFilterProp='children'
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
								>
									<Option value='NSW'>NSW</Option>
									<Option value='QLD'>QLD</Option>
									<Option value='SA'>SA</Option>
									<Option value='TAS'>TAS</Option>
									<Option value='VIC'>VIC</Option>
									<Option value='WA'>WA</Option>
									<Option value='ACT'>ACT</Option>
									<Option value='NT'>NT</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label='Post Code'
								name={'postCode'}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
								style={colStyle}
								rules={fieldsValidator.PostCode}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						label='Country'
						name={'country'}
						rules={fieldsValidator.Required}
						wrapperCol={{ span: 6 }}
						style={colStyle}
					>
						<Select
							showSearch
							optionFilterProp='children'
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							<Option value='Australia'>Australia</Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddEntityAddressModal;
