import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const PeopleSummaryWrapper = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	@media only screen and (max-width: 992px) {
		border-right: 0px;
	}

	.deleteButton {
		&:hover {
			border-color: ${palette('error', 2)};
			color: ${palette('error', 2)};
		}

		@media only screen and (max-width: 1000px) {
			margin-right: 0px;
			display: none;
		}
	}
	.header {
		display: flex;
		gap: 20px;
		justify-content: space-between;
		align-items: flex-start;
	}

	.profileImage {
		display: flex;
		flex-direction: column;
	}

	.infoContainer {
		display: grid;
		grid-auto-flow: row;
	}

	.personRole {
		font-weight: 700;
		color: ${palette('secondary', 5)};
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.personHeaderCol {
		display: grid;
		grid-auto-flow: column;
		gap: 1em;
		grid-auto-columns: auto 1fr;
		justify-content: space-between;
		width: 100%;
		.personTitle {
			font-weight: 700;
			color: ${palette('secondary', 5)};
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.idLabel {
			font-size: 10px;
			font-weight: 500;
			opacity: 1;
			color: ${palette('secondary', 5)};
			padding-left: 10;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.fBID {
			font-size: 10px;
			font-weight: 500;
			opacity: 0.7;
			color: ${palette('secondary', 5)};
			padding-left: 10;
			display: flex;
		}

		.actionsContainer {
			display: flex;
			justify-content: end;
			gap: 10px;

			.actionButton {
				.ant-btn {
					margin-right: 10px;

					&:last-child {
						margin-right: 0px;
					}
				}
				.switchPersonButton {
					margin-right: 10px;

					@media only screen and (max-width: 1626px) {
						margin-right: 0px;
						display: none;
					}
				}
				.deletedTag {
					height: 36px;
					line-height: 36px;
					font-size: 12px;
					font-weight: 500;
					${borderRadius('4px')};
					&:last-child {
						margin-right: 0px;
					}
				}
			}
		}
	}
	.infoLabel {
		font-size: 15px;
		font-weight: 500;
		opacity: 0.7;
		color: ${palette('primary', 8)};
		margin-right: 5px;
	}
	.infoDiv {
		display: flex;
		flex-wrap: wrap;
		column-gap: 10px;
		margin-top: 5px;
	}

	.info {
		display: flex;
	}

	.infoActivityLabel {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.7;
	}

	.infoTitle {
		font-size: 30px;
		font-weight: 700;
		opacity: 0.8;
		color: black;
		margin-left: 20px;
		vertical-align: middle;
	}
	.infoProp {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 10px;
		color: ${palette('secondary', 3)};
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.infoActivityProp {
		font-size: 12px;
		font-weight: 400;
		opacity: 0.7;
		display: inline-block;
	}

	.editButton {
		display: flex;
		justify-content: flex-end;
	}
	.editPerson {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
`;

export default PeopleSummaryWrapper;
