import React from 'react';
import styled from 'styled-components';
import Row from '@iso/ui/Antd/Grid/Row';
import Col from '@iso/ui/Antd/Grid/Col';
import Card from '@iso/components/uielements/card';
import SiteCardWrapper from './siteCardWrapper.styles';
import superFetch from '@iso/lib/helpers/superFetch';
import Input from '@iso/components/uielements/input';
import sitesAction from '@iso/redux/sites/actions';
import Form from '@iso/components/uielements/form';
import Button from '@iso/components/uielements/button';
import { MoreOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Divider, ProfileImageComponent } from '@Z/components/atoms';
import { notification } from '@iso/components';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { getPrimaryAddress, getPrimaryContact } from '@iso/lib/helpers/getPrimary';
import withHyperLink from '@iso/lib/hoc/withHyperLink';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { LabelWrapper } from '@Z/components/atoms';
import fieldsValidator from '@iso/lib/helpers/validators/fieldsValidator';
import { getInitials } from '@iso/lib/helpers/getInitials';

const SiteCard = ({ item }) => {
	const { loadSites } = sitesAction;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [editVisible, setEditVisible] = React.useState(false);
	const [updateSiteLoading, setUpdateSiteLoading] = React.useState(false);
	const [buttonDisable, setButtonDisable] = React.useState(true);
	const [updateSiteObj, setUpdateSiteObj] = React.useState({});
	const [initialValues, setInitialValues] = React.useState({});
	const primaryAddress = getPrimaryAddress(item.address);
	const primaryContact = getPrimaryContact(item.contact);
	const displayNames = useGetPersonDisplayName(true);

	const floorsData = useSelector((state) => state.floors.floorsData);
	const peopleData = useSelector((state) => state.people.peopleData);

	// HOC
	const TitleWithLink = withHyperLink(() => (
		<LabelWrapper
			typeLabel={'primary'}
			text={item.name}
			color={'secondary'}
			tonality={0}
			fontSize={18}
			fontWeight={600}
		/>
	));
	const WrapperWithLink = withHyperLink((i) => {
		return <p>{i.children}</p>;
	});

	const handleCancel = () => {
		setButtonDisable(true);
		setInitialValues({});
		setEditVisible(false);

		form.resetFields();
	};

	const checkFloors = floorsData.filter((floor) => floor.siteID === item.siteID && !floor.deletedDate).length;

	const checkPeople = peopleData.filter((person) => person.siteID === item.siteID && !person.deletedDate).length;

	const onFinish = () => {
		let newSiteInfo = {};
		const formData = form.getFieldsValue(true);

		if (formData.name !== updateSiteObj.name && formData.name !== '') {
			newSiteInfo['name'] = formData.name;
		}

		if (newSiteInfo.name) {
			superFetch
				.patch('/sites/' + updateSiteObj.siteID, newSiteInfo)
				.then((res) => {
					if (res) {
						if (res.statusCode === 200) {
							notification('success', 'Sites Information Updated.');
						}
						dispatch(loadSites());
					}
					return res;
				})
				.catch((err) => {
					notification('error', 'Update Failed, Please try again.');
				});
		}
		handleCancel();
		setUpdateSiteLoading(false);
	};

	const updateChange = () => {
		const formData = form.getFieldsValue(true);
		if (updateSiteObj.name === formData.name || formData.name === '') {
			return true;
		} else {
			return false;
		}
	};

	// Todo : Add functionality to delete a site #408
	// const isPermittedDeleteSite = useManagePermittedRoles('deleteSite', { orgID: [ orgID ] });
	const isPermittedEditSite = useManagePermittedRoles('updateSite', {
		orgID: [item.orgID],
		siteID: [item.siteID]
	});

	const actionMenu = (item) => (
		<DropdownMenu>
			<WrapperWithLink type='site' dataID={item.siteID}>
				<MenuItem key='view'>View Site</MenuItem>
			</WrapperWithLink>
			{isPermittedEditSite && (
				<MenuItem
					onClick={() => {
						setUpdateSiteObj(item);
						setEditVisible(true);
						setInitialValues(item);
					}}
					key='edit'
				>
					Edit Site
				</MenuItem>
			)}
			{/* {isPermittedDeleteSite && (
				// Todo : Add functionality to delete a site #408
				<MenuItem onClick={() => {}} key='delete'>
					Delete Site
				</MenuItem>
			)} */}
		</DropdownMenu>
	);

	return (
		<Col lg={12} md={24} sm={24} xs={24}>
			<SiteCardWrapper>
				<Card
					className='hoverCard'
					size='large'
					style={{ height: '100%' }}
					title={<TitleWithLink type='site' dataID={item.siteID} />}
					bordered
					extra={
						<Dropdown overlay={actionMenu(item)} trigger={['click']}>
							<MoreOutlinedIcon />
						</Dropdown>
					}
				>
					<Row className='statContainer'>
						{primaryContact.contactInformation ? (
							<div className='contact-container'>
								<p className='statTitle'>{primaryContact.contactType}</p>
								<p>{primaryContact.contactInformation}</p>
							</div>
						) : null}
						{primaryAddress ? (
							<div className='address-container'>
								{' '}
								<p className='statTitle'>{primaryAddress.addressType}</p>
								<p>{primaryAddress.addressLine1}</p>
								{primaryAddress.addressLine2 !== 'null' && <p>{primaryAddress.addressLine2}</p>}
								<p>
									{primaryAddress.suburb} {primaryAddress.state} {primaryAddress.postCode}
								</p>
							</div>
						) : null}
						<Divider className='dividerStyle' type='vertical'></Divider>

						<div className='statRow'>
							<p className='statTitle'>Floors</p>
							<p className='statValue'>{checkFloors}</p>
						</div>
						<div className='statRow'>
							<p className='statTitle'>People</p>
							<p className='statValue'>{checkPeople}</p>
						</div>
						{item.adminPerson.length > 0 ? (
							<div style={{ display: 'flex' }}>
								<Avatar.Group
									maxCount={2}
									size='large'
									style={{
										bottom: 0,
										right: 0
									}}
								>
									{item.adminPerson &&
										item.adminPerson.map((admin) => (
											<Tooltip
												key={admin.personID}
												title={
													<WrapperWithLink type='person' dataID={admin.personID}>
														<p>{`${admin.displayName} - ${admin.adminType}`}</p>
													</WrapperWithLink>
												}
												placement='top'
											>
												{admin.photoUrl === 'n/a' || !admin.photoUrl ? (
													<WrapperWithLink type='person' dataID={admin.personID}>
														<Avatar key={admin.personID}>
															{getInitials(displayNames[admin.personID]?.displayName)}
														</Avatar>
													</WrapperWithLink>
												) : (
													<WrapperWithLink type='person' dataID={admin.personID}>
														<ProfileImageComponent
															entityObject={admin}
															size={40}
															entityName={admin.displayName}
															shape='circle'
															edit={false}
														></ProfileImageComponent>
													</WrapperWithLink>
												)}
											</Tooltip>
										))}
								</Avatar.Group>
							</div>
						) : null}
					</Row>
				</Card>
				<Modal
					title='Edit Site'
					visible={editVisible}
					onCancel={handleCancel}
					destroyOnClose={true}
					footer={[
						<Button key='back' onClick={handleCancel}>
							Cancel
						</Button>,
						<Button
							form={form}
							key='submit'
							htmlType='submit'
							type='primary'
							loading={updateSiteLoading}
							disabled={buttonDisable}
						>
							Update
						</Button>
					]}
				>
					<Form
						form={form}
						id={form}
						labelCol={{
							lg: { span: 3, offset: 2 },
							sm: { span: 3, offset: 0 }
						}}
						wrapperCol={{
							lg: { span: 17 },
							sm: { span: 16 },
							xs: { span: 10 }
						}}
						onFinish={onFinish}
						initialValues={initialValues}
						autoComplete='off'
						onValuesChange={() => setButtonDisable(updateChange())}
					>
						<Form.Item label='Name: ' name='name' rules={fieldsValidator.Name}>
							<Input />
						</Form.Item>
					</Form>
				</Modal>
			</SiteCardWrapper>
		</Col>
	);
};

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export default SiteCard;
