import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { DeviceDeleteModel, DeviceEditModal, DeviceMapEditModal, RemoteActionsButton } from '@Z/components/molecules';
import { ExportDeviceCSVButton } from '@Z/components/atoms';
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Button from '@iso/components/uielements/button';
import { DownOutlined } from '@ant-design/icons';
import { SpaceWrapper } from './DCA.styles';

const RenderDeviceActions = ({ children, device, onIndividualDeviceModal }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const [locationEditVisible, setLocationEditVisible] = useState(false);
	const [remoteUnlockVisible, setRemoteUnlockVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);

	// return null if Device is not defined.
	if (!device) return null;

	// Basic Action menu Render.
	const actionMenu = (actionsList) => (
		<DropdownMenu>
			{/* filter removed the unauthorised values */}
			{actionsList
				.filter((action) => action)
				.map((action, index) => (
					<MenuItem key={index} style={{ fontSize: '12px' }}>
						{action}
					</MenuItem>
				))}
		</DropdownMenu>
	);

	// Add any additional actions here. with the permission controller.
	const allActionsMenu = [
		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && (
			<DeviceEditModal
				editVisible={updateVisible}
				setEditVisible={setUpdateVisible}
				data={{
					orgID: device.orgID,
					deviceID: device.uID,
					categoryID: device.categoryID
				}}
			/>
		),
		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && (
			<DeviceMapEditModal
				editVisible={locationEditVisible}
				setEditVisible={setLocationEditVisible}
				data={{
					deviceID: device.uID
				}}
			/>
		),
		<ExportDeviceCSVButton device={device} />,
		!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && (
			<DeviceDeleteModel
				deleteVisible={deleteVisible}
				setDeleteVisible={setDeleteVisible}
				data={{
					orgID: device.orgID,
					deviceID: device.uID,
					name: device.name
				}}
			/>
		)
	];

	if (constants.SMART_CATEGORIES.includes(device?.defaultCategoryID) && onIndividualDeviceModal) {
		return (
			<Fragment>
				<Dropdown overlay={() => actionMenu(allActionsMenu)} trigger={['click']}>
					<Button>
						Actions <DownOutlined />
					</Button>
				</Dropdown>
			</Fragment>
		);
	} else if (constants.ACTIONABLE_SMART_LOCKS.includes(device?.defaultCategoryID)) {
		return [
			<RemoteActionsButton
				data={{
					orgID: device.orgID,
					deviceID: device.uID,
					name: device.name,
					siteID: device.siteID
				}}
				visible={remoteUnlockVisible}
				setVisible={setRemoteUnlockVisible}
				onIndividualDeviceModal={onIndividualDeviceModal}
			/>
		];
	} else {
		return (
			<Dropdown overlay={() => actionMenu(allActionsMenu)} trigger={['click']}>
				{children ? (
					children
				) : onIndividualDeviceModal ? (
					<Button>
						Actions <DownOutlined />
					</Button>
				) : (
					<SpaceWrapper>
						Actions <i className='material-icons-outlined'>expand_more</i>
					</SpaceWrapper>
				)}
			</Dropdown>
		);
	}
};

export default RenderDeviceActions;
