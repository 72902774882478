const actions = {
	LOAD_REMOTE_CONFIG_DATA: 'LOAD_REMOTE_CONFIG_DATA',
	LOAD_REMOTE_CONFIG_SUCCESS: 'LOAD_REMOTE_CONFIG_SUCCESS',
	LOAD_REMOTE_CONFIG_ERROR: 'LOAD_REMOTE_CONFIG_ERROR',

	loadRemoteConfig: () => ({
		type: actions.LOAD_REMOTE_CONFIG_DATA
	})
};
export default actions;
