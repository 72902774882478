import styled from 'styled-components';
import Select from '@iso/components/uielements/select';
import WithDirection from '@iso/lib/helpers/rtl';

const AddCategoryFormWrapper = styled.div`
	margin: 0 50px;
	&.ant-space-item {
		width: 100%;
	}
	.title {
		margin-bottom: 18px;

		.ant-divider-inner-text {
			font-weight: 500;
			padding-left: 0;
		}
		&.ant-divider-horizontal.ant-divider-with-text-left::before {
			width: 0;
		}
		&.ant-divider-horizontal.ant-divider-with-text-left::after {
			width: 100%;
		}
	}
`;

const MultiSelect = WithDirection(styled(Select)`
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: auto;
	}
`);

export { MultiSelect };

export default AddCategoryFormWrapper;
