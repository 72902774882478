import { Modal } from '@Z/components/atoms';
import Icon from '@ant-design/icons';
import { ReactComponent as lockIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import { notification } from '@iso/components';
import Button from '@iso/components/uielements/button';
import Form from '@iso/components/uielements/form';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import superFetch from '@iso/lib/helpers/superFetch';
import devicesAction from '@iso/redux/devices/actions';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect, { SelectAssetsWrapper, TagWrapper, TeamGrantWrapper } from './ManagePermissionDeviceModal.styles';
import { useGetPersonDisplayName } from '@iso/lib/hooks/readableInfoHooks';
const { loadDevices } = devicesAction;

const ManagePermissionDeviceModal = ({ entityID, entityType, children, permissionID }) => {
	const [visible, setVisible] = useState(false);
	const [grantButtonLoading, setGrantButtonLoading] = useState(false);
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
	const [initialTeamAccessData, setInitialTeamAccessData] = useState([]);
	const displayNames = useGetPersonDisplayName(true);

	// Global States
	const { peopleData } = useSelector((state) => state.people);
	const { teamsData } = useSelector((state) => state.teams);
	const {
		remoteConfig: { constants, accessControlPermissionType }
	} = useSelector((state) => state.remoteConfig);

	const dispatch = useDispatch();
	const [form] = Form.useForm();

	useEffect(() => {
		const getData = async () => {
			const tAccessData = await getAccessData({ deviceID: entityID });

			const uniqueAssetGroupIDs = new Set();
			const structuredAccessData = {
				people: tAccessData
					.map((person) => {
						if (person.entityTypeID === constants.ENTITY_TYPE_ID_PERSON) {
							return person.personID;
						}
						return null;
					})
					.filter((id) => {
						return id !== null && (uniqueAssetGroupIDs.has(id) ? false : uniqueAssetGroupIDs.add(id));
					}),
				teams: tAccessData
					.map((person) => {
						if (person.entityTypeID === constants.ENTITY_TYPE_ID_TEAM) {
							return person.teamID;
						}
						return null;
					})
					.filter((id) => {
						return id !== null && (uniqueAssetGroupIDs.has(id) ? false : uniqueAssetGroupIDs.add(id));
					})
			};

			setInitialTeamAccessData(structuredAccessData);
			form.setFieldsValue(structuredAccessData);
		};
		getData();
	}, [entityID, entityType, visible, form, constants]);

	const handleChange = () => {
		const formData = form.getFieldsValue(true);
		setSubmitButtonDisable(
			!(
				!isEqual(formData.teams.sort(), initialTeamAccessData.teams.sort()) ||
				!isEqual(formData.people.sort(), initialTeamAccessData.people.sort())
			)
		);
	};

	const handleCancel = () => {
		form.setFieldsValue(initialTeamAccessData);
		setSubmitButtonDisable(true);
		setVisible(false);
	};
	const onFinish = async () => {
		setGrantButtonLoading(true);
		const formData = form.getFieldsValue(true);
		let res = false;
		const addedPeople = formData['people'].filter((x) => !initialTeamAccessData.people.includes(x));
		const addedTeams = formData['teams'].filter((x) => !initialTeamAccessData.teams.includes(x));

		const removedPeople = initialTeamAccessData.people.filter((x) => !formData['people'].includes(x));
		const removedTeams = initialTeamAccessData.teams.filter((x) => !formData['teams'].includes(x));

		if (addedPeople.length > 0 || addedTeams.length > 0) {
			const permGrantBody = {
				deviceIDs: [entityID],
				permissionTypeID: accessControlPermissionType[1].id, // Permanent Permission,
				...(addedPeople.length > 0 ? { personIDs: addedPeople } : null),
				...(addedTeams.length > 0 ? { teamIDs: addedTeams } : null)
			};

			res = await superFetch.post(`/access/grantPermission`, permGrantBody);
		}

		if (removedPeople.length > 0 || removedTeams.length > 0) {
			const permRevokeBody = {
				deviceIDs: [entityID],
				permissionTypeID: accessControlPermissionType[1].id, // Permanent Permission
				...(removedPeople.length > 0 ? { personIDs: addedPeople } : null),
				...(removedTeams.length > 0 ? { teamIDs: removedTeams } : null)
			};

			res = await superFetch.post(`/access/revokePermission`, permRevokeBody);
		}

		if (res.statusCode === 200) {
			dispatch(loadDevices());
			setSubmitButtonDisable(true);
			setGrantButtonLoading(false);
			setVisible(false);
			notification('success', res.message);
		} else {
			setVisible(false);
			setSubmitButtonDisable(true);
			setGrantButtonLoading(false);
			notification('error', 'Error while revoking permission to people. Please try again.');
		}
	};

	const tagRender = (props) => {
		const { label, onClose } = props;
		const onPreventMouseDown = (event) => {
			onClose();
			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<TagWrapper
				icon={<Icon component={lockIcon} className='tagIcons' />}
				onMouseDown={onPreventMouseDown}
				closable={true}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</TagWrapper>
		);
	};

	// Get all the access control devices from the global state
	const getDropDown = (type) => {
		const options = [];
		if (type === 'people') {
			peopleData.forEach((person) => {
				options.push({
					label: displayNames[person.personID].displayName,
					value: person.personID,
					title: displayNames[person.personID].title
				});
			});
		} else if (type === 'teams') {
			teamsData.forEach((team) => {
				options.push({
					label: team.name,
					value: team.teamID
				});
			});
		}
		return options;
	};

	// ! need to work on this. Currently not decided, who grants permission to the team.
	const isPermittedManageMember = useManagePermittedRoles('manageMembers', {
		orgID: [teamsData[0]?.orgID],
		teamID: [teamsData.map((item) => item.teamID)]
	});

	return (
		<div>
			{isPermittedManageMember ? (
				children ? (
					children
				) : (
					<Button size={'small'} onClick={() => setVisible(true)}>
						Grant Permissiona
					</Button>
				)
			) : (
				'' // Member management is not permitted.
			)}
			<Modal
				title={`Update permissions to devices`}
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						disabled={submitButtonDisable}
						form={form}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={grantButtonLoading}
					>
						Update
					</Button>
				]}
			>
				<TeamGrantWrapper>
					<Form layout='vertical' form={form} id={form} onFinish={onFinish} autoComplete='off'>
						<Form.Item name='people' label={<span className='label'>People</span>}>
							<MultiSelect
								tagRender={tagRender}
								mode='multiple'
								style={{ width: '100%' }}
								onChange={(val) => handleChange(val, 'people')}
								getPopupContainer={() => document.getElementById('assetsSelectInput')}
								placeholder='Select People'
								filterOption={(input, option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								options={getDropDown('people')}
							/>
						</Form.Item>
						<SelectAssetsWrapper id='assetsSelectInput' />
						<Form.Item name='teams' label={<span className='label'>Teams</span>}>
							<MultiSelect
								tagRender={tagRender}
								mode='multiple'
								getPopupContainer={() => document.getElementById('assetsGroupSelectInput')}
								style={{ width: '100%' }}
								onChange={(val) => handleChange(val, 'teams')}
								placeholder='Select Teams'
								filterOption={(input, option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								options={getDropDown('teams')}
							/>
						</Form.Item>
						<SelectAssetsWrapper id='assetsGroupSelectInput' />
					</Form>
				</TeamGrantWrapper>
			</Modal>
		</div>
	);
};

export default ManagePermissionDeviceModal;
