import styled from 'styled-components';
import { palette } from 'styled-theme';

const OrganisationInfoWrapper = styled.div`
	.orgInfoContainer {
		display: flex;
		flex-direction: column;

		.primaryInfo {
			background-color: ${palette('secondary', 7)};
			border-radius: 10px;
			padding: 10px;
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;

			div {
				padding-bottom: 5px;
			}
			div > p {
				color: ${palette('text', 3)};
				font-size: 15px;
				font-weight: 500;
			}
			.nameRowContainer {
				p {
					font-size: 16px;
					font-weight: 600;
				}
			}
			.credRowContainer {
				display: flex;
				p {
					margin-right: 20px;
				}
			}
			.aliasRowContainer {
			}
			.infoLabel {
				font-size: 13px;
				font-weight: 500;
				padding-right: 4px;
			}
		}

		.infoRowContainer {
			margin-bottom: 15px;
			margin-left: 10px;

			.infoLabel {
				margin-right: 10px;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0.016em;
				color: ${palette('text', 3)};
				margin-bottom: 10px;
			}
			.infoValue {
				font-size: 15px;
			}
			p {
				margin-left: 10px;
			}
		}
	}
	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
`;

export default OrganisationInfoWrapper;
