/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 09-08-2022  10:43:20
 * @desc [description]
 */
import basicStyle, { smallTimeOptions } from '@iso/assets/styles/constants';
import Button from '@iso/components/uielements/button';
import IsoWidgetBox from '@iso/components/utility/WidgetBox';
import { Loading, SearchableTable, AddSite } from '@Z/components/atoms';
import ContentHolder from '@iso/components/utility/contentHolder';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Empty } from 'antd';
const { rowStyle, colStyle, loadingStyle } = basicStyle;

const SiteTable = () => {
	const history = useHistory();

	const [data, setData] = React.useState([]);
	const { sitesData, pending } = useSelector((state) => state.sites);

	React.useEffect(() => {
		if (!pending) {
			setData(sitesData);
		}
	}, [pending, sitesData]);

	const columns = [
		{
			title: 'Site Name',
			dataIndex: 'name',
			key: 'name',
			width: 65,
			ellipsis: true,
			render: (text, record) => (
				<span
					style={{
						cursor: 'pointer'
					}}
					onClick={() => {
						history.push({
							pathname: '/dashboard/sites/site',
							state: { siteID: record.siteID }
						});
					}}
				>
					{text}
				</span>
			)
		},
		{
			title: 'Added On',
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: 50,
			ellipsis: true,
			render: (text, record) => <p>{new Date(record.createdDate).toLocaleString('en-AU', smallTimeOptions)}</p>
		},
		{
			title: 'Primary Contact',
			dataIndex: 'contactInformation',
			key: 'contactInformation',
			width: 50,
			ellipsis: true,
			render: (text, record) => {
				const primaryContact =
					record.contact.find((contact) => contact.contactTypeID === 5) ||
					record.contact.find(() => true) ||
					[];
				return <p>{primaryContact.contactInformation}</p>;
			}
		},

		{
			title: 'View Site',
			key: 'Actions',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<Button
						size='small'
						style={{
							height: '28px',
							lineHeight: '28px',
							fontSize: '12px',
							fontWeight: '500'
						}}
						onClick={() => {
							history.push({
								pathname: '/dashboard/sites/site',
								state: { siteID: record.siteID }
							});
						}}
					>
						View
					</Button>
				</div>
			),
			ellipsis: true,
			width: 30,
			align: 'center'
		}
	];

	return pending ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : data && data.length > 0 ? (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<SearchableTable
				columns={columns}
				pageSize={7}
				dataSource={data}
				filterColumn={['name']}
				title='Sites'
				placeholder={'Team Name'}
			/>
		</IsoWidgetBox>
	) : (
		<IsoWidgetBox style={{ overflow: 'hidden' }}>
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Site Data</span>}>
				<AddSite />
			</Empty>
		</IsoWidgetBox>
	);
};

export default SiteTable;
