import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SiteNameWrapper from './styles';
const RenderSiteName = (props) => {
	const { dataID: id } = props;
	const [siteName, setSiteName] = useState('');
	const { sitesData } = useSelector((state) => state.sites);

	useMemo(() => {
		if (id) {
			const groupSite = sitesData.find((site) => site.siteID === id);
			setSiteName(groupSite?.name);
		}
	}, [sitesData, id]);

	return <SiteNameWrapper {...props}>{siteName ? siteName : 'Unassigned'}</SiteNameWrapper>;
};

export default RenderSiteName;
