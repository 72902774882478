import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntTab = (ComponentName) => styled(ComponentName)`
	&.ant-tabs {
		&:not(.ant-tabs-vertical) {
			> .ant-tabs-content-animated {
				display: ${(props) => (props['data-rtl'] === 'rtl' ? 'block' : 'flex')};
			}
		}

		.ant-tabs-nav {
			margin-bottom: 7px;
			.ant-tabs-tab {
				background-color: ${palette('secondary', 1)};
				margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 0 0 24px' : '0 0px 0 0')};

				.anticon:not(.anticon-close) {
					margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 0 0 8px' : '0 8px 0 0')};

					&.anticon-close {
						right: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '2px')};
						left: ${(props) => (props['data-rtl'] === 'rtl' ? '2px' : 'inherit')};
					}
				}
			}
		}

		.ant-tabs-tab-prev {
			left: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
			right: ${(props) => (props['data-rtl'] === 'rtl' ? '2px' : 'inherit')};
			transform: ${(props) => (props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)')};
		}

		.ant-tabs-tab-next {
			left: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
			right: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '2px')};
			transform: ${(props) => (props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)')};
		}

		&.ant-tabs-vertical {
			.ant-tabs-tab-prev,
			.ant-tabs-tab-next {
				transform: rotate(0);
			}
		}
	}
	.ant-tabs-nav:before {
		border-bottom-color: ${palette('primary', 0)} !important;
		z-index: 1;
	}
	.ant-tabs-nav-wrap {
		justify-content: center;
		align-items: center;
	}
	.ant-tabs-nav-list {
		width: 100%;

		.ant-tabs-tab {
			padding-left: 10px;
			padding-right: 10px;
			border-top-left-radius: 6px !important;
			border-top-right-radius: 6px !important;

			&:hover {
				border-color: ${palette('primary', 0)};
				color: ${palette('primary', 0)};
			}
			&:active {
				border-color: ${palette('primary', 0)};
				color: ${palette('primary', 0)};
			}
		}
		.ant-tabs-tab-active {
			border-color: ${palette('primary', 0)} !important;
			background-color: ${palette('whitescale', 0)} !important;
		}
		.ant-tabs-tab-btn {
			color: ${palette('primary', 0)} !important;

			&:active {
				color: ${palette('primary', 2)} !important;
			}
		}
		.ant-tabs-ink-bar {
			background-color: ${palette('primary', 0)};
			color: ${palette('primary', 0)};
		}
	}
`;

export default AntTab;
