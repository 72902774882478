import { Modal } from '@Z/components/atoms';
import { notification } from '@iso/components';
import { Button } from '@iso/components/uielements';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import groupAction from '@iso/redux/assetGroups/actions';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const { loadAssetGroups } = groupAction;

const DeleteAssetGroupModal = ({ visible, setVisible, children, data: group }) => {
	// Hooks
	const dispatch = useDispatch();

	// Local States
	const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);

	const handleCancel = () => {
		setVisible(false);
	};
	const handleDelete = () => {
		setDeleteGroupLoading(true);

		superFetch
			.delete('/deviceGroups/' + group.groupID)
			.then((res) => {
				if (res.statusCode === 200) {
					notification('success', `Successfully Deleted ${group.name}.`);
					dispatch(loadAssetGroups());
					handleCancel();
				} else {
					notification('error', 'There is an Error! We are mending the problem, try again soon!.');
				}
				setDeleteGroupLoading(false);
			})
			.catch((e) => {
				notification('error', 'Something went wrong, try again soon.');
				setDeleteGroupLoading(false);
				handleCancel();
			});
	};

	const isPermittedDeleteGroup = useManagePermittedRoles('deleteGroup', {
		orgID: [group.orgID],
		siteID: [group.siteID],
		floorID: []
	});

	return (
		<>
			<Tooltip
				title={!isPermittedDeleteGroup ? "You don't have permission" : 'Delete this Group from your Workspace'}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<Button
						disabled={!isPermittedDeleteGroup}
						onClick={() => {
							setVisible(true);
						}}
						danger
						size={'small'}
						style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						Delete
					</Button>
				)}
			</Tooltip>

			<Modal
				title='Delete a Group'
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				danger
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button onClick={() => handleDelete()} type='primary' loading={deleteGroupLoading} danger>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to delete{' '}
					<span style={{ fontWeight: 600, color: '#ff5b58' }}>'{group.name}' </span> from the organisation?
				</p>
			</Modal>
		</>
	);
};

export default DeleteAssetGroupModal;
