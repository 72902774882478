import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { useDispatch } from 'react-redux';
import { notification } from '@iso/components';
import devicesAction from '@iso/redux/devices/actions';

const DeviceLocationDeleteModal = ({ data, deleteLocationVisible, setDeleteLocationVisible, children }) => {
	const dispatch = useDispatch();
	const { locationID, deviceID } = data;

	const { loadDevices } = devicesAction;
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		setLoading(true);
		superFetch
			.delete(`/devices/${deviceID}/location/${locationID}`)
			.then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						notification('success', 'Device Location Deleted.');

						setDeleteLocationVisible(false);
					}

					dispatch(loadDevices());
				}
				setLoading(false);
				return res;
			})
			.catch(() => {
				setLoading(false);
				notification('error', 'Delete Failed, Please try again.');
			});
	};
	return (
		<div>
			{children}
			<Modal
				title={`Delete a Device Location`}
				danger
				visible={deleteLocationVisible}
				onCancel={() => setDeleteLocationVisible(false)}
				maskClosable={false}
				destroyOnClose={true}
				footer={[
					<Button key='cancel' onClick={() => setDeleteLocationVisible(false)}>
						Cancel
					</Button>,
					<Button loading={loading} key='delete' danger onClick={() => handleDelete()}>
						Delete
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>Are you sure you want to delete this device from this location?</p>
			</Modal>
		</div>
	);
};

export default DeviceLocationDeleteModal;
