import actions from './actions';

const initState = { sitesData: [], pending: true, searchable: true };

const sitesReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_SITES_SUCCESS:
			return { ...state, sitesData: action.sitesData, pending: false };
		case actions.LOAD_SITES_ERROR:
			return { ...state, sitesData: [], pending: false };
		default:
			return state;
	}
};
export default sitesReducer;
