import { entityType } from '@iso/config/entityType';
import primaryConfig from '@iso/config/primary.config';
/**
 * @param addressList a list of address
 * @param primaryAddressID the ID of primary address with a default ID of 5
 * @description use to find the primary address.
 * @return {Object} primary address ID and information, if given primary address ID is not existed, it will return the first address
 */
export const getPrimaryAddress = (addressList, primaryAddressID = primaryConfig.primaryAddressID) => {
	return (
		addressList.find((address) => address.addressTypeID === primaryAddressID) || addressList.find(() => true) || {}
	);
};

/**
 * @param addressList a list of contact
 * @param primaryAddressID the ID of primary contact with a default ID of 5
 * @description use to find the primary contact.
 * @return {Object} primary contact ID and information, if given primary contact ID is not existed, it will return the first contact
 */
export const getPrimaryContact = (contactList, primaryContactID = primaryConfig.primaryContactID) => {
	return (
		contactList.find((contact) => contact.contactTypeID === primaryContactID) || contactList.find(() => true) || {}
	);
};

/**
 * Returns the permission rules based on the entity type and data.
 *
 * @param {string} type - The entity type.
 * @param {object} data - The data object containing the entity IDs.
 * @returns {object} - The permission rules object.
 */
export const getPermissionByEntityType = (type, data) => {
	let rules = {};
	switch (type) {
		case entityType.WORKSPACE:
			rules.permissionName = 'orgInformationCUD';
			rules.rules = { orgID: [data.orgID] };
			break;
		case entityType.SITE:
			rules.permissionName = 'siteInformationCUD';
			rules.rules = { orgID: [data.orgID], siteID: [data.siteID] };
			break;
		case entityType.FLOOR:
			rules.permissionName = 'floorInformationCUD';
			rules.rules = {
				orgID: [data.orgID],
				siteID: [data.siteID],
				floorID: [data.floorID]
			};
			break;
		case entityType.TEAM:
			rules.permissionName = 'teamInformationCUD';
			rules.rules = {
				orgID: [data.orgID],
				siteID: [data.siteID],
				teamID: [data.teamID]
			};
			break;
		case entityType.PERSON:
			rules.permissionName = 'personInformationCUD';
			rules.rules = {};
			break;
		default:
			rules.permissionName = '';
			rules.rules = {};
	}

	return rules;
};
