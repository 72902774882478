export const entityType = {
	WORKSPACE: 1,
	SITE: 2,
	FLOOR: 3,
	PERSON: 4,
	TEAM: 5,
	ASSET_DEVICE: 6,
	ASSET_GROUPS: 7,
	BOOKINGS: 8,
	ACCESS_CONTROL: 9,
	SETTINGS: 10,
	PROFILE: 11,
	ASSET_CATEGORY: 12,
	ZEZAMII: 13
};
