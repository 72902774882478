import styled from 'styled-components';
import { palette } from 'styled-theme';

const FloorAdditionalInfoComponentWrapper = styled.div`
	.headerTitle {
		font-size: 16px;
		color: ${palette('secondary', 0)};
		font-weight: 550;
	}
`;
export default FloorAdditionalInfoComponentWrapper;
