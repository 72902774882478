import actions from './actions';

const initState = {
	deviceCategoriesData: [],
	currentCategory: {},
	activeCategoryID: null,
	pending: true,
	searchable: true
};

const deviceCategoriesReducer = (state = initState, action) => {
	switch (action.type) {
		case actions.LOAD_DEVICE_CATEGORIES_SUCCESS:
			return {
				...state,
				deviceCategoriesData: action.deviceCategoriesData,
				currentCategory: action.deviceCategoriesData[0],
				pending: false
			};
		case actions.LOAD_DEVICE_CATEGORIES_ERROR:
			return {
				...state,
				deviceCategoriesData: [],
				pending: false
			};
		case actions.ADD_CURRENT_CATEGORY:
			return {
				...state,
				currentCategory: action.payload
			};
		case actions.EDIT_CATEGORY:
			return {
				...state,
				deviceCategoriesData: state.deviceCategoriesData.map((category) => {
					if (category.categoryID === action.payload.categoryID) {
						return action.payload;
					}
					return category;
				}),
				currentCategory: action.payload
			};
		default:
			return state;
	}
};
export default deviceCategoriesReducer;
