import { notification } from 'antd';

const createNotification = (type, message, description, placement) => {
	notification[type]({
		message,
		description,
		placement
	});
};
export default createNotification;
