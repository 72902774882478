import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { notification } from '@iso/components';
import superFetch from '@iso/lib/helpers/superFetch';

const ExportDeviceCategoryCSVButton = ({ category }) => {
	const { devicesData } = useSelector((state) => state.devices);
	const floorData = useSelector((state) => state.floors.floorsData);
	const {
		remoteConfig: { defaultDeviceHeaders: defaultHeaders, defaultCategory }
	} = useSelector((state) => state.remoteConfig);

	const [downloadReady, setDownloadReady] = useState(false);
	const [floors, setFloors] = useState([]);
	const [headers, setHeaders] = useState(defaultHeaders);

	const [devices, setDevices] = useState([]);

	useEffect(() => {
		setFloors(floorData);
		setDevices(devicesData.filter((device) => device.categoryID === category.categoryID));
	}, [floorData, devicesData, category]);

	// Fix for the API response call.
	const csvInstance = useRef();
	useEffect(() => {
		if (downloadReady && csvInstance.current && csvInstance.current.link) {
			csvInstance.current.link.click();
			setDownloadReady(false);
			setHeaders(defaultHeaders);
		}
	}, [downloadReady, defaultHeaders]);

	const getData = async () => {
		// get all the custom field value of the devices
		const cFValueRes = await superFetch
			.get(`/deviceCategories/${category.categoryID}/devices/fields`)
			.then((res) => {
				if (res.statusCode === 200) {
					return res.data;
				} else {
					notification('error', 'Error exporting device CSV.');
					return [];
				}
			});

		// get all the custom fields of the category
		const cFHeaderRes = await superFetch.get(`/deviceCategories/${category.categoryID}/fields`).then((res) => {
			if (res.statusCode === 200) {
				return res.data;
			} else {
				notification('error', 'Error exporting device CSV.');
				return [];
			}
		});

		// Getting Custom fields Value Headers and aggregating them with default device Headers
		const cFHeader = cFHeaderRes.map((field) => {
			return { label: field.title, key: field.title };
		});
		// Only includes custom fields that is allowed to be exported for default categories.
		if (
			category.isDefault &&
			defaultCategory[category.defaultCategoryID]?.exportAbleDeviceCustomFields.length > 0
		) {
			setHeaders([
				...headers,
				...cFHeader.filter((val) =>
					defaultCategory[category.defaultCategoryID].exportAbleDeviceCustomFields.includes(val.label)
				)
			]);
		} else {
			setHeaders([...headers, ...cFHeader]);
		}

		const devicesFields = [];

		// Organising the Default fields
		devices.forEach((individualDevice) => {
			const deviceFields = {};
			// aggregating the device data
			Object.keys(individualDevice).forEach((key) => {
				deviceFields[key] = individualDevice[key];
			});
			// aggregating the custom fields data
			cFValueRes.forEach((individualFieldValue) => {
				if (individualFieldValue.deviceID === individualDevice.uID) {
					individualFieldValue.fields.forEach((eachField) => {
						deviceFields[eachField.title] = eachField.value;
					});
				}
			});
			devicesFields.push(deviceFields);
		});

		// aggregating the Location data
		devicesFields.forEach((individualDevice) => {
			if (individualDevice?.location?.length > 0) {
				const locations = individualDevice.location.map((item) =>
					floors.find((floor) => floor.floorID === item.floorID)
				);
				// Only getting the first location floor and site
				const sites = [];
				locations.forEach((site) => {
					if (!sites.includes(site.siteName)) sites.push(site.siteName);
				});

				const floorNames = [];
				locations.forEach((floor) => {
					if (!floorNames.includes(floor.name)) floorNames.push(floor.name);
				});
				individualDevice['site'] = sites.join(' - ');
				individualDevice['floor'] = floorNames.join(' - ');
			}
		});

		return devicesFields;
	};

	return (
		<React.Fragment>
			<div
				onClick={async () => {
					const newData = await getData();
					if (newData.length > 0) {
						setDownloadReady(newData);
					}
				}}
			>
				<p>Export Category</p>
			</div>
			{downloadReady ? (
				<CSVLink data={downloadReady} headers={headers} filename={`${category.name}.csv`} ref={csvInstance} />
			) : undefined}
		</React.Fragment>
	);
};
export default ExportDeviceCategoryCSVButton;
