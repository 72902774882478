import React, { useState, useEffect } from 'react';
import Button from '@iso/components/uielements/button';
import { Modal, LeafletMap, Loading } from '@Z/components/atoms';
import superFetch from '@iso/lib/helpers/superFetch';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from '@iso/components';
import devicesAction from '@iso/redux/devices/actions';
import basicStyle from '@iso/assets/styles/constants';
import { Empty, Tooltip, Col, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Dropdown, { DropdownMenu, MenuItem } from '@iso/components/uielements/dropdown';
import { getDownloadURL } from '@iso/lib/firebase/firebase.util';
import Alerts from '@iso/components/Feedback/Alert';
import FloorMapFormItem, { DeviceLocationWrapper } from './DeviceMapEditModal.styles';
import suggestions from '@iso/config/suggestions.config';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const { EditDeviceLocationTips } = suggestions;

const DeviceMapEditModal = ({ editVisible, setEditVisible, children, data }) => {
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const dispatch = useDispatch();
	const { loadDevices } = devicesAction;
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const MAP_HEIGHT = 460;
	const MAP_WIDTH = 800;

	// basic information for this modal.
	const { deviceID } = data;
	const [loading, setLoading] = useState(true);
	const { devicesData } = useSelector((state) => state.devices);
	const { floorsData } = useSelector((state) => state.floors);

	const [device, setDevice] = React.useState({});
	const [floors, setFloors] = React.useState([]);
	const [selectedFloor, setSelectedFloor] = useState({});
	const [mapUrl, setMapUrl] = useState('');
	const [markersData, setMarkersData] = useState([]);
	const [updateLocationLoading, setUpdateLocationLoading] = useState(false);
	const [updateDisable, setUpdateDisable] = useState(true);
	const [marker, setMarker] = useState({});

	// Get Device information from the store.
	useEffect(() => {
		if (devicesData.length > 0 && editVisible) {
			setDevice(devicesData.find((device) => device.uID === deviceID) || {});
		}
	}, [devicesData, deviceID, editVisible]);

	// Initially set floors information from the store.
	// Get Map Path / URL
	useEffect(() => {
		// Set all the floors in local state.
		setFloors(floorsData);

		// Setting default selected floor
		if (device.location && device.location.length > 0 && editVisible) {
			const deviceLocation = device.location[0];
			const floor = floorsData.find((floor) => floor.floorID === deviceLocation.floorID);
			if (floor) {
				setSelectedFloor(floor);
				getDownloadURL(floor.url).then((url) => setMapUrl(url));
				setMarkersData([
					{ x: deviceLocation.xAxis, y: deviceLocation.yAxis, defaultCategoryID: device?.defaultCategoryID }
				]);
			}
		} else {
			setSelectedFloor(floorsData[0] || {});
			setMarkersData([]);
		}
		setLoading(false);
	}, [floorsData, device, editVisible]);

	// changing the map based on the selected floor
	useEffect(() => {
		if (device.location) {
			// if the device has a location, showing that location on the map.
			const selectedLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (selectedLocation) {
				setMarkersData([
					{
						x: selectedLocation.xAxis,
						y: selectedLocation.yAxis,
						defaultCategoryID: device?.defaultCategoryID
					}
				]);
			} else {
				setMarkersData([]);
			}

			// Setting the map url based on the selected floor.
			if (selectedFloor.url && selectedFloor.url !== 'N/A') {
				getDownloadURL(selectedFloor.url)
					.then((url) => setMapUrl(url))
					.catch(() => setMapUrl(''));
			} else {
				setMapUrl('');
			}
		}
	}, [selectedFloor, device]);

	// changing marker location based on the selected floor
	useEffect(() => {
		if (Object.keys(selectedFloor).length > 0 && Object.keys(device).length > 0) {
			const selectedLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (selectedLocation) {
				setMarkersData([
					{
						x: selectedLocation.xAxis,
						y: selectedLocation.yAxis,
						defaultCategoryID: device?.defaultCategoryID
					}
				]);
			}
		}
	}, [device, selectedFloor]);

	// updating the disable state of the update button
	useEffect(() => {
		// properly set up  Disable based on the form values.
		if (editVisible && Object.keys(device).length > 0) {
			const deviceLocation = device.location.find((f) => f.floorID === selectedFloor.floorID);
			if (deviceLocation && marker.lng === deviceLocation.xAxis && marker.lat === deviceLocation.yAxis) {
				setUpdateDisable(true);
			} else if (!marker.lat && !marker.lng) {
				setUpdateDisable(true);
			} else {
				setUpdateDisable(false);
			}
		}
	}, [marker, selectedFloor, editVisible, device]);

	const renderFloors = () => (
		<DropdownMenu>
			{floors.map((floor) => {
				return (
					<MenuItem key={floor.id} onClick={() => setSelectedFloor(floor)}>
						{floor.name}
					</MenuItem>
				);
			})}
		</DropdownMenu>
	);

	const handleCancel = () => {
		setEditVisible(false);
		setUpdateLocationLoading(false);
	};

	const onFinish = () => {
		setUpdateLocationLoading(true);
		const locationBody = {};

		// prepare location body
		locationBody.floorID = selectedFloor.floorID;
		locationBody.mapID = selectedFloor.mapID;

		if (
			marker.lat > MAP_HEIGHT ||
			marker.lat < 0 ||
			marker.lng > MAP_WIDTH ||
			marker.lng < 0 ||
			!marker.lng ||
			!marker.lat ||
			selectedFloor.mapID === 'N/A'
		) {
			setUpdateLocationLoading(false);
			return notification('error', 'Please place marker within the map');
		}
		locationBody.xAxis = marker.lng;
		locationBody.yAxis = marker.lat;
		superFetch.patch(`/devices/${device.uID}`, locationBody).then((res) => {
			if (res.statusCode === 200) {
				notification('success', 'Device Location Updated.');
				dispatch(loadDevices());
			} else {
				notification('error', 'Device Location Update Failed.');
			}
			handleCancel();
		});
	};

	const isPermittedUpdateDevice = useManagePermittedRoles('updateDevice', {
		orgID: [orgID],
		siteID: [],
		floorID: []
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedUpdateDevice
						? "You don't have permission"
						: 'Move the Location of your Device in it’s Environment'
				}
				placement='rightBottom'
			>
				{children ? (
					children
				) : (
					<>
						<Button
							size={'small'}
							onClick={() => {
								setEditVisible(true);
							}}
							disabled={
								!isPermittedUpdateDevice &&
								!constants.SMART_CATEGORIES.includes(device?.defaultCategoryID)
							}
							style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Change Device Location
						</Button>
					</>
				)}
			</Tooltip>
			<Modal
				title='Edit Device Location'
				visible={editVisible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button disabled={updateDisable} onClick={onFinish} type='primary' loading={updateLocationLoading}>
						Update
					</Button>
				]}
			>
				<DeviceLocationWrapper>
					<div className='selectFloorContainer'>
						<div className='floorLabel'>Select Floor:</div>

						<Dropdown overlay={() => renderFloors()} trigger={['click']}>
							<Button size='medium'>
								{selectedFloor.name}
								<DownOutlined />
							</Button>
						</Dropdown>
					</div>
					<div className='locationInfoContainer'>
						<label className='infoActivityLabel'>Location:</label>
						<Tooltip title='Click and Drag the marker to the desired location.' placement='right'>
							<i className='material-icons-outlined customIcon'>help</i>
						</Tooltip>
					</div>
				</DeviceLocationWrapper>
				{loading ? (
					<Row style={rowStyle} gutter={0} justify='space-around'>
						<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
							<div style={loadingStyle}>
								<Loading />
							</div>
						</Col>
					</Row>
				) : (
					<FloorMapFormItem>
						<div
							style={{
								height: 300,
								margin: 'auto',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							{mapUrl !== '' ? (
								<LeafletMap
									mapHeight={MAP_HEIGHT}
									mapWidth={MAP_WIDTH}
									scrollWheelZoom={true}
									url={mapUrl} // map url if selected floor
									setMarker={setMarker}
									editMarkerLocation={markersData}
									draggableMarkerLocation={markersData.length > 0}
									drawSingleMarkerEnabled={markersData.length < 1}
									iconType={device.categoryName}
								/>
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										floors.length === 0 ? (
											<span>
												Please create a floor on the floors page and upload a floor plan
											</span>
										) : (
											<span>Please upload a floor plan from the floors page</span>
										)
									}
								/>
							)}
						</div>
					</FloorMapFormItem>
				)}

				<Alerts
					message='Editing Location Tips'
					description={<EditDeviceLocationTips />}
					type='info'
					showIcon
					closable
				/>
			</Modal>
		</div>
	);
};

export default DeviceMapEditModal;
