import React from 'react';
import { WidgetBoxStyle } from './Widgets.styles';

const WidgetBox = ({ children, style, height, padding }) => {
	return (
		<WidgetBoxStyle className='isoWidgetBox' height={height} padding={padding} style={style}>
			{children}
		</WidgetBoxStyle>
	);
};
export default WidgetBox;
