import styled from 'styled-components';
import { palette } from 'styled-theme';

const SiteNameWrapper = styled.p`
	font-size: 11px;
	font-weight: 600;
	margin-bottom: 10px;
	color: ${palette('text', 1)};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export default SiteNameWrapper;
