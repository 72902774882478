import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

const FloorSummaryWrapper = styled.div`
	.floorName {
	}

	.deleteButton {
		&:hover {
			border-color: ${palette('error', 2)};
			color: ${palette('error', 2)};
		}
	}

	.actionButton {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;

		.ant-btn {
			margin-right: 10px;

			&:last-child {
				margin-right: 0px;
			}
		}
		.switchFloorButton {
			margin-right: 5px;

			@media only screen and (max-width: 1600px) {
				margin-right: 0px;
				display: none;
			}
		}
		.deletedTag {
			height: 36px;
			line-height: 36px;
			font-size: 12px;
			font-weight: 500;
			${borderRadius('4px')};
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.fDescription {
		font-size: 14px;
		font-weight: 300;
		margin: 0px 5px;
		padding: 0px 5px;
		border: 1px solid ${palette('border', 1)};
		${borderRadius('6px')};
	}
	.suppInformationContainer {
		padding-top: 10px;

		.contactContainer {
			.infoRowContainer {
				margin-left: 8px;

				.infoLabel {
					margin-right: 10px;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0.016em;
					color: ${palette('text', 3)};
					margin-bottom: 10px;
				}
				.infoValue {
					font-size: 15px;
				}
				p {
					margin-left: 10px;
				}
			}
		}
		.addressContainer {
			.infoRowContainer {
				margin-left: 8px;

				.infoLabel {
					margin-right: 10px;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0.016em;
					color: ${palette('text', 3)};
					margin-bottom: 10px;
				}
				.infoValue {
					font-size: 15px;
				}
				p {
					margin-left: 10px;
				}
			}
		}
	}
	.metaInfo {
		margin-left: 8px;
		.infoLabel {
			letter-spacing: 0.016em;
			color: ${palette('text', 3)};
			font-size: 15px;
			font-weight: 500;
		}
	}
`;

export default FloorSummaryWrapper;
