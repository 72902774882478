/**
 * @author Kishan Virani
 * @email Kishan@digitaltechexec.com
 * @create date 24-04-2020  10:14:12
 * @modify date 25-02-2022  16:36:58
 * @desc [description]
 */

import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Loading, PageHeader, EnrolPersonButton } from '@Z/components/atoms';
import basicStyle from '@iso/assets/styles/constants';
import ContentHolder from '@iso/components/utility/contentHolder';
import { useSelector } from 'react-redux';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';

const PeopleHeader = () => {
	const [loadings, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const { peopleData, pending } = useSelector((state) => state.people);
	const { orgID } = useSelector((state) => state.Auth.userProfile.defaultProfile);
	useEffect(() => {
		setLoading(true);
		if (!pending) {
			setData(peopleData);
			setLoading(false);
		}
	}, [peopleData, pending]);
	const { rowStyle, colStyle, loadingStyle } = basicStyle;
	const deletedNumber = peopleData.filter((person) => person.deletedDate).length;
	const isPermittedAddPerson = useManagePermittedRoles('addPerson', {
		orgID: [orgID],
		siteID: [],
		floorID: [],
		teamID: []
	});

	return loadings ? (
		<Row style={rowStyle} gutter={0} justify='space-around'>
			<Col lg={24} md={24} sm={24} xs={24} style={colStyle}>
				<ContentHolder>
					<div style={loadingStyle}>
						<Loading />
					</div>
				</ContentHolder>
			</Col>
		</Row>
	) : (
		<PageHeader title='People' counter={deletedNumber < 1 ? data.length : data.length - deletedNumber}>
			{isPermittedAddPerson && (
				<Row gutter={0} justify='end'>
					<EnrolPersonButton />
				</Row>
			)}
		</PageHeader>
	);
};

export default PeopleHeader;
