import {
	LabelWrapper,
	ProfileImageComponent,
	RenderButtonCard,
	RenderPeopleActions,
	UserStatusAlert
} from '@Z/components/atoms';
import { IndividualDeviceInformationModal } from '@Z/components/organisms';
import { ReactComponent as lockIcon } from '@iso/assets/images/icon/SmartLock_Icon.svg';
import { getAccessData } from '@iso/lib/helpers/accessControl';
import { deviceGrouper } from '@iso/lib/helpers/deviceGrouper';
import WithHyperLink from '@iso/lib/hoc/withHyperLink';
import { getUserStatus, useGetSiteName } from '@iso/lib/hooks/useNamesFromEntities';
import { Collapse, Divider, Row, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProfilePreviewWrapper } from './styles';

const { Panel } = Collapse;

const ProfilePreview = ({ person }) => {
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);
	const { bookingData } = useSelector((state) => state.booking);

	const [permittedDevices, setPermittedDevices] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [showAllAccess, setShowAllAccess] = useState(false);
	const [showAllContacts, setShowAllContacts] = useState(false);
	const [showAllAddress, setShowAllAddress] = useState(false);
	const [showAllTeams, setShowAllTeams] = useState(false);
	const [showAllBookings, setShowAllBookings] = useState(false);
	const [deviceModalVisible, setDeviceModalVisible] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState({});

	const maxItemsToShow = 4;

	useEffect(() => {
		setShowAllAccess(false);
		setShowAllContacts(false);
		setShowAllAddress(false);
		setShowAllTeams(false);
		setShowAllBookings(false);
	}, [person.personID]);

	useEffect(() => {
		const getPermittedDevices = async () => {
			const permittedDevices = await getAccessData({ personID: person.personID });
			setPermittedDevices(deviceGrouper(permittedDevices, constants));
		};
		getPermittedDevices();
	}, [person.personID, constants]);

	useEffect(() => {
		const getBookings = async () => {
			const bookings = bookingData.filter((booking) => booking.person.personID === person.personID);
			setBookings(bookings);
		};
		getBookings();
	}, [bookingData, person.personID]);

	const smallTimeOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
	const siteName = useGetSiteName(person.siteID);
	const userStatus = getUserStatus(person);

	const ViewAllLabel = ({ showAll, onClick }) => {
		return (
			<LabelWrapper
				text={!showAll ? 'View More' : 'View Less'}
				fontWeight={500}
				color='secondary'
				tonality={0}
				style={{
					marginTop: '5px',
					cursor: 'pointer'
				}}
				fontSize={12}
				typeLabel='secondary'
				onClick={onClick}
			/>
		);
	};

	const EntityWithLink = WithHyperLink((a) => {
		return <div>{a.children}</div>;
	});

	const handleSelectDevice = (device) => {
		setSelectedDevice(device);
		setDeviceModalVisible(true);
	};

	return (
		<ProfilePreviewWrapper style={{ border: `3px solid ${userStatus[1]}` }}>
			{userStatus[2] === 'info' && <UserStatusAlert userStatus={userStatus} />}
			{userStatus[2] === 'error' && <UserStatusAlert userStatus={userStatus} />}
			{userStatus[2] === 'disabled' && <UserStatusAlert userStatus={userStatus} />}

			<div className='imageContainer'>
				<ProfileImageComponent entityObject={person} size={150} shape={'circle'} entityName={'People'} />
			</div>
			<div className='titleContainer'>
				<EntityWithLink type='person' dataID={person.personID}>
					{person.displayName ? <h1 className='name'>{person.displayName}</h1> : 'N/A'}
				</EntityWithLink>
				{person.userType ? <h1 className='userType'>{person.userType.name}</h1> : 'N/A'}
			</div>
			<div className='infoContainer'>
				<EntityWithLink type='person' dataID={person.personID}>
					{person.email && <h1>{person.email.charAt(0).toUpperCase() + person.email.slice(1)}</h1>}
				</EntityWithLink>
				{person.siteID && (
					<EntityWithLink type='site' dataID={person.siteID}>
						<Divider className='divider' type='vertical' />
						<h1>{siteName}</h1>
					</EntityWithLink>
				)}
			</div>
			<div className='actionContainer'>
				{person.deletedDate ? (
					<Tooltip
						key={person.personID}
						title={new Date(person.deletedDate).toLocaleString('en-AU', smallTimeOptions)}
						placement='top'
						color='red'
					>
						<Tag
							color='red'
							style={{
								height: '34px',
								lineHeight: '34px',
								fontSize: '12px',
								fontWeight: '500',
								borderRadius: '4px'
							}}
						>
							DELETED
						</Tag>
					</Tooltip>
				) : (
					<RenderPeopleActions
						data={person}
						placement={'bottom'}
						size={'medium'}
						shape={'circle'}
						showIcon={false}
					/>
				)}
			</div>
			<Collapse
				expandIconPosition='right'
				defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8]}
				className='extraInfoCollapse'
				ghost
			>
				{person.contact?.length > 0 && (
					<Panel header='Contact' key='1'>
						<Row className='containerCard'>
							{person.contact
								.slice(0, showAllContacts ? person.contact.length : maxItemsToShow)
								.map((contact, index) => (
									<RenderButtonCard
										key={index}
										title={contact.contactType}
										subtitle={contact.contactInformation}
										icon
										getIconByTitle
										border={false}
										maxWidth
									/>
								))}
						</Row>
						{person.contact.length > maxItemsToShow && (
							<Row justify='end'>
								<ViewAllLabel
									showAll={showAllContacts}
									onClick={() => setShowAllContacts(!showAllContacts)}
								/>
							</Row>
						)}
					</Panel>
				)}
				{person.address?.length > 0 && (
					<Panel header='Address' key='2'>
						<Row className='containerCard'>
							{person.address
								.slice(0, showAllAddress ? person.address.length : maxItemsToShow)
								.map((address, index) => (
									<RenderButtonCard
										key={index}
										title='Address'
										subtitle={address.fullAddress}
										icon
										getIconByTitle
										border={false}
									/>
								))}
						</Row>
						{person.address.length > maxItemsToShow && (
							<Row justify='end'>
								<ViewAllLabel
									showAll={showAllAddress}
									onClick={() => setShowAllAddress(!showAllAddress)}
								/>
							</Row>
						)}
					</Panel>
				)}
				{person.team?.length > 0 && (
					<Panel header='Teams' key='3'>
						<Row className='containerCard'>
							{person.team.slice(0, showAllTeams ? person.team.length : maxItemsToShow).map((team) => (
								<EntityWithLink type='team' dataID={team.teamID} key={team.teamID}>
									<RenderButtonCard
										title={team.name}
										icon
										iconName='group'
										iconSize={15}
										getIconByTitle={false}
										border
										padding
									/>
								</EntityWithLink>
							))}
						</Row>
						{person.team.length > maxItemsToShow && (
							<Row justify='end'>
								<ViewAllLabel showAll={showAllTeams} onClick={() => setShowAllTeams(!showAllTeams)} />
							</Row>
						)}
					</Panel>
				)}
				{permittedDevices?.length > 0 && (
					<Panel header='Access' key='4'>
						<Row className='containerCard'>
							{permittedDevices
								.slice(0, showAllAccess ? permittedDevices.length : maxItemsToShow)
								.map((permission, index) => (
									<RenderButtonCard
										key={permission.uID}
										subtitle={permission.name}
										title={permission.permissions[0].entityTypeID === 6 ? 'Device ' : 'Group '}
										icon={false}
										getIconByTitle={false}
										iconName='key'
										border={false}
										maxWidth
										customIcon={lockIcon}
										onClick={() => handleSelectDevice(permission)}
									/>
								))}
						</Row>
						{permittedDevices.length > maxItemsToShow && (
							<Row justify='end'>
								<ViewAllLabel
									showAll={showAllAccess}
									onClick={() => setShowAllAccess(!showAllAccess)}
								/>
							</Row>
						)}
					</Panel>
				)}
				{bookings?.length > 0 && (
					<Panel header='Bookings' key='5'>
						<Row className='containerCard'>
							{bookings.slice(0, showAllBookings ? bookings.length : maxItemsToShow).map((booking) => (
								<RenderButtonCard
									key={booking.uID}
									header={`${booking.device.name} (${booking.bookingType.name})`}
									title='From'
									subtitle={moment(booking.startTime).format('ddd, DD MMM')}
									secondTitle='Until'
									secondSubTitle={moment(booking.startTime).format('ddd, DD MMM')}
									icon={false}
									iconName='chevron_right'
									getIconByTitle={false}
									border
									maxWidth
								/>
							))}
						</Row>
						{bookings.length > maxItemsToShow && (
							<Row justify='end'>
								<ViewAllLabel
									showAll={showAllBookings}
									onClick={() => setShowAllBookings(!showAllBookings)}
								/>
							</Row>
						)}
					</Panel>
				)}
			</Collapse>
			<IndividualDeviceInformationModal
				visible={deviceModalVisible}
				setVisible={setDeviceModalVisible}
				data={{
					deviceID: Number(selectedDevice.uID)
				}}
			/>
		</ProfilePreviewWrapper>
	);
};

export default ProfilePreview;
