import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius } from '@iso/lib/helpers/style_utils';

import { MoreOutlined } from '@ant-design/icons';
const SearchDeviceCardWrapper = styled.div`
	margin: 0px 10px;
	border-bottom: 1px solid ${palette('border', 0)};
	.textHeader {
		font-size: 14px;

		margin-right: 5px;
		color: ${palette('secondary', 0)};
	}
	.textValue {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	.cardContainer {
		display: flex;
		align-items: baseline;
		justify-content: space-between;

		.infoContainer {
			flex-direction: column;
			width: 75%;
			display: flex;
			align-items: flex-start;
		}
		.ant-typography {
			margin-bottom: 0px;
		}
	}

	.ant-card-hoverable {
		cursor: default;
	}

	.ant-card {
		border: none;
		.ant-card-body {
			padding: 5px;
		}
	}

	.hoverCard:hover {
		border-color: ${palette('secondary', 0)};
	}
	.ant-card-bordered {
		border-color: ${palette('grayscale', 2)};
		${borderRadius()};
	}

	.ant-card-head {
		min-height: 20px !important;

		height: 25px !important;
		padding: 5px !important;
		border-bottom: none !important;

		.ant-card-head-title {
			padding: 0px !important;
			font-size: 16px;
			color: ${palette('secondary', 0)};

			.titleText {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`;

const MoreOutlinedIcon = styled(MoreOutlined)`
	&:hover {
		cursor: pointer;
	}
`;

export { MoreOutlinedIcon };
export default SearchDeviceCardWrapper;
