import { EditableField } from '@Z/components/atoms';
import { boolType, smallTimeOptions } from '@iso/assets/styles/constants';
import { notification } from '@iso/components';
import { Button } from '@iso/components/uielements';
import useManagePermittedRoles from '@iso/lib/hooks/useManagePermittedRoles';
import superFetch from '@iso/lib/helpers/superFetch';
import { CustomFieldValidationsByRegex as customFieldValidation } from '@iso/lib/helpers/validators/fieldsValidator';
import devicesAction from '@iso/redux/devices/actions';
import { Descriptions, Empty, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeviceEditModal from '../DeviceEditModal';
import DeviceCustomFieldsWrapper, { DescriptionsWrapper } from './DeviceCustomFields.styles';

const { loadDevices } = devicesAction;

const IndividualDeviceCustomFields = ({ data }) => {
	const dispatch = useDispatch();
	const { deviceID, categoryID, orgID } = data;
	const { devicesData } = useSelector((state) => state.devices);
	const [customValues, setCustomValues] = React.useState([]);
	const [customValuesToShow, setCustomValuesToShow] = React.useState([]);
	const [editable, setEditable] = React.useState(true);
	const [device, setDevice] = useState({});
	const [updateVisible, setUpdateVisible] = useState(false);
	const {
		remoteConfig: { constants }
	} = useSelector((state) => state.remoteConfig);

	const getCustomFields = useCallback(() => {
		superFetch.get(`/devices/${deviceID}/fields`).then((res) => {
			if (res.statusCode === 200) {
				setCustomValues(res.data);
			} else notification('error', 'There is an Error! We are mending the problem, try again soon!.');
		});
	}, [deviceID]);

	// get Devices data from the store. (this is the data that is used in the modal)
	useEffect(() => {
		if (devicesData.length > 0) {
			const currentDevice = devicesData.find((device) => device.uID === deviceID);
			setDevice(currentDevice || {});
			if (constants.SMART_CATEGORIES.includes(currentDevice?.defaultCategoryID)) {
				setEditable(false);
			} else {
				setEditable(true);
			}
		}
		if (deviceID) {
			getCustomFields();
		}
	}, [devicesData, deviceID, getCustomFields, constants.SMART_CATEGORIES]);

	// Updating the custom values in order to show based on the config.
	useEffect(() => {
		if (device.isDefault && device?.showOnAdditionalFields?.length > 0) {
			const fieldsToShow = customValues.filter((value) =>
				device.showOnAdditionalFields.includes(value.fieldTitle)
			);
			setCustomValuesToShow(fieldsToShow);
		} else if (device.isDefault && device?.showOnAdditionalFields?.length === 0) {
			setCustomValuesToShow(customValues);
		} else {
			setCustomValuesToShow(customValues);
		}
	}, [customValues, device]);

	const updateFieldValue = (fieldID, value) => {
		const field = customValues.find((field) => field.fieldID === fieldID);

		// check if the field value is same as the original one
		switch (field.fieldTypeName) {
			case 'Boolean':
				if (parseInt(field.value) === value) {
					return;
				}
				break;
			case 'Date':
				if (value.isSame(field.value, 'days')) {
					return;
				}
				break;
			default:
				if (field.value === value) {
					return;
				}
				break;
		}

		if (field.fieldTypeID in customFieldValidation) {
			const { valid, message } = customFieldValidation[field.fieldTypeID];

			// Value manipulation based on the field type. (for example: if the field type is date, the value should be in isoString format)
			let val;
			switch (field.fieldTypeID) {
				case 3:
					val = value.toISOString();
					break;
				case 4:
					val = Boolean(value);
					break;
				default:
					val = value.toString().trim();
					break;
			}

			// check if the value is valid or not.
			if (val === '' || !val.toString().match(valid)) {
				notification('error', message);
				return;
			}
		}

		superFetch.post(`/devices/${deviceID}/fields`, [{ fieldID, value: value.toString().trim() }]).then((res) => {
			if (res.statusCode === 200) {
				getCustomFields();
				dispatch(loadDevices());
				notification('success', `Updated successfully`);
			} else {
				notification('error', `Update failed! Please check value is valid.`);
			}
		});
	};

	const deleteFieldValue = (fieldID) => {
		superFetch.delete(`/devices/${deviceID}/fields`, [{ fieldID }]).then((res) => {
			if (res.statusCode === 200) {
				getCustomFields();
				dispatch(loadDevices());
				notification('success', `Deleted successfully`);
			}
		});
	};

	const isPermittedUpdateGroup = useManagePermittedRoles('updateGroup', {
		orgID: [data.orgID],
		siteID: [data.siteID],
		floorID: []
	});

	return customValuesToShow.length > 0 ? (
		<DeviceCustomFieldsWrapper>
			<DescriptionsWrapper>
				<Descriptions column={3}>
					{customValuesToShow.map((values, key) => (
						<Descriptions.Item key={key} label={<span title={values.fieldTitle}>{values.fieldTitle}</span>}>
							{editable ? (
								<EditableField
									editable
									handleSave={updateFieldValue}
									handleDelete={deleteFieldValue}
									title={values.fieldTitle}
									value={values.value}
									fieldID={values.fieldID}
									fieldTypeName={values.fieldTypeName}
									style={{ flexGrow: '100' }}
								>
									{values.fieldTypeName === 'Date' ? (
										<p className='infoProp editable'>
											{new Date(values.value).toLocaleString('en-AU', smallTimeOptions)}
										</p>
									) : values.fieldTypeName === 'Boolean' ? (
										<p className='infoProp editable'>{boolType[values.value]}</p>
									) : values.fieldTypeName === 'Number' ? (
										<p className='infoProp editable'>
											{values.value !== 'null' ? values.value : 'N/A'}
										</p>
									) : values.fieldTypeName === 'String' ? (
										<p className='infoProp editable'>
											{values.value !== 'null' ? values.value : 'N/A'}
										</p>
									) : (
										<p className='infoProp editable'>
											{values.value !== 'null' ? values.value : 'N/A'}
										</p>
									)}
								</EditableField>
							) : (
								<div title='Non-editable fields' className='propValueContainer'>
									{values.fieldTypeName === 'Date' ? (
										<p className='infoProp'>
											{new Date(values.value).toLocaleString('en-AU', smallTimeOptions)}
										</p>
									) : values.fieldTypeName === 'Boolean' ? (
										<p className='infoProp'>{boolType[values.value]}</p>
									) : values.fieldTypeName === 'Number' ? (
										<p className='infoProp'>{values.value !== 'null' ? values.value : 'N/A'}</p>
									) : values.fieldTypeName === 'String' ? (
										<p className='infoProp'>{values.value !== 'null' ? values.value : 'N/A'}</p>
									) : (
										<p className='infoProp'>{values.value !== 'null' ? values.value : 'N/A'}</p>
									)}
								</div>
							)}
						</Descriptions.Item>
					))}
				</Descriptions>
			</DescriptionsWrapper>
		</DeviceCustomFieldsWrapper>
	) : (
		<DeviceCustomFieldsWrapper>
			<DescriptionsWrapper>
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={
						<Tooltip
							title={
								!isPermittedUpdateGroup
									? "You don't have permission"
									: 'Update Information for this Device’s Additional Fields'
							}
							placement='rightBottom'
						>
							<>
								<Button
									disabled={!isPermittedUpdateGroup}
									type='primary'
									onClick={() => {
										setUpdateVisible(true);
									}}
								>
									Modify Additional Fields
								</Button>
							</>
						</Tooltip>
					}
				/>
				<DeviceEditModal
					editVisible={updateVisible}
					setEditVisible={setUpdateVisible}
					data={{
						orgID: orgID,
						deviceID: deviceID,
						categoryID: categoryID
					}}
				>
					<div />
				</DeviceEditModal>
			</DescriptionsWrapper>
		</DeviceCustomFieldsWrapper>
	);
};

export default IndividualDeviceCustomFields;
